<template>
  <div class="user-editor">
    <EditorHeader>
      <template slot="header">
        <span>
          {{ fullName }}
        </span>
        <span class="edit-text"> . {{ translations.editTitle }} </span>
      </template>

      <template slot="buttons">
        <HeaderIconButton iconName="fa-times" test-id="userEditor.closeButton" @clicked="closeButtonClicked" />
      </template>
    </EditorHeader>

    <EditorContent>
      <TextInput
        class="mb-2"
        :label="translations.email"
        :value.sync="email"
        :labelWidth="labelWidth"
        :invalid="emailValidationFailed"
        :disabled="isLoading"
        placeholder="Enter user email..."
        test-id="userEditor.emailInput"
      />

      <TextInput
        class="mb-2"
        :label="translations.firstName"
        :value.sync="firstName"
        :labelWidth="labelWidth"
        :invalid="firstNameValidationFailed"
        :disabled="isLoading"
        placeholder="Enter user first name..."
        test-id="userEditor.firstNameInput"
      />

      <TextInput
        class="mb-2"
        :label="translations.secondName"
        :value.sync="secondName"
        :labelWidth="labelWidth"
        :invalid="secondNameValidationFailed"
        :disabled="isLoading"
        placeholder="Enter user second name..."
        test-id="userEditor.secondNameInput"
      />

      <TextInput
        :label="translations.phone"
        :value.sync="phone"
        :labelWidth="labelWidth"
        :invalid="phoneValidationFailed"
        :disabled="isLoading"
        placeholder="Enter user phone..."
        test-id="userEditor.phoneInput"
      />

      <div v-if="isSuperAdmin" :class="['roles-container mt-2', isLoading ? 'disabled' : '']">
        <div class="label mr-2" :style="{ 'min-width': labelWidth + 'px' }">
          <span>Roles</span>
        </div>
        <div class="roles">
          <div v-for="role in userRoles" :key="role.role.Id.toString()" class="role">
            <input v-model="role.checked" class="mr-2" type="checkbox" data-testid="userEditor.checkboxRole" />
            <span>{{ role.caption }}</span>
          </div>
        </div>
      </div>

      <fade-transition :maxHeight="28">
        <div v-if="validationErrors.length != 0" class="errors-container">
          <span class="mt-2">{{ validationErrors[0] }}</span>
        </div>
      </fade-transition>

      <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
    </EditorContent>

    <EditorFooter>
      <template slot="buttons">
        <IconButton
          class="mr-3"
          :disabled="isLoading || !hasChanges"
          iconName="fa-check"
          :text="translations.applyButton"
          test-id="userEditor.applyButton"
          @clicked="applyButtonClicked"
        />
        <IconButton
          class="mr-3"
          :disabled="isLoading"
          iconName="fa-sync-alt"
          :text="translations.resetButton"
          test-id="userEditor.resetButton"
          @clicked="resetButtonClicked"
        />
        <IconButton
          :disabled="isLoading"
          iconName="fa-times"
          :iconSize="15"
          :text="translations.cancelButton"
          test-id="userEditor.cancelButton"
          @clicked="closeButtonClicked"
        />
      </template>
    </EditorFooter>
  </div>
</template>

<script lang="ts">
import en from '@/localization/en';
import { Role, User } from '@/models/Entities';
import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator';
import FadeTransition from '../presentation/FadeTransition.vue';
import * as EmailValidator from 'email-validator';
import { UserModule } from '@/store/modules/userModule';
import { RequestStatus } from '@/models/enums/RequestStatus';
import IconButton from '../buttons/IconButton.vue';
import EditorFooter from '@/components/forms/base/EditorFooter.vue';
import EditorHeader from '@/components/forms/base/EditorHeader.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import EditorContent from '@/components/forms/base/EditorContent.vue';
import { RoleModule } from '@/store/modules/roleModule';
import { LoginModule } from '@/store/modules/loginModule';
import ComponentHelper from '@/util/ComponentHelper';
import HeaderIconButton from '@/components/buttons/HeaderIconButton.vue';

type UserRole = {
  role: Role;
  caption: string;
  checked: boolean;
};

@Component({
  name: 'user-editor',
  components: {
    HeaderIconButton,
    FadeTransition: FadeTransition,
    IconButton: IconButton,
    EditorHeader: EditorHeader,
    EditorContent: EditorContent,
    EditorFooter: EditorFooter,
    TextInput: TextInput,
  },
})
export default class UserEditor extends Vue {
  labelWidth: number = 125;

  @Prop()
  private user!: User;

  @Watch('user', { immediate: true, deep: true })
  OnUserChanged(newUser: User) {
    if (!this.isLoading) {
      this.setStateFromUser(newUser);
    }
  }

  private validationTimeoutHandle: number = -1;
  private emailValidationFailed: boolean = false;
  private firstNameValidationFailed: boolean = false;
  private secondNameValidationFailed: boolean = false;
  private phoneValidationFailed: boolean = false;
  private validationErrors: string[] = [];

  private email = '';
  private firstName = '';
  private secondName = '';
  private phone = '';
  private userRoles: UserRole[] = [];

  private isLoading = false;

  private get fullName() {
    return this.user.FirstName + ' ' + this.user.SecondName;
  }

  private get hasInfoChanges() {
    return (
      this.user.FirstName != this.firstName || this.user.SecondName != this.secondName || this.phone != this.user.Phone
    );
  }

  private get hasEmailChanges() {
    return this.user.Email != this.email;
  }

  private get hasChanges() {
    return (
      this.hasInfoChanges || this.hasEmailChanges || this.userRolesToRemove.length > 0 || this.userRolesToAdd.length > 0
    );
  }

  private get userRolesToRemove() {
    const rolesToRemove: Role[] = [];
    for (const data of this.userRoles) {
      const userRole = this.user.Roles.find(userRole => userRole.Id === data.role.Id);
      if (!data.checked && userRole) {
        rolesToRemove.push(data.role);
      }
    }

    return rolesToRemove;
  }

  private get userRolesToAdd() {
    const rolesToAdd: Role[] = [];
    for (const data of this.userRoles) {
      const userRole = this.user.Roles.find(userRole => userRole.Id === data.role.Id);
      if (data.checked && !userRole) {
        rolesToAdd.push(data.role);
      }
    }

    return rolesToAdd;
  }

  private get isSuperAdmin() {
    return LoginModule.IsSuperAdmin;
  }

  private resetButtonClicked() {
    this.setStateFromUser(this.user);
  }

  private setStateFromUser(user: User) {
    this.email = user.Email;
    this.firstName = user.FirstName;
    this.secondName = user.SecondName;
    this.phone = user.Phone;
    this.userRoles = [];
    const excludedRoles = ['User', 'BillingAccountOwner', 'BillingAccountAdmin'];

    for (const role of RoleModule.Roles) {
      if (excludedRoles.includes(role.Name)) {
        continue;
      }

      if (role.IsSuperAdmin || role.IsBillingAccountAdmin || role.IsBillingAccountOwner) {
        if (!LoginModule.IsSuperAdmin) {
          continue;
        }
      }

      if (role.IsAdmin) {
        if (
          !(LoginModule.IsSuperAdmin || (LoginModule.IsAdmin && this.user.CompanyId.equals(LoginModule.CompanyId!)))
        ) {
          continue;
        }
      }

      this.userRoles.push({
        role: role,
        caption: ComponentHelper.GetReadableRoleName(role),
        checked: this.user.Roles.includes(role),
      });
    }
  }

  private clearValidationErrors() {
    if (this.validationTimeoutHandle !== -1) {
      clearTimeout(this.validationTimeoutHandle);
    }

    this.validationErrors = [];
    this.emailValidationFailed = false;
    this.firstNameValidationFailed = false;
    this.secondNameValidationFailed = false;
    this.phoneValidationFailed = false;
    this.validationTimeoutHandle = -1;
  }

  private validate(): boolean {
    this.clearValidationErrors();

    // Validate
    if (this.email == null || this.email.length == 0) {
      this.validationErrors.push('Enter user email');
      this.emailValidationFailed = true;
    } else if (this.firstName == null || this.firstName.length == 0) {
      this.validationErrors.push('Enter user first name');
      this.firstNameValidationFailed = true;
    } else if (this.secondName == null || this.secondName.length == 0) {
      this.validationErrors.push('Enter user second name');
      this.secondNameValidationFailed = true;
    } else if (!EmailValidator.validate(this.email)) {
      this.validationErrors.push('Enter correct user email');
      this.emailValidationFailed = true;
    }

    const failed = this.validationErrors.length != 0;

    if (failed) {
      this.validationTimeoutHandle = window.setTimeout(() => {
        this.clearValidationErrors();
      }, 3500);
    }

    return !failed;
  }

  @Emit('close-clicked')
  private closeButtonClicked() {}

  @Emit('apply-clicked')
  private async applyButtonClicked() {
    if (!this.validate()) {
      return null;
    }

    this.isLoading = true;

    if (this.hasInfoChanges) {
      const result = await UserModule.ChangeInfo([this.user.Id, this.firstName, this.secondName, this.phone]);
      if (result[0] !== RequestStatus.OK) {
        this.isLoading = false;
        return false;
      }
    }

    if (this.hasEmailChanges) {
      const result = await UserModule.ChangeEmail([this.user.Id, this.email]);
      if (result[0] !== RequestStatus.OK) {
        this.isLoading = false;
        return false;
      }
    }

    for (const role of this.userRolesToAdd) {
      const [result] = await UserModule.AddRole([this.user, role]);
      if (result !== RequestStatus.OK) {
        this.isLoading = false;
        return false;
      }
    }

    for (let role of this.userRolesToRemove) {
      const [result] = await UserModule.DeleteRole([this.user, role]);
      if (result !== RequestStatus.OK) {
        this.isLoading = false;
        return false;
      }
    }

    this.isLoading = false;

    return true;
  }

  get translations() {
    return {
      email: en.email.growFirst(),
      firstName: en.firstName.growFirst(),
      secondName: en.secondName.growFirst(),
      phone: en.phone.growFirst(),
      applyButton: en.apply.titleCase(),
      resetButton: en.reset.titleCase(),
      cancelButton: en.cancel.titleCase(),
      editTitle: en.edit.toUpperCase(),
    };
  }
}
</script>

<style lang="scss" scoped>
.user-editor {
  border-radius: 6px;
  background: var(--editor-background);

  .edit-text {
    color: #f63e00;
  }

  .user-name {
    color: var(--editor-field-link);
  }

  .errors-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      color: var(--editor-error-text);
      font-size: 14px;
    }
  }

  .roles-container {
    display: flex;
    color: var(--editor-field-name);
    font-size: 14px;

    .label {
      display: flex;
    }

    .roles {
      display: flex;
      flex-direction: column;

      .role {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
