import store from '@/store';
import { HubConnection } from '@microsoft/signalr';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import * as ips from '@/util/api/ips';
import * as routes from '@/util/api/routes';
import * as hubActions from '@/util/api/hubActions';
import { TypeHelper } from '@/util/TypeHelper';
import { UtilModule } from '../utilModule';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { Product, ProductPrice, PurchasedItem } from '@/models/Entities';
import { RequestReadAllProduct } from '@/models/requests/RequestsBilling';
import { ResponseProduct, ResponseProducts } from '@/models/responses/ResponseBilling';
import { ProductPriceModule } from './productPriceModule';
import { LoginModule } from '../loginModule';
import { HubConnectionModule } from '@/store/modules/hubConnectionModule';

export interface ProductFull {
  product: Product;
  prices: ProductPrice[];
  baseProduct: Product | null;
}

@Module({ dynamic: true, name: 'productModule', store: store })
export default class productModule extends VuexModule {
  private products: Product[] = [];

  get Products(): Product[] {
    let result = this.products;

    if (!LoginModule.IsSuperAdmin) {
      result = result.filter(a => a.isPublic);
    }

    return result;
  }

  get ProductsFull(): ProductFull[] {
    const result: ProductFull[] = [];

    if (this.Products === undefined) {
      return [];
    }

    for (const product of this.Products) {
      const prices = ProductPriceModule.ProductPrices.filter(a => a.productId === product.id);

      const baseProduct = this.Products.firstOrDefault(a => a.id.toString() === product.baseProductId?.toString());

      const productFull: ProductFull = {
        product: product,
        prices: prices,
        baseProduct: baseProduct,
      };

      result.push(productFull);
    }

    return result;
  }

  @Mutation
  AddProduct(val: Product) {
    this.products.push(val);
  }

  @Mutation
  UpdateProduct(val: Product) {
    this.products = this.products.map(prod => {
      if (val.id.toString() === prod.id.toString()) {
        prod.description = val.description;
        prod.isPublic = val.isPublic;
        prod.name = val.name;
        prod.outdated = val.outdated;
      }

      return prod;
    });
  }

  @Mutation
  ClearProducts() {
    this.products = [];
  }

  @Action({ rawError: true })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ProductSubscribe(hubConnection: HubConnection) {}

  @Action({ rawError: true })
  async LoadProducts() {
    this.ClearProducts();

    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.PRODUCT,
      routes.UriProduct.READ_ALL,
    );

    const request = new RequestReadAllProduct();

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = axiosResponse.data.response as ResponseProducts;
      const products = TypeHelper.DeepCopyFrom(response, ResponseProducts).products;

      for (const res of products) {
        const product = TypeHelper.DeepCopyFrom(res, ResponseProduct);
        this.AddProduct(product.Map());
      }
    }
  }

  @Action({ rawError: true })
  async AddToProductGroup() {
    if (HubConnectionModule.BillingConnection == null) return;
    await HubConnectionModule.BillingConnection.send(hubActions.ADD_TO_PRODUCT_GROUP);
    HubConnectionModule.AddBillingConnectionGroup({
      func: this.AddToProductGroup,
    });
  }

  @Action({ rawError: true })
  async DeleteFromProductGroup() {
    if (HubConnectionModule.BillingConnection == null) return;
    await HubConnectionModule.BillingConnection.send(hubActions.DELETE_FROM_PRODUCT_GROUP);
    HubConnectionModule.DeleteBillingConnectionGroup({
      func: this.AddToProductGroup,
    });
  }
}

export class ProductModuleHelper {
  static ProductForPurchasedItem(value: PurchasedItem): Product | null {
    const price = ProductPriceModule.ProductPrices.firstOrDefault(a => a.id === value.productPriceId);

    if (price === null || ProductModule.Products == undefined) {
      return null;
    }

    const product = ProductModule.Products.firstOrDefault(a => a.id === price!.productId);

    return product;
  }
}

export const ProductModule = getModule(productModule);
