import { Countries } from '../responses/Countries';
import { SortMode } from '@/models/requests/Datas';
import { IPlainResponse } from '@/models/responses/BaseResponse';

export enum UserSortBy {
  BY_EMAIL,
  BY_FULL_NAME,
  BY_PRINTS,
  BY_LAST_SEEN,
}

export class RequestDeleteLoginSession {
  idLoginSession!: string;
}

export class RequestDeleteLoginSessions {
  ids!: string[];
}

export type RequestLogout = {
  refreshToken: string;
};

export class RequestReadUserById {
  id!: string;
}

export class RequestReadUserByIds {
  ids!: string[];
}

export class RequestReadCompanyAdmins {
  companyId!: string;
}

export class RequestReadCompanyAccountOwner {
  companyId!: string;
}

export type RequestSignIn = {
  email: string;
  password: string;
};

export type RequestForgotPassword = {
  email: string;
};

export type RequestForgotPasswordCheckCode = {
  email: string;
  code: string;
};

export type RequestForgotPasswordNew = {
  email: string;
  code: string;
  newPassword: string;
};

export class RequestUpdateEmail {
  newEmail!: string;
  userId!: string;
}

export class RequestUpdateMyEmail {
  newEmail!: string;
}

export type RequestUpdateToken = {
  userId: string;
  currentRefreshToken: string;
};

export class RequestUpdateUserInfo {
  userId!: string;
  firstName!: string;
  secondName!: string;
  phone!: string;
}

export class RequestReadMoreUsers {
  from!: number | null;
  count!: number | null;
}

export class RequestReadUsersPaged {
  companyId!: string;
  sortBy!: UserSortBy | null;
  sortMode!: SortMode | null;
  pageSize!: number;
  page!: number;
  includeRoles!: string[];
  excludeRoles!: string[];
}

export class RequestAddUserRole {
  userId!: string;
  roleId!: string;
}

export class RequestDeleteUserRole {
  userId!: string;
  roleId!: string;
}

export class RequestChangeBillingAccountOwner {
  fromUserId!: string;
  toUserId!: string;
}

export class RequestDeleteUser {
  id!: string;
}

export class RequestCreateUser {
  firstName!: string;
  secondName!: string;
  password!: string;
  email!: string;
}
export class RequestUpdateUserPassword {
  data!: RequestUpdateUserPasswordInUser;
  userId!: string;
}
export class RequestUpdateUserPasswordInUser implements IPlainResponse {
  newPassword!: string;
  currentPassword!: string | null;
  loginSessionId!: string | null;
}

//#region COMPANY

export class RequestReadMoreCompanies {
  from!: number | null;
  count!: number | null;
  sortBy!: CompanySortBy | null;
  sortMode!: SortMode | null;
}

export type RequestUpdateCompany = {
  companyId: string;
  name?: string;
  description?: string;
  country?: Countries;
  address?: string;
  email?: string;
};

export class RequestCreateCompany {
  name!: string;
  description!: string | null;
  country!: Countries;
  address!: string;
  email!: string;
  user!: RequestCreateUser;
}
export class RequestDeleteCompany {
  id!: string;
}
export enum CompanySortBy {
  BY_NAME,
}

export class RequestReadCountsForCompanies {
  ids!: string[];
}

//#endregion
