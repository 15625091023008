import { Action, Module, VuexModule, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { TextHider } from '@/util/ComponentHelper';

@Module({ dynamic: true, name: 'errors', store: store })
export default class errorsModule extends VuexModule {
  private textHider: TextHider = new TextHider(10000);

  get Error() {
    return this.textHider.Text;
  }

  get HasError() {
    return this.textHider.HasError;
  }

  @Action({ rawError: true })
  ShowError(text: string) {
    this.textHider.Show(text);
  }

  @Action({ rawError: true })
  Unshow() {
    this.textHider.Unshow();
  }
}

export const ErrorModule = getModule(errorsModule);
