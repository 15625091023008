import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import axios from 'axios';
import { Guid } from 'guid-typescript';
import { SignalDispatcher } from 'strongly-typed-events';

import * as routes from '@/util/api/routes';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { UtilModule } from './utilModule';
import store from '@/store';
import { ResponseIdentityHelper, ResponseToken, ResponseUpdateToken } from '@/models/responses/ResponseIdentity';
import {
  RequestForgotPassword,
  RequestForgotPasswordCheckCode,
  RequestForgotPasswordNew,
  RequestLogout,
  RequestSignIn,
  RequestUpdateToken,
} from '@/models/requests/RequestIdentity';
import { Company, Role, User } from '@/models/Entities';
import { UserModule } from './userModule';
import { ErrorModule } from '@/store/modules/errorsModule';
import { CompanyModule } from '@/store/modules/companyModule';
import { RoleModule } from '@/store/modules/roleModule';
import { isBullingEnabled, isDevMode, isPROM } from '@/util/env';

const SECONDS_OFFSET = 30;
export const USER_TOKEN_FIELD = 'user-token';
export const USER_ACCESS_TOKEN_FIELD = 'user-access-token';
export const USER_TOKEN_LAST_UPDATED_FIELD = 'user-token-last-updated';
export const USER_ID_FIELD = 'user-id';

type TimerId = ReturnType<typeof setTimeout>;

@Module({ dynamic: true, name: 'login', store: store })
export default class loginModule extends VuexModule {
  private validBeforeToken: Date | null = null;
  private loginSessionId: Guid = Guid.createEmpty();
  private updateTokenTimerId: TimerId | null = null;

  private isInitialized = false;
  private isAuthorized = false;

  private me: User | null = null;
  private company: Company | null = null;

  private onUpdateToken = new SignalDispatcher();

  get IsAuthorized() {
    return this.isAuthorized;
  }

  get Initialized() {
    return this.isInitialized;
  }

  get Me() {
    return this.me;
  }

  get UserId() {
    if (this.me) {
      return this.me.Id;
    }

    const storedId = localStorage.getItem(USER_ID_FIELD);
    if (storedId) {
      return Guid.parse(storedId);
    }

    return Guid.createEmpty();
  }

  get IsAdmin() {
    return this.me?.Roles?.some(a => a.IsAdmin) ?? false;
  }

  get IsSuperAdmin() {
    return this.me?.Roles?.some(a => a.IsSuperAdmin) ?? false;
  }

  get IsBillingAccountAdmin() {
    if (!isBullingEnabled()) {
      return false;
    }

    return this.me?.Roles?.some(a => a.IsBillingAccountAdmin) ?? false;
  }

  get IsBillingAccountOwner() {
    if (!isBullingEnabled()) {
      return false;
    }

    return this.me?.Roles?.some(a => a.IsBillingAccountOwner) ?? false;
  }

  get IsConnectUser() {
    return this.me?.Roles?.some(a => a.IsConnectUser) ?? false;
  }

  get LoginSessionId() {
    return this.loginSessionId;
  }

  get CompanyId() {
    return this.Me?.CompanyId ?? null;
  }

  get Company() {
    return this.company ?? null;
  }

  get OnUpdateToken() {
    return this.onUpdateToken.asEvent();
  }

  @Mutation
  setInitialized(value: boolean) {
    this.isInitialized = value;
  }

  @Mutation
  setAuthorized(value: boolean) {
    this.isAuthorized = value;
  }

  @Mutation
  setMe(user: User | null) {
    this.me = user;
    if (user) {
      localStorage.setItem(USER_ID_FIELD, user.Id.toString());
    }
  }

  @Mutation
  setCompany(company: Company | null) {
    this.company = company;
  }

  @Mutation
  resetMeAndCompany() {
    this.me = null;
    this.company = null;
  }

  @Mutation
  addMyRole(value: Role) {
    if (this.me?.Roles.find(a => a.Id.equals(value.Id))) return;
    if (this.me) this.me.Roles.push(value);
  }

  @Mutation
  deleteMyRole(value: Role) {
    if (!this.me?.Roles.find(a => a.Id.equals(value.Id))) return;
    if (this.me) this.me.Roles.delete(value);
  }

  @Mutation
  setUpdateTokenTimerId(timerId: TimerId | null) {
    this.updateTokenTimerId = timerId;
  }

  @Mutation
  setValidBeforeToken(value: string) {
    this.validBeforeToken = Number.isNaN(Date.parse(value)) ? new Date(0) : new Date(value);
  }

  @Mutation
  setLoginSessionId(value: Guid) {
    this.loginSessionId = value;
  }

  @Action({ rawError: true })
  async updateToken() {
    if (isPROM) {
      await UtilModule.loadGlobals();
      await this.loadCurrentUser();
      this.setAuthorized(true);
      this.setInitialized(true);
      return;
    }

    const refreshToken = await this.getRefreshToken();
    const userId = this.UserId;

    if (!refreshToken || !userId) {
      this.resetAuthData();
      this.setInitialized(true);
      return;
    }

    const request: RequestUpdateToken = {
      userId: userId.toString(),
      currentRefreshToken: refreshToken,
    };
    const url = UtilModule.SHW.getUrl(
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.UPDATE_TOKEN,
    );

    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(url, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseIdentityHelper.createRealUpdateToken(axiosResponse.data.response as ResponseUpdateToken);
      await this.handleAuthSuccess(response);
    } else {
      await this.handleAuthError(message);
    }

    this.setInitialized(true);
  }

  @Action({ rawError: true })
  async login([username, password]: [string, string]): Promise<[RequestStatus, string]> {
    const request: RequestSignIn = {
      email: username,
      password,
    };
    const url = UtilModule.SHW.getUrl(routes.UriServices.IDENTITY, routes.UriController.USER, routes.UriUser.LOGIN);

    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(url, request);
    if (result === RequestStatus.OK && axiosResponse != null && axiosResponse.data.success) {
      const response = ResponseIdentityHelper.createRealToken(axiosResponse.data.response as ResponseToken);
      await this.handleAuthSuccess(response);
    } else {
      await this.handleAuthError(message);
    }

    return [result, message];
  }

  @Action({ rawError: true })
  async forgotPassword(email: string) {
    const request: RequestForgotPassword = {
      email,
    };
    const url = UtilModule.SHW.getUrl(
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.FORGOT_PASSWORD,
    );

    const { result, message } = await UtilModule.SHW.ProcessPost(url, request);
    if (result !== RequestStatus.OK) {
      ErrorModule.ShowError(message);
    }

    this.resetAuthData();
  }

  @Action({ rawError: true })
  async forgotPasswordCheckCode([email, code]: [string, string]): Promise<[RequestStatus, string]> {
    const request: RequestForgotPasswordCheckCode = {
      email,
      code,
    };
    const url = UtilModule.SHW.getUrl(
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.FORGOT_PASSWORD_CHECK,
    );

    const { result, message } = await UtilModule.SHW.ProcessPost(url, request);
    return [result, message];
  }

  @Action({ rawError: true })
  async forgotPasswordNew([email, code, newPassword]: [string, string, string]): Promise<[RequestStatus, string]> {
    const request: RequestForgotPasswordNew = {
      email,
      code,
      newPassword,
    };

    const url = UtilModule.SHW.getUrl(
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.FORGOT_PASSWORD_NEW,
    );

    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(url, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseIdentityHelper.createRealToken(axiosResponse.data.response as ResponseToken);
      await this.handleAuthSuccess(response);
    } else {
      ErrorModule.ShowError(message);
      await this.resetAllData();
    }
    return [result, message];
  }

  @Action({ rawError: true })
  async logout() {
    const refreshToken = await this.getRefreshToken();
    const request: RequestLogout = {
      refreshToken,
    };
    const uri = UtilModule.SHW.getUrl(routes.UriServices.IDENTITY, routes.UriController.USER, routes.UriUser.LOGOUT);
    const { result, message } = await UtilModule.SHW.ProcessPost(uri, request);
    await this.resetAllData();
    if (result !== RequestStatus.OK) {
      ErrorModule.ShowError(message);
    }
  }

  @Action({ rawError: true })
  async getAuthQueryParams() {
    if (!isDevMode()) {
      return '';
    }

    const userId = this.UserId.toString();
    const refreshToken = await this.getRefreshToken();
    return `?userId=${encodeURIComponent(userId)}&refreshToken=${encodeURIComponent(refreshToken)}`;
  }

  @Action({ rawError: true })
  async getRefreshToken() {
    return localStorage.getItem(USER_TOKEN_FIELD) || '';
  }

  @Action({ rawError: true })
  async getAccessToken() {
    return localStorage.getItem(USER_ACCESS_TOKEN_FIELD) || '';
  }

  @Action({ rawError: true })
  async resetAllData() {
    this.resetAuthData();
    this.resetMeAndCompany();
    await this.cancelUpdateToken();
    await UtilModule.resetData();
  }

  @Action({ rawError: true })
  private async handleAuthSuccess(response: ResponseToken | ResponseUpdateToken) {
    this.setRefreshToken(response.refreshToken);
    this.setRefreshTokenLastUpdated(new Date());
    this.setAccessToken(response.token);
    this.setValidBeforeToken(response.validBeforeToken);
    this.setLoginSessionId(response.loginSessionId);

    await UtilModule.loadGlobals();
    if (response instanceof ResponseToken) {
      this.setMe(response.responseUser.Map(RoleModule.Roles));
    } else {
      await this.loadCurrentUser();
    }
    await this.loadUserCompany();
    await this.scheduleUpdateToken();

    this.setAuthorized(true);
  }

  @Action({ rawError: true })
  private async handleAuthError(message: string) {
    ErrorModule.ShowError(message);
    await this.resetAllData();
  }

  @Action({ rawError: true })
  private setAccessToken(token: string) {
    if (!token) {
      delete axios.defaults.headers.common['Authorization'];
    } else {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
    this.updateValueInStorage({ key: USER_ACCESS_TOKEN_FIELD, value: token });
    this.onUpdateToken.dispatch();
  }

  @Action({ rawError: true })
  private setRefreshToken(token: string) {
    this.updateValueInStorage({ key: USER_TOKEN_FIELD, value: token });
  }

  @Action({ rawError: true })
  private setRefreshTokenLastUpdated(date: Date | null) {
    this.updateValueInStorage({ key: USER_TOKEN_LAST_UPDATED_FIELD, value: date ? date.getTime().toString() : '' });
  }

  @Action({ rawError: true })
  private updateValueInStorage({ key, value }: { key: string; value: string }) {
    if (!value) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, value);
    }
  }

  @Action({ rawError: true })
  private resetAuthData() {
    this.setValidBeforeToken('');
    this.setRefreshToken('');
    this.setAccessToken('');
    this.setLoginSessionId(Guid.createEmpty());
    this.setRefreshTokenLastUpdated(null);
    this.setAuthorized(false);
  }

  @Action({ rawError: true })
  private async loadCurrentUser() {
    if (this.me) {
      return;
    }

    const meAndSessions = await UserModule.JustLoadUsers([this.UserId]);
    if (meAndSessions.length > 0) {
      const [me] = meAndSessions[0];
      this.setMe(me);
      await this.loadUserCompany();
    }
  }

  @Action({ rawError: true })
  private async loadUserCompany() {
    if (this.me?.CompanyId) {
      this.setCompany(await CompanyModule.GetCompany(this.me.CompanyId));
    }
  }

  @Action({ rawError: true })
  private async scheduleUpdateToken() {
    if (this.validBeforeToken === null) {
      return;
    }

    await this.cancelUpdateToken();

    const timeout = UtilModule.SHW.GetTimeoutToUpdateToken(this.validBeforeToken, SECONDS_OFFSET);
    const timerId = setTimeout(() => {
      this.updateToken();
    }, timeout);
    this.setUpdateTokenTimerId(timerId);
  }

  @Action({ rawError: true })
  private async cancelUpdateToken() {
    if (this.updateTokenTimerId) {
      clearTimeout(this.updateTokenTimerId);
    }
    this.setUpdateTokenTimerId(null);
  }
}

export const LoginModule = getModule(loginModule);
