<template>
  <div
    class="admin-view-container px-3 py-3"
    :style="{ 'user-select': isResizing ? 'none' : 'unset' }"
    @mousemove="mouseMove"
    @mouseup="mouseUp"
  >
    <div class="admin-view">
      <div ref="mainPanel" class="main-panel" :style="{ width: `${mainPanelWidthInPercent * 100}%` }">
        <ActionCard
          headerText="Global messages"
          test-id="admin.globalNotifications"
          :headerButtonDatas="[
            {
              iconType: 'far',
              iconName: 'fa-plus-square',
              text: 'Add New',
              name: 'add-new',
              testId: 'addNewButton',
            },
          ]"
          class="w-100 mb-3 global-messages-card"
          @add-new-header-button-clicked="onClickAddNewGlobalNotification"
        >
          <SortableHeader test-id="admin.globalNotifications" :header-items="OpenedGlobalNotificationHeader" />
          <div class="global-messages-list thin-scroll">
            <ListItemV3
              v-for="item of GlobalNotifications"
              :id="item.id"
              :key="item.id.toString()"
              test-id="admin.globalNotifications"
              :itemsAsync="GetGlobalNotificationListItems(item)"
              :headerText="IsGlobalNotificationCurrentlyDisplayed(item) ? 'now' : null"
              @openItem="onClickOpenGlobalNotification(item)"
            />
          </div>
        </ActionCard>

        <ActionCard
          headerText="Companies"
          test-id="admin.companies"
          :headerButtonDatas="[
            {
              iconType: 'far',
              iconName: 'fa-plus-square',
              text: 'Register New',
              name: 'register-new',
              testId: 'registerNewButton',
            },
          ]"
          class="w-100 companies-card"
          @register-new-header-button-clicked="onClickAddNewCompany"
        >
          <SortableHeader test-id="admin.companies" :header-items="Headers" />
          <div class="companies-list thin-scroll" @scroll="OnCompaniesScrolled">
            <ListItemV3
              v-for="item of CompanyDatas"
              :id="item.company.Id"
              :key="item.company.Id.toString()"
              test-id="admin.companies"
              :items="GetListItems(item)"
              @openItem="onClickOpenCompany(item)"
            />
          </div>

          <div :class="['spinner-container', isLoadingCompanies ? '' : 'hidden']">
            <div :class="['spinner', isLoadingCompanies ? '' : 'hidden']"></div>
          </div>
        </ActionCard>
      </div>

      <div ref="verticalResizer" class="vertical-resizer" @mousedown="verticalResizerMouseDown"></div>

      <div ref="infoPanel" class="info-panel thin-scroll" :style="{ width: `${infoPanelWidthInPercent * 100}%` }">
        <GlobalNotificationEditor
          v-if="OpenedGlobalNotificationShow"
          class="w-100 mb-3"
          :globalNotification="openedGlobalNotification"
          @close-clicked="openedGlobalNotification = null"
          @delete-clicked="onDeleteGlobalNotification"
          @apply-clicked="onUpdateGlobalNotification"
        />

        <AddGlobalNotification
          v-if="showAddGlobalNotification"
          class="w-100 mb-3"
          @add-clicked="onAddGlobalNotification"
          @close-clicked="showAddGlobalNotification = false"
        />

        <CompanyEditor
          v-if="OpenedCompanyShow"
          class="w-100 mb-3"
          :company="openedCompanyData.company"
          @close-clicked="openedCompanyData = null"
          @add-products-clicked="onAddProductsCompany"
        />

        <ActionCard
          v-if="openedCompanyData != null"
          :headerText="AccountInfoCaption"
          class="w-100 mb-3"
          :initiallyCollapsed="true"
          test-id="admin.companies"
          @edit-header-button-clicked="EditingAccount = true"
        >
          <div class="account-info-line">
            <span class="field">{{ NameCaption }}</span>
            <span class="value">{{ AccountName }}</span>
          </div>

          <div class="account-info-line">
            <span class="field">{{ CountryCaption }}</span>
            <span class="value">{{ AccountCountry }}</span>
          </div>

          <div class="account-info-line">
            <span class="field">{{ StateRegionCaption }}</span>
            <span class="value">{{ AccountState }}</span>
          </div>

          <div class="account-info-line">
            <span class="field">{{ PostalCodeCaption }}</span>
            <span class="value">{{ AccountZipCode }}</span>
          </div>

          <div class="account-info-line">
            <span class="field">{{ CityCaption }}</span>
            <span class="value">{{ AccountCity }}</span>
          </div>

          <div class="account-info-line">
            <span class="field">{{ EmailCaption }}</span>
            <span class="value">{{ AccountEmail }}</span>
          </div>

          <div class="account-info-line">
            <span class="field">{{ AddressCaption }}</span>
            <span class="value">{{ AccountAddress }}</span>
          </div>

          <div class="account-info-line">
            <span class="field">{{ PhoneCaption }}</span>
            <span class="value">{{ AccountPhone }}</span>
          </div>

          <div class="account-info-line">
            <span class="field">{{ OwnerCaption }}</span>
            <span class="value cur-pointer" @click="OnClickAccountOwner">{{ AccountOwnerName }}</span>
          </div>
        </ActionCard>

        <ActionCard
          v-if="openedCompanyData != null"
          class="aura-keys w-100 mb-3 overflow-auto thin-scroll"
          test-id="admin.companyInfo"
          :headerText="AuraKeysCaption"
          :headerSpecialText="FreeOccupiedString"
          :initiallyCollapsed="true"
        >
          <SortableHeader :header-items="AuraKeysHeader" test-id="admin.companyInfo" />
          <div class="overflow-overlay thin-scroll">
            <ListItemV3
              v-for="item in PurchasedAuraKeys"
              :id="item.id"
              :key="item.item.id.toString()"
              test-id="admin.companyInfo"
              :items="AuraKeyData(item)"
            />
          </div>
        </ActionCard>

        <ActionCard
          v-if="openedCompanyData != null && showAddCompanyProducts"
          headerText="Add products"
          test-id="admin.productEditor"
          class="add-products w-100 mb-3"
          :initiallyCollapsed="false"
        >
          <div :class="['admin-products-container', addingProducts ? 'disabled' : '']">
            <AdminProductEditor
              v-for="(item, index) in AdminProducts"
              :key="index"
              class="mb-4"
              :purchasedItemFull="item.isLocal ? null : item.purchasedItemFull"
              :showAddButton="index == 0"
              :adminProduct="item"
              @add-button-clicked="AddProduct"
              @delete-button-clicked="RemoveProduct(item)"
              @admin-product-updated="OnProductUpdated"
            >
            </AdminProductEditor>

            <button data-testid="c.applyButton" class="w-100 mb-3" @click="ApplyProductsButtonClicked">Apply</button>
            <button
              data-testid="admin.productEditor.cancelButton"
              class="w-100"
              @click="showAddCompanyProducts = false"
            >
              Cancel
            </button>
          </div>
          <div :class="['spinner', addingProducts ? '' : 'hidden']"></div>
        </ActionCard>

        <AddCompany
          v-if="showAddCompany"
          ref="addCompany"
          class="w-100 mb-3"
          @add-clicked="onAddCompany"
          @close-clicked="showAddCompany = false"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Vue, Watch } from 'vue-property-decorator';
import { Guid } from 'guid-typescript';
import { delay } from 'lodash';

import { getHomePage, safeNavigate, isRouteAllowed } from '@/router';
import { Account, Company, GlobalNotification, Product, ProductPrice, User } from '@/models/Entities';
import { CompanyModule, CompanyQueryAdditions } from '@/store/modules/companyModule';
import en from '@/localization/en';
import ComponentHelper, { HeaderItem, ItemData } from '@/util/ComponentHelper';
import SortableHeader from '@/components/presentation/SortableHeader.vue';
import ListItemV3 from '@/components/presentation/ListItemV3.vue';
import { Countries } from '@/models/responses/Countries';
import { LoginModule } from '@/store/modules/loginModule';
import { UtilModule } from '@/store/modules/utilModule';
import { UserModule } from '@/store/modules/userModule';
import { GlobalNotificationsModule } from '@/store/modules/globalNotificationsModule';
import ActionCard from '@/components/presentation/ActionCard.vue';
import AddGlobalNotification from '@/components/forms/AddGlobalNotification.vue';
import GlobalNotificationEditor from '@/components/forms/GlobalNotificaionEditor.vue';
import AddCompany from '@/components/forms/AddCompany.vue';
import CompanyEditor from '@/components/forms/CompanyEditor.vue';

import dateformat from 'dateformat';
import 'vue2-datepicker/index.css';
import { AccountModule } from '@/store/modules/billing/accountModule';
import {
  AdminPurchaseItemsData,
  PurchasedItemFull,
  PurchasedItemModule,
} from '@/store/modules/billing/purchasedItemModule';
import { PurchasedSubscriptionModule } from '@/store/modules/billing/purchasedSubscriptionModule';
import AdminProductEditor from '@/components/forms/AdminProductEditor.vue';
import { ProductPriceModule } from '@/store/modules/billing/productPriceModule';
import { ProductModuleHelper } from '@/store/modules/billing/productModule';
import { toast } from '@/main';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { POSITION } from 'vue-toastification';
import { LicenseType } from '@/models/enums/LicenseType';

import { AsyncBatchQueueSignalR } from '@/store/util/Globals';

const COMPANIES_PORTION = 30;
const NAME_WIDTH = 250;
const COUNTRY_WIDTH = 200;
const USER_COUNT_WIDTH = 115;
const ADMIN_EMAIL_WIDTH = 250;
const ADMIN_FIRST_NAME_WIDTH = 200;
const ADMIN_SECOND_NAME_WIDTH = 200;

type CompanyData = { company: Company; count: number };

export interface AdminProduct {
  purchasedItemFull: PurchasedItemFull | null;
  isLocal: boolean;
  product: Product | null;
  addons: Product[];
  auraKey: string | null;
}

@Component({
  components: {
    SortableHeader: SortableHeader,
    ListItemV3: ListItemV3,
    ActionCard: ActionCard,
    AddGlobalNotification: AddGlobalNotification,
    GlobalNotificationEditor: GlobalNotificationEditor,
    AddCompany: AddCompany,
    CompanyEditor: CompanyEditor,
    AdminProductEditor: AdminProductEditor,
  },
  name: 'admin-view',
})
export default class AdminView extends Vue {
  private componentId: Guid = Guid.create();

  @Watch('Account')
  private async AccountChanged(newAcc: Account | null, oldAcc: Account | null) {
    if (oldAcc !== null) {
      this.UnsubscribeFromBillingGroups(oldAcc.id);
    }
    if (newAcc !== null) {
      this.SubscribeToBillingGroups(newAcc.id);
    }
  }

  //#region VERTICAL RESIZE
  private mainPanelWidthInPercent = 0.5;
  private infoPanelWidthInPercent = 0.5;

  private mainPanelWidthInPercentMin = 0.4;
  private infoPanelWidthInPercentMin = 0.3;

  private mainPanelWidth: number = -1;
  private infoPanelWidth: number = -1;

  private isResizing: boolean = false;

  private resizingStartX: number = -1;

  private verticalResizerMouseDown(e: MouseEvent) {
    this.isResizing = true;

    this.resizingStartX = e.clientX;

    const mainPanel = this.$refs.mainPanel as Element;
    const infoPanel = this.$refs.infoPanel as Element;

    this.infoPanelWidth = infoPanel.getBoundingClientRect().width;
    this.mainPanelWidth = mainPanel.getBoundingClientRect().width;
  }

  private mouseUp() {
    this.isResizing = false;
  }

  private mouseMove(e: MouseEvent) {
    if (!this.isResizing) {
      return;
    }

    const verticalResizer = this.$refs.verticalResizer as Element;
    let parentWidth = verticalResizer.parentElement!.getBoundingClientRect().width;
    parentWidth -= verticalResizer.getBoundingClientRect().width;
    parentWidth -= parseFloat(getComputedStyle(verticalResizer).width);

    const dx = e.clientX - this.resizingStartX;

    const newMainPanelWidth = this.mainPanelWidth + dx;
    const newInfoPanelWidth = this.infoPanelWidth - dx;

    const newMainPanelWidthInPercent = newMainPanelWidth / parentWidth;
    const newInfoPanelWidthInPercent = newInfoPanelWidth / parentWidth;

    if (
      newMainPanelWidthInPercent < this.mainPanelWidthInPercentMin ||
      newInfoPanelWidthInPercent < this.infoPanelWidthInPercentMin
    ) {
      return;
    }

    this.mainPanelWidth = newMainPanelWidth;
    this.infoPanelWidth = newInfoPanelWidth;

    this.mainPanelWidthInPercent = newMainPanelWidthInPercent;
    this.infoPanelWidthInPercent = newInfoPanelWidthInPercent;

    this.resizingStartX = e.clientX;
  }
  //#endregion

  //#region GLOBAL NOTIFICATIONS
  IsGlobalNotificationCurrentlyDisplayed(gn: GlobalNotification) {
    let start = new Date(gn.startShowing);
    let end = new Date(gn.endShowing);
    let now = new Date();

    return start <= now && end >= now;
  }

  private showAddGlobalNotification: boolean = false;

  async onAddGlobalNotification() {}

  async onDeleteGlobalNotification() {}

  async onUpdateGlobalNotification() {}

  get GlobalNotifications(): GlobalNotification[] {
    return GlobalNotificationsModule.GlobalNotifications;
  }

  private async GetGlobalNotificationListItems(value: GlobalNotification) {
    const start = dateformat(new Date(value.startShowing), 'yyyy-mm-dd HH:MM');
    const end = dateformat(new Date(value.endShowing), 'yyyy-mm-dd HH:MM');

    var user = await UserModule.LoadUser(value.userId);

    let items: ItemData[] = [];
    let itemMessage: ItemData = new ItemData('Text', value.message, 'grow-1');
    let itemDateStart: ItemData = new ItemData('Date from', start, 150);
    let itemDateEnd: ItemData = new ItemData('Date to', end, 150);
    let itemUserId: ItemData = new ItemData(
      'Created by',
      user === null ? 'Error' : user.FirstName + ' ' + user.SecondName,
      225,
    );
    items.push(itemMessage);
    items.push(itemDateStart);
    items.push(itemDateEnd);
    items.push(itemUserId);

    return items;
  }

  private get OpenedGlobalNotificationHeader(): HeaderItem[] {
    let headers: HeaderItem[] = [];
    let headerMessage: HeaderItem = {
      caption: 'Text',
      itemClass: ComponentHelper.GetWidthClass('grow-1'),
      isSortable: false,
    };
    let headerFromDate: HeaderItem = {
      caption: 'From date',
      itemClass: ComponentHelper.GetWidthClass(150),
      isSortable: false,
    };
    let headerToDate: HeaderItem = {
      caption: 'To date',
      itemClass: ComponentHelper.GetWidthClass(150),
      isSortable: false,
    };
    let headerCreatedBy: HeaderItem = {
      caption: 'Created by',
      itemClass: ComponentHelper.GetWidthClass(225),
      isSortable: false,
    };
    headers.push(headerMessage);
    headers.push(headerFromDate);
    headers.push(headerToDate);
    headers.push(headerCreatedBy);
    return headers;
  }
  //#endregion

  //#region COMPANIES

  private currentCompaniesCount = 0;
  private isLoadingCompanies = false;
  private isScrollLoadingCompanies: boolean = false;

  private get Companies(): Company[] {
    let companies = CompanyModule.Companies;
    CompanyModule.CompaniesSync([companies, this.componentId]);
    return companies;
  }

  @Watch('Companies', { immediate: true, deep: false })
  private async onCompaniesChanged(newValue: Company[]) {
    if (newValue) {
      this.companyDatas = await this.UpdateCompanyDatas(newValue);
    }
  }

  private companyDatas: CompanyData[] = [];
  private get CompanyDatas(): CompanyData[] {
    return this.companyDatas;
  }

  async ReadMore() {
    this.isLoadingCompanies = true;

    let companyAdditions = new CompanyQueryAdditions();
    companyAdditions.from = this.currentCompaniesCount;
    companyAdditions.count = COMPANIES_PORTION;
    await CompanyModule.ReadMore(companyAdditions);
    this.currentCompaniesCount = this.Companies.length;

    this.isLoadingCompanies = false;
  }

  private async UpdateCompanyDatas(companies: Company[]): Promise<CompanyData[]> {
    let ids = companies.map(a => a.Id);
    let companyDatas: CompanyData[] = [];
    let res = await CompanyModule.ReadCounts(ids);
    for (let item of res) {
      let company = companies.singleOrDefault(c => c.Id.equals(item.id));

      if (!company) continue;

      let companyData: CompanyData = {
        company: company,
        count: item.count,
      };
      companyDatas.push(companyData);
    }
    this.SortCompanyDatas(companyDatas);
    companyDatas.slice(0, this.currentCompaniesCount);
    return companyDatas;
  }
  private get SortByNameASC(): (a: CompanyData, b: CompanyData) => 1 | -1 {
    return (a, b) => (a.company.Name > b.company.Name ? 1 : -1);
  }
  private SortCompanyDatas(companyDatas: CompanyData[]) {
    companyDatas.sort(this.SortByNameASC);
  }

  async OnCompaniesScrolled(e: any) {
    const currScroll = e.target.scrollTop;
    const endScroll = e.target.scrollHeight - e.target.clientHeight;

    if (currScroll === endScroll && currScroll > 0 && !this.isScrollLoadingCompanies) {
      this.isScrollLoadingCompanies = true;

      await this.ReadMore();

      delay(() => {
        this.isScrollLoadingCompanies = false;
      }, 500);
    }
  }

  private get Headers(): HeaderItem[] {
    let headerName: HeaderItem = {
      caption: this.NameCaption,
      itemClass: ComponentHelper.GetWidthClass(NAME_WIDTH),
      isSortable: false,
    };
    let headerCountry: HeaderItem = {
      caption: this.CountryCaption,
      itemClass: ComponentHelper.GetWidthClass(COUNTRY_WIDTH),
      isSortable: false,
    };
    let headerAddress: HeaderItem = {
      caption: this.AddressCaption,
      itemClass: ComponentHelper.GetWidthClass('grow-1'),
      isSortable: false,
    };
    let headerCount: HeaderItem = {
      caption: this.UserCountCaption,
      itemClass: ComponentHelper.GetWidthClass(USER_COUNT_WIDTH),
      isSortable: false,
      width: USER_COUNT_WIDTH,
    };
    let headers: HeaderItem[] = [];
    headers.push(headerName);
    headers.push(headerCountry);
    headers.push(headerAddress);
    headers.push(headerCount);
    return headers;
  }
  private GetListItems(value: CompanyData): ItemData[] {
    let items: ItemData[] = [];
    let itemName: ItemData = new ItemData('Name', value.company.Name, NAME_WIDTH);
    let itemCountry: ItemData = new ItemData('Country', en.CountryNames[value.company.Country], COUNTRY_WIDTH);
    let itemAddress: ItemData = new ItemData(
      'Address',
      value.company.Address.length === 0 ? '-' : value.company.Address,
      'grow-1',
    );
    let itemUserCounts: ItemData = new ItemData('UserCounts', value.count.toString(), USER_COUNT_WIDTH);
    items.push(itemName);
    items.push(itemCountry);
    items.push(itemAddress);
    items.push(itemUserCounts);

    return items;
  }

  private showAddCompany: boolean = false;
  private showAddCompanyProducts: boolean = false;

  async onAddCompany() {}

  async onAddProductsCompany() {
    this.showAddCompanyProducts = true;
  }

  async onDeleteCompany() {}

  //#endregion

  //#region ACCOUNT
  private get Account() {
    return AccountModule.LoadedAccount;
  }
  private get AccountOwner(): User | null {
    return this.openedCompanyAccountOwner;
  }
  private get AccountName(): string | undefined {
    return this.Account?.name === '' ? '—' : this.Account?.name;
  }
  private get AccountCountry(): string | undefined {
    if (this.Account === null) {
      return undefined;
    }
    return en.CountryNames[this.Account!.country];
  }
  private get AccountState(): string | undefined {
    return this.Account?.state === null || this.Account == null ? '—' : en.StateNames[this.Account?.state];
  }
  private get AccountZipCode(): string | undefined {
    return this.Account?.postalCode === '' ? '—' : this.Account?.postalCode;
  }
  private get AccountCity(): string | undefined {
    return this.Account?.city === '' ? '—' : this.Account?.city;
  }
  private get AccountEmail(): string | undefined {
    return this.Account?.email === '' ? '—' : this.Account?.email;
  }
  private get AccountAddress(): string | undefined {
    return this.Account?.address === '' ? '—' : this.Account?.address;
  }
  private get AccountPhone(): string | undefined {
    if (this.AccountOwner === null) return '—';

    return this.AccountOwner!.Phone === null ? '—' : this.AccountOwner.Phone;
  }
  private get AccountOwnerName(): string {
    if (this.AccountOwner === null) return '—';

    return ComponentHelper.GetFullname(this.AccountOwner);
  }
  private localProducts: AdminProduct[] = [];
  private get PurchasedItemsFull(): PurchasedItemFull[] {
    return PurchasedItemModule.PurchasedItemsFull.filter(
      a => a.price.recurringInterval == null && a.product.baseProductId == null,
    );
  }
  private get AdminProducts(): AdminProduct[] {
    const result: AdminProduct[] = [];

    for (const local of this.localProducts) {
      result.push(local);
    }

    if (result.length == 0) {
      this.AddProduct();
      result.push(this.localProducts[0]);
    }

    return result;
  }

  private async AddProduct() {
    this.localProducts.push({
      isLocal: true,
      purchasedItemFull: null,
      product: null,
      addons: [],
      auraKey: null,
    });
  }

  private async RemoveProduct(item: AdminProduct) {
    if (item.isLocal) {
      this.localProducts.delete(item);
      return;
    } else {
      // remove from server
    }
  }

  private async OnProductUpdated() {}

  private addingProducts: boolean = false;
  private async ApplyProductsButtonClicked() {
    const adminPurchaseData: AdminPurchaseItemsData = {
      accountId: AccountModule.LoadedAccount!.id,
      items: [],
    };

    for (const item of this.AdminProducts) {
      const priceEur = ProductPriceModule.ProductPrices.firstOrDefault(
        a => a.productId.toString() === item.product?.id.toString() && a.currency == 'eur',
      )!;

      const addonPricesEur: ProductPrice[] = [];

      for (const addon of item.addons) {
        const addonPriceEur = ProductPriceModule.ProductPrices.firstOrDefault(
          a => a.productId.toString() === addon.id.toString() && a.currency == 'eur' && a.recurringInterval == null,
        )!;

        addonPricesEur.push(addonPriceEur);
      }

      adminPurchaseData.items.push({
        auraKey: item.auraKey,
        productPriceId: priceEur.id,
        addonProductPrices: addonPricesEur.map(a => a.id),
      });
    }

    this.addingProducts = true;
    const result = await PurchasedItemModule.AdminPurchase(adminPurchaseData);
    this.addingProducts = false;

    if (result[0] !== RequestStatus.OK) {
      toast.error(result[1], {
        position: POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.success('Products added successfully', {
        position: POSITION.BOTTOM_RIGHT,
      });
    }
  }

  private get PurchasedAuraKeys(): PurchasedItemFull[] {
    return this.PurchasedItemsFull.filter(a => {
      return a.product.licenseType === LicenseType.CryptlexKey;
    });
  }

  private get FreeOccupiedString(): string {
    const total = this.PurchasedAuraKeys.length;
    const free = this.PurchasedAuraKeys.filter(a => !a.item.activated).length;

    return `${free}/${total} ${en.free}`;
  }

  private get AuraKeysHeader(): HeaderItem[] {
    if (this.PurchasedAuraKeys.length == 0) return [];

    let headerKey: HeaderItem = {
      caption: '#',
      itemClass: ComponentHelper.GetWidthClass('grow-1'),
    };
    let headerStatus: HeaderItem = {
      caption: en.status.titleCase(),
      itemClass: ComponentHelper.GetWidthClass(125),
    };
    let headerMachineId: HeaderItem = {
      caption: en.machineId,
      itemClass: ComponentHelper.GetWidthClass(200),
    };

    let headerItems: HeaderItem[] = [];

    headerItems.push(headerKey);
    headerItems.push(headerStatus);
    headerItems.push(headerMachineId);

    return headerItems;
  }

  private AuraKeyData(full: PurchasedItemFull): ItemData[] {
    let items: ItemData[] = [];

    const item = full.item;
    const addonsStr =
      '+ ' + full.addons.map(a => ProductModuleHelper.ProductForPurchasedItem(a)?.shortName).join(' + ');
    let itemKey: ItemData = new ItemData(
      'Key',
      item.cryptlexKey === null ? '-' : item.cryptlexKey!,
      'grow-1',
      full.addons.length > 0 ? addonsStr : undefined,
      'fa-clone',
      'far',
      () => {
        navigator.clipboard.writeText(item.cryptlexKey!);
        toast.info(en.keyCopiedToClipboard, {
          position: POSITION.BOTTOM_RIGHT,
        });
      },
    );
    let itemStatus: ItemData = new ItemData(
      'Status',
      item.activated ? en.activated.titleCase() : en.free.titleCase(),
      125,
    );
    let itemMachineId: ItemData = new ItemData('MachineId', item.deviceName === null ? '-' : item.deviceName!, 200);

    items.push(itemKey);
    items.push(itemStatus);
    items.push(itemMachineId);

    return items;
  }
  //#endregion

  //#region HOOKS

  async created() {
    if (!isRouteAllowed(this.$route)) {
      return safeNavigate({ path: getHomePage() });
    }

    await CompanyModule.SubscribeToAuraAdminGroup();
  }
  async mounted() {
    await this.ReadMore();
    await UtilModule.loadBillingData();
  }
  async beforeDestroy() {
    await CompanyModule.DeleteFromAuraAdminGroup();
    let companyIds = this.Companies.map(a => a.Id).except([LoginModule.CompanyId!]);
    await CompanyModule.LooseCompanies([companyIds, this.componentId]);
  }
  //#endregion

  //#region OPENED

  private openedCompanyData: CompanyData | null = null;
  private openedGlobalNotification: GlobalNotification | null = null;
  private openedCompanyUsers: User[] = [];
  private openedCompanyAccountOwner: User | null = null;

  private get OpenedCompanyShow(): boolean {
    return this.openedCompanyData !== null;
  }

  private get OpenedGlobalNotificationShow(): boolean {
    return this.openedGlobalNotification !== null;
  }

  private get OpenedCompanyName(): string {
    if (!this.openedCompanyData) return '';
    return this.openedCompanyData.company.Name;
  }

  //#endregion

  //#region ACTIONS

  private async SubscribeToBillingGroups(accountId: Guid) {
    AsyncBatchQueueSignalR.Queue({
      Batch: async () => {
        await AccountModule.AddToSpecificAccountGroup(accountId);
        await PurchasedItemModule.AddToSpecificPurchasedItemGroup(accountId);
        await PurchasedSubscriptionModule.AddToSpecificPurchasedSubscriptionGroup(accountId);
      },
    });
  }

  private async UnsubscribeFromBillingGroups(accountId: Guid) {
    AsyncBatchQueueSignalR.Queue({
      Batch: async () => {
        await AccountModule.DeleteFromSpecificAccountGroup(accountId);
        await PurchasedItemModule.DeleteFromSpecificPurchasedItemGroup(accountId);
        await PurchasedSubscriptionModule.DeleteFromSpecificPurchasedSubscriptionGroup(accountId);
      },
    });
  }

  private async onClickOpenGlobalNotification(value: GlobalNotification) {
    // this.openedCompanyData = null;
    // this.showAddCompany = false;
    this.openedGlobalNotification = value;
  }

  private async onClickOpenCompany(value: CompanyData) {
    // this.openedGlobalNotification = null;
    // this.showAddGlobalNotification = false;
    if (this.openedCompanyData != null && this.openedCompanyData == value) {
      return;
    }

    this.showAddCompanyProducts = false;
    this.showAddCompany = false;
    this.openedCompanyData = value;
    let admins = await UserModule.JustLoadCompanyAdmins(this.openedCompanyData.company.Id);
    admins.sort(this.SortByEmailASC);
    this.openedCompanyUsers = admins;
    await AccountModule.LoadAccountByCompanyId(this.openedCompanyData.company.Id);
    this.openedCompanyAccountOwner = await UserModule.LoadCompanyAccountOwner(this.openedCompanyData.company.Id);
    await PurchasedItemModule.LoadPurchasedItems(AccountModule.LoadedAccount!.id);
    await PurchasedSubscriptionModule.LoadPurchasedSubscriptions(AccountModule.LoadedAccount!.id);
  }

  private async onClickAddNewCompany() {
    // this.showAddGlobalNotification = false;
    // this.openedGlobalNotification = null;

    this.showAddCompany = true;
    this.openedCompanyData = null;
  }

  private async onClickAddNewGlobalNotification() {
    // this.showAddCompany = false;
    // this.openedCompanyData = null;

    this.showAddGlobalNotification = true;
  }
  //#endregion

  //#region ADMINS

  private get OpenedAdminHeaders(): HeaderItem[] {
    let headers: HeaderItem[] = [];
    let headerEmail: HeaderItem = {
      caption: this.EmailCaption,
      itemClass: ComponentHelper.GetWidthClass(ADMIN_EMAIL_WIDTH),
      isSortable: false,
    };
    let headerFirstName: HeaderItem = {
      caption: this.FirstNameCaption,
      itemClass: ComponentHelper.GetWidthClass(ADMIN_FIRST_NAME_WIDTH),
      isSortable: false,
    };
    let headerSecondName: HeaderItem = {
      caption: this.SecondNameCaption,
      itemClass: ComponentHelper.GetWidthClass(ADMIN_SECOND_NAME_WIDTH),
      isSortable: false,
    };
    headers.push(headerEmail);
    headers.push(headerFirstName);
    headers.push(headerSecondName);
    return headers;
  }
  private get SortByEmailASC(): (a: User, b: User) => 1 | -1 {
    return (a, b) => (a.Email > b.Email ? -1 : 1);
  }
  private OpenedAdminGetListItems(value: User): ItemData[] {
    let items: ItemData[] = [];
    let itemEmail: ItemData = new ItemData('Email', value.Email, ADMIN_EMAIL_WIDTH);
    let itemFirstName: ItemData = new ItemData(
      'FirstName',
      value.FirstName ? value.FirstName : '-',
      ADMIN_FIRST_NAME_WIDTH,
    );
    let itemSecondName: ItemData = new ItemData(
      'SecondName',
      value.SecondName ? value.SecondName : '-',
      ADMIN_SECOND_NAME_WIDTH,
    );
    items.push(itemEmail);
    items.push(itemFirstName);
    items.push(itemSecondName);
    return items;
  }

  @Emit('openUser')
  private OnClickAccountOwner() {
    return this.AccountOwner?.Id;
  }

  @Emit('openUser')
  private OpenUser() {}

  //#endregion

  //#region CAPTIONS

  private get AccountInfoCaption() {
    return en.accountInfo.titleCase();
  }

  private get EditCaption() {
    return en.edit.titleCase();
  }
  private get StateRegionCaption() {
    return en.stateOrRegion.titleCase();
  }
  private get PostalCodeCaption() {
    return en.postalCode.titleCase();
  }
  private get CityCaption() {
    return en.city.titleCase();
  }
  private get PhoneCaption() {
    return en.phone.titleCase();
  }
  private get OwnerCaption() {
    return en.owner.titleCase();
  }

  private get NameCaption(): string {
    return en.nameCaption.titleCase();
  }
  private get CountryCaption(): string {
    return en.country.titleCase();
  }
  private get AddressCaption(): string {
    return en.address.titleCase();
  }
  private get UserCountCaption(): string {
    return en.userCount;
  }
  private get FillRequiredCaption(): string {
    return en.fillRequired;
  }
  private get StatusDoneCaption(): string {
    return en.done;
  }
  private get AddNewCompanyCaption(): string {
    return en.addNewCompany;
  }
  private get NewCompanyPlaceholderCaption(): string {
    return en.newCompanyPlaceholder;
  }
  private get AddressPlaceholderCaption(): string {
    return en.enterCompanyAddress;
  }
  private GetCountryName(country: Countries): string {
    return en.CountryNames[country];
  }
  private get AdminEmailCaption(): string {
    return en.newCompanyAdminEmail;
  }
  private get EmailCaption(): string {
    return en.email.titleCase();
  }
  private get EmailPlaceholderCaption(): string {
    return en.newCompanyAdminEmailPlaceholder;
  }
  private get PasswordCaption(): string {
    return en.newCompanyAdminPassword;
  }
  private get PasswordPlaceholderCaption(): string {
    return en.newCompanyAdminPasswordPlaceholder;
  }
  private get AdminFirstNameCaption(): string {
    return en.newCompanyAdminFirstName;
  }
  private get FirstNameCaption(): string {
    return en.firstName;
  }
  private get FirstNamePlaceholderCaption(): string {
    return en.newCompanyAdminFirstNamePlaceholder;
  }
  private get AdminSecondNameCaption(): string {
    return en.newCompanyAdminSecondName;
  }
  private get SecondNameCaption(): string {
    return en.secondName;
  }
  private get SecondNamePlaceholderCaption(): string {
    return en.newCompanyAdminSecondNamePlaceholder;
  }
  private get CancelCaption(): string {
    return en.cancel;
  }
  private get ActionsTooltip(): string {
    return en.actions.growFirst();
  }
  private get DeleteCompanyCaption(): string {
    return en.deleteCompany;
  }
  private get AdminsCaptions(): string {
    return en.admins;
  }
  private get AuraKeysCaption() {
    return en.auraKeys;
  }

  //#endregion
}
</script>

<style lang="scss" scoped>
.admin-view-container {
  flex-grow: 1;
  height: 100%;
  overflow: auto;
}

.admin-view {
  display: flex;
  width: 100%;
  height: 100%;

  .main-panel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .global-messages-card {
      max-height: 50%;

      .global-messages-list {
        overflow-y: overlay;
      }
    }

    .companies-card {
      max-height: 50%;

      .companies-list {
        overflow-y: overlay;
      }
    }
  }

  .vertical-resizer {
    cursor: col-resize;
    width: 10px;
    margin: 0 3px;
  }

  .info-panel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: hidden;

    :last-child {
      margin-bottom: 0 !important;
    }
  }

  .account-info-line {
    display: flex;
    font-size: 13px;
    margin-bottom: 0.75rem;
    &:last-child {
      margin-bottom: 0;
    }

    margin-left: 8px;
    .field {
      color: #949494;
      width: 138px;
      text-align: start;
    }

    .value {
      color: #cecece;
    }
  }
  button {
    border: 1px solid var(--editor-field-value);
    border-radius: 6px;
    font-size: 13px;
    color: var(--editor-field-value);
    background: transparent;
    width: 150px;
    height: 26px;
  }

  .add-products {
    position: relative;
  }

  .aura-keys {
    min-height: 300px;
    &.collapsed {
      min-height: 42px;
    }
  }

  .spinner-container {
    min-height: 40px;
    margin-top: 20px;
    position: relative;
    opacity: 1;

    &.hidden {
      opacity: 0;
      min-height: 0px;
      margin-top: 0px;
    }

    transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);
  }
}

.dark-theme {
  .admin-view {
    background: linear-gradient(130deg, #cccccc, #eeeeee);
  }
}
</style>
