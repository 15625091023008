import Vue from 'vue';
import VueRouter, { RouteConfig, Route, RawLocation } from 'vue-router';

import MainView from '../views/MainView.vue';
import NotFound from '../views/errors/NotFound.vue';
import Unauthorized from '../views/errors/Unauthorized.vue';
import { Guid } from 'guid-typescript';
import { LoginModule } from '@/store/modules/loginModule';
import { CompanyRouteTab, Routes } from './routes';
import { getBaseAppPath } from '@/util/env';

Vue.use(VueRouter);

export enum WorkFieldChild {
  DASHBOARD,
  COMPANY,
  FILE_LIBRARY,
  MACHINES,
  JOBS,
  MY_ACCOUNT,
  USER,
  ADMIN,
}

export function getHomePage() {
  return LoginModule.IsConnectUser ? Routes.DASHBOARD : Routes.MY_ACCOUNT;
}

export function IsConnectUserRoute(route: string | null | undefined) {
  return route == Routes.DASHBOARD || route == Routes.FILE_LIBRARY || route == Routes.MACHINES || route == Routes.JOBS;
}

export function IsBillingAdminCompanyTab(tab: string | null) {
  return tab == CompanyRouteTab.BILLING || tab == CompanyRouteTab.PRODUCTS || tab == CompanyRouteTab.STORE;
}

function getId(route: Route): Guid | null {
  const idStr = route.params.id;
  if (idStr === undefined) return null;
  return Guid.parse(idStr.toString());
}

function dynamicPropsFn(route: Route): any {
  if (route.name === Routes.DASHBOARD) return { pageParam: { page: WorkFieldChild.DASHBOARD } };
  if (route.name === Routes.MY_ACCOUNT) return { pageParam: { page: WorkFieldChild.MY_ACCOUNT } };
  if (route.name === Routes.USER) return { pageParam: { page: WorkFieldChild.USER, id: getId(route) } };
  if (route.name === Routes.COMPANY) return { pageParam: { page: WorkFieldChild.COMPANY } };
  if (route.name === Routes.FILE_LIBRARY)
    return {
      pageParam: { page: WorkFieldChild.FILE_LIBRARY, id: getId(route) },
    };
  if (route.name === Routes.MACHINES) return { pageParam: { page: WorkFieldChild.MACHINES, id: getId(route) } };
  if (route.name === Routes.JOBS) return { pageParam: { page: WorkFieldChild.JOBS, id: getId(route) } };
  if (route.name === Routes.ADMIN) return { pageParam: { page: WorkFieldChild.ADMIN } };

  if (LoginModule.IsConnectUser) {
    return { pageParam: { page: WorkFieldChild.DASHBOARD } };
  } else {
    return { pageParam: { page: WorkFieldChild.MY_ACCOUNT } };
  }
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: Routes.HOME,
    component: MainView,
    props: dynamicPropsFn,
  },
  {
    path: '/dashboard',
    name: Routes.DASHBOARD,
    component: MainView,
    props: dynamicPropsFn,
  },
  {
    path: '/login',
    name: Routes.LOGIN,
    component: MainView,
    props: dynamicPropsFn,
  },
  {
    path: '/my-account',
    name: Routes.MY_ACCOUNT,
    component: MainView,
    props: dynamicPropsFn,
  },
  {
    path: '/user',
    name: Routes.USER,
    component: MainView,
    props: dynamicPropsFn,
  },
  {
    path: '/company',
    name: Routes.COMPANY,
    component: MainView,
    props: dynamicPropsFn,
  },
  {
    path: '/file-library',
    name: Routes.FILE_LIBRARY,
    component: MainView,
    props: dynamicPropsFn,
  },
  {
    path: '/machines',
    name: Routes.MACHINES,
    component: MainView,
    props: dynamicPropsFn,
  },
  {
    path: '/jobs',
    name: Routes.JOBS,
    component: MainView,
    props: dynamicPropsFn,
  },
  {
    path: '/admin',
    name: Routes.ADMIN,
    component: MainView,
    props: dynamicPropsFn,
  },
  {
    path: '/unauthorized',
    name: Routes.UNAUTHORIZED,
    component: Unauthorized,
  },
  {
    path: '*',
    name: Routes.NOT_FOUND,
    component: NotFound,
  },
];

const router = new VueRouter({
  routes,
  base: getBaseAppPath(),
  mode: 'history',
});

export function safeNavigate(location: RawLocation, method: 'push' | 'replace' = 'replace') {
  const currentPath = router.currentRoute.fullPath;

  if (typeof location === 'string') {
    if (currentPath === location) {
      return;
    }
  } else {
    if (location.path === currentPath) {
      return;
    }
    if (location.name === currentPath.replace(/^\/+/, '')) {
      return;
    }
  }

  return router[method](location);
}

export function isRouteAllowed(route: Route) {
  if (route.name === Routes.ADMIN && !LoginModule.IsSuperAdmin) {
    return false;
  }

  if (IsConnectUserRoute(route.name) && !LoginModule.IsConnectUser) {
    return false;
  }

  if (
    route.name == Routes.COMPANY &&
    IsBillingAdminCompanyTab(route.query.tab as string) &&
    !LoginModule.IsBillingAccountAdmin
  ) {
    return false;
  }

  return true;
}

export default router;
