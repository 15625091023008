<template>
  <AuraDialogWrapper
    ref="dialogWrapper"
    test-id="startNewJobDialog"
    :headerText="HeaderText"
    @closed="DialogWrapperClosed"
    @dismissed="DialogWrapperClosed"
  >
    <div class="wizard-wrapper">
      <div v-if="currentStep == 0" :class="['printer-selection', isStartingJob ? 'disabled' : '']">
        <SortableHeader
          :header-items="PrinterHeaders"
          :by.sync="SortByPrinterHeader"
          :mode.sync="PrinterSortMode"
          test-id="startNewJobDialog.printers"
          @sortBy="OnPrinterSortBy"
        />
        <div class="overflow-auto thin-scroll">
          <ListItemV3
            v-for="item in Printers"
            :id="item.Id"
            :key="item.Id.toString()"
            mainClass="cur-pointer"
            :items="PrinterItems(item)"
            :selected="IsPrinterSelected(item)"
            test-id="startNewJobDialog.printers"
            @openItem="PrinterSelected(item)"
          />
        </div>

        <div :class="['spinner-container', isLoadingPrinters ? '' : 'hidden']">
          <div :class="['spinner', isLoadingPrinters ? '' : 'hidden']"></div>
        </div>

        <RegularButton
          :class="['mt-3 w-100']"
          :disabled="selectedPrinter == null"
          :label="NextCaption"
          test-id="startNewJobDialog.confirmSelectedPrinterButton"
          @clicked="ConfirmSelectedPrinterClicked"
        />
      </div>

      <div v-else-if="currentStep == 1" :class="['source-selection', isStartingJob ? 'disabled' : '']">
        <div class="source-search-container">
          <SearchInput
            class="source-search mb-3"
            test-id="startNewJobDialog.sources"
            @search-changed="OnSourceSearchChanged"
          >
          </SearchInput>
        </div>

        <SortableHeader
          :header-items="FullSourcesHeader"
          :by.sync="SortByFullSourceHeader"
          :mode.sync="FullSourceSortMode"
          test-id="startNewJobDialog.sources"
          @sortBy="OnFullSourcesSortBy"
        />
        <div class="thin-scroll overflow-overlay" @scroll="OnSourcesScrolled">
          <ListItemV3
            v-for="item in FullSources"
            :id="item.file.Id"
            :key="item.file.Id.toString()"
            class="cur-pointer"
            test-id="startNewJobDialog.sources"
            :items="FullSourceItems(item)"
            :selected="IsSourceSelected(item)"
            @openItem="OpenFullSource(item)"
          />
        </div>

        <div :class="['spinner-container', isLoadingSources ? '' : 'hidden']">
          <div :class="['spinner', isLoadingSources ? '' : 'hidden']"></div>
        </div>

        <RegularButton
          :class="['mt-3 w-100']"
          :disabled="selectedSource == null || isStartingJob"
          :label="ConfirmCaption"
          test-id="startNewJobDialog.confirmJobStartButton"
          @clicked="ConfirmJobStart"
        />

        <RegularButton
          v-if="backButtonAvailable"
          :class="['mt-2 w-100']"
          :disabled="isStartingJob"
          :label="BackCaption"
          test-id="startNewJobDialog.BackToPrinterSelectionButton"
          @clicked="BackToPrinterSelectionClicked"
        />
      </div>

      <div :class="['spinner', isStartingJob ? '' : 'hidden']"></div>
    </div>

    <div class="buttons"></div>
  </AuraDialogWrapper>
</template>

<script lang="ts">
import RegularButton from '@/components/buttons/RegularButton.vue';
import ListItemV3 from '@/components/presentation/ListItemV3.vue';
import SearchInput from '@/components/inputs/SearchInput.vue';
import SortableHeader from '@/components/presentation/SortableHeader.vue';
import en from '@/localization/en';
import { toast } from '@/main';
import { FullSource } from '@/models/CompositeEntities';
import { Printer, Source, SourceGroup } from '@/models/Entities';
import { PrinterSortBy, SourceSortBy } from '@/models/requests/RequestMonolith';
import {
  SortPrinterByIdDESC,
  SortPrinterByPrinterNameASC,
  SortPrinterByPrinterNameDESC,
  SortPrinterByPrinterModelASC,
  SortPrinterByPrinterModelDESC,
  SortPrinterByStatusASC,
  SortPrinterByStatusDESC,
  SortPrinterByJobCountASC,
  SortPrinterByJobCountDESC,
  SortPrinterByQueuedJobCountASC,
  SortPrinterByQueuedJobCountDESC,
} from '@/models/util/PrinterSortings';
import { JobModule } from '@/store/modules/jobModule';
import { LoginModule } from '@/store/modules/loginModule';
import { PrinterModule, LoadPrinterPageData } from '@/store/modules/printerModule';
import { SourceGroupModule } from '@/store/modules/sourceGroupModule';
import { SourceModule } from '@/store/modules/sourceModule';
import { UserModule } from '@/store/modules/userModule';
import { HubConnectionModule } from '@/store/modules/hubConnectionModule';
import ComponentHelper, { HeaderItem, SortMode, ItemData } from '@/util/ComponentHelper';
import { GuidHelper } from '@/util/GuidHelper';
import { Guid } from 'guid-typescript';
import { debounce } from 'lodash';
import { DialogComponent } from 'vue-modal-dialogs';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { POSITION } from 'vue-toastification';
import { PrintersPageSize } from './AddSourceToQueueDialog.vue';
import AuraDialogWrapper from '@/views/dialogs/base/AuraDialogWrapper.vue';
import { loadMoreOnScroll } from '@/util/loadMoreOnScroll';

export interface StartNewJobDialogResult {
  source: Source;
  printer: Printer;
}

enum StartNewJobStep {
  PRINTER_SELECTION,
  SOURCE_SELECTION,
}

const PAGE_SIZE = 25;

@Component({
  components: {
    SortableHeader: SortableHeader,
    ListItemV3: ListItemV3,
    AuraDialogWrapper: AuraDialogWrapper,
    RegularButton: RegularButton,
    SearchInput: SearchInput,
  },
})
export default class StartNewJobDialog extends DialogComponent<StartNewJobDialogResult | null> {
  @Prop() printer!: Printer | null;
  @Prop({ default: true }) backButtonAvailable!: boolean;

  @Ref() dialogWrapper!: AuraDialogWrapper;
  private componentId!: Guid;

  //#region WATCHERS
  @Watch('printer', { immediate: true })
  OnPrinterChanged(newValue: Printer | null) {
    // Todo: react to this once
    if (newValue != null) {
      this.selectedPrinter = newValue;
      this.ConfirmSelectedPrinterClicked();
    }
  }
  //#endregion

  //#region STATE

  private subscribedToSources: boolean = false;
  private subscribedToJobs: boolean = false;

  private currentStep: StartNewJobStep = StartNewJobStep.PRINTER_SELECTION;
  private debouncePerformSearch: any;

  //#region PRINTER RELATED
  private selectedPrinter: Printer | null = null;

  private get Printers() {
    if (this.isLoadingPrinters) return [];

    const currentPrinters = this.SortPrinters(PrinterModule.Printers.map(a => a));

    const maxCount = (this.printerPage + 1) * PrintersPageSize;

    const toDisplay = currentPrinters.slice(0, maxCount);
    const toLoose = currentPrinters.slice(maxCount);

    PrinterModule.LoosePrintersNew([toLoose, this.componentId]);
    PrinterModule.OccupyPrinters([toDisplay, this.componentId]);

    if (toLoose.length > 0) {
      PrinterModule.CollectPrinters();
    }

    return toDisplay;
  }

  private sortByPrinterHeader: HeaderItem | null = null;
  private printerSortMode: SortMode | null = null;
  private printerPage: number = 0;

  private isLoadingPrinters: boolean = false;
  private isLoadingSources: boolean = false;
  private isStartingJob: boolean = false;

  private get SortByPrinterHeader(): HeaderItem | null {
    return this.sortByPrinterHeader;
  }
  private set SortByPrinterHeader(value: HeaderItem | null) {
    this.sortByPrinterHeader = value;
  }
  private get PrinterSortMode(): SortMode | null {
    return this.printerSortMode;
  }
  private set PrinterSortMode(value: SortMode | null) {
    this.printerSortMode = value;
  }

  private headerPrinterName: HeaderItem = {
    caption: 'Name',
    itemClass: ComponentHelper.GetWidthClass(),
    isSortable: true,
  };
  private headerPrinterJobCount: HeaderItem = {
    caption: 'Jobs queued',
    itemClass: ComponentHelper.GetWidthClass(125),
    isSortable: true,
  };
  private headerPrinterStatus: HeaderItem = {
    caption: 'Status',
    itemClass: ComponentHelper.GetWidthClass(125),
    isSortable: true,
  };

  private get PrinterHeaders(): HeaderItem[] {
    let headerItems: HeaderItem[] = [];
    headerItems.push(this.headerPrinterName);
    headerItems.push(this.headerPrinterJobCount);
    headerItems.push(this.headerPrinterStatus);
    return headerItems;
  }

  private PrinterItems(value: Printer): ItemData[] {
    let items: ItemData[] = [];
    let name: ItemData = new ItemData('Name', value.Name, 'grow-1');

    let jobCount: ItemData = new ItemData('JobsCount', value.QueuedJobCount.toString(), 125);

    let status: ItemData = new ItemData('Status', ComponentHelper.GetPrinterStateReadableText(value.PrinterState), 125);

    items.push(name);
    items.push(jobCount);
    items.push(status);
    return items;
  }

  private async PrinterSelected(printer: Printer) {
    this.selectedPrinter = printer;
  }

  private IsPrinterSelected(printer: Printer) {
    if (this.selectedPrinter == null) return false;

    return this.selectedPrinter.Id.toString() == printer.Id.toString();
  }

  private async OnPrinterSortBy() {
    PrinterModule.LoosePrintersNew([this.Printers, this.componentId]);
    PrinterModule.CollectPrinters();
    this.printerPage = 0;

    await this.LoadMorePrinters();
  }

  private async LoadMorePrinters() {
    this.isLoadingPrinters = true;
    await PrinterModule.LoadPrinterPage(this.GetPrinterLoadData());
    this.isLoadingPrinters = false;
  }

  private GetPrinterLoadData(): LoadPrinterPageData {
    return {
      companyId: LoginModule.Me!.CompanyId,
      sortBy: this.GetPrinterSortBy(this.SortByPrinterHeader),
      sortMode: this.PrinterSortMode,
      page: this.printerPage,
    };
  }

  private SortPrinters(printers: Printer[]): Printer[] {
    let sortBy = this.SortByPrinterHeader == null ? undefined : this.GetPrinterSortBy(this.SortByPrinterHeader);
    if (
      (this.PrinterSortMode == null && sortBy !== undefined) ||
      (this.PrinterSortMode != null && sortBy === undefined) ||
      printers.length == 0
    )
      return printers;

    if (sortBy === PrinterSortBy.BY_PRINTER_NAME) {
      if (this.PrinterSortMode === SortMode.ASC)
        return printers.sort(SortPrinterByIdDESC).sort(SortPrinterByPrinterNameASC);
      if (this.PrinterSortMode === SortMode.DESC)
        return printers.sort(SortPrinterByIdDESC).sort(SortPrinterByPrinterNameDESC);
    } else if (sortBy === PrinterSortBy.BY_PRINTER_MODEL) {
      if (this.PrinterSortMode === SortMode.ASC)
        return printers.sort(SortPrinterByIdDESC).sort(SortPrinterByPrinterModelASC);
      if (this.PrinterSortMode === SortMode.DESC)
        return printers.sort(SortPrinterByIdDESC).sort(SortPrinterByPrinterModelDESC);
    } else if (sortBy === PrinterSortBy.BY_STATUS) {
      if (this.PrinterSortMode === SortMode.ASC) return printers.sort(SortPrinterByIdDESC).sort(SortPrinterByStatusASC);
      if (this.PrinterSortMode === SortMode.DESC)
        return printers.sort(SortPrinterByIdDESC).sort(SortPrinterByStatusDESC);
    } else if (sortBy === PrinterSortBy.BY_JOB_COUNT) {
      if (this.PrinterSortMode === SortMode.ASC)
        return printers.sort(SortPrinterByIdDESC).sort(SortPrinterByJobCountASC);
      if (this.PrinterSortMode === SortMode.DESC)
        return printers.sort(SortPrinterByIdDESC).sort(SortPrinterByJobCountDESC);
    } else if (sortBy === PrinterSortBy.BY_QUEUED_JOB_COUNT) {
      if (this.PrinterSortMode === SortMode.ASC)
        return printers.sort(SortPrinterByIdDESC).sort(SortPrinterByQueuedJobCountASC);
      if (this.PrinterSortMode === SortMode.DESC)
        return printers.sort(SortPrinterByIdDESC).sort(SortPrinterByQueuedJobCountDESC);
    }

    return printers;
  }

  private GetPrinterSortBy(headerItem: HeaderItem | null): PrinterSortBy | null {
    if (headerItem === this.headerPrinterName) return PrinterSortBy.BY_PRINTER_NAME;
    else if (headerItem === this.headerPrinterStatus) return PrinterSortBy.BY_STATUS;
    else if (headerItem == this.headerPrinterJobCount) return PrinterSortBy.BY_JOB_COUNT;

    return null;
  }
  //#endregion

  //#region SOURCE RELATED
  private everythingGroup!: SourceGroup;
  private get EverythingGroup(): SourceGroup {
    this.everythingGroup.FileCount = 0;

    for (const group of this.GroupsExeptAll) {
      this.everythingGroup.FileCount += group.FileCount;
    }

    this.everythingGroup.LastUpdated = this.GroupsExeptAll.map(a => a.LastUpdated).sort()[0];

    return this.everythingGroup;
  }

  private get Groups(): SourceGroup[] {
    const result = SourceGroupModule.SourceGroups.map(a => a);
    result.unshift(this.EverythingGroup);
    return result;
  }

  private get GroupsExeptAll(): SourceGroup[] {
    return SourceGroupModule.SourceGroups.map(a => a);
  }

  private sourceSearch: string | undefined = undefined;
  private selectedSource: FullSource | null = null;

  private sortByFullSourceHeader: HeaderItem | null = null;
  private fullSourceSortMode: SortMode | null = null;
  private fullSourcePage = 0;
  private loadingFullSource = false;
  private hasMoreSource = true;

  private get SortByFullSourceHeader(): HeaderItem | null {
    return this.sortByFullSourceHeader;
  }
  private set SortByFullSourceHeader(value: HeaderItem | null) {
    console.log('???');

    this.sortByFullSourceHeader = value;
  }
  private get FullSourceSortMode(): SortMode | null {
    return this.fullSourceSortMode;
  }
  private set FullSourceSortMode(value: SortMode | null) {
    this.fullSourceSortMode = value;
  }

  private get Sources(): Source[] {
    let result = SourceModule.Sources.map(a => a);

    SourceModule.OccupySources([result, this.componentId]);

    return result;
  }

  // Can't make this a getter, for some reason it doesn't react to sourceSearch
  private SourcesWithSearch(): Source[] {
    let result = this.Sources.map(a => a);

    if (this.sourceSearch != undefined && this.sourceSearch != '') {
      result = result.filter(a => a.CodeFileName.toLowerCase().includes(this.sourceSearch!.toLowerCase()));
    }

    return result;
  }

  private get FullSources(): FullSource[] {
    let fullSources: FullSource[] = [];

    for (let file of this.SourcesWithSearch()) {
      let group: SourceGroup | null = null;

      if (file.GroupId != null) {
        group = this.Groups.singleOrDefault(a => a.Id.equals(file.GroupId!));
      }

      let user = UserModule.Users.singleOrDefault(a => a.Id.equals(file.UserId));

      let fullSource: FullSource = {
        file: file,
        group: group,
        author: user,
      };

      fullSources.push(fullSource);
    }

    const maxCount = (this.fullSourcePage + 1) * PAGE_SIZE;

    const toDisplay = fullSources.splice(0, maxCount);
    const toLoose = fullSources.slice(maxCount);
    const toLooseFiltered = this.Sources.filter(
      a => fullSources.firstOrDefault(b => b.file.Id.toString() == a.Id.toString()) == null,
    );

    SourceModule.LooseSources([toLoose.map(a => a.file), this.componentId]);
    SourceModule.LooseSources([toLooseFiltered, this.componentId]);
    SourceModule.OccupySources([toDisplay.map(a => a.file), this.componentId]);

    if (toLoose.length > 0) {
      SourceModule.CollectSources();
    }

    return toDisplay;
  }

  //#region HEADERS & ITEMS

  private fullSourceHeaderName: HeaderItem = {
    caption: 'Name',
    itemClass: ComponentHelper.GetWidthClass('grow-2'),
    isSortable: true,
  };
  private fullSourceHeaderAuthor: HeaderItem = {
    caption: 'Author',
    itemClass: ComponentHelper.GetWidthClass(),
    isSortable: true,
  };
  private fullSourceHeaderCreatedAt: HeaderItem = {
    caption: 'Created at',
    itemClass: ComponentHelper.GetWidthClass(),
    isSortable: true,
  };
  private fullSourceHeaderSize: HeaderItem = {
    caption: 'Size',
    itemClass: ComponentHelper.GetWidthClass(125),
    isSortable: true,
  };
  private fullSourceHeaderPrintCount: HeaderItem = {
    caption: 'Print count',
    itemClass: ComponentHelper.GetWidthClass(100),
    isSortable: true,
  };
  private get FullSourcesHeader(): HeaderItem[] {
    let result: HeaderItem[] = [];

    result.push(this.fullSourceHeaderName);
    result.push(this.fullSourceHeaderAuthor);
    result.push(this.fullSourceHeaderCreatedAt);
    // result.push(this.fullSourceHeaderSize);
    // result.push(this.fullSourceHeaderPrintCount);

    return result;
  }

  private FullSourceItems(value: FullSource): ItemData[] {
    let result: ItemData[] = [];

    const itemName: ItemData = new ItemData('Name', value.file.CodeFileName, 'grow-2');
    const itemAuthor: ItemData = new ItemData(
      'Author',
      value.author == undefined ? '—' : ComponentHelper.GetFullname(value.author),
      'grow-1',
    );
    const itemLastUpdate: ItemData = new ItemData(
      'CreatedAt',
      ComponentHelper.GetReadableDateTime(value.file.CreationDateTime, true),
      'grow-1',
    );
    // const itemFiles: ItemData = new ItemData('Files', value.file.PrintCount.toString(), 100);
    // const itemSize: ItemData = new ItemData('Size', ComponentHelper.GetReadbaleBytesSize(value.file.CodeFileSize), 125);

    result.push(itemName);
    result.push(itemAuthor);
    result.push(itemLastUpdate);
    // result.push(itemSize);
    // result.push(itemFiles);

    return result;
  }
  //#endregion

  private get HeaderText() {
    if (this.currentStep == StartNewJobStep.PRINTER_SELECTION) {
      return 'Select a printer';
    } else if (this.currentStep == StartNewJobStep.SOURCE_SELECTION) {
      return 'Select a source';
    }

    return '?';
  }

  //#endregion

  //#region LOGIC
  private DialogWrapperClosed() {
    this.$close(null);
  }

  private async ConfirmSelectedPrinterClicked() {
    this.currentStep = StartNewJobStep.SOURCE_SELECTION;

    await SourceModule.LooseSources([this.Sources, this.componentId]);
    await SourceModule.CollectSources();
    this.fullSourcePage = 0;
    this.LoadMoreSources();
  }

  private async BackToPrinterSelectionClicked() {
    this.currentStep = StartNewJobStep.PRINTER_SELECTION;

    await SourceModule.LooseSources([this.Sources, this.componentId]);
    await SourceModule.CollectSources();
    this.fullSourcePage = 0;
    this.selectedSource = null;
  }

  private async ConfirmJobStart() {
    if (this.selectedSource == null || this.selectedPrinter == null) {
      return;
    }

    this.isStartingJob = true;

    const result = await JobModule.StartNewJob([this.selectedSource.file.Id, this.selectedPrinter.Id]);

    this.isStartingJob = false;

    if (result != null) {
      toast.success(
        `New job for file ${this.selectedSource.file.CodeFileName} has been successfully queued for printer ${this.selectedPrinter.Name}`,
        {
          position: POSITION.BOTTOM_RIGHT,
        },
      );

      this.dialogWrapper.CloseDialog();
      this.$close({
        source: this.selectedSource.file,
        printer: this.selectedPrinter,
      });
    } else {
      toast.error(
        `Could not queue job ${this.selectedSource.file.CodeFileName} for printer ${this.selectedPrinter.Name}`,
        {
          position: POSITION.BOTTOM_RIGHT,
        },
      );
    }
  }

  private GetFullSourceSortBy(): SourceSortBy | null {
    if (this.SortByFullSourceHeader == this.fullSourceHeaderName) {
      return SourceSortBy.BY_FILENAME;
    } else if (this.SortByFullSourceHeader == this.fullSourceHeaderAuthor) {
      return SourceSortBy.BY_AUTHOR;
    } else if (this.SortByFullSourceHeader == this.fullSourceHeaderCreatedAt) {
      return SourceSortBy.BY_CREATED_DATE;
    } else if (this.SortByFullSourceHeader == this.fullSourceHeaderSize) {
      return SourceSortBy.BY_CODE_SIZE;
    } else if (this.SortByFullSourceHeader == this.fullSourceHeaderPrintCount) {
      return SourceSortBy.BY_PRINT_COUNT;
    }

    return null;
  }

  private async LoadMoreSources() {
    // Todo: replace this with another implementation which takes search into count
    // SourceModule.ClearSources();
    let sources: Source[] = [];

    this.isLoadingSources = true;

    sources = await SourceModule.JustLoadSources([
      this.fullSourcePage,
      PAGE_SIZE,
      this.GetFullSourceSortBy(),
      this.FullSourceSortMode,
      this.sourceSearch,
    ]);

    this.hasMoreSource = sources.length >= PAGE_SIZE;

    let sourceUserIds = sources.map(a => a.UserId);
    let authorsIds = GuidHelper.distinct(sourceUserIds);
    await UserModule.LoadUsers(authorsIds);

    this.isLoadingSources = false;

    for (let source of sources) {
      SourceModule.AddSourceToModule(source);
    }
  }

  async OnFullSourcesSortBy() {
    await SourceModule.LooseSources([this.Sources, this.componentId]);
    await SourceModule.CollectSources();
    this.fullSourcePage = 0;
    this.LoadMoreSources();
  }

  async OnSourcesScrolled(event: Event) {
    return loadMoreOnScroll({
      target: event.target as HTMLElement,
      isLoading: this.loadingFullSource,
      hasMore: this.hasMoreSource,
      loadMore: this.LoadMoreSources,
      setLoading: loading => (this.loadingFullSource = loading),
      nextPage: () => this.fullSourcePage++,
    });
  }

  private async UnsubscribeFromSourceGroups() {
    // console.log("HELLLLLLLLLLLLLLLLLLLLLLLLO");

    if (this.subscribedToSources) {
      await SourceModule.DeleteFromCompanySourcesGroup();
    }

    if (this.subscribedToJobs) {
      await JobModule.DeleteFromCompanyJobsGroup();
    }
  }

  private async SubscribeToSourceGroups() {
    if (
      HubConnectionModule.MonolithConnectionGroups.firstOrDefault(
        a => a.func == SourceModule.SubscribeToCompanySourcesGroup,
      ) == null
    ) {
      await SourceModule.SubscribeToCompanySourcesGroup();
      this.subscribedToSources = true;
    }

    if (
      HubConnectionModule.MonolithConnectionGroups.firstOrDefault(
        a => a.func == JobModule.SubscribeToCompanyJobsGroup,
      ) == null
    ) {
      await JobModule.SubscribeToCompanyJobsGroup();
      this.subscribedToJobs = true;
    }
  }

  private async OnSourceSearchApplied(search: string) {
    if (search.length == 0) {
      this.sourceSearch = undefined;
    } else {
      this.sourceSearch = search;
    }

    await SourceModule.LooseSources([this.Sources, this.componentId]);
    await SourceModule.CollectSources();
    this.fullSourcePage = 0;
    this.LoadMoreSources();
  }

  private async OnSourceSearchChanged(search: string) {
    if (search.length == 0) {
      this.sourceSearch = undefined;
    } else {
      this.sourceSearch = search;
    }

    this.debouncePerformSearch();
  }

  private async OpenFullSource(fullSource: FullSource) {
    this.selectedSource = fullSource;
  }

  private IsSourceSelected(item: FullSource) {
    return item.file.Id.toString() == this.selectedSource?.file.Id.toString();
  }
  //#endregion

  //#region HOOKS
  async beforeCreate() {
    this.componentId = Guid.create();
    this.everythingGroup = new SourceGroup();
    this.everythingGroup.Id = Guid.createEmpty();
    this.everythingGroup.FileCount = 0;
    this.everythingGroup.Name = '# All';

    this.debouncePerformSearch = debounce(async () => {
      await SourceModule.LooseSources([this.Sources, this.componentId]);
      await SourceModule.CollectSources();
      this.fullSourcePage = 0;
      await this.LoadMoreSources();
    }, 500);
  }

  async mounted() {
    this.PrinterSortMode = SortMode.DESC;
    this.SortByPrinterHeader = this.headerPrinterName;

    this.FullSourceSortMode = SortMode.DESC;
    this.SortByFullSourceHeader = this.fullSourceHeaderCreatedAt;

    await this.SubscribeToSourceGroups();

    await this.LoadMorePrinters();
  }

  async beforeDestroy() {
    this.UnsubscribeFromSourceGroups();

    await PrinterModule.LoosePrintersNew([this.Printers, this.componentId]);
    await PrinterModule.CollectPrinters();

    await SourceModule.LooseSources([this.Sources, this.componentId]);
    await SourceModule.CollectSources();
  }
  //#endregion

  //#region EVENTS
  //#endregion

  //#region TRANSLATIONS
  private get NextCaption() {
    return en.next.growFirst();
  }
  private get ConfirmCaption() {
    return en.confirm.growFirst();
  }
  private get BackCaption() {
    return en.back.growFirst();
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.wizard-wrapper {
  min-width: 450px;
  max-width: 600px;
  max-height: 50vh;
  width: 50vw;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .source-selection {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .printer-selection {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}

.spinner-container {
  min-height: 40px;
  margin: 20px 0;
  position: relative;
  opacity: 1;

  &.hidden {
    opacity: 0;
    min-height: 0px;
    margin: 0px;
  }

  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);
}
</style>
