import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import store from '@/store';
import { HubConnection } from '@microsoft/signalr';
import { PendingSubscriptionUpdate } from '@/models/Entities';

import { UtilModule } from '../utilModule';
import * as ips from '@/util/api/ips';
import * as routes from '@/util/api/routes';
import * as hubActions from '@/util/api/hubActions';
import { RequestStatus } from '@/models/enums/RequestStatus';
import {
  ResponsePendingSubscriptionUpdate,
  ResponsePendingSubscriptionUpdates,
} from '@/models/responses/ResponseBilling';
import { TypeHelper } from '@/util/TypeHelper';

import '@/models/responses/ResponseBillingExtensions';
import { RequestReadAllByAccountIdPendingSubscriptionUpdate } from '@/models/requests/RequestsBilling';
import { Guid } from 'guid-typescript';
import { HubConnectionModule } from '@/store/modules/hubConnectionModule';

@Module({ dynamic: true, name: 'pendingSubscriptionUpdate', store: store })
export default class pendingSubscriptionUpdateModule extends VuexModule {
  private pendingSubscriptionUpdates: PendingSubscriptionUpdate[] = [];

  get PendingSubscriptionUpdates(): PendingSubscriptionUpdate[] {
    return this.pendingSubscriptionUpdates;
  }

  @Action({ rawError: true })
  PendingSubscriptionUpdateSubscribe(hubConnection: HubConnection) {
    hubConnection.on(hubActions.RECEIVE_CREATE_PENDING_SUBSCRIPTION, async (res: ResponsePendingSubscriptionUpdate) => {
      const psu = TypeHelper.DeepCopyFrom(res, ResponsePendingSubscriptionUpdate).Map();
      this.AddPendingSubscriptionUpdate(psu);
    });

    hubConnection.on(hubActions.RECEIVE_DELETE_PENDING_SUBSCRIPTION, async (res: ResponsePendingSubscriptionUpdate) => {
      const psu = TypeHelper.DeepCopyFrom(res, ResponsePendingSubscriptionUpdate).Map();

      const local = this.pendingSubscriptionUpdates.firstOrDefault(a => a.id.toString() == psu.id.toString());

      if (local !== null) {
        this.RemovePendingSubscriptionUpdate(local);
      }
    });

    hubConnection.on(hubActions.RECEIVE_UPDATE_PENDING_SUBSCRIPTION, async (res: ResponsePendingSubscriptionUpdate) => {
      const psu = TypeHelper.DeepCopyFrom(res, ResponsePendingSubscriptionUpdate).Map();
      this.UpdatePendingSubscriptionUpdate(psu);
    });
  }

  @Action({ rawError: true })
  async LoadPendingSubscriptionUpdates(accountId: Guid) {
    this.ClearPendingSubscriptionUpdates();

    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.PENDING_SUBSCRIPTION_UPDATE,
      routes.UriPendingSubscriptionUpdate.READ_ALL,
    );

    const request = new RequestReadAllByAccountIdPendingSubscriptionUpdate();
    request.accountId = accountId.toString();

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = axiosResponse.data.response as ResponsePendingSubscriptionUpdates;
      const pendingSubscriptionUpdates = TypeHelper.DeepCopyFrom(
        response,
        ResponsePendingSubscriptionUpdates,
      ).pendingSubscriptionUpdates;

      for (const res of pendingSubscriptionUpdates) {
        const pendingSubscriptionUpdate = TypeHelper.DeepCopyFrom(res, ResponsePendingSubscriptionUpdate);
        this.AddPendingSubscriptionUpdate(pendingSubscriptionUpdate.Map());
      }
    }
  }

  @Mutation
  AddPendingSubscriptionUpdate(psu: PendingSubscriptionUpdate) {
    this.pendingSubscriptionUpdates.push(psu);
  }

  @Mutation
  RemovePendingSubscriptionUpdate(psu: PendingSubscriptionUpdate) {
    this.pendingSubscriptionUpdates.delete(psu);
  }

  @Mutation
  UpdatePendingSubscriptionUpdate(psu: PendingSubscriptionUpdate) {
    this.pendingSubscriptionUpdates = this.pendingSubscriptionUpdates.map(val => {
      if (val.id.toString() === psu.id.toString()) {
        val.invoiceId = psu.invoiceId;
      }

      return val;
    });
  }

  @Mutation
  ClearPendingSubscriptionUpdates() {
    this.pendingSubscriptionUpdates = [];
  }

  @Action({ rawError: true })
  async AddToPendingSubscriptionUpdateGroup() {
    if (HubConnectionModule.BillingConnection == null) return;
    await HubConnectionModule.BillingConnection.send(hubActions.ADD_TO_PENDING_SUBSCRIPTION_GROUP);
    HubConnectionModule.AddBillingConnectionGroup({
      func: this.AddToPendingSubscriptionUpdateGroup,
    });
  }

  @Action({ rawError: true })
  async DeleteFromPendingSubscriptionUpdateGroup() {
    if (HubConnectionModule.BillingConnection == null) return;
    await HubConnectionModule.BillingConnection.send(hubActions.DELETE_FROM_PENDING_SUBSCRIPTION_GROUP);
    HubConnectionModule.DeleteBillingConnectionGroup({
      func: this.AddToPendingSubscriptionUpdateGroup,
    });
  }
}

export const PendingSubscriptionUpdateModule = getModule(pendingSubscriptionUpdateModule);
