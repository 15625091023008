import getEnv from '@/util/env';

export function PROTOCOL(): string {
  return getEnv('VITE_APP_MAIN_PROTOCOL') || window.location.protocol.replace(':', '');
}

export function IP(): string {
  return getEnv('VITE_APP_MAIN_IP') || window.location.hostname;
}

export function PORT(): string {
  return getEnv('VITE_APP_MAIN_PORT') || window.location.port;
}
