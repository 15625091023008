import { VuexModule, Module, Action, getModule } from 'vuex-module-decorators';
import { HubConnection } from '@microsoft/signalr';

import { StoreHelperWrapper } from '../util/storeHelpers';
import store from '@/store';
import { RoleModule } from './roleModule';
import { UserModule } from './userModule';
import { JobModule } from './jobModule';
import { PrinterModule } from './printerModule';
import { SourceModule } from './sourceModule';
import { LoginSessionModule } from './loginSessionModule';
import { ConnectorModule } from './connectorModule';
import { CompanyModule } from '@/store/modules/companyModule';
import { SourceGroupModule } from '@/store/modules/sourceGroupModule';
import { StatModule } from '@/store/modules/statModule';
import { GlobalNotificationsModule } from '@/store/modules/globalNotificationsModule';
import { HubConnectionModule } from '@/store/modules/hubConnectionModule';
import { AccountModule } from './billing/accountModule';
import { PaymentMethodModule } from './billing/paymentMethodModule';
import { InvoiceModule } from './billing/invoiceModule';
import { PurchasedItemModule } from './billing/purchasedItemModule';
import { ProductModule } from './billing/productModule';
import { ProductPriceModule } from './billing/productPriceModule';
import { PurchasedSubscriptionModule } from './billing/purchasedSubscriptionModule';
import { PendingSubscriptionUpdateModule } from './billing/pendingSubscriptionUpdateModule';
import { isPROM } from '@/util/env';

@Module({ dynamic: true, name: 'util', store: store })
export default class utilModule extends VuexModule {
  private shw: StoreHelperWrapper = new StoreHelperWrapper();

  get SHW(): StoreHelperWrapper {
    return this.shw;
  }

  @Action({ rawError: true })
  async loadGlobals() {
    if (RoleModule.Roles.empty()) {
      await RoleModule.ReadAllRoles();
    }
  }

  @Action({ rawError: true })
  async loadBillingData() {
    if (isPROM) {
      return;
    }

    await AccountModule.LoadMyAccount();
    await ProductPriceModule.LoadProductPrices();
    await ProductModule.LoadProducts();
  }

  @Action({ rawError: true })
  async resetData() {
    await CompanyModule.DeleteFromCompanyGroup();
    InvoiceModule.ClearInvoices();
    UserModule.ClearUsers();
    HubConnectionModule.resetAllConnections();
  }

  @Action({ rawError: true })
  SubscribeIdentity(identityConnection: HubConnection) {
    RoleModule.RolesSubscribe(identityConnection);
    UserModule.UserSubscribe(identityConnection);
    CompanyModule.CompanySubscribe(identityConnection);
    LoginSessionModule.LoginSessionSubscribe(identityConnection);
  }

  @Action({ rawError: true })
  async SubscribePrinting(monolithConnection: HubConnection) {
    JobModule.JobsSubscribe(monolithConnection);
    await PrinterModule.PrinterSubscribe(monolithConnection);
    await SourceModule.SourceSubscribe(monolithConnection);
    await SourceGroupModule.SourceGroupSubscribe(monolithConnection);
    await StatModule.UserStatSubscribe(monolithConnection);
    await StatModule.CompanyStatSubscribe(monolithConnection);
    await StatModule.PrinterStatSubscribe(monolithConnection);
  }

  @Action({ rawError: true })
  async SubscribeMonolith(monolithConnection: HubConnection) {
    await GlobalNotificationsModule.GlobalNotificationsSubscribe(monolithConnection);
  }

  @Action({ rawError: true })
  async SubscribeConnector() {
    await ConnectorModule.ConnectorSubscribe();
  }

  @Action({ rawError: true })
  async SubscribeBilling(billingConnection: HubConnection) {
    await AccountModule.AccountSubscribe(billingConnection);
    await PaymentMethodModule.PaymentMethodSubscribe(billingConnection);
    await InvoiceModule.InvoiceSubscribe(billingConnection);
    await PurchasedItemModule.PurchasedItemSubscribe(billingConnection);
    await ProductModule.ProductSubscribe(billingConnection);
    await ProductPriceModule.ProductPriceSubscribe(billingConnection);
    await PurchasedSubscriptionModule.PurchasedSubscriptionSubscribe(billingConnection);
    await PendingSubscriptionUpdateModule.PendingSubscriptionUpdateSubscribe(billingConnection);
  }
}

export const UtilModule = getModule(utilModule);
