import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import store from '@/store';
import { HubConnection } from '@microsoft/signalr';
import { Account, TaxRate } from '@/models/Entities';

import { UtilModule } from '../utilModule';
import * as ips from '@/util/api/ips';
import * as routes from '@/util/api/routes';
import * as hubActions from '@/util/api/hubActions';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { ResponseAccount } from '@/models/responses/ResponseBilling';
import { HubConnectionModule } from '../hubConnectionModule';
import { TypeHelper } from '@/util/TypeHelper';

import '@/models/responses/ResponseBillingExtensions';
import { Countries, States } from '@/models/responses/Countries';
import { RequestReadAccountById, RequestUpdateAccountById } from '@/models/requests/RequestsBilling';
import { Guid } from 'guid-typescript';
import { TaxRateModule } from './taxRateModule';

export interface UpdateBillingAccountData {
  id: string;
  name: string;
  country: Countries;
  state: States | null;
  postalCode: string;
  city: string;
  address: string;
  email: string;
  taxId: string | null;
}

@Module({ dynamic: true, name: 'account', store: store })
export default class accountModule extends VuexModule {
  //#region MY ACCOUNT
  private account: Account | null = null;
  get Account(): Account | null {
    return this.account;
  }

  private accountTaxRate: TaxRate | null = null;
  get AccountTaxRate(): TaxRate | null {
    return this.accountTaxRate;
  }
  //#endregion

  //#region LOADED ACCOUNT
  private loadedAccount: Account | null = null;
  get LoadedAccount(): Account | null {
    return this.loadedAccount;
  }

  private loadedAccountTaxRate: TaxRate | null = null;
  get LoadedAccountTaxRate(): TaxRate | null {
    return this.loadedAccountTaxRate;
  }
  //#endregion

  @Mutation
  SetAccount(value: Account) {
    this.account = value;
  }

  @Mutation
  SetAccountTaxRate(value: TaxRate | null) {
    if (value == null) {
      // console.log("My account has no tax rate");
    } else {
      // console.log("My account tax rate", value.percentage + "%");
    }
    this.accountTaxRate = value;
  }

  @Mutation
  SetLoadedAccount(value: Account) {
    this.loadedAccount = value;
  }

  @Mutation
  SetLoadedAccountTaxRate(value: TaxRate | null) {
    this.loadedAccountTaxRate = value;
  }

  @Action({ rawError: true })
  AccountSubscribe(hubConnection: HubConnection) {
    hubConnection.on(hubActions.RECEIVE_UPDATE_ACCOUNT, async (res: ResponseAccount) => {
      const account = TypeHelper.DeepCopyFrom(res, ResponseAccount).Map();

      if (account.id.toString() === this.Account?.id.toString()) {
        this.SetAccount(account);
        await this.LoadMyTaxRate();
      } else if (account.id.toString() === this.LoadedAccount?.id.toString()) {
        this.SetLoadedAccount(account);
        await this.LoadLoadedAccountTaxRate();
      }
    });
  }

  @Action({ rawError: true })
  async LoadMyAccount() {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.ACCOUNT,
      routes.UriAccount.READ_MY_ACCOUNT,
    );

    const { result, axiosResponse } = await UtilModule.SHW.ProcessGet(uri, null);

    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = axiosResponse.data.response as ResponseAccount;
      const account = TypeHelper.DeepCopyFrom(response, ResponseAccount).Map();
      this.SetAccount(account);
      await this.LoadMyTaxRate();
    }
  }

  @Action({ rawError: true })
  private async LoadMyTaxRate() {
    if (this.Account == null) return;

    const res = await TaxRateModule.LoadTaxRateByAccountId(this.Account.id);

    // Todo: maybe show error somewhere :)
    if (res.result === RequestStatus.OK) {
      this.SetAccountTaxRate(res.taxRate);
    }
  }

  @Action({ rawError: true })
  async LoadAccountByCompanyId(companyId: Guid) {
    if (this.loadedAccount !== null && this.loadedAccount.id.toString() === companyId.toString()) {
      return;
    }

    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.ACCOUNT,
      routes.UriAccount.READ_BY_ID,
    );

    const request = new RequestReadAccountById();
    request.accountId = companyId.toString();

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = axiosResponse.data.response as ResponseAccount;
      const account = TypeHelper.DeepCopyFrom(response, ResponseAccount).Map();
      this.SetLoadedAccount(account);
      await this.LoadLoadedAccountTaxRate();
    }
  }

  @Action({ rawError: true })
  private async LoadLoadedAccountTaxRate() {
    if (this.LoadedAccount == null) return;

    const res = await TaxRateModule.LoadTaxRateByAccountId(this.LoadedAccount.id);

    // Todo: maybe show error somewhere :)
    if (res.result === RequestStatus.OK) {
      this.SetLoadedAccountTaxRate(res.taxRate);
    }
  }

  @Action({ rawError: true })
  async UpdateAccountInfo(data: UpdateBillingAccountData) {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.ACCOUNT,
      routes.UriAccount.UPDATE,
    );

    const request = new RequestUpdateAccountById();
    request.accountId = data.id;
    request.address = data.address;
    request.city = data.city;
    request.country = data.country;
    request.email = data.email;
    request.name = data.name;
    request.postalCode = data.postalCode;
    request.state = data.state;
    request.taxId = data.taxId;

    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result === RequestStatus.OK && axiosResponse != null) {
      return { success: true, message: message };
    }

    return { success: false, message: message };
  }

  @Action({ rawError: true })
  async AddToAccountGroup() {
    if (HubConnectionModule.BillingConnection == null) return;
    await HubConnectionModule.BillingConnection.send(hubActions.ADD_TO_ACCOUNT_GROUP);
    HubConnectionModule.AddBillingConnectionGroup({
      func: this.AddToAccountGroup,
    });
  }

  @Action({ rawError: true })
  async AddToSpecificAccountGroup(accountId: Guid) {
    if (HubConnectionModule.BillingConnection == null) return;
    await HubConnectionModule.BillingConnection.send(hubActions.ADD_TO_SPECIFIC_ACCOUNT_GROUP, accountId.toString());
    HubConnectionModule.AddBillingConnectionGroup({
      func: this.AddToSpecificAccountGroup,
      args: accountId,
    });
  }

  @Action({ rawError: true })
  async DeleteFromAccountGroup() {
    if (HubConnectionModule.BillingConnection == null) return;
    await HubConnectionModule.BillingConnection.send(hubActions.DELETE_FROM_ACCOUNT_GROUP);
    HubConnectionModule.DeleteBillingConnectionGroup({
      func: this.AddToAccountGroup,
    });
  }

  @Action({ rawError: true })
  async DeleteFromSpecificAccountGroup(accountId: Guid) {
    if (HubConnectionModule.BillingConnection == null) return;
    await HubConnectionModule.BillingConnection.send(
      hubActions.DELETE_FROM_SPECIFIC_ACCOUNT_GROUP,
      accountId.toString(),
    );
    HubConnectionModule.DeleteBillingConnectionGroup({
      func: this.AddToSpecificAccountGroup,
      args: accountId,
    });
  }
}

export const AccountModule = getModule(accountModule);
