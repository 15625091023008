import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import * as signalR from '@microsoft/signalr';
import { SimpleEventDispatcher } from 'strongly-typed-events';

import store from '@/store';
import { UtilModule } from './utilModule';
import { CompanyModule } from './companyModule';
import { LoginModule } from './loginModule';

import * as routes from '@/util/api/routes';
import * as hubActions from '@/util/api/hubActions';
import { isPROM } from '@/util/env';

type SignalRGroupSubscription = {
  func: (args: any | undefined) => Promise<void>;
  args?: any | undefined;
};

@Module({ dynamic: true, name: 'hubConnection', store: store, namespaced: true })
export default class hubConnectionModule extends VuexModule {
  private isInitialized = false;

  private identityConnection: signalR.HubConnection | null = null;

  private printingConnectionGroups: SignalRGroupSubscription[] = [];
  private printingConnection: signalR.HubConnection | null = null;

  private monolithConnectionGroups: SignalRGroupSubscription[] = [];
  private monolithConnection: signalR.HubConnection | null = null;

  private connectorConnectionGroups: SignalRGroupSubscription[] = [];
  private connectorConnection: signalR.HubConnection | null = null;

  private billingConnectionGroups: SignalRGroupSubscription[] = [];
  private billingConnection: signalR.HubConnection | null = null;

  private onBillingConnectionReconnected = new SimpleEventDispatcher<signalR.HubConnection>();

  get IsInitialized() {
    return this.isInitialized;
  }

  get IdentityConnection() {
    return this.identityConnection;
  }

  get PrintingConnection() {
    return this.printingConnection;
  }

  get MonolithConnection() {
    return this.monolithConnection;
  }

  get ConnectorConnection() {
    return this.connectorConnection;
  }

  get BillingConnection() {
    return this.billingConnection;
  }

  get BillingConnectionGroups() {
    return this.billingConnectionGroups;
  }

  get PrintingConnectionGroups() {
    return this.printingConnectionGroups;
  }

  get MonolithConnectionGroups() {
    return this.monolithConnectionGroups;
  }

  get ConnectorConnectionGroups() {
    return this.connectorConnectionGroups;
  }

  get OnBillingConnectionReconnected() {
    return this.onBillingConnectionReconnected;
  }

  @Mutation
  setIsInitialized(value: boolean) {
    this.isInitialized = value;
  }

  @Mutation
  setIdentityConnection(connection: signalR.HubConnection | null) {
    this.identityConnection?.stop();
    this.identityConnection = connection;
  }

  @Mutation
  SetPrintingConnection(connection: signalR.HubConnection | null) {
    this.printingConnection?.stop();
    this.printingConnection = connection;
  }

  @Mutation
  SetMonolithConnection(connection: signalR.HubConnection | null) {
    this.monolithConnection?.stop();
    this.monolithConnection = connection;
  }

  @Mutation
  SetConnectorConnection(connection: signalR.HubConnection | null) {
    this.connectorConnection?.stop();
    this.connectorConnection = connection;
  }

  @Mutation
  SetBillingConnection(connection: signalR.HubConnection | null) {
    this.billingConnection?.stop();
    this.billingConnection = connection;
  }

  @Mutation
  resetAllConnections() {
    this.identityConnection = null;
    this.monolithConnection = null;
    this.connectorConnection = null;
    this.billingConnection = null;
  }

  @Mutation
  AddPrintingConnectionGroup(group: SignalRGroupSubscription) {
    const local = this.printingConnectionGroups.firstOrDefault(a => a.func == group.func && a.args == a.args);
    if (local === null) {
      this.printingConnectionGroups.push(group);
    }
  }

  @Mutation
  DeletePrintingConnectionGroup(group: SignalRGroupSubscription) {
    const local = this.printingConnectionGroups.firstOrDefault(a => a.func == group.func && a.args == a.args);
    if (local !== null) {
      this.printingConnectionGroups.delete(local);
    }
  }

  @Mutation
  AddConnectorConnectionGroup(group: SignalRGroupSubscription) {
    this.connectorConnectionGroups.push(group);
  }

  @Mutation
  DeleteConnectorConnectionGroup(group: SignalRGroupSubscription) {
    this.connectorConnectionGroups.delete(group);
  }

  @Mutation
  AddBillingConnectionGroup(group: SignalRGroupSubscription) {
    const local = this.billingConnectionGroups.firstOrDefault(a => a.func == group.func && a.args == a.args);
    if (local === null) {
      this.billingConnectionGroups.push(group);
    }
  }

  @Mutation
  DeleteBillingConnectionGroup(group: SignalRGroupSubscription) {
    const local = this.billingConnectionGroups.firstOrDefault(a => a.func == group.func && a.args == a.args);
    if (local !== null) {
      this.billingConnectionGroups.delete(local);
    }
  }

  @Action({ rawError: true })
  async refreshAllConnections() {
    const token = await LoginModule.getAccessToken();
    try {
      await Promise.allSettled([
        this.connectToIdentity(token),
        this.connectToMonolith(token),
        this.connectToConnector(token),
        this.connectToBilling(token),
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      this.setIsInitialized(true);
    }
  }

  @Action({ rawError: true })
  async connectToIdentity(token: string) {
    if (isPROM) {
      return;
    }

    if (this.identityConnection == null || this.identityConnection.state === signalR.HubConnectionState.Disconnected) {
      const identityHubUri = UtilModule.SHW.getUrl(routes.UriHubs.HUBS, routes.UriHubs.IDENTITY_HUB);
      const identityConnection = UtilModule.SHW.CreateConnection(identityHubUri, token);
      await identityConnection?.start();
      this.setIdentityConnection(identityConnection);
      UtilModule.SubscribeIdentity(identityConnection);
      await CompanyModule.SubscribeToCompanyGroup();
    } else if (this.identityConnection.state === signalR.HubConnectionState.Connected) {
      this.IdentityConnection?.send(hubActions.UPDATE_TOKEN, token);
    }
  }

  @Action({ rawError: true })
  async connectToMonolith(token: string) {
    if (this.PrintingConnection == null || this.PrintingConnection.state === signalR.HubConnectionState.Disconnected) {
      const printingHubUri = UtilModule.SHW.getUrl(routes.UriHubs.HUBS, routes.UriHubs.PRINTING_HUB);
      const printingConnection = UtilModule.SHW.CreateConnection(printingHubUri, token);
      await printingConnection?.start();
      this.SetPrintingConnection(printingConnection);
      await UtilModule.SubscribePrinting(printingConnection);
    } else if (this.PrintingConnection.state === signalR.HubConnectionState.Connected) {
      this.printingConnection?.send(hubActions.UPDATE_TOKEN, token);
    }
    if (isPROM) {
      return;
    }

    if (this.MonolithConnection == null || this.MonolithConnection.state === signalR.HubConnectionState.Disconnected) {
      const monolithHubUri = UtilModule.SHW.getUrl(routes.UriHubs.HUBS, routes.UriHubs.MONOLITH_HUB);
      const monolithConnection = UtilModule.SHW.CreateConnection(monolithHubUri, token);
      await monolithConnection?.start();
      this.SetMonolithConnection(monolithConnection);
      await UtilModule.SubscribeMonolith(monolithConnection);
    } else if (this.MonolithConnection.state === signalR.HubConnectionState.Connected) {
      this.monolithConnection?.send(hubActions.UPDATE_TOKEN, token);
    }
  }

  @Action({ rawError: true })
  async connectToConnector(token: string) {
    if (
      this.ConnectorConnection == null ||
      this.ConnectorConnection.state === signalR.HubConnectionState.Disconnected
    ) {
      const connectorHubUri = UtilModule.SHW.getUrl(routes.UriHubs.HUBS, routes.UriHubs.CONNECTOR_HUB);
      const connectorConnection = UtilModule.SHW.CreateConnection(connectorHubUri, token);
      await connectorConnection?.start();
      this.SetConnectorConnection(connectorConnection);
      await UtilModule.SubscribeConnector();
    } else if (this.ConnectorConnection.state === signalR.HubConnectionState.Connected) {
      this.ConnectorConnection?.send(hubActions.UPDATE_TOKEN, token);
    }
  }

  @Action({ rawError: true })
  async connectToBilling(token: string) {
    if (isPROM || !LoginModule.IsBillingAccountAdmin) return;

    if (this.BillingConnection == null || this.BillingConnection.state === signalR.HubConnectionState.Disconnected) {
      const billingHubUri = UtilModule.SHW.getUrl(routes.UriHubs.HUBS, routes.UriHubs.BILLING_HUB);
      const billingConnection = UtilModule.SHW.CreateConnection(billingHubUri, token, signalR.LogLevel.Error);
      await billingConnection?.start();
      this.SetBillingConnection(billingConnection);
      await UtilModule.SubscribeBilling(billingConnection);
      await this.resubscribeToBillingGroups();
      billingConnection?.onreconnected(async () => {
        await this.resubscribeToBillingGroups();
        this.onBillingConnectionReconnected.dispatch(billingConnection);
      });
    } else if (this.BillingConnection.state === signalR.HubConnectionState.Connected) {
      this.BillingConnection?.send(hubActions.UPDATE_TOKEN, token);
    }
  }

  @Action({ rawError: true })
  private async resubscribeToBillingGroups() {
    for (const sub of this.billingConnectionGroups) {
      if (sub.func.constructor.name == 'AsyncFunction') {
        await sub.func(sub.args);
      } else {
        sub.func(sub.args);
      }
    }
  }
}

export const HubConnectionModule = getModule(hubConnectionModule);
