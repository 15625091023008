<template>
  <div class="company-info">
    <div class="left-panel">
      <ActionCard
        :headerText="InfoCaption"
        :headerButtonDatas="InfoCardButtonDatas"
        class="w-100 mb-3"
        :initiallyCollapsed="InfoCollapsed"
        test-id="company.info"
        @edit-header-button-clicked="editingCompany = true"
        @collapse-toggled="InfoCollapseToggled"
      >
        <TextInput
          :readonly="true"
          :label="CountryCaption"
          :labelWidth="100"
          :value="CompanyCountry"
          :fontSize="13"
          labelColor="#949494"
          valueColor="#cecece"
        />

        <TextInput
          :readonly="true"
          :labelWidth="100"
          :fontSize="13"
          :label="AddressCaption"
          :value="CompanyAddress"
          labelColor="#949494"
          valueColor="#cecece"
        />
      </ActionCard>

      <ActionCard
        :headerText="UsersCaption"
        :headerButtonDatas="UsersCardButtonDatas"
        class="w-100 overflow-auto"
        :initiallyCollapsed="UsersCollapsed"
        test-id="company.users"
        @register-new-user-header-button-clicked="AddingNewUser = true"
        @collapse-toggled="UsersCollapseToggled"
      >
        <SortableHeader
          :header-items="UsersHeader"
          :by.sync="SortByUserHeader"
          :mode.sync="UserSortMode"
          test-id="company.users"
          @sortBy="OnUserSortBy"
        />
        <div class="thin-scroll overflow-auto" @scroll="OnUsersScrolled">
          <ListItemV3
            v-for="item in CompanyUsers"
            :id="item.id"
            :key="item.Id.toString()"
            mainClass="cur-pointer"
            test-id="company.users"
            :items="UserListItems(item)"
            :footerIconFontSize="13"
            :footerIconName="IsCompanyAdmin ? 'fa-trash' : undefined"
            footerIconType="far"
            @openItem="OpenUser(item.Id)"
            @footer-icon-clicked="RemoveUser(item)"
          />
        </div>

        <div :class="['spinner-container', isLoadingUsers ? '' : 'hidden']">
          <div :class="['spinner', isLoadingUsers ? '' : 'hidden']"></div>
        </div>
      </ActionCard>
    </div>

    <div ref="verticalResizer" class="vertical-resizer"></div>

    <div class="right-panel thin-scroll">
      <BasicCompanyEditor
        v-if="editingCompany"
        :company="Company"
        class="mb-3"
        @close-clicked="companyEditorCloseClicked"
        @apply-clicked="handleEditCompanyInfo"
      />

      <AddCompanyUser v-if="AddingNewUser" ref="addCompanyUser" class="mb-3" @close-clicked="AddingNewUser = false" />

      <ActionCard
        :headerText="AdminsCaption"
        :headerButtonDatas="AdminsCardButtonDatas"
        class="w-100 overflow-auto"
        test-id="company.admins"
        :initiallyCollapsed="AdminsCollapsed"
        @add-header-button-clicked="AddCompanyAdmin"
        @collapse-toggled="AdminsCollapseToggled"
      >
        <SortableHeader
          :header-items="AdminUsersHeader"
          :by.sync="SortByAdminUserHeader"
          :mode.sync="AdminUserSortMode"
          test-id="company.admins"
          @sortBy="OnAdminUserSortBy"
        />
        <div class="thin-scroll overflow-auto" @scroll="OnAdminUsersScrolled">
          <ListItemV3
            v-for="item in CompanyAdminUsers"
            :id="item.id"
            :key="item.Id.toString()"
            :items="AdminUserListItems(item)"
            mainClass="cur-pointer"
            :footerIconFontSize="13"
            :footerIconName="IsCompanyAdmin ? 'fa-trash' : undefined"
            footerIconType="far"
            test-id="company.admins"
            @openItem="OpenUser(item.Id)"
            @footer-icon-clicked="RemoveCompanyAdmin(item)"
          />
        </div>

        <div :class="['spinner-container', isLoadingAdminUsers ? '' : 'hidden']">
          <div :class="['spinner', isLoadingAdminUsers ? '' : 'hidden']"></div>
        </div>
      </ActionCard>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { Company, CompanyUser, LoginSession, Role, User } from '@/models/Entities';

import SortableHeader from '@/components/presentation/SortableHeader.vue';
import ActionCard, { HeaderButtonData } from '@/components/presentation/ActionCard.vue';
import ListItemV3 from '@/components/presentation/ListItemV3.vue';
import BasicCompanyEditor from '@/components/forms/BasicCompanyEditor.vue';
import AddCompanyUser from '@/components/forms/AddCompanyUser.vue';

import ComponentHelper, { HeaderItem, ItemData, SortMode } from '@/util/ComponentHelper';

import { JobModule } from '@/store/modules/jobModule';
import { Countries } from '@/models/responses/Countries';

import en from '@/localization/en';
import { LoadUsersPageData, SortUsers, UserModule, UserPageSize } from '@/store/modules/userModule';
import { Guid } from 'guid-typescript';
import { create } from 'vue-modal-dialogs';
import SelectUserDialog from '@/views/dialogs/company/SelectUserDialog.vue';
import { RoleModule } from '@/store/modules/roleModule';
import { LoginSessionModule } from '@/store/modules/loginSessionModule';
import { UserSortBy } from '@/models/requests/RequestIdentity';

import { delay } from 'lodash';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { toast } from '@/main';
import { POSITION } from 'vue-toastification';
import { LoginModule } from '@/store/modules/loginModule';
import { GlobalDataModule } from '@/store/modules/globalDataModule';
import AuraMessageBoxDialog, { Result } from '@/views/dialogs/AuraMessageBoxDialog.vue';
import TextInput from '@/components/inputs/TextInput.vue';

const EMAIL_WIDTH = 250;
const LASTSEEN_WIDTH = 125;

const AddCompanyAdminDialog = create<Company | null, Role[] | null, Role[] | null, string, string>(
  SelectUserDialog,
  'company',
  'includeRoles',
  'excludeRoles',
  'header',
  'testId',
);

const RemoveUserSure = create<Result, String, String, Result>(AuraMessageBoxDialog, 'results', 'header', 'mainText');

@Component({
  name: 'company-info',
  components: {
    SortableHeader: SortableHeader,
    ActionCard: ActionCard,
    ListItemV3: ListItemV3,
    BasicCompanyEditor: BasicCompanyEditor,
    AddCompanyUser: AddCompanyUser,
    TextInput: TextInput,
  },
})
export default class CompanyInfo extends Vue {
  @Prop() private Company!: Company;
  private componentId!: Guid;
  private componentIdAdmins!: Guid;

  @Watch('Company', { immediate: true, deep: true })
  private async OnCompanyChanged(newValue: Company, oldValue: Company) {
    if (oldValue && newValue.Id.toString() != oldValue.Id.toString()) {
      await UserModule.DeleteFromCompanyUsersGroup();
      if (this.Users.length > 0) {
        let users = this.Users.map(a => a.user);
        let loginSessions = Array.from(this.Users.map(a => a.loginSessions)).reduce(
          (accumulator: LoginSession[], value) => accumulator.concat(value),
          [],
        );
        await UserModule.LooseUsersNew([users.map(a => a.Id), this.componentId]);
        await UserModule.LooseUsersNew([users.map(a => a.Id), this.componentIdAdmins!]);
        await LoginSessionModule.LooseLoginSessions([loginSessions.map(a => a.Id), this.componentId]);
      }
    }
    if (newValue && oldValue != null && newValue.Id.toString() != oldValue.Id.toString()) {
      await UserModule.SubscribeToCompanyUsersGroup();

      await UserModule.LooseUsersNew([this.Users.map(a => a.user.Id), this.componentId!]);
      await UserModule.LooseUsersNew([this.Users.map(a => a.user.Id), this.componentIdAdmins!]);

      await UserModule.CollectUsers();

      this.usersPage = 0;
      this.adminUserPage = 0;

      await this.LoadMoreUsers();
      await this.LoadMoreAdminUsers();
    }
  }

  private get InfoCardButtonDatas(): HeaderButtonData[] {
    if (!this.IsCompanyAdmin) return [];

    return [
      {
        iconType: 'far',
        iconName: 'fa-pen',
        text: this.EditCaption,
        name: 'edit',
        testId: 'editButton',
      },
    ];
  }

  private get UsersCardButtonDatas(): HeaderButtonData[] {
    if (!this.IsCompanyAdmin) return [];

    return [
      {
        iconType: 'far',
        iconName: 'fa-plus-square',
        text: this.RegisterNewUserCaption,
        name: 'register-new-user',
        testId: 'registerNewUserButton',
      },
    ];
  }

  private get AdminsCardButtonDatas(): HeaderButtonData[] {
    if (!this.IsCompanyAdmin) return [];

    return [
      {
        iconType: 'far',
        iconName: 'fa-plus-square',
        text: this.AddCaption,
        name: 'add',
        testId: 'addNewAdminButton',
      },
    ];
  }
  //#endregion

  private editingCompany: boolean = false;

  private isOpeningUser: boolean = false;

  private get CompanyCountry(): string {
    return this.Company == null ? '' : this.GetCountryName(this.Company.Country);
  }

  private get CompanyAddress(): string {
    return this.Company == null ? '' : this.Company.Address;
  }

  private get Users(): { user: User; loginSessions: LoginSession[] }[] {
    let users = UserModule.Users;

    let userWithSessions: {
      user: User;
      loginSessions: LoginSession[];
    }[] = [];

    for (let item of users) {
      let userLss = LoginSessionModule.LoginSessions.filter(a => a.UserId.equals(item.Id));
      userWithSessions.push({ user: item, loginSessions: userLss });
      LoginSessionModule.LoginSessionsSync([userLss, this.componentId]);
    }

    return userWithSessions;
  }

  private get CompanyTitle(): string {
    if (this.Company == null) return '';
    return this.Company.Name;
  }

  private get IsCompanyAdmin(): boolean {
    return LoginModule.IsAdmin;
  }

  private get IsConnectUser(): boolean {
    return LoginModule.IsConnectUser;
  }

  private get IsSuperAdmin(): boolean {
    return LoginModule.IsSuperAdmin;
  }

  //#region VIEW STATE

  private get InfoCollapsed() {
    return GlobalDataModule.CompanyViewState.infoTabViewState.infoCollapsed;
  }

  private get UsersCollapsed() {
    return GlobalDataModule.CompanyViewState.infoTabViewState.usersCollapsed;
  }

  private get AdminsCollapsed() {
    return GlobalDataModule.CompanyViewState.infoTabViewState.adminsCollapsed;
  }

  //#endregion

  //#region USERS RELATED
  private AddingNewUser: boolean = false;
  private canScrollLoadUsers: boolean = true;

  private get CompanyUsers(): CompanyUser[] {
    if (this.Users.length == 0) return [];

    let companyUsers: CompanyUser[] = [];

    for (let item of this.Users) {
      let model = new CompanyUser();
      model.Id = item.user.Id;
      model.Email = item.user.Email;
      model.FullName = ComponentHelper.GetFullname(item.user);
      if (item.loginSessions.length > 0) {
        let lastSeenMax = item.loginSessions.map((a: LoginSession) => a.LastSeen.getTime()).max();
        let loginSession = item.loginSessions.firstOrDefault((a: LoginSession) => a.LastSeen.getTime() === lastSeenMax);
        if (loginSession != null) model.LastSeen = loginSession.LastSeen;
      } else model.LastSeen = new Date(0);
      model.TotalPrints = JobModule.JobCount.get(item.user) || 0;
      companyUsers.push(model);
    }

    companyUsers = SortUsers(
      companyUsers.map(a => a),
      this.GetUserSortBy(this.SortByUserHeader),
      this.UserSortMode,
    );

    const maxCount = (this.usersPage + 1) * UserPageSize;

    const toDisplay = companyUsers.slice(0, maxCount);
    const toLoose = companyUsers.slice(maxCount);

    UserModule.LooseUsersNew([toLoose.map(a => a.Id), this.componentId]);
    UserModule.OccupyUsers([toDisplay.map(a => a.Id), this.componentId]);

    if (toLoose.length > 0) {
      UserModule.CollectUsers();
    }

    // console.log("USERS", toDisplay.length);

    return toDisplay;
  }

  private sortByUserHeader: HeaderItem | null = null;
  private userSortMode: SortMode | null = null;
  private usersPage: number = 0;

  private isLoadingUsers: boolean = false;

  private get SortByUserHeader(): HeaderItem | null {
    return this.sortByUserHeader;
  }
  private set SortByUserHeader(value: HeaderItem | null) {
    this.sortByUserHeader = value;
  }
  private get UserSortMode(): SortMode | null {
    return this.userSortMode;
  }
  private set UserSortMode(value: SortMode | null) {
    this.userSortMode = value;
  }
  //#endregion

  //#region ADMINS RELATED
  private get CompanyAdminUsers(): CompanyUser[] {
    if (this.Users.length == 0) return [];

    let companyUsers: CompanyUser[] = [];

    for (let item of this.Users) {
      if (item.user.Roles.firstOrDefault(a => a.IsAdmin) === null) continue;

      let model = new CompanyUser();
      model.Id = item.user.Id;
      model.Email = item.user.Email;
      model.FullName = ComponentHelper.GetFullname(item.user);
      if (item.loginSessions.length > 0) {
        let lastSeenMax = item.loginSessions.map((a: LoginSession) => a.LastSeen.getTime()).max();
        let loginSession = item.loginSessions.firstOrDefault((a: LoginSession) => a.LastSeen.getTime() === lastSeenMax);
        if (loginSession != null) model.LastSeen = loginSession.LastSeen;
      } else model.LastSeen = new Date(0);
      model.TotalPrints = JobModule.JobCount.get(item.user) || 0;
      companyUsers.push(model);
    }

    companyUsers = SortUsers(
      companyUsers.map(a => a),
      this.GetAdminUserSortBy(this.SortByAdminUserHeader),
      this.AdminUserSortMode,
    );

    const maxCount = (this.adminUserPage + 1) * UserPageSize;

    const toDisplay = companyUsers.slice(0, maxCount);
    const toLoose = companyUsers.slice(maxCount);

    UserModule.LooseUsersNew([toLoose.map(a => a.Id), this.componentIdAdmins]);
    UserModule.OccupyUsers([toDisplay.map(a => a.Id), this.componentIdAdmins]);

    if (toLoose.length > 0) {
      UserModule.CollectUsers();
    }

    // console.log("ADMINS", toDisplay.length);

    return toDisplay;
  }

  private sortByAdminUserHeader: HeaderItem | null = null;
  private adminUserSortMode: SortMode | null = null;
  private adminUserPage: number = 0;

  private isLoadingAdminUsers: boolean = false;
  private canScrollLoadAdminUsers: boolean = true;

  private get SortByAdminUserHeader(): HeaderItem | null {
    return this.sortByAdminUserHeader;
  }
  private set SortByAdminUserHeader(value: HeaderItem | null) {
    this.sortByAdminUserHeader = value;
  }
  private get AdminUserSortMode(): SortMode | null {
    return this.adminUserSortMode;
  }
  private set AdminUserSortMode(value: SortMode | null) {
    this.adminUserSortMode = value;
  }
  //#endregion

  //#endregion

  //#region HEADERS AND ITEMS
  private userEmailHeader: HeaderItem = {
    caption: en.email.titleCase(),
    itemClass: ComponentHelper.GetWidthClass(EMAIL_WIDTH),
    isSortable: true,
  };
  private userFullNameHeader: HeaderItem = {
    caption: en.nameCaption.titleCase(),
    itemClass: ComponentHelper.GetWidthClass(),
    isSortable: true,
  };
  private userPrintsHeader: HeaderItem = {
    caption: en.prints.titleCase(),
    itemClass: ComponentHelper.GetWidthClass(),
    isSortable: true,
  };
  private userLastSeenHeader: HeaderItem = {
    caption: en.lastSeen.titleCase(),
    itemClass: ComponentHelper.GetWidthClass(LASTSEEN_WIDTH),
    isSortable: true,
  };
  private get UsersHeader(): HeaderItem[] {
    if (this.Company == null) return [];
    let headerItems: HeaderItem[] = [];
    headerItems.push(this.userEmailHeader);
    headerItems.push(this.userFullNameHeader);

    if (this.IsConnectUser) {
      headerItems.push(this.userPrintsHeader);
    }

    headerItems.push(this.userLastSeenHeader);
    return headerItems;
  }
  private UserListItems(value: CompanyUser): ItemData[] {
    let items: ItemData[] = [];
    let itemEmail: ItemData = new ItemData('Email', value.Email, EMAIL_WIDTH);
    let itemFullName: ItemData = new ItemData('FullName', value.FullName, 'grow-1');
    let itemTotalPrints: ItemData = new ItemData('TotalPrints', value.TotalPrints.toString(), undefined);
    let itemLastSeen: ItemData = new ItemData(
      'LastSeen',
      ComponentHelper.GetSimpleDate(value.LastSeen),
      LASTSEEN_WIDTH,
    );
    items.push(itemEmail);
    items.push(itemFullName);

    if (this.IsConnectUser) {
      items.push(itemTotalPrints);
    }

    items.push(itemLastSeen);
    return items;
  }

  private adminsEmailHeader: HeaderItem = {
    caption: en.email.titleCase(),
    itemClass: ComponentHelper.GetWidthClass(EMAIL_WIDTH),
    isSortable: true,
  };
  private adminsFullNameHeader: HeaderItem = {
    caption: en.nameCaption.titleCase(),
    itemClass: ComponentHelper.GetWidthClass(),
    isSortable: true,
  };
  private adminsLastSeenHeader: HeaderItem = {
    caption: en.lastSeen.titleCase(),
    itemClass: ComponentHelper.GetWidthClass(LASTSEEN_WIDTH),
    isSortable: true,
  };
  private get AdminUsersHeader(): HeaderItem[] {
    if (this.Company == null) return [];
    let headerItems: HeaderItem[] = [];
    headerItems.push(this.adminsEmailHeader);
    headerItems.push(this.adminsFullNameHeader);
    headerItems.push(this.adminsLastSeenHeader);
    return headerItems;
  }
  private AdminUserListItems(value: CompanyUser): ItemData[] {
    let items: ItemData[] = [];
    let itemEmail: ItemData = new ItemData('Email', value.Email, EMAIL_WIDTH);
    let itemFullName: ItemData = new ItemData('FullName', value.FullName, 'grow-1');
    let itemLastSeen: ItemData = new ItemData(
      'LastSeen',
      ComponentHelper.GetSimpleDate(value.LastSeen),
      LASTSEEN_WIDTH,
    );
    items.push(itemEmail);
    items.push(itemFullName);
    items.push(itemLastSeen);
    return items;
  }
  //#endregion

  //#region LOGIC
  //#region USER RELATED
  private GetUserSortBy(headerItem: HeaderItem | null): UserSortBy | null {
    if (headerItem === this.userEmailHeader) return UserSortBy.BY_EMAIL;
    else if (headerItem === this.userPrintsHeader) return UserSortBy.BY_PRINTS;
    else if (headerItem === this.userFullNameHeader) return UserSortBy.BY_FULL_NAME;
    else if (headerItem === this.userLastSeenHeader) return UserSortBy.BY_LAST_SEEN;

    return null;
  }

  private GetUserLoadData(): LoadUsersPageData {
    return {
      companyId: this.Company.Id,
      sortBy: this.GetUserSortBy(this.SortByUserHeader),
      sortMode: this.UserSortMode,
      page: this.usersPage,
      includeRoles: [],
      excludeRoles: [],
    };
  }

  private async LoadMoreUsers() {
    if (this.isLoadingUsers || this.Company === null) return;

    this.isLoadingUsers = true;

    const loaded = await UserModule.LoadUsersForCompanyPaged(this.GetUserLoadData());
    await JobModule.LoadCountsForUsers(this.Users.map(a => a.user));

    this.isLoadingUsers = false;

    return loaded;
  }

  private async OnUserSortBy() {
    UserModule.LooseUsersNew([this.Users.map(a => a.user.Id), this.componentId]);
    UserModule.CollectUsers();
    this.usersPage = 0;

    await this.LoadMoreUsers();
  }

  private async OnUsersScrolled(e: any) {
    const currScroll = e.target.scrollTop;
    const endScroll = e.target.scrollHeight - e.target.clientHeight;

    if (currScroll === endScroll && currScroll > 0 && this.canScrollLoadUsers) {
      ++this.usersPage;

      this.canScrollLoadUsers = false;

      var loaded = await this.LoadMoreUsers();

      if (loaded == 0) {
        --this.usersPage;
      }
      delay(() => {
        this.canScrollLoadUsers = true;
      }, 500);
    }
  }
  //#endregion

  //#region ADMIN USER RELATED
  private GetAdminUserSortBy(headerItem: HeaderItem | null): UserSortBy | null {
    if (headerItem === this.adminsEmailHeader) return UserSortBy.BY_EMAIL;
    else if (headerItem === this.adminsFullNameHeader) return UserSortBy.BY_FULL_NAME;
    else if (headerItem === this.adminsLastSeenHeader) return UserSortBy.BY_LAST_SEEN;

    return null;
  }

  private GetAdminUserLoadData(): LoadUsersPageData {
    return {
      companyId: this.Company.Id,
      sortBy: this.GetAdminUserSortBy(this.SortByAdminUserHeader),
      sortMode: this.AdminUserSortMode,
      page: this.adminUserPage,
      includeRoles: [RoleModule.Roles.firstOrDefault(a => a.IsAdmin)!],
      excludeRoles: [],
    };
  }

  private async LoadMoreAdminUsers() {
    if (this.isLoadingAdminUsers || this.Company === null) return;

    this.isLoadingAdminUsers = true;

    const loaded = await UserModule.LoadUsersForCompanyPaged(this.GetAdminUserLoadData());
    await JobModule.LoadCountsForUsers(this.Users.map(a => a.user));

    this.isLoadingAdminUsers = false;

    return loaded;
  }

  private async OnAdminUserSortBy() {
    UserModule.LooseUsersNew([this.Users.map(a => a.user.Id), this.componentIdAdmins]);
    UserModule.CollectUsers();
    this.adminUserPage = 0;

    await this.LoadMoreAdminUsers();
  }

  private async OnAdminUsersScrolled(e: any) {
    const currScroll = e.target.scrollTop;
    const endScroll = e.target.scrollHeight - e.target.clientHeight;

    if (currScroll === endScroll && currScroll > 0 && this.canScrollLoadAdminUsers) {
      ++this.adminUserPage;

      this.canScrollLoadAdminUsers = false;

      var loaded = await this.LoadMoreAdminUsers();

      if (loaded == 0) {
        --this.adminUserPage;
      }
      delay(() => {
        this.canScrollLoadAdminUsers = true;
      }, 500);
    }
  }

  private companyEditorCloseClicked() {
    this.editingCompany = false;
  }

  handleEditCompanyInfo(isSuccess?: boolean) {
    if (isSuccess === true) {
      toast.success(en.changedCompanyInfo, {
        position: POSITION.BOTTOM_RIGHT,
      });
      this.editingCompany = false;
    } else if (isSuccess === false) {
      toast.error(en.couldNotChangeCompanyInfo, {
        position: POSITION.BOTTOM_RIGHT,
      });
    }
  }

  async AddCompanyAdmin() {
    const result = await AddCompanyAdminDialog(
      this.Company,
      RoleModule.Roles.map(a => a),
      RoleModule.Roles.filter(a => a.IsAdmin),
      en.addCompanyAdminHeader,
      'addCompanyAdminDialog',
    );

    if (result === null) return;

    var user = this.Users.firstOrDefault(a => a.user.Id == result!.Id)?.user!;
    var role = RoleModule.Roles.firstOrDefault(a => a.IsAdmin)!;

    await UserModule.AddRole([user, role]);
  }

  async RemoveUser(user: CompanyUser) {
    const userConfirmation = await RemoveUserSure(
      Result.Yes | Result.No,
      'Delete user',
      `Are you sure you want to delete user ${user.FullName}?`,
    );

    if (userConfirmation == Result.No || userConfirmation == Result.Cancel) {
      return;
    }

    var found = this.Users.firstOrDefault(a => a.user.Id.equals(user.Id))?.user!;

    var result = await UserModule.DeleteUser(found);

    if (result[0] !== RequestStatus.OK) {
      toast.error(en.couldNotDeleteUser.titleCase(true), {
        position: POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.success(en.userDeleted.titleCase(true), {
        position: POSITION.BOTTOM_RIGHT,
      });
    }
  }

  async RemoveCompanyAdmin(user: CompanyUser) {
    const userConfirmation = await RemoveUserSure(
      Result.Yes | Result.No,
      'Delete company admin',
      `Are you sure you want to remove ${user.FullName} from company admins?`,
    );

    if (userConfirmation == Result.No || userConfirmation == Result.Cancel) {
      return;
    }

    var found = this.Users.firstOrDefault(a => a.user.Id.equals(user.Id))?.user!;
    var role = RoleModule.Roles.firstOrDefault(a => a.IsAdmin)!;

    var result = await UserModule.DeleteRole([found, role]);

    if (result[0] !== RequestStatus.OK) {
      toast.error(en.couldNotDeleteCompanyAdmin.titleCase(true), {
        position: POSITION.BOTTOM_RIGHT,
      });
    }
  }

  //#region VIEW STATE

  private InfoCollapseToggled(newValue: boolean) {
    GlobalDataModule.ChangeCompanyViewInfoTabInfoCollapsed(newValue);
  }

  private UsersCollapseToggled(newValue: boolean) {
    GlobalDataModule.ChangeCompanyViewInfoTabUsersCollapsed(newValue);
  }

  private AdminsCollapseToggled(newValue: boolean) {
    GlobalDataModule.ChangeCompanyViewInfoTabAdminsCollapsed(newValue);
  }

  //#endregion

  //#endregion

  //#region EVENTS
  @Emit('openUser')
  private OpenUser() {
    this.isOpeningUser = true;
  }
  //#endregion

  //#region HOOKS
  async beforeCreate() {
    this.componentId = Guid.create();
    this.componentIdAdmins = Guid.create();
  }

  async activated() {
    this.isOpeningUser = false;

    await UserModule.SubscribeToCompanyUsersGroup();

    await UserModule.LooseUsersNew([this.Users.map(a => a.user.Id), this.componentId!]);
    await UserModule.LooseUsersNew([this.Users.map(a => a.user.Id), this.componentIdAdmins!]);

    await UserModule.CollectUsers();

    this.usersPage = 0;
    this.adminUserPage = 0;

    await this.LoadMoreUsers();
    await this.LoadMoreAdminUsers();
  }

  async deactivated() {
    await UserModule.DeleteFromCompanyUsersGroup();

    await UserModule.LooseUsersNew([this.Users.map(a => a.user.Id), this.componentId!]);
    await UserModule.LooseUsersNew([this.Users.map(a => a.user.Id), this.componentIdAdmins!]);

    if (!this.isOpeningUser) {
      await UserModule.CollectUsers();
    }
  }

  async mounted() {}
  //#endregion

  //#region TRANSLATIONS
  private GetCountryName(country: Countries): string {
    return en.CountryNames[country];
  }

  private get UsersCaption() {
    return en.users.titleCase();
  }

  private get InfoCaption() {
    return en.info.titleCase();
  }

  private get EditCaption() {
    return en.edit.titleCase();
  }

  private get CountryCaption() {
    return en.country.titleCase();
  }

  private get AddressCaption() {
    return en.address.titleCase();
  }

  private get RegisterNewUserCaption() {
    return en.registerNewUser.titleCase();
  }

  private get AdminsCaption() {
    return en.admins.titleCase();
  }

  private get AddCaption() {
    return en.add.titleCase();
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.company-info {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;

  .left-panel {
    width: 55%;
    max-height: 100%;
    min-width: 750px;
    display: flex;
    flex-direction: column;
  }

  .right-panel {
    width: 45%;
    overflow: auto;
    min-width: 550px;
    display: flex;
    flex-direction: column;
  }

  .company-info-line {
    display: flex;
    font-size: 13px;
    margin-bottom: 0.75rem;
    &:last-child {
      margin-bottom: 0;
    }

    margin-left: 8px;
    .field {
      color: #949494;
      width: 138px;
      text-align: start;
    }

    .value {
      color: #cecece;
    }
  }

  .vertical-resizer {
    cursor: col-resize;
    width: 10px;
    margin: 0 3px;
  }

  .spinner-container {
    min-height: 40px;
    margin-top: 20px;
    position: relative;
    opacity: 1;

    &.hidden {
      opacity: 0;
      min-height: 0px;
      margin-top: 0px;
    }

    transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);
  }
}
</style>
