<template>
  <div class="h-100 app-container d-flex flex-column">
    <div :class="['global-notification']" :style="GlobalNotificationStyle">
      <span class="my-2">{{ GlobalMessage == null ? '' : GlobalMessage.message }}</span>
      <button data-testid="globalNotification.closeButton" @click="HideCurrentGlobalMessage">
        <i class="fas fa-times" aria-hidden="true"></i>
      </button>
    </div>

    <div v-if="!isPROM" class="workfield-header" :style="WorkfieldHeaderStyle">
      <div class="content">
        <img
          ref="auraLogo"
          class="aura-logo"
          src="@/assets/svg/aura-connect-logo.svg"
          alt="logo"
          data-testid="header.logo"
          @click="AuraLogoClicked"
        />
        <span class="username" data-testid="header.userName" @click="HeaderUsernameClicked">{{
          CurrentUserEmail
        }}</span>
        <div class="separator mx-3"></div>
        <div class="logout-button" data-testid="header.logoutButton" @click="LogoutButtonClicked">
          <span class="mr-2">{{ LogoutCaption }}</span>
          <i class="fas fa-sign-out-alt"></i>
        </div>
      </div>
      <div class="bottom-border"></div>
    </div>

    <main :class="['thin-scroll d-flex flex-row overflow-auto flex-grow-1']">
      <side-menu v-if="!isPROM" :items="sideMenuItems" :specialItem="specialItem" :checkedItem="checkedItem" />
      <div class="inner-container">
        <component :is="ActiveComponent" v-if="isInitialized" v-bind="componentProperties" v-on="ComponentEvents" />
        <div v-else class="spinner"></div>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import confetti from 'canvas-confetti';
import { Guid } from 'guid-typescript';

import { WorkFieldChild } from '@/router';
import * as hubActions from '@/util/api/hubActions';
import { ResponseIdentityHelper, ResponseUser } from '@/models/responses/ResponseIdentity';
import SideMenu from '../components/SideMenu.vue';
import CompanyView from './company/CompanyView.vue';
import MainDashboard from './MainDashboard.vue';
import en from '../localization/en';
import { LoginModule } from '@/store/modules/loginModule';
import { HubConnectionModule } from '@/store/modules/hubConnectionModule';
import { UserModule } from '@/store/modules/userModule';
import { UtilModule } from '@/store/modules/utilModule';
import { GlobalNotification } from '@/models/Entities';
import { GlobalNotificationsModule } from '@/store/modules/globalNotificationsModule';
import AdminView from '@/views/AdminView.vue';
import { JobsRouteTab, Routes } from '@/router/routes';
import FileLibraryNew from './FileLibraryNew.vue';
import UserInfoNew from './UserInfoNew.vue';
import JobsNew from './jobs/JobsNew.vue';
import { GlobalDataModule } from '@/store/modules/globalDataModule';
import MachinesNew from './MachinesNew.vue';
import { TypeHelper } from '@/util/TypeHelper';
import { isPROM } from '@/util/env';

export type ComponentNames =
  | 'main-dashboard'
  | 'company-view'
  | 'user-info-new'
  | 'admin-view'
  | 'file-library-new'
  | 'jobs-new'
  | 'machines-new';

export type WFComponentData = {
  componentName: ComponentNames;
  name: string;
  routeName: string;
  symbol?: string;
};
type WFComponent = Record<WorkFieldChild, WFComponentData>;

const globalNotificationHeight = '40px';

@Component({
  components: {
    SideMenu: SideMenu,
    UserInfoNew: UserInfoNew,
    CompanyView: CompanyView,
    FileLibraryNew: FileLibraryNew,
    JobsNew: JobsNew,
    AdminView: AdminView,
    MainDashboard: MainDashboard,
    MachinesNew: MachinesNew,
  },
})
export default class WorkField extends Vue {
  @Prop() pageParam!: any;

  @Ref('auraLogo') auraLogo!: HTMLImageElement;

  //#region WATCHERS
  @Watch('IsSuperAdmin', { immediate: true, deep: false })
  private async onIsSuperAdminChanged(newValue: boolean) {
    if (newValue) {
      let data = this.Children[WorkFieldChild.ADMIN];
      this.sideMenuItems.push(data);
    } else {
      let data = this.Children[WorkFieldChild.ADMIN];
      this.sideMenuItems.delete(data);
    }
  }

  @Watch('pageParam', { immediate: true, deep: false })
  private async onPageParamChanged(newValue: any) {
    if (!newValue) return;
    if ('page' in this.pageParam) {
      this.checkedItem = this.Children[this.pageParam.page as WorkFieldChild];

      switch (this.pageParam.page) {
        case WorkFieldChild.COMPANY:
          this.componentProperties = { companyId: LoginModule.CompanyId };
          //TODO: сделать загрузку имени компании (где-то параллельно с коннектом)
          //this.children[WorkFieldChild.COMPANY].name = company.Name;
          break;
        case WorkFieldChild.MY_ACCOUNT: {
          let userMe = await UserModule.LoadUser(LoginModule.UserId);
          this.componentProperties = { user: userMe };
          break;
        }
        case WorkFieldChild.USER: {
          let user = null;

          if (newValue.id !== null) {
            user = await UserModule.LoadUser(newValue.id);
          }

          if (user != null) this.componentProperties = { user: user };
          break;
        }
        case WorkFieldChild.FILE_LIBRARY:
          this.componentProperties = {
            input: {
              companyId: LoginModule.CompanyId,
              sourceId: newValue.id,
            },
          };
          break;
        case WorkFieldChild.JOBS:
          this.componentProperties = {
            input: {
              companyId: LoginModule.CompanyId,
              jobId: newValue.id,
            },
          };
          break;
        case WorkFieldChild.MACHINES:
          this.componentProperties = {
            input: {
              companyId: LoginModule.CompanyId,
              printerId: newValue.id,
            },
          };
          break;
        case WorkFieldChild.ADMIN:
          break;
        case WorkFieldChild.DASHBOARD:
          break;
        default:
          if (this.$router.currentRoute.path != '/') {
            await this.$router.push({ name: Routes.MY_ACCOUNT });
          }
          break;
      }
    }
  }
  //#endregion

  //#region STATE
  private componentProperties: Object = {};

  private children: WFComponent = {
    [WorkFieldChild.DASHBOARD]: {
      componentName: 'main-dashboard',
      name: en.dashboard,
      routeName: Routes.DASHBOARD,
      symbol: 'far fa-tachometer-alt-fast',
    },
    [WorkFieldChild.COMPANY]: {
      componentName: 'company-view',
      name: en.company,
      routeName: Routes.COMPANY,
      symbol: 'far fa-users',
    },
    [WorkFieldChild.FILE_LIBRARY]: {
      componentName: 'file-library-new',
      name: en.fileLibrary,
      routeName: Routes.FILE_LIBRARY,
      symbol: 'far fa-cabinet-filing',
    },
    [WorkFieldChild.MACHINES]: {
      componentName: 'machines-new',
      name: en.machines,
      routeName: Routes.MACHINES,
      symbol: 'far fa-sitemap',
    },
    [WorkFieldChild.JOBS]: {
      componentName: 'jobs-new',
      name: en.jobs,
      routeName: Routes.JOBS,
      symbol: 'fal fa-tasks',
    },
    [WorkFieldChild.MY_ACCOUNT]: {
      componentName: 'user-info-new',
      name: en.account,
      routeName: Routes.MY_ACCOUNT,
      symbol: 'far fa-user-circle',
    },
    [WorkFieldChild.USER]: {
      componentName: 'user-info-new',
      name: 'user',
      routeName: Routes.USER,
    },
    [WorkFieldChild.ADMIN]: {
      componentName: 'admin-view',
      name: 'admin',
      routeName: Routes.ADMIN,
      symbol: 'far fa-user-lock',
    },
  };

  private sideMenuItems: WFComponentData[] = [];

  private specialItem: WFComponentData = this.Children[WorkFieldChild.DASHBOARD];

  private checkedItem: WFComponentData = this.Children[WorkFieldChild.DASHBOARD];

  private get WorkfieldHeaderStyle() {
    if (this.GlobalMessage != null) {
      return {
        'margin-top': globalNotificationHeight,
        'max-height': `calc(100% - ${globalNotificationHeight})`,
      };
    }

    return {
      'margin-top': '0px',
      'max-height': '0px',
    };
  }

  private get GlobalNotificationStyle() {
    if (this.GlobalMessage == null) {
      return {
        transform: `translateY(-${globalNotificationHeight})`,
      };
    }

    return {
      transform: `translateY(0px)`,
    };
  }

  private get Children(): WFComponent {
    return this.children;
  }

  private get CurrentUserEmail() {
    return LoginModule.Me?.Email;
  }

  private get IsSuperAdmin() {
    return LoginModule.IsSuperAdmin;
  }

  private get GlobalMessage(): GlobalNotification | null {
    if (GlobalNotificationsModule.GlobalNotificationsRelevant.length == 0) {
      return null;
    }

    return GlobalNotificationsModule.GlobalNotificationsRelevant[0];
  }

  private get ComponentEvents() {
    if (this.pageParam === undefined) {
      return null;
    }

    if ('page' in this.pageParam) {
      if (this.pageParam.page === WorkFieldChild.USER)
        return {
          close: this.DoShowCompany,
          openJob: this.OpenJob,
          openArchive: this.OpenArchive,
        };
      if (this.pageParam.page === WorkFieldChild.MY_ACCOUNT)
        return {
          close: this.DoShowCompany,
          openJob: this.OpenJob,
          openArchive: this.OpenArchive,
        };
      if (this.pageParam.page === WorkFieldChild.COMPANY) return { openUser: this.OpenUser };
      if (this.pageParam.page === WorkFieldChild.FILE_LIBRARY)
        return { openUser: this.OpenUser, openArchive: this.OpenArchive };
      if (this.pageParam.page === WorkFieldChild.MACHINES)
        return {
          openUser: this.OpenUser,
          openJob: this.OpenJob,
          openArchive: this.OpenArchive,
        };
      if (this.pageParam.page === WorkFieldChild.JOBS)
        return {
          openMachine: this.OpenMachine,
          openSource: this.OpenSource,
          openUser: this.OpenUser,
        };
      if (this.pageParam.page === WorkFieldChild.ADMIN)
        return {
          openUser: this.OpenUser,
        };
    }

    return null;
  }

  private get ActiveComponent(): string {
    if (this.pageParam === undefined || !('page' in this.pageParam))
      return this.Children[WorkFieldChild.DASHBOARD].componentName;
    return this.Children[this.pageParam.page as WorkFieldChild].componentName;
  }
  //#endregion

  //#region LOGIC
  async HideCurrentGlobalMessage() {
    await GlobalNotificationsModule.HideGlobalMessage(this.GlobalMessage!);
  }

  private DoShowCompany() {
    this.$router.push({ name: Routes.COMPANY });
  }

  private OpenUser(userId: Guid) {
    this.$router.push({
      name: Routes.USER,
      params: { id: userId.toString() },
      query: { userId: userId.toString() },
    });
  }

  private OpenMachine(machineId: Guid) {
    this.$router.push({
      name: Routes.MACHINES,
      params: { id: machineId.toString() },
      query: { machineId: machineId.toString() },
    });
  }

  private OpenSource(sourceId: Guid) {
    this.$router.push({
      name: Routes.FILE_LIBRARY,
      params: { id: sourceId.toString() },
      query: { sourceId: sourceId.toString() },
    });
  }

  private OpenJob(jobId: Guid) {
    GlobalDataModule.ChangeJobsViewTab(JobsRouteTab.CURRENT);
    this.$router.push({
      name: Routes.JOBS,
      params: { id: jobId.toString() },
      query: { tab: 'current', jobId: jobId.toString() },
    });
  }

  private OpenArchive(archiveId: Guid) {
    GlobalDataModule.ChangeJobsViewTab(JobsRouteTab.CURRENT);
    this.$router.push({
      name: Routes.JOBS,
      params: { id: archiveId.toString() },
      query: { tab: 'archive', archiveId: archiveId.toString() },
    });
  }

  private async AuraLogoClicked() {
    if (this.auraLogo == undefined) return;

    const viewportHeight = window.visualViewport?.height;
    const viewportWidth = window.visualViewport?.width;

    if (!viewportWidth || !viewportHeight) {
      return;
    }

    const logoRect = this.auraLogo.getBoundingClientRect();
    const targetConfettiY = logoRect.y + logoRect.height / 2;
    const targetConfettiX = logoRect.x + logoRect.width / 2;

    await confetti({
      particleCount: TypeHelper.RandomInRange(60, 140),
      spread: TypeHelper.RandomInRange(80, 120),
      angle: TypeHelper.RandomInRange(0, -75),
      origin: {
        x: targetConfettiX / viewportWidth,
        y: targetConfettiY / viewportHeight,
      },
      startVelocity: TypeHelper.RandomInRange(25, 35),
    });
  }

  private async HeaderUsernameClicked() {
    if (this.$route.name != Routes.MY_ACCOUNT) {
      this.$router.push({ name: Routes.MY_ACCOUNT });
    }
  }

  private async LogoutButtonClicked() {
    await LoginModule.logout();
  }

  created() {
    HubConnectionModule.refreshAllConnections().then(() => {
      this.subscribeDisconnectUser();
    });

    LoginModule.OnUpdateToken.subscribe(() => {
      HubConnectionModule.refreshAllConnections();
    });

    if (LoginModule.IsBillingAccountAdmin) {
      UtilModule.loadBillingData();
    }

    for (let item in this.Children) {
      let data = this.Children[parseInt(item) as WorkFieldChild];
      //позже можно заменить на динамичски появляющийся пункт меню
      if (data.name === 'user') continue;
      if (data.name === 'admin') continue;
      this.sideMenuItems.push(data);
    }

    const foundAdmin = this.sideMenuItems.find(i => i.name === 'admin');
    if (foundAdmin) {
      // Keep it in the end
      this.sideMenuItems.delete(foundAdmin);
      this.sideMenuItems.push(foundAdmin);
    }
  }

  subscribeDisconnectUser() {
    HubConnectionModule.IdentityConnection?.on(hubActions.RECEIVE_DISCONNECT_USER, async (response: ResponseUser) => {
      const realResponse = ResponseIdentityHelper.createRealUser(response);
      if (LoginModule.UserId.equals(realResponse.id)) {
        await LoginModule.resetAllData();
      }
    });
  }

  get LogoutCaption() {
    return en.logout;
  }

  get isPROM() {
    return isPROM;
  }

  get isInitialized() {
    return HubConnectionModule.IsInitialized;
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  background: var(--main-background);
}

.global-notification {
  background: #e14b18;
  color: white;
  align-content: center;
  justify-content: center;
  align-items: center;
  position: fixed;
  max-height: unset;
  font-size: 16px;
  z-index: 9000;
  display: flex;
  width: 100%;
  height: 40px;
  min-height: 40px;

  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);

  &.hidden {
    display: none;
  }

  span {
    word-break: break-word;
    overflow-x: hidden;
    text-overflow: ellipsis;
    padding: 0 4rem;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow-y: hidden;
  }

  button {
    border: none;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    margin: 0 0.5rem;
    background: rgba(255, 255, 255, 0.3);
    width: 26px;
    height: 26px;
    border-radius: 4px;
    padding: 0;

    i {
      font-size: 20px;
      margin-top: 1px;
      color: #383333;
    }
  }
}

.workfield-header {
  min-height: 61px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 28px;
    padding-left: 0;

    font-family: 'Roboto';

    .aura-logo {
      width: 24px;
      margin: 0 0.85rem;
      cursor: pointer;
      transition: all 0.075s ease-out;

      &:hover {
        width: 24px;
      }

      &:active {
        width: 20px;
      }
    }

    .username {
      color: #dedede;
      text-transform: lowercase;
      font-size: 20px;
      transform: translateY(-2px);
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .separator {
      background-color: #909090;
      width: 2px;
      height: 50%;
    }

    .logout-button {
      color: #909090;
      font-size: 16px;
      text-transform: uppercase;
      cursor: pointer;
      &:hover {
        opacity: 0.75;
      }
    }
  }

  .bottom-border {
    height: 4px;

    background: linear-gradient(to right, #313131, #333333);
  }
}

.inner-container {
  position: relative;
  flex: 1;
}
</style>
