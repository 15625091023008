import store from '@/store';
import { Product, ProductPrice, PurchasedItem, PurchasedSubscription } from '@/models/Entities';
import { HubConnection } from '@microsoft/signalr';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import * as ips from '@/util/api/ips';
import * as routes from '@/util/api/routes';
import * as hubActions from '@/util/api/hubActions';
import {
  ResponsePurchasedItem,
  ResponsePurchasedItemActivateOffline,
  ResponsePurchasedItems,
} from '@/models/responses/ResponseBilling';
import { TypeHelper } from '@/util/TypeHelper';
import { Guid } from 'guid-typescript';
import { UtilModule } from '../utilModule';
import {
  RequestAdminPurchaseItems,
  RequestAttachPurchasedItem,
  RequestDetachPurchasedItem,
  RequestDownloadOfflineActivationResponsePurchasedItem,
  RequestPurchaseItems,
  RequestReadAllByAccountIdPurchasedItem,
} from '@/models/requests/RequestsBilling';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { PurchaseStatus } from '@/models/enums/PurchaseStatus';
import { ProductPriceModule } from './productPriceModule';
import { PurchasedSubscriptionModule } from './purchasedSubscriptionModule';
import { ProductModuleHelper } from './productModule';
import { SimpleEventDispatcher } from 'strongly-typed-events';
import { StoreHelper } from '@/store/util/storeHelpers';
import { HubConnectionModule } from '@/store/modules/hubConnectionModule';

export interface AdminPurchaseItemData {
  productPriceId: Guid;
  addonProductPrices: Guid[];
  auraKey: string | null;
}

export interface AdminPurchaseItemsData {
  accountId: Guid;
  items: AdminPurchaseItemData[];
}

export interface PurchaseItemData {
  productPriceId: Guid;
  quantity: number;
}

export interface PurchaseItemsData {
  accountId: Guid;
  items: PurchaseItemData[];
}

export interface AttachData {
  id: Guid;
  attachToId: Guid;
}

export interface DetachData {
  id: Guid;
  detachFromId: Guid;
}

export interface PurchasedItemFull {
  item: PurchasedItem;
  price: ProductPrice;
  subscription: PurchasedSubscription | null;
  baseItem: PurchasedItem | null;
  product: Product;
  addons: PurchasedItem[];
}

export interface PurchasedItemFullGroup {
  group: PurchasedItemFull[];
  id: Guid;
}

@Module({ dynamic: true, name: 'purchased-item', store: store })
export default class purchasedItemModule extends VuexModule {
  private purchasedItems: PurchasedItem[] = [];

  private onPurchasedItemUpdated = new SimpleEventDispatcher<PurchasedItem>();

  get PurchasedItems(): PurchasedItem[] {
    return this.purchasedItems.filter(
      a =>
        (a.status === PurchaseStatus.Active ||
          a.status === PurchaseStatus.Canceled ||
          a.status === PurchaseStatus.PastDue) &&
        !a.deleted,
    );
  }

  get PurchasedItemsFull(): PurchasedItemFull[] {
    const result: PurchasedItemFull[] = [];

    for (const item of this.PurchasedItems) {
      const price = ProductPriceModule.ProductPrices.firstOrDefault(a => a.id == item.productPriceId);

      let subscription: PurchasedSubscription | null = null;
      if (item.purchasedSubscriptionId !== null) {
        subscription = PurchasedSubscriptionModule.PurchasedSubscriptions.firstOrDefault(
          a =>
            a.id.toString() === item.purchasedSubscriptionId?.toString() &&
            a.currentPeriodEnd !== null &&
            a.currentPeriodEnd! > new Date(),
        );
      }

      const baseItem = this.PurchasedItems.firstOrDefault(a => a.id.toString() === item.baseItemId?.toString());

      const product = ProductModuleHelper.ProductForPurchasedItem(item);

      if (price === null || product === null || (price.recurringInterval != null && subscription == null)) {
        continue;
      }

      const full: PurchasedItemFull = {
        item: item,
        price: price,
        subscription: subscription,
        product: product,
        addons: this.PurchasedItems.filter(a => a.baseItemId?.toString() === item.id.toString()),
        baseItem: baseItem,
      };

      result.push(full);
    }

    return result;
  }

  get PurchasedItemsGroups(): PurchasedItemFullGroup[] {
    const result: PurchasedItemFullGroup[] = [];

    const oneTimeGroup = this.PurchasedItemsFull.filter(a => a.subscription === null).groupBy(a =>
      a.product.id.toString(),
    );

    const subscriptionGroup = this.PurchasedItemsFull.filter(a => a.subscription !== null).groupBy(a =>
      a.subscription?.id.toString(),
    );

    for (const item of oneTimeGroup) {
      result.push({
        group: item[1],
        id: item[1][0].product.id,
      });
    }
    for (const item of subscriptionGroup) {
      result.push({
        group: item[1],
        id: item[1][0].subscription!.id,
      });
    }

    return result;
  }

  get OnPurchasedItemUpdated() {
    return this.onPurchasedItemUpdated.asEvent();
  }

  @Mutation
  AddPurchasedItem(val: PurchasedItem) {
    this.purchasedItems.push(val);
  }

  @Mutation
  RemovePurchasedItem(val: PurchasedItem) {
    const found = this.purchasedItems.firstOrDefault(a => a.id.toString() === val.id.toString());

    if (found !== null) {
      this.purchasedItems.delete(found);
    }
  }

  @Mutation
  UpdatePurchasedItem(val: PurchasedItem) {
    this.purchasedItems = this.purchasedItems.map(item => {
      if (val.id.toString() === item.id.toString()) {
        item.activated = val.activated;
        item.baseItemId = val.baseItemId;
        item.productPriceId = val.productPriceId;
        item.deviceName = val.deviceName;
        item.invoiceId = val.invoiceId;
        item.purchaseDate = val.purchaseDate;
        item.purchasedSubscriptionId = val.purchasedSubscriptionId;
        item.status = val.status;
        item.cryptlexKey = val.cryptlexKey;
        item.cryptlexId = val.cryptlexId;
        item.deleted = val.deleted;
        item.offlineActivationResponse = val.offlineActivationResponse;
        item.offlineActivationResponseValidBefore = val.offlineActivationResponseValidBefore;

        this.onPurchasedItemUpdated.dispatch(item);
      }

      return item;
    });
  }

  @Mutation
  ClearPurchasedItems() {
    this.purchasedItems = [];
  }

  @Action({ rawError: true })
  PurchasedItemSubscribe(hubConnection: HubConnection) {
    hubConnection.on(hubActions.RECEIVE_CREATE_PURCHASED_ITEM, async (res: ResponsePurchasedItem) => {
      const item = TypeHelper.DeepCopyFrom(res, ResponsePurchasedItem).Map();

      this.AddPurchasedItem(item);
    });

    hubConnection.on(hubActions.RECEIVE_UPDATE_PURCHASED_ITEM, async (res: ResponsePurchasedItem) => {
      const item = TypeHelper.DeepCopyFrom(res, ResponsePurchasedItem).Map();

      this.UpdatePurchasedItem(item);
    });

    hubConnection.on(hubActions.RECEIVE_ATTACHED_PURCHASED_ITEM, async (res: ResponsePurchasedItem) => {
      const item = TypeHelper.DeepCopyFrom(res, ResponsePurchasedItem).Map();

      this.UpdatePurchasedItem(item);
    });

    hubConnection.on(hubActions.RECEIVE_DETACHED_PURCHASED_ITEM, async (res: ResponsePurchasedItem) => {
      const item = TypeHelper.DeepCopyFrom(res, ResponsePurchasedItem).Map();

      this.UpdatePurchasedItem(item);
    });

    hubConnection.on(hubActions.RECEIVE_SOFT_DELETE_PURCHASED_ITEM, async (res: ResponsePurchasedItem) => {
      const item = TypeHelper.DeepCopyFrom(res, ResponsePurchasedItem).Map();

      this.RemovePurchasedItem(item);
    });
  }

  @Action({ rawError: true })
  async LoadPurchasedItems(accountId: Guid) {
    this.ClearPurchasedItems();

    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.PURCHASED_ITEM,
      routes.UriPurchasedItem.READ_ALL_BY_ACCOUNT_ID,
    );

    const request = new RequestReadAllByAccountIdPurchasedItem();
    request.accountId = accountId.toString();

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = axiosResponse.data.response as ResponsePurchasedItems;
      const items = TypeHelper.DeepCopyFrom(response, ResponsePurchasedItems).items;

      for (const res of items) {
        const item = TypeHelper.DeepCopyFrom(res, ResponsePurchasedItem);
        this.AddPurchasedItem(item.Map());
      }
    }
  }

  @Action({ rawError: true })
  async Attach(data: AttachData): Promise<boolean> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.PURCHASED_ITEM,
      routes.UriPurchasedItem.ATTACH,
    );

    const request = new RequestAttachPurchasedItem();
    request.id = data.id.toString();
    request.attachToId = data.attachToId.toString();

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result === RequestStatus.OK && axiosResponse != null) {
      return true;
    }

    return false;
  }

  @Action({ rawError: true })
  async Detach(data: DetachData): Promise<boolean> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.PURCHASED_ITEM,
      routes.UriPurchasedItem.DETACH,
    );

    const request = new RequestDetachPurchasedItem();
    request.id = data.id.toString();
    request.detachFromId = data.detachFromId.toString();

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result === RequestStatus.OK && axiosResponse != null) {
      return true;
    }

    return false;
  }

  @Action({ rawError: true })
  async AdminPurchase(data: AdminPurchaseItemsData): Promise<[RequestStatus, string]> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.PURCHASED_ITEM,
      routes.UriPurchasedItem.ADMIN_PURCHASE_ITEMS,
    );

    const request = new RequestAdminPurchaseItems();
    request.accountId = data.accountId.toString();
    request.items = [];

    for (const item of data.items) {
      request.items.push({
        productPriceId: item.productPriceId.toString(),
        auraKey: item.auraKey == '' ? null : item.auraKey,
        addonProductPrices: item.addonProductPrices.map(a => a.toString()),
      });
    }

    const { result, message } = await UtilModule.SHW.ProcessPost(uri, request);

    return [result, message];
  }

  @Action({ rawError: true })
  async Purchase(data: PurchaseItemsData): Promise<boolean> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.PURCHASED_ITEM,
      routes.UriPurchasedItem.PURCHASE_ITEMS,
    );

    const request = new RequestPurchaseItems();
    request.accountId = data.accountId.toString();
    request.items = [];

    for (const item of data.items) {
      request.items.push({
        productPriceId: item.productPriceId.toString(),
        quantity: item.quantity,
      });
    }

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result === RequestStatus.OK && axiosResponse != null) {
      return true;
    }

    return false;
  }

  @Action({ rawError: true })
  async ActivateOffline(formData: FormData): Promise<{
    result: ResponsePurchasedItemActivateOffline | null;
    message: string;
  }> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.PURCHASED_ITEM,
      routes.UriPurchasedItem.ACTIVATE_OFFLINE,
    );

    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPostFile(uri, formData);

    if (result === RequestStatus.OK && axiosResponse != null) {
      const res = axiosResponse.data.response as ResponsePurchasedItemActivateOffline;

      return { result: res, message: message };
    }

    return { result: null, message: message };
  }

  @Action({ rawError: true })
  async DeactivateOffline(formData: FormData) {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.PURCHASED_ITEM,
      routes.UriPurchasedItem.DEACTIVATE_OFFLINE,
    );

    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPostFile(uri, formData);

    if (result === RequestStatus.OK && axiosResponse != null) {
      return { result: true, message: message };
    }

    return { result: false, message: message };
  }

  @Action({ rawError: true })
  async DownloadOfflineActivationResponseFile(item: PurchasedItem) {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.BILLING,
      routes.UriController.PURCHASED_ITEM,
      routes.UriPurchasedItem.DOWNLOAD_OFFLINE_ACTIVATION_RESPONSE,
    );

    const request = new RequestDownloadOfflineActivationResponsePurchasedItem();
    request.itemId = item.id.toString();

    const res = await UtilModule.SHW.ProcessDownloadFile(uri, request, () => {
      // Todo: add progress later
    });

    if (res) {
      StoreHelper.DownloadBlob(res.blob, res.fileName);
    }
  }

  @Action({ rawError: true })
  async AddToPurchasedItemGroup() {
    if (HubConnectionModule.BillingConnection == null) return;
    await HubConnectionModule.BillingConnection.send(hubActions.ADD_TO_PURCHASED_ITEM_GROUP);
    HubConnectionModule.AddBillingConnectionGroup({
      func: this.AddToPurchasedItemGroup,
    });
  }

  @Action({ rawError: true })
  async AddToSpecificPurchasedItemGroup(accountId: Guid) {
    if (HubConnectionModule.BillingConnection == null) return;
    await HubConnectionModule.BillingConnection.send(
      hubActions.ADD_TO_SPECIFIC_PURCHASED_ITEM_GROUP,
      accountId.toString(),
    );
    HubConnectionModule.AddBillingConnectionGroup({
      func: this.AddToSpecificPurchasedItemGroup,
      args: accountId,
    });
  }

  @Action({ rawError: true })
  async DeleteFromPurchasedItemGroup() {
    if (HubConnectionModule.BillingConnection == null) return;
    await HubConnectionModule.BillingConnection.send(hubActions.DELETE_FROM_PURCHASED_ITEM_GROUP);
    HubConnectionModule.DeleteBillingConnectionGroup({
      func: this.AddToPurchasedItemGroup,
    });
  }

  @Action({ rawError: true })
  async DeleteFromSpecificPurchasedItemGroup(accountId: Guid) {
    if (HubConnectionModule.BillingConnection == null) return;
    await HubConnectionModule.BillingConnection.send(
      hubActions.DELETE_FROM_SPECIFIC_PURCHASED_ITEM_GROUP,
      accountId.toString(),
    );
    HubConnectionModule.DeleteBillingConnectionGroup({
      func: this.AddToSpecificPurchasedItemGroup,
      args: accountId,
    });
  }
}

export const PurchasedItemModule = getModule(purchasedItemModule);
