<template>
  <div class="company-billing">
    <div class="left-panel">
      <ActionCard
        :headerText="AccountInfoCaption"
        :headerButtonDatas="[
          {
            iconType: 'far',
            iconName: 'fa-pen',
            text: EditCaption,
            name: 'edit',
          },
        ]"
        class="w-100 mb-3"
        :initiallyCollapsed="AccountInfoCollapsed"
        @edit-header-button-clicked="EditingAccount = true"
        @collapse-toggled="AccountInfoCollapseToggled"
      >
        <div class="account-info-line">
          <span class="field">{{ NameCaption }}</span>
          <span class="value">{{ AccountName }}</span>
        </div>

        <div class="account-info-line">
          <span class="field">{{ CountryCaption }}</span>
          <span class="value">{{ AccountCountry }}</span>
        </div>

        <div class="account-info-line">
          <span class="field">{{ StateRegionCaption }}</span>
          <span class="value">{{ AccountState }}</span>
        </div>

        <div class="account-info-line">
          <span class="field">{{ PostalCodeCaption }}</span>
          <span class="value">{{ AccountZipCode }}</span>
        </div>

        <div class="account-info-line">
          <span class="field">{{ CityCaption }}</span>
          <span class="value">{{ AccountCity }}</span>
        </div>

        <div class="account-info-line">
          <span class="field">{{ EmailCaption }}</span>
          <span class="value">{{ AccountEmail }}</span>
        </div>

        <div class="account-info-line">
          <span class="field">{{ AddressCaption }}</span>
          <span class="value">{{ AccountAddress }}</span>
        </div>

        <div class="account-info-line">
          <span class="field">{{ PhoneCaption }}</span>
          <span class="value">{{ AccountPhone }}</span>
        </div>

        <div class="account-info-line">
          <span class="field">{{ OwnerCaption }}</span>
          <span class="value">{{ AccountOwnerName }}</span>
        </div>

        <div v-if="TaxRateAvailable" class="account-info-line">
          <span class="field">{{ TaxRateCaption }}</span>
          <span class="value">{{ TaxRate }}</span>
        </div>

        <div v-if="TaxIdAvailable" class="account-info-line">
          <span class="field">{{ TaxIdCaption }}</span>
          <span class="value">{{ TaxId }}</span>
        </div>
      </ActionCard>

      <action-card
        :headerText="AccountAdminsCaption"
        :headerButtonDatas="AccountAdminsButtonDatas"
        class="w-100 overflow-auto"
        :initiallyCollapsed="AccountAdminsCollapsed"
        @add-new-header-button-clicked="AddBillingAdmin"
        @change-owner-header-button-clicked="ChangeOwner"
        @collapse-toggled="AccountAdminsCollapseToggled"
      >
        <sortable-header
          :header-items="AdminUsersHeader"
          :by.sync="SortByAccountAdminUserHeader"
          :mode.sync="AccountAdminUserSortMode"
          @sortBy="OnAccountAdminUserSortBy"
        />
        <div class="thin-scroll overflow-auto" @scroll="OnAccountAdminUsersScrolled">
          <list-item-v-3
            v-for="item in AccountAdmins"
            :id="item.id"
            :key="item.Id.toString()"
            mainClass="cur-pointer"
            :items="AdminUserListItems(item)"
            :footerIconFontSize="13"
            :footerIconName="['fa-trash']"
            footerIconType="far"
            @openItem="OpenUser(item.Id)"
            @footer-icon-clicked="RemoveBillingAdmin(item)"
          />
        </div>

        <div :class="['spinner-container', isLoadingAccountAdminUsers ? '' : 'hidden']">
          <div :class="['spinner', isLoadingAccountAdminUsers ? '' : 'hidden']"></div>
        </div>

        <!-- <template v-slot:account-admin-more>
          <b-dropdown no-caret :disabled="false" variant="outline" dropup>
            <template slot="button-content" style="padding:0">
              <i class="far fa-ellipsis-v"></i>
            </template>
            <b-dropdown-item @click="ChangeOwner" v-if="ShowChangeOwner">
              {{ ChangeOwnerCaption }}
            </b-dropdown-item>
            <b-dropdown-item @click="RemoveBillingAdmin">
              {{ DeleteAdminsCaption }}
            </b-dropdown-item>
          </b-dropdown>
        </template> -->
      </action-card>
    </div>

    <div ref="verticalResizer" class="vertical-resizer"></div>

    <div class="right-panel thin-scroll">
      <billing-account-editor
        v-if="EditingAccount"
        ref="billingAccountEditor"
        class="mb-3"
        :account="Account"
        @close-clicked="EditingAccount = false"
      />

      <div class="position-relative">
        <action-card
          :headerText="PaymentMethodsCaption"
          :headerButtonDatas="[
            {
              iconType: 'far',
              iconName: 'fa-plus-square',
              text: AddCaption,
              name: 'add',
            },
            {
              iconType: 'fas',
              iconName: 'fa-exchange-alt',
              text: ChangeDefaultCaption,
              name: 'change-default',
            },
          ]"
          class="w-100 mb-3"
          :initiallyCollapsed="PaymentMethodsCollapsed"
          @add-header-button-clicked="OnAddPaymentMethod"
          @change-default-header-button-clicked="OnChangeDefaultPaymentMethod"
          @collapse-toggled="PaymentMethodsCollapseToggled"
        >
          <sortable-header :header-items="PaymentMethodHeader" />
          <div class="thin-scroll">
            <list-item-v-3
              v-for="item in PaymentMethods"
              :id="item.id"
              :key="item.id.toString()"
              :items="PaymentMethodListItems(item)"
              :headerIconName="cardIconName(item)"
              :headerText="item.isDefault ? DefaultCaption : null"
              headerIconType="fab"
              :headerIconScale="2"
              :footerIconFontSize="13"
              footerIconName="fa-trash"
              footerIconType="far"
              @footer-icon-clicked="DeletePaymentMethod(item)"
            />
          </div>
        </action-card>
      </div>

      <action-card
        v-if="AccountBalanceNonZero > 0"
        :headerText="BalanceCaption"
        class="w-100 balance mb-3"
        :initiallyCollapsed="BalanceCollapsed"
        @collapse-toggled="BalanceCollapseToggled"
      >
        <div class="account-info-line">
          <span class="field">{{ AccountBalanceCaption }}</span>
          <span class="value mr-3">{{ AccountBalance }}</span>
          <v-popover trigger="hover" placement="bottom">
            <span class="value">
              <i style="font-size: 16px" class="fas fa-info-circle"></i>
            </span>

            <template slot="popover">
              Next invoice will deduct money from your balance before charging your default payment method
            </template>
          </v-popover>
        </div>
      </action-card>

      <action-card
        :headerText="InvoicesCaption"
        class="w-100 invoices thin-scroll"
        :initiallyCollapsed="InvoicesCollapsed"
        @collapse-toggled="InvoicesCollapseToggled"
      >
        <sortable-header
          :header-items="InvoiceHeader"
          :by.sync="SortByInvoiceHeader"
          :mode.sync="InvoiceSortMode"
          @sortBy="OnInvoicesSortBy"
        />
        <div
          :class="['overflow-overlay thin-scroll', confirmingInvoicePayment ? 'disabled' : '']"
          @scroll="OnInvoicesScrolled"
        >
          <list-item-v-3
            v-for="item in Invoices"
            :id="item.id"
            :key="item.id.toString()"
            :items="InvoiceListItems(item)"
            :footerIconFontSize="13"
            footerIconName="fa-download"
            @footer-icon-clicked="downloadInvoice(item)"
          />
        </div>

        <div :class="['spinner-container', isLoadingInvoices ? '' : 'hidden']">
          <div :class="['spinner', isLoadingInvoices ? '' : 'hidden']"></div>
        </div>

        <div :class="['spinner', confirmingInvoicePayment ? '' : 'hidden']"></div>
      </action-card>
    </div>
  </div>
</template>

<script lang="ts">
import ComponentHelper, { HeaderItem, ItemData } from '@/util/ComponentHelper';
import SortableHeader from '@/components/presentation/SortableHeader.vue';
import ActionCard, { HeaderButtonData } from '@/components/presentation/ActionCard.vue';
import ListItemV3 from '@/components/presentation/ListItemV3.vue';
import en from '@/localization/en';
import { AccountModule } from '@/store/modules/billing/accountModule';
import { PaymentMethodModule } from '@/store/modules/billing/paymentMethodModule';
import { JobModule } from '@/store/modules/jobModule';
import { Account, Company, CompanyUser, Invoice, LoginSession, PaymentMethod, Role, User } from '@/models/Entities';
import { Guid } from 'guid-typescript';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { InvoiceModule, InvoicePageSize, LoadInvoicesPageData } from '@/store/modules/billing/invoiceModule';
import { InvoiceStatus } from '@/models/enums/InvoiceStatus';
import SelectUserDialog from '@/views/dialogs/company/SelectUserDialog.vue';
import dateformat from 'dateformat';
import { create } from 'vue-modal-dialogs';
import { RoleModule } from '@/store/modules/roleModule';
import { LoadUsersPageData, SortUsers, UserModule, UserPageSize } from '@/store/modules/userModule';
import BillingAccountEditor from '@/components/forms/BillingAccountEditor.vue';
import { SortMode } from '@/models/requests/Datas';
import { InvoiceSortBy } from '@/models/requests/RequestsBilling';
import { LoginModule } from '@/store/modules/loginModule';
import {
  SortInvoiceByDateASC,
  SortInvoiceByDateDESC,
  SortInvoiceByIdDESC,
  SortInvoiceByNumberASC,
  SortInvoiceByNumberDESC,
  SortInvoiceByStatusASC,
  SortInvoiceByStatusDESC,
  SortInvoiceBySumASC,
  SortInvoiceBySumDESC,
} from '@/models/util/InvoiceSortings';

import { delay } from 'lodash';
import { LoginSessionModule } from '@/store/modules/loginSessionModule';
import { UserSortBy } from '@/models/requests/RequestIdentity';
import SelectDefaultPaymentMethodDialog from '@/views/dialogs/SelectDefaultPaymentMethodDialog.vue';
import { toast } from '@/main';
import { POSITION } from 'vue-toastification';
import { GlobalDataModule } from '@/store/modules/globalDataModule';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { PaymentState } from '@/models/enums/PaymentState';
import { Routes } from '@/router/routes';
import { VPopover } from 'v-tooltip';

const SelectDefaultPaymentMethod = create<PaymentMethod[] | null, PaymentMethod | null>(
  SelectDefaultPaymentMethodDialog,
  'paymentMethods',
);

//#region CONSTS
const EMAIL_WIDTH = 250;
// const FULLNAME_WIDTH = 225;
const LASTSEEN_WIDTH = 125;
const VALID_BEFORE_WIDTH = 125;
//#endregion

const AddBillingAdmin = create<Company | null, Role[] | null, Role[] | null, string, CompanyUser | null>(
  SelectUserDialog,
  'company',
  'includeRoles',
  'excludeRoles',
  'header',
);

const RemoveBillingAdmin = create<Company | null, Role[] | null, Role[] | null, string, CompanyUser | null>(
  SelectUserDialog,
  'company',
  'includeRoles',
  'excludeRoles',
  'header',
);

// const ChangeOwner = create<Company | null, Role[] | null, Role[] | null, string, CompanyUser | null>(
//   SelectUserDialog,
//   'company',
//   'includeRoles',
//   'excludeRoles',
//   'header',
// );

@Component({
  name: 'company-billing',
  components: {
    SortableHeader: SortableHeader,
    ActionCard: ActionCard,
    ListItemV3: ListItemV3,
    BillingAccountEditor: BillingAccountEditor,
    VPopover: VPopover,
  },
})
export default class CompanyBilling extends Vue {
  @Prop() private Company!: Company;
  private _componentId!: Guid;
  private componentIdAccountAdmins!: Guid;

  @Watch('$route', { immediate: true })
  private async OnRouteChanged() {
    let action = this.$route.query.action;

    if (action === 'add-payment-method') {
      await this.OnAddPaymentMethod();
    } else if (action === 'confirm-invoice-payment') {
      const invoiceId = this.$route.query.invoiceId as string;
      await this.ConfirmInvoicePayment(Guid.parse(invoiceId));

      this.$router.replace({
        name: Routes.COMPANY,
        query: { tab: 'billing' },
      });
    } else if (action == 'edit-account') {
      this.EditingAccount = true;
    }
  }

  @Watch('Company', { immediate: true })
  private async OnCompanyChanged(newValue: Company, oldValue: Company) {
    if (oldValue && newValue.Id.toString() != oldValue.Id.toString()) {
      await UserModule.DeleteFromCompanyUsersGroup();
      if (this.Users.length > 0) {
        let users = this.Users.map(a => a.user);
        let loginSessions = Array.from(this.Users.map(a => a.loginSessions)).reduce(
          (accumulator: LoginSession[], value) => accumulator.concat(value),
          [],
        );
        await UserModule.LooseUsersNew([users.map(a => a.Id), this.componentIdAccountAdmins]);
        await LoginSessionModule.LooseLoginSessions([loginSessions.map(a => a.Id), this.componentIdAccountAdmins]);
      }
    }
    if (newValue && oldValue != null && newValue.Id.toString() != oldValue.Id.toString()) {
      await UserModule.SubscribeToCompanyUsersGroup();

      await UserModule.LooseUsersNew([this.Users.map(a => a.user.Id), this.componentIdAccountAdmins!]);

      await UserModule.CollectUsers();

      this.accountAdminUserPage = 0;

      await this.LoadMoreAccountAdminUsers();
    }
  }

  //#region STATE
  private isOpeningUser: boolean = false;

  private get Users(): { user: User; loginSessions: LoginSession[] }[] {
    let users = UserModule.Users;

    let userWithSessions: {
      user: User;
      loginSessions: LoginSession[];
    }[] = [];

    for (let item of users) {
      let userLss = LoginSessionModule.LoginSessions.filter(a => a.UserId.equals(item.Id));
      userWithSessions.push({ user: item, loginSessions: userLss });
      LoginSessionModule.LoginSessionsSync([userLss, this.componentIdAccountAdmins]);
    }

    return userWithSessions;
  }

  private get IsBillingAccountOwner() {
    return LoginModule.IsBillingAccountOwner;
  }

  private get AccountAdminsButtonDatas(): HeaderButtonData[] {
    const result: HeaderButtonData[] = [];
    result.push({
      iconType: 'far',
      iconName: 'fa-plus-square',
      text: this.AddNewCaption,
      name: 'add-new',
    });

    if (this.IsBillingAccountOwner) {
      result.push({
        iconType: 'fas',
        iconName: 'fa-exchange-alt',
        text: this.ChangeOwnerCaption,
        name: 'change-owner',
      });
    }
    return result;
  }

  //#region INVOICE RELATED
  private sortByInvoiceHeader: HeaderItem | null = null;
  private invoiceSortMode: SortMode | null = null;
  private currentPage: number = 0;

  private isLoadingInvoices: boolean = false;
  private canScrollLoadInvoices: boolean = true;

  private confirmingInvoicePayment: boolean = false;

  private get SortByInvoiceHeader(): HeaderItem | null {
    return this.sortByInvoiceHeader;
  }
  private set SortByInvoiceHeader(value: HeaderItem | null) {
    this.sortByInvoiceHeader = value;
  }
  private get InvoiceSortMode(): SortMode | null {
    return this.invoiceSortMode;
  }
  private set InvoiceSortMode(value: SortMode | null) {
    this.invoiceSortMode = value;
  }
  private get Invoices(): Invoice[] {
    let currentInvoices = this.SortInvoices(InvoiceModule.Invoices.map(a => a));

    // console.log("INVOICES PAGE", this.currentPage);

    const maxCount = (this.currentPage + 1) * InvoicePageSize;

    const toDisplay = currentInvoices.slice(0, maxCount);
    const toLoose = currentInvoices.slice(maxCount);

    // InvoiceModule.OccupyInvoices([currentInvoices, this._componentId]);
    InvoiceModule.LooseInvoices([toLoose, this._componentId]);
    InvoiceModule.OccupyInvoices([toDisplay, this._componentId]);

    if (toLoose.length > 0) {
      InvoiceModule.CollectInvoices();
    }
    return toDisplay;
  }
  //#endregion

  //#region ACCOUNT RELATED
  private EditingAccount: boolean = false;
  private AddingAccountAdmin: boolean = false;

  private get PaymentElementLoading() {
    return false;
  }
  private get Account(): Account {
    return AccountModule.Account!;
  }
  private get AccountOwner(): User | undefined {
    const owner = this.Users.firstOrDefault(a => a.user.Roles.firstOrDefault(b => b.IsBillingAccountOwner));

    return owner?.user;
  }
  private get PaymentMethods(): PaymentMethod[] {
    return PaymentMethodModule.PaymentMethods;
  }

  private get ShowChangeOwner(): boolean {
    return LoginModule.Me?.Roles.firstOrDefault(a => a.IsBillingAccountOwner) !== null;
  }
  private get AccountName(): string {
    return this.Account.name === '' ? '—' : this.Account.name;
  }
  private get AccountCountry(): string {
    return en.CountryNames[this.Account.country];
  }
  private get AccountState(): string {
    return this.Account.state === null ? '—' : en.StateNames[this.Account.state];
  }
  private get AccountZipCode(): string {
    return this.Account.postalCode === '' ? '—' : this.Account.postalCode;
  }
  private get AccountCity(): string {
    return this.Account.city === '' ? '—' : this.Account.city;
  }
  private get AccountEmail(): string {
    return this.Account.email === '' ? '—' : this.Account.email;
  }
  private get AccountAddress(): string {
    return this.Account.address === '' ? '—' : this.Account.address;
  }
  private get AccountPhone(): string {
    if (this.AccountOwner === undefined) return '—';

    // console.log(this.AccountOwner!.Phone);

    return this.AccountOwner!.Phone === null ? '—' : this.AccountOwner.Phone;
  }
  private get AccountOwnerName(): string {
    if (this.AccountOwner === undefined) return '—';

    return ComponentHelper.GetFullname(this.AccountOwner);
  }
  private get AccountBalanceNonZero(): boolean {
    return this.Account.stripeBalance != 0;
  }
  private get AccountBalance(): string {
    const accountCurrecy = en.CurrencySymbols['EUR'];

    return accountCurrecy + ' ' + (-this.Account.stripeBalance / 100).toFixed(2);
  }
  private get TaxRateAvailable(): boolean {
    return AccountModule.AccountTaxRate != null;
  }
  private get TaxRate(): string {
    const taxRate = AccountModule.AccountTaxRate!;

    return `${taxRate.name}, ${taxRate.percentage}%`;
  }
  private get TaxIdAvailable(): boolean {
    return AccountModule.Account?.taxId != null;
  }
  private get TaxId(): string {
    return AccountModule.Account!.taxId!;
  }
  //#endregion

  //#region ACCOUNT ADMINS RELATED
  private get AccountAdmins(): CompanyUser[] {
    let companyUsers: CompanyUser[] = [];

    for (let item of this.Users) {
      if (item.user.Roles.firstOrDefault(a => a.IsBillingAccountAdmin)) {
        let model = new CompanyUser();
        model.Id = item.user.Id;
        model.Email = item.user.Email;
        model.FullName = ComponentHelper.GetFullname(item.user);
        if (item.loginSessions.length > 0) {
          let lastSeenMax = item.loginSessions.map((a: LoginSession) => a.LastSeen.getTime()).max();
          let loginSession = item.loginSessions.firstOrDefault(
            (a: LoginSession) => a.LastSeen.getTime() === lastSeenMax,
          );
          if (loginSession != null) model.LastSeen = loginSession.LastSeen;
        } else model.LastSeen = new Date(0);
        model.TotalPrints = JobModule.JobCount.get(item.user) || 0;
        model.Roles = item.user.Roles;
        companyUsers.push(model);
      }
    }

    companyUsers = SortUsers(
      companyUsers.map(a => a),
      this.GetAccountAdminUserSortBy(this.SortByAccountAdminUserHeader),
      this.accountAdminUserSortMode,
    );

    const maxCount = (this.accountAdminUserPage + 1) * UserPageSize;

    const toDisplay = companyUsers.slice(0, maxCount);
    const toLoose = companyUsers.slice(maxCount);

    UserModule.LooseUsersNew([toLoose.map(a => a.Id), this.componentIdAccountAdmins]);
    UserModule.OccupyUsers([toDisplay.map(a => a.Id), this.componentIdAccountAdmins]);

    if (toLoose.length > 0) {
      UserModule.CollectUsers();
    }

    // console.log("USERS", toDisplay.length);

    return toDisplay;
  }

  private sortByAccountAdminHeader: HeaderItem | null = null;
  private accountAdminUserSortMode: SortMode | null = null;
  private accountAdminUserPage: number = 0;

  private isLoadingAccountAdminUsers: boolean = false;
  private canScrollLoadAccoutAdminUsers: boolean = true;

  private get SortByAccountAdminUserHeader(): HeaderItem | null {
    return this.sortByAccountAdminHeader;
  }
  private set SortByAccountAdminUserHeader(value: HeaderItem | null) {
    this.sortByAccountAdminHeader = value;
  }
  private get AccountAdminUserSortMode(): SortMode | null {
    return this.accountAdminUserSortMode;
  }
  private set AccountAdminUserSortMode(value: SortMode | null) {
    this.accountAdminUserSortMode = value;
  }
  //#endregion

  //#region VIEW STATE

  private get AccountInfoCollapsed() {
    return GlobalDataModule.CompanyViewState.billingTabViewState.accountInfoCollapsed;
  }

  private get AccountAdminsCollapsed() {
    return GlobalDataModule.CompanyViewState.billingTabViewState.accountAdminsCollapsed;
  }

  private get PaymentMethodsCollapsed() {
    return GlobalDataModule.CompanyViewState.billingTabViewState.paymentMethodsCollapsed;
  }

  private get BalanceCollapsed() {
    return GlobalDataModule.CompanyViewState.billingTabViewState.balanceCollapsed;
  }

  private get InvoicesCollapsed() {
    return GlobalDataModule.CompanyViewState.billingTabViewState.invoicesCollapsed;
  }

  //#endregion

  //#endregion

  //#region HOOKS
  async beforeCreate() {
    this._componentId = Guid.create();
    this.componentIdAccountAdmins = Guid.create();
  }

  async created() {
    this.InvoiceSortMode = SortMode.DESC;
    this.SortByInvoiceHeader = this.invoiceHeaderDate;
  }

  async activated() {
    this.isOpeningUser = false;

    await UserModule.SubscribeToCompanyUsersGroup();

    await InvoiceModule.LooseInvoices([this.Invoices, this._componentId!]);
    await InvoiceModule.CollectInvoices();
    this.currentPage = 0;

    await this.LoadMoreInvoices();

    await UserModule.LooseUsersNew([this.Users.map(a => a.user.Id), this.componentIdAccountAdmins!]);

    await UserModule.CollectUsers();

    this.accountAdminUserPage = 0;
    await this.LoadMoreAccountAdminUsers();
  }

  async deactivated() {
    await UserModule.DeleteFromCompanyUsersGroup();

    await InvoiceModule.LooseInvoices([this.Invoices, this._componentId!]);
    await InvoiceModule.CollectInvoices();

    await UserModule.LooseUsersNew([this.Users.map(a => a.user.Id), this.componentIdAccountAdmins!]);

    if (!this.isOpeningUser) {
      await UserModule.CollectUsers();
    }
  }

  async beforeDestroy() {}
  //#endregion

  //#region HEADERS AND LIST ITEMS
  private accountAdminEmailHeader: HeaderItem = {
    caption: en.email.titleCase(),
    itemClass: ComponentHelper.GetWidthClass(EMAIL_WIDTH),
    isSortable: true,
  };
  private accountAdminFullNameHeader: HeaderItem = {
    caption: en.nameCaption.titleCase(),
    itemClass: ComponentHelper.GetWidthClass(),
    isSortable: true,
  };
  private accountAdminLastSeenHeader: HeaderItem = {
    caption: en.lastSeen.titleCase(),
    itemClass: ComponentHelper.GetWidthClass(LASTSEEN_WIDTH),
    isSortable: true,
  };
  private get AdminUsersHeader(): HeaderItem[] {
    let headerItems: HeaderItem[] = [];
    headerItems.push(this.accountAdminEmailHeader);
    headerItems.push(this.accountAdminFullNameHeader);
    headerItems.push(this.accountAdminLastSeenHeader);
    return headerItems;
  }

  private AdminUserListItems(value: CompanyUser): ItemData[] {
    let items: ItemData[] = [];

    const isOwner = value.Roles.firstOrDefault(a => a.IsBillingAccountOwner) !== null;

    let itemEmail: ItemData = new ItemData(en.empty, value.Email, EMAIL_WIDTH);
    let itemFullName: ItemData = new ItemData('FullName', value.FullName, 'grow-1', isOwner ? en.owner : undefined);
    let itemLastSeen: ItemData = new ItemData(
      'LastSeen',
      ComponentHelper.GetSimpleDate(value.LastSeen),
      LASTSEEN_WIDTH,
    );
    items.push(itemEmail);
    items.push(itemFullName);
    items.push(itemLastSeen);
    return items;
  }

  private get PaymentMethodHeader(): HeaderItem[] {
    if (this.PaymentMethods.length == 0) return [];
    let header1: HeaderItem = {
      caption: '#',
      itemClass: ComponentHelper.GetWidthClass('grow-1'),
    };
    let header3: HeaderItem = {
      caption: en.validBefore.titleCase(),
      itemClass: ComponentHelper.GetWidthClass(VALID_BEFORE_WIDTH),
    };
    let headerItems: HeaderItem[] = [];
    headerItems.push(header1);
    headerItems.push(header3);
    return headerItems;
  }

  private PaymentMethodListItems(value: PaymentMethod): ItemData[] {
    let items: ItemData[] = [];
    let itemInfo: ItemData = new ItemData(
      'Info',
      ComponentHelper.GetReadablePaymentMethodKind(value.methodType) + '*' + value.last4Digits,
      'grow-1',
    );

    let validBefore: ItemData = new ItemData(
      'ValidBefore',
      value.expirationMonth + '/' + value.expirationYear,
      VALID_BEFORE_WIDTH,
    );
    items.push(itemInfo);
    items.push(validBefore);
    return items;
  }

  private invoiceHeaderNumber: HeaderItem = {
    caption: '#',
    itemClass: ComponentHelper.GetWidthClass(200),
    isSortable: true,
  };
  private invoiceHeaderDate: HeaderItem = {
    caption: en.date.titleCase(),
    itemClass: ComponentHelper.GetWidthClass(150),
    isSortable: true,
  };
  private invoiceHeaderStatus: HeaderItem = {
    caption: en.status.titleCase(),
    itemClass: ComponentHelper.GetWidthClass('grow-1'),
    isSortable: true,
  };
  private invoiceHeaderSum: HeaderItem = {
    caption: en.sum.titleCase(),
    itemClass: ComponentHelper.GetWidthClass(125),
    isSortable: true,
  };
  private get InvoiceHeader(): HeaderItem[] {
    let headerItems: HeaderItem[] = [];
    headerItems.push(this.invoiceHeaderNumber);
    headerItems.push(this.invoiceHeaderDate);
    headerItems.push(this.invoiceHeaderStatus);
    headerItems.push(this.invoiceHeaderSum);
    return headerItems;
  }

  private InvoiceListItems(value: Invoice): ItemData[] {
    let items: ItemData[] = [];

    const requiresAction = value.paymentState & PaymentState.RequiresAction && value.status === InvoiceStatus.Open;

    // Todo: add this to UI too
    const paymentFailed = value.paymentState & PaymentState.Failed && value.status === InvoiceStatus.Open;

    let numberItem = new ItemData('Number', value.invoiceNumber === null ? '—' : '#' + value.invoiceNumber, 200);

    let dateItem = new ItemData('Date', dateformat(value.createdDate, 'dd/mm/yyyy'), 150);

    let statusItem: ItemData = new ItemData('Status', ComponentHelper.ReadableInvoiceStatus(value), 'grow-1');

    if (requiresAction) {
      statusItem = new ItemData(
        'Status',
        ComponentHelper.ReadableInvoiceStatus(value),
        'grow-1',
        undefined,
        'far fa-exclamation-circle',
        'far',
        () => {
          this.ConfirmInvoicePayment(value.id);
        },
        true,
        'Click to confirm invoice payment',
        17,
        '#ff4305',
      );
    }

    if (paymentFailed) {
      statusItem = new ItemData(
        'Status',
        ComponentHelper.ReadableInvoiceStatus(value),
        'grow-1',
        undefined,
        'fa-redo',
        'fas',
        () => {
          this.ConfirmInvoicePayment(value.id);
        },
        true,
        'Click to retry invoice payment',
        15,
        '#ff4305',
      );
    }

    let sumItem = new ItemData(
      'Sum',
      en.CurrencySymbols[value.currency.toUpperCase()] + ' ' + value.totalAmount / 100,
      125,
    );

    items.push(numberItem);
    items.push(dateItem);
    items.push(statusItem);
    items.push(sumItem);

    return items;
  }
  //#endregion

  //#region LOGIC

  //#region LOGIC FOR INVOICES
  private SortInvoices(invoices: Invoice[]): Invoice[] {
    let sortBy = this.SortByInvoiceHeader == null ? undefined : this.GetInvoiceSortBy(this.SortByInvoiceHeader);
    if (
      (this.InvoiceSortMode == null && sortBy !== undefined) ||
      (this.InvoiceSortMode != null && sortBy === undefined) ||
      invoices.length == 0
    )
      return invoices;

    if (sortBy === InvoiceSortBy.BY_NUMBER) {
      if (this.InvoiceSortMode === SortMode.ASC) return invoices.sort(SortInvoiceByIdDESC).sort(SortInvoiceByNumberASC);
      if (this.InvoiceSortMode === SortMode.DESC)
        return invoices.sort(SortInvoiceByIdDESC).sort(SortInvoiceByNumberDESC);
    } else if (sortBy === InvoiceSortBy.BY_DATE) {
      if (this.InvoiceSortMode === SortMode.ASC) return invoices.sort(SortInvoiceByIdDESC).sort(SortInvoiceByDateASC);
      if (this.InvoiceSortMode === SortMode.DESC) return invoices.sort(SortInvoiceByIdDESC).sort(SortInvoiceByDateDESC);
    } else if (sortBy === InvoiceSortBy.BY_STATUS) {
      if (this.InvoiceSortMode === SortMode.ASC) return invoices.sort(SortInvoiceByIdDESC).sort(SortInvoiceByStatusASC);
      if (this.InvoiceSortMode === SortMode.DESC)
        return invoices.sort(SortInvoiceByIdDESC).sort(SortInvoiceByStatusDESC);
    } else if (sortBy === InvoiceSortBy.BY_SUM) {
      if (this.InvoiceSortMode === SortMode.ASC) return invoices.sort(SortInvoiceByIdDESC).sort(SortInvoiceBySumASC);
      if (this.InvoiceSortMode === SortMode.DESC) return invoices.sort(SortInvoiceByIdDESC).sort(SortInvoiceBySumDESC);
    }

    return invoices;
  }

  private GetInvoiceSortBy(headerItem: HeaderItem | null): InvoiceSortBy | null {
    if (headerItem === this.invoiceHeaderNumber) return InvoiceSortBy.BY_NUMBER;
    else if (headerItem === this.invoiceHeaderDate) return InvoiceSortBy.BY_DATE;
    else if (headerItem === this.invoiceHeaderStatus) return InvoiceSortBy.BY_STATUS;
    else if (headerItem === this.invoiceHeaderSum) return InvoiceSortBy.BY_SUM;

    return null;
  }

  private GetInvoiceLoadData(): LoadInvoicesPageData {
    return {
      accountId: this.Account.id,
      sortBy: this.GetInvoiceSortBy(this.SortByInvoiceHeader),
      sortMode: this.InvoiceSortMode,
      page: this.currentPage,
    };
  }

  private async LoadMoreInvoices() {
    this.isLoadingInvoices = true;
    await InvoiceModule.LoadInvoicesPage(this.GetInvoiceLoadData());
    this.isLoadingInvoices = false;
  }

  private async OnInvoicesSortBy() {
    InvoiceModule.LooseInvoices([this.Invoices, this._componentId!]);
    InvoiceModule.CollectInvoices();
    this.currentPage = 0;

    await this.LoadMoreInvoices();
  }

  private async OnInvoicesScrolled(e: any) {
    const currScroll = e.target.scrollTop;
    const endScroll = e.target.scrollHeight - e.target.clientHeight;

    if (currScroll === endScroll && currScroll > 0 && this.canScrollLoadInvoices) {
      this.canScrollLoadInvoices = false;

      ++this.currentPage;
      await this.LoadMoreInvoices();

      delay(() => {
        this.canScrollLoadInvoices = true;
      }, 500);
    }
  }

  private downloadInvoice(inv: Invoice) {
    if (inv.invoicePdfUrl === null) {
      toast.warning(en.invoiceIsNotReady.titleCase(true), {
        position: POSITION.BOTTOM_RIGHT,
      });
      return;
    }

    window.open(inv.invoicePdfUrl, '_blank')!.focus();
  }

  private async ConfirmInvoicePayment(invoiceId: Guid) {
    this.confirmingInvoicePayment = true;

    let paymentIntentSecret = await InvoiceModule.LoadPaymentIntentSecret(invoiceId);

    if (paymentIntentSecret === undefined) {
      toast.error(en.couldNotConfirmInvoicePayment, {
        position: POSITION.BOTTOM_RIGHT,
      });
      return;
    }

    var defaultPM = this.PaymentMethods.firstOrDefault(a => a.isDefault);

    if (defaultPM == null) {
      toast.error('No default payment method selected', {
        position: POSITION.BOTTOM_RIGHT,
      });

      return;
    }

    this.confirmingInvoicePayment = false;
  }

  private async OnAddPaymentMethod() {
    console.log('OnAddPaymentMethod');
  }

  private async OnChangeDefaultPaymentMethod() {
    const selected = await SelectDefaultPaymentMethod(PaymentMethodModule.PaymentMethods.filter(a => !a.isDefault));

    if (selected === null) {
      return;
    }

    var result = await PaymentMethodModule.MakeDefault(selected.id);

    if (result) {
      toast.success(en.paymentMethodMadeDefault.titleCase(true), {
        position: POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error(en.couldNotMakePaymentMethodDefault.titleCase(true), {
        position: POSITION.BOTTOM_RIGHT,
      });
    }
  }

  private async DeletePaymentMethod(pm: PaymentMethod) {
    var result = await PaymentMethodModule.Delete(pm.id);

    if (result) {
      toast.success(en.paymentMethodDeleted.titleCase(true), {
        position: POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error(en.couldNotDeletePaymentMethod.titleCase(true), {
        position: POSITION.BOTTOM_RIGHT,
      });
    }
  }

  async AddBillingAdmin() {
    const result = await AddBillingAdmin(
      this.Company,
      RoleModule.Roles.filter(a => !a.IsBillingAccountAdmin),
      RoleModule.Roles.filter(a => a.IsBillingAccountAdmin),
      en.addAccountAdmin.titleCase(),
    );

    if (result === null) return;

    var user = this.Users.firstOrDefault(a => a.user.Id == result!.Id)?.user!;
    var role = RoleModule.Roles.firstOrDefault(a => a.IsBillingAccountAdmin)!;

    await UserModule.AddRole([user, role]);
  }

  async RemoveBillingAdmin(user: CompanyUser) {
    var found = this.Users.firstOrDefault(a => a.user.Id.equals(user.Id))?.user!;
    var role = RoleModule.Roles.firstOrDefault(a => a.IsBillingAccountAdmin)!;

    var result = await UserModule.DeleteRole([found, role]);

    if (result[0] !== RequestStatus.OK) {
      toast.error(en.couldNotDeleteBillingAdmin.titleCase(true), {
        position: POSITION.BOTTOM_RIGHT,
      });
    }
  }

  async ChangeOwner() {
    const result = await RemoveBillingAdmin(
      this.Company,
      RoleModule.Roles.filter(a => a.IsBillingAccountAdmin),
      RoleModule.Roles.filter(a => a.IsBillingAccountOwner),
      en.selectNewOwner.titleCase(),
    );

    if (result === null) return;

    var user = this.Users.firstOrDefault(a => a.user.Id == result!.Id)?.user!;
    var currentOwner = this.Users.firstOrDefault(
      a => a.user.Roles.firstOrDefault(r => r.IsBillingAccountOwner) !== null,
    )?.user!;

    await UserModule.ChangeBillingAccountOwner([currentOwner, user]);
  }
  //#endregion

  //#region LOGIC FOR ACCOUT ADMIN USERS
  private GetAccountAdminUserSortBy(headerItem: HeaderItem | null): UserSortBy | null {
    if (headerItem === this.accountAdminEmailHeader) return UserSortBy.BY_EMAIL;
    else if (headerItem === this.accountAdminFullNameHeader) return UserSortBy.BY_FULL_NAME;
    else if (headerItem === this.accountAdminLastSeenHeader) return UserSortBy.BY_LAST_SEEN;

    return null;
  }

  private GetAccountAdminUserLoadData(): LoadUsersPageData {
    return {
      companyId: this.Company.Id,
      sortBy: this.GetAccountAdminUserSortBy(this.SortByAccountAdminUserHeader),
      sortMode: this.accountAdminUserSortMode,
      page: this.accountAdminUserPage,
      includeRoles: RoleModule.Roles.filter(a => a.IsBillingAccountAdmin || a.IsBillingAccountOwner),
      excludeRoles: [],
    };
  }

  private async LoadMoreAccountAdminUsers() {
    if (this.isLoadingAccountAdminUsers || this.Company == null) return;

    this.isLoadingAccountAdminUsers = true;

    const loaded = await UserModule.LoadUsersForCompanyPaged(this.GetAccountAdminUserLoadData());
    await JobModule.LoadCountsForUsers(this.Users.map(a => a.user));

    this.isLoadingAccountAdminUsers = false;

    return loaded;
  }

  private async OnAccountAdminUserSortBy() {
    UserModule.LooseUsersNew([this.Users.map(a => a.user.Id), this.componentIdAccountAdmins]);
    UserModule.CollectUsers();
    this.accountAdminUserPage = 0;

    await this.LoadMoreAccountAdminUsers();
  }

  private async OnAccountAdminUsersScrolled(e: any) {
    const currScroll = e.target.scrollTop;
    const endScroll = e.target.scrollHeight - e.target.clientHeight;

    if (currScroll === endScroll && currScroll > 0 && this.canScrollLoadAccoutAdminUsers) {
      this.canScrollLoadAccoutAdminUsers = false;

      ++this.accountAdminUserPage;
      var loaded = await this.LoadMoreAccountAdminUsers();

      if (loaded == 0) {
        --this.accountAdminUserPage;
      }

      delay(() => {
        this.canScrollLoadAccoutAdminUsers = true;
      }, 500);
    }
  }
  //#endregion

  //#region VIEW STATE

  private AccountInfoCollapseToggled(newVal: boolean) {
    GlobalDataModule.ChangeCompanyViewBillingTabAccountInfoCollapsed(newVal);
  }

  private AccountAdminsCollapseToggled(newVal: boolean) {
    GlobalDataModule.ChangeCompanyViewBillingTabAccountAdminsCollapsed(newVal);
  }

  private PaymentMethodsCollapseToggled(newVal: boolean) {
    GlobalDataModule.ChangeCompanyViewBillingTabPaymentMethodsCollapsed(newVal);
  }

  private BalanceCollapseToggled(newVal: boolean) {
    GlobalDataModule.ChangeCompanyViewBillingTabBalanceCollapsed(newVal);
  }

  private InvoicesCollapseToggled(newVal: boolean) {
    GlobalDataModule.ChangeCompanyViewBillingTabInvoicesCollapsed(newVal);
  }

  //#endregion
  //#endregion
  //#region EVENTS
  @Emit('openUser')
  private OpenUser() {
    this.isOpeningUser = true;
  }
  //#endregion

  //#region TRANSLATIONS
  private cardIconName(pm: PaymentMethod): string {
    return ComponentHelper.cardIconName(pm);
  }

  private get AccountInfoCaption() {
    return en.accountInfo.titleCase();
  }

  private get EditCaption() {
    return en.edit.titleCase();
  }
  private get NameCaption() {
    return en.nameCaption.titleCase();
  }
  private get TaxRateCaption() {
    return en.taxRate.titleCase();
  }
  private get TaxIdCaption() {
    return en.taxId.titleCase();
  }
  private get CountryCaption() {
    return en.country.titleCase();
  }
  private get StateRegionCaption() {
    return en.stateOrRegion.titleCase();
  }
  private get PostalCodeCaption() {
    return en.postalCode.titleCase();
  }
  private get EmailCaption() {
    return en.email.titleCase();
  }
  private get CityCaption() {
    return en.city.titleCase();
  }
  private get AddressCaption() {
    return en.address.titleCase();
  }
  private get PhoneCaption() {
    return en.phone.titleCase();
  }
  private get OwnerCaption() {
    return en.owner.titleCase();
  }
  private get AddNewCaption() {
    return en.addNew.titleCase();
  }
  private get ChangeOwnerCaption() {
    return en.changeOwner.titleCase();
  }
  private get DeleteAdminsCaption() {
    return en.deleteAdmins.titleCase();
  }
  private get PaymentMethodsCaption() {
    return en.paymentMethods.titleCase();
  }
  private get BalanceCaption() {
    return en.balance.titleCase();
  }
  private get AccountBalanceCaption() {
    return en.accountBalance;
  }
  private get AddCaption() {
    return en.add.titleCase();
  }
  private get DefaultCaption() {
    return en.default;
  }
  private get ConfirmCaption() {
    return en.confirm.titleCase();
  }
  private get InvoicesCaption() {
    return en.invoices.titleCase();
  }
  private get AccountAdminsCaption() {
    return en.accountAdmins.titleCase();
  }
  private get ChangeDefaultCaption() {
    return en.changeDefault.titleCase();
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.company-billing {
  width: 100%;

  display: flex;
  flex-direction: row;

  .left-panel {
    width: 55%;
    max-height: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
  }

  .right-panel {
    width: 45%;
    display: flex;
    flex-direction: column;
    z-index: 5;
    min-width: 650px;
  }

  #payment-form-container {
    &.hidden {
      height: 0px;
      opacity: 0;
      pointer-events: none;
    }

    button {
      border: none;
      background-color: var(--main-orange);
      color: white;
      border-radius: 6px;
      height: 32px;
      font-size: 16px;

      &:hover {
        opacity: 0.85;
      }
    }

    transition:
      max-height 1s ease-in,
      opacity 1s ease-in;
  }

  .account-info-line {
    display: flex;
    font-size: 13px;
    margin-bottom: 0.75rem;
    &:last-child {
      margin-bottom: 0;
    }

    margin-left: 8px;
    .field {
      color: #949494;
      width: 138px;
      text-align: start;
    }

    .value {
      color: #cecece;
    }
  }

  .invoices {
    position: relative;
    min-height: 350px;
    &.collapsed {
      min-height: 42px;
    }
  }

  .vertical-resizer {
    cursor: col-resize;
    width: 10px;
    margin: 0 3px;
  }

  .spinner-container {
    min-height: 40px;
    margin: 20px 0;
    position: relative;
    opacity: 1;

    &.hidden {
      opacity: 0;
      min-height: 0px;
      margin: 0px;
    }

    transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);
  }
}
</style>
