<template>
  <div class="main-view-container">
    <component :is="currentComponent" v-bind="componentProps" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { getHomePage, safeNavigate } from '@/router';
import { Routes } from '@/router/routes';
import { LoginModule, USER_ID_FIELD, USER_TOKEN_FIELD } from '@/store/modules/loginModule';
import { StoreHelper } from '@/store/util/storeHelpers';

import Login from './Login.vue';
import Loading from './Loading.vue';
import WorkField from './WorkField.vue';
import { isDevMode } from '@/util/env';

const CHILD_COMPONENTS = {
  LOADING: 'loading',
  LOGIN: 'login',
  MAIN: 'workField',
};

@Component({
  components: {
    loading: Loading,
    login: Login,
    workField: WorkField,
  },
})
export default class MainView extends Vue {
  @Prop() pageParam?: string;

  returnPath = '';
  isRedirecting = false;

  created() {
    this.extractDevAuthParams();
    LoginModule.updateToken();
  }

  get initialized() {
    return LoginModule.Initialized;
  }

  get isAuthorized() {
    return LoginModule.IsAuthorized;
  }

  get shouldRedirectToAura3() {
    return Boolean(this.returnPath);
  }

  get componentProps() {
    return {
      pageParam: this.pageParam,
    };
  }

  get currentComponent() {
    if (!this.initialized || this.isRedirecting) {
      return CHILD_COMPONENTS.LOADING;
    }
    if (!this.isAuthorized) {
      return CHILD_COMPONENTS.LOGIN;
    }

    return CHILD_COMPONENTS.MAIN;
  }

  @Watch('initialized')
  @Watch('isAuthorized')
  async onInitialized() {
    if (!this.initialized) {
      return;
    }

    const { fullPath, path, params, query } = this.$route;
    const { forwardTo } = params;

    if (typeof query.returnPath === 'string') {
      this.returnPath = query.returnPath;
    }

    if (!LoginModule.IsAuthorized) {
      return safeNavigate({
        name: Routes.LOGIN,
        params: {
          forwardTo: fullPath,
        },
      });
    }

    if (this.shouldRedirectToAura3) {
      this.isRedirecting = true;
      const queryParams = await LoginModule.getAuthQueryParams();
      return StoreHelper.redirectToAuraApp(this.returnPath + queryParams);
    }

    if (forwardTo && forwardTo !== '/') {
      return safeNavigate({ path: forwardTo });
    }

    if (path === '/' || path === '/login') {
      return safeNavigate({ path: getHomePage() });
    }
  }

  extractDevAuthParams() {
    if (isDevMode()) {
      const { refreshToken, userId } = this.$route.query;
      if (typeof refreshToken === 'string' && typeof userId === 'string') {
        localStorage.setItem(USER_ID_FIELD, decodeURIComponent(userId));
        localStorage.setItem(USER_TOKEN_FIELD, decodeURIComponent(refreshToken));
        history.replaceState(null, '', window.location.origin + window.location.pathname);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.main-view-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
