import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import * as ips from '../../util/api/ips';
import * as routes from '../../util/api/routes';
import * as hubActions from '../../util/api/hubActions';
import { Guid } from 'guid-typescript';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { UtilModule } from './utilModule';
import store from '@/store';
import { Job, JobArchieve, JobState, MaterialConsumptionArchive, Source, User } from '../../models/Entities';
import '../../models/responses/ResponseIdentityExtensions';
import {
  ResponseJob,
  ResponseJobActive,
  ResponseJobArchieve,
  ResponseJobArchieveCount,
  ResponseJobArchieveCounts,
  ResponseJobArchieves,
  ResponseJobInvoked,
  ResponseJobProgress,
  ResponseJobStartFileTransferProgress,
  ResponseJobs,
  ResponseJobStatus,
  ResponseMonolithHelper,
  ResponseJobUpdateErrorCount,
  ResponseJobUpdateLastError,
  ResponseMaterialConsumptionArchives,
} from '../../models/responses/ResponseMonolith';
import {
  ArchieveJobSortBy,
  JobSortBy,
  RequestChangeJobState,
  RequestCreateJob,
  RequestDeleteJob,
  RequestJobForceStart,
  RequestReadActiveJobsByPrinterId,
  RequestReadByIdSource,
  RequestReadJobArchieveById,
  RequestReadJobArchievesByPrinterId,
  RequestReadJobArchievesByUserId,
  RequestReadJobArchievesCount,
  RequestReadJobArchievesBySourceId,
  RequestReadJobById,
  RequestReadJobCountForUserIds,
  RequestReadMoreForPrinter,
  RequestReadMoreJobArchieves,
  RequestReadMoreJobs as RequestReadMoreJobs,
  RequestReadMoreJobsForUser,
  RequestUpdateJobOrder,
  RequestReadAdjacentJobs,
  RequestReadMaterialConsumptionsByArchiveId,
} from '../../models/requests/RequestMonolith';
import { HubConnection } from '@microsoft/signalr';
import { nameof, TypeHelper } from '@/util/TypeHelper';
import { GuidHelper } from '@/util/GuidHelper';
import { PrinterModule } from './printerModule';
import { SourceModule } from './sourceModule';
import { New_GC } from '../util/GC';
import { UserModule } from './userModule';
import { ErrorModule } from '@/store/modules/errorsModule';
import { HubConnectionModule } from '@/store/modules/hubConnectionModule';
import Vue from 'vue';
import { SortMode } from '@/models/requests/Datas';
import en from '@/localization/en';
import { SimpleEventDispatcher } from 'strongly-typed-events';

@Module({ dynamic: true, name: 'job', store: store })
export default class jobModule extends VuexModule {
  private jobs: Job[] = [];
  private jobArchieves: JobArchieve[] = [];
  private userJobsCount: Map<User, number> = new Map();

  private _gcJobArchievesNew = new New_GC();
  private _gcJobNew = new New_GC();

  private onJobUpdated = new SimpleEventDispatcher<Job>();
  private onJobFileTransferProgressUpdated = new SimpleEventDispatcher<ResponseJobStartFileTransferProgress>();

  public get OnJobUpdated() {
    return this.onJobUpdated.asEvent();
  }

  public get OnJobFileTransferProgressUpdated() {
    return this.onJobFileTransferProgressUpdated.asEvent();
  }

  get Jobs(): Job[] {
    // console.log("Jobs: ", this.jobs.length);
    return this.jobs;
  }
  get JobArchieves(): JobArchieve[] {
    // console.log("Job archieves: ", this.jobArchieves.length);
    return this.jobArchieves;
  }
  get JobCount(): Map<User, number> {
    return this.userJobsCount;
  }

  @Mutation
  AddJobToModule(value: Job) {
    if (this.jobs.find(a => a.Id.equals(value.Id))) return;
    // this._gcJobs.UpdateSubscription(value.Id);
    this._gcJobNew.Add([value.Id]);
    this.jobs.push(value);
  }
  @Mutation
  DeleteJobFromModule(value: Job) {
    // this._gcJobs.DeleteSubscription(value.Id);
    this._gcJobNew.Remove([value.Id]);
    this.jobs.delete(value);
  }
  @Mutation
  AddJobArchieveToModule(value: JobArchieve) {
    if (this.jobArchieves.find(a => a.Id.equals(value.Id))) return;
    // this._gcJobArchieves.UpdateSubscription(value.Id);
    this._gcJobArchievesNew.Add([value.Id]);
    this.jobArchieves.push(value);
  }
  @Mutation
  DeleteJobArchieveFromModule(value: JobArchieve) {
    // this._gcJobArchieves.DeleteSubscription(value.Id);
    this._gcJobArchievesNew.Remove([value.Id]);
    this.jobArchieves.delete(value);
  }
  @Mutation
  SetUserJobCount([user, count]: [User, number]) {
    this.userJobsCount.set(user, count);
  }
  @Mutation
  IncreaseUserJobsCount(user: User) {
    let count = this.userJobsCount.get(user);
    if (count === undefined) return;
    this.userJobsCount.set(user, ++count);
  }
  @Mutation
  DecreaseUserJobsCount(user: User) {
    let count = this.userJobsCount.get(user);
    if (count === undefined) return;
    this.userJobsCount.set(user, --count);
  }
  @Mutation
  DeleteUserJobCount(value: User) {
    this.userJobsCount.delete(value);
  }
  @Mutation
  UpdateJobStartFileTransferProgress([job, newProgress]: [Job, number]) {
    job.StartFileTransferProgress = newProgress;
  }
  @Mutation
  UpdateJobErrorCount([job, newCount]: [Job, number]) {
    job.ErrorCount = newCount;
  }
  @Mutation
  UpdateJobLastError([job, newCount]: [Job, string]) {
    job.LastError = newCount;
  }
  static GetUserIdsByAll(jobs: Job[], jobArchieves: JobArchieve[]): Guid[] {
    const ids1 = jobModule.GetUserIdsByJobs(jobs);
    const ids2 = jobModule.GetUserIdsByArchieves(jobArchieves);
    return GuidHelper.distinct(ids1.concat(ids2));
  }
  static GetUserIdsByJobs(jobs: Job[]): Guid[] {
    return GuidHelper.distinct(jobs.filter(a => a.UserId != null).map(a => a.UserId!));
  }
  static GetUserIdsByArchieves(jobArchieves: JobArchieve[]): Guid[] {
    return GuidHelper.distinct(jobArchieves.filter(a => a.UserId != null).map(a => a.UserId!));
  }
  static GetSourceIdsByJobs(jobs: Job[]): Guid[] {
    return GuidHelper.distinct(jobs.filter(a => a.SourceId != null).map(a => a.SourceId!));
  }
  static GetSourceIdsByArchieves(jobArchieves: JobArchieve[]): Guid[] {
    return GuidHelper.distinct(jobArchieves.filter(a => a.SourceId != null).map(a => a.SourceId!));
  }
  static GetSourceIdsByAll(jobs: Job[], jobArchieves: JobArchieve[]): Guid[] {
    const ids1 = jobModule.GetSourceIdsByJobs(jobs);
    const ids2 = jobModule.GetSourceIdsByArchieves(jobArchieves);
    return GuidHelper.distinct(ids1.concat(ids2));
  }
  @Action({ rawError: true })
  async GetJob(jobId: Guid): Promise<Job | null> {
    const inLstRemote = this.Jobs.singleOrDefault(a => a.Id.equals(jobId));
    if (inLstRemote) return inLstRemote;
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.JOB,
      routes.UriJob.READ_BY_ID,
    );
    const request = new RequestReadJobById();
    request.id = jobId.toString();
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealJob(axiosResponse.data.response as ResponseJob);
      const job = response.Map();
      this.AddJobToModule(job);
      return job;
    } else {
      ErrorModule.ShowError(message);
    }
    return null;
  }
  @Action({ rawError: true })
  async GetJobArchieve(jobArchieveId: Guid): Promise<JobArchieve | null> {
    const inLst = this.JobArchieves.singleOrDefault(a => a.Id.equals(jobArchieveId));
    if (inLst) return inLst;
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.JOB_ARCHIEVE,
      routes.UriJobArchieve.READ_BY_ID,
    );
    const request = new RequestReadJobArchieveById();
    request.id = jobArchieveId.toString();
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealJobArchieve(axiosResponse.data.response as ResponseJobArchieve);
      const jobArchieve = response.Map();
      this.AddJobArchieveToModule(jobArchieve);
      return jobArchieve;
    } else {
      ErrorModule.ShowError(message);
    }
    return null;
  }
  @Action({ rawError: true })
  async StartNewJob([sourceId, printerId, startTime, order]: [Guid, Guid, Date?, number?]): Promise<Job | null> {
    const request = new RequestCreateJob();
    request.jobSourceId = sourceId.toString();
    request.printerId = printerId.toString();
    if (startTime !== undefined) request.scheduledTime = startTime.toJSON();
    if (order !== undefined) request.order = order;
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.JOB,
      routes.UriJob.CREATE,
    );
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    let newJob: Job | null = null;
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealJob(axiosResponse.data.response as ResponseJob);
      await PrinterModule.LoadPrinters([printerId]);
      await SourceModule.LoadSources([sourceId]);
      newJob = response.Map();
      this.AddJobToModule(newJob);
    } else ErrorModule.ShowError(message);
    return newJob;
  }
  @Action({ rawError: true })
  async DeleteJob(job: Job): Promise<[RequestStatus, string]> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.JOB,
      routes.UriJob.DELETE,
    );
    const request = new RequestDeleteJob();
    request.id = job.Id.toString();
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      this.DeleteJobFromModule(job);
      if (job.UserId != null) {
        const userId = job.UserId;
        const user = UserModule.Users.singleOrDefault(a => a.Id.equals(userId));
        if (user != null) {
          this.DecreaseUserJobsCount(user);
        }
      }
    }
    return [result, message];
  }

  @Action({ rawError: true })
  async ReadMaterialConsumptionsByArchiveId(archiveId: Guid): Promise<MaterialConsumptionArchive[] | null> {
    const request = new RequestReadMaterialConsumptionsByArchiveId();
    request.archiveId = archiveId.toString();

    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.JOB_ARCHIEVE,
      routes.UriJobArchieve.READ_MATERIAL_CONSUMPTIONS_BY_ARCHIVE_ID,
    );

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    const consumptions: MaterialConsumptionArchive[] = [];

    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealMaterialConsumptionArchives(
        axiosResponse.data.response as ResponseMaterialConsumptionArchives,
      );

      for (const item of response.materialConsumptionArchives) {
        consumptions.push(item.Map());
      }
    } else {
      return null;
    }

    return consumptions;
  }

  @Action({ rawError: true })
  async FetchAdjacent([jobId, count]: [Guid, number]): Promise<Job[] | null> {
    this._gcJobNew.Lock();

    const request = new RequestReadAdjacentJobs();
    request.count = count;
    request.jobId = jobId.toString();

    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.JOB,
      routes.UriJob.READ_ADJACENT,
    );

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    const jobs: Job[] = [];

    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealJobs(axiosResponse.data.response as ResponseJobs);
      await Promise.all([
        PrinterModule.LoadPrinters(GuidHelper.distinct(response.printJobs.map(a => a.printerId))),
        SourceModule.LoadSources(
          GuidHelper.distinct(<Guid[]>response.printJobs.map(a => a.sourceId).filter(a => a != null)),
        ),
        UserModule.LoadUsers(GuidHelper.distinct(<Guid[]>response.printJobs.map(a => a.userId).filter(a => a != null))),
      ]);
      for (const item of response.printJobs) {
        jobs.push(item.Map());
        this.AddJobToModule(item.Map());
      }
    } else {
      return null;
    }

    Vue.nextTick(() => {
      this._gcJobNew.Unlock();
    });

    return jobs;
  }

  @Action({ rawError: true })
  async LoadMoreForUser([additions, userId]: [JobQueryAdditions, Guid?]): Promise<void> {
    let uri: string;

    this._gcJobNew.Lock();

    const requestOuter = new RequestReadMoreJobs();
    requestOuter.page = additions.page;
    requestOuter.pageSize = additions.pageSize;
    requestOuter.sortMode = additions.sortMode;
    requestOuter.sortBy = additions.sortBy;
    let request: any;
    if (userId === undefined) {
      uri = UtilModule.SHW.GetUri(
        ips.PROTOCOL(),
        ips.IP(),
        ips.PORT(),
        routes.UriServices.MONOLITH,
        routes.UriController.JOB,
        routes.UriJob.READ_MORE_MY_JOBS,
      );
      request = requestOuter;
    } else {
      uri = UtilModule.SHW.GetUri(
        ips.PROTOCOL(),
        ips.IP(),
        ips.PORT(),
        routes.UriServices.MONOLITH,
        routes.UriController.JOB,
        routes.UriJob.READ_MORE_USER_JOBS,
      );
      const requestForUser = new RequestReadMoreJobsForUser();
      requestForUser.userId = userId.toString();
      requestForUser.outer = requestOuter;
      request = requestForUser;
    }
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealJobs(axiosResponse.data.response as ResponseJobs);
      await Promise.all([
        PrinterModule.LoadPrinters(GuidHelper.distinct(response.printJobs.map(a => a.printerId))),
        SourceModule.LoadSources(
          GuidHelper.distinct(<Guid[]>response.printJobs.map(a => a.sourceId).filter(a => a != null)),
        ),
      ]);
      for (const item of response.printJobs) {
        this.AddJobToModule(item.Map());
      }
    } else ErrorModule.ShowError(message);

    Vue.nextTick(() => {
      this._gcJobNew.Unlock();
    });
  }

  @Action({ rawError: true })
  async LoadMoreArchieveForUser([additions, userId]: [ArchieveJobQueryAdditions, Guid]): Promise<void> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.JOB_ARCHIEVE,
      routes.UriJobArchieve.READ_BY_USER_ID,
    );

    this._gcJobArchievesNew.Lock();
    const requestOuter = new RequestReadMoreJobArchieves();
    requestOuter.pageSize = additions.pageSize;
    requestOuter.page = additions.page;
    requestOuter.sortMode = additions.sortMode;
    requestOuter.sortBy = additions.sortBy;
    const request = new RequestReadJobArchievesByUserId();
    request.outer = requestOuter;
    request.userId = userId.toString();
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealJobArchieves(
        axiosResponse.data.response as ResponseJobArchieves,
      );
      await PrinterModule.LoadPrinters(GuidHelper.distinct(response.jobArchieves.map(a => a.printerId)));
      await SourceModule.LoadSources(
        GuidHelper.distinct(<Guid[]>response.jobArchieves.map(a => a.sourceId).filter(a => a != null)),
      );
      for (const item of response.jobArchieves) {
        this.AddJobArchieveToModule(item.Map());
      }
    } else ErrorModule.ShowError(message);

    Vue.nextTick(() => {
      this._gcJobArchievesNew.Unlock();
    });
  }
  @Action({ rawError: true })
  async JustLoadMoreJobArcievesForSource([additions, sourceId]: [ArchieveJobQueryAdditions, Guid]) {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.JOB_ARCHIEVE,
      routes.UriJobArchieve.READ_BY_SOURCE_ID,
    );
    const requestOuter = new RequestReadMoreJobArchieves();
    requestOuter.pageSize = additions.pageSize;
    requestOuter.page = additions.page;
    requestOuter.sortMode = additions.sortMode;
    requestOuter.sortBy = additions.sortBy;
    const request = new RequestReadJobArchievesBySourceId();
    request.outer = requestOuter;
    request.sourceId = sourceId.toString();
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealJobArchieves(
        axiosResponse.data.response as ResponseJobArchieves,
      );
      return response.jobArchieves.map(a => a.Map());
    } else ErrorModule.ShowError(message);

    return [];
  }
  @Action({ rawError: true })
  async LoadAndAddJobsForCompany([additions, companyId]: [JobQueryAdditions, Guid?]): Promise<Job[]> {
    const jobs = await this.JustLoadMoreForCompany([additions, companyId]);
    if (jobs == null || jobs.empty()) return [];
    const jobIds = jobs.map(a => a.Id);
    const inLst = this.Jobs.filter(a => GuidHelper.includes(jobIds, a.Id));
    const inLstIds = inLst.map(a => a.Id);
    const toAdd = jobs.filter(a => !GuidHelper.includes(inLstIds, a.Id));
    for (const item of toAdd) {
      this.AddJobToModule(item);
    }
    return this.Jobs.filter(a => GuidHelper.includes(jobIds, a.Id));
  }
  @Action({ rawError: true })
  async JustLoadMoreForCompany([additions]: [JobQueryAdditions, Guid?]): Promise<Job[]> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.JOB,
      routes.UriJob.READ_MORE,
    );
    const request = new RequestReadMoreJobs();
    request.page = additions.page;
    request.pageSize = additions.pageSize;
    request.sortBy = additions.sortBy;
    request.sortMode = additions.sortMode;
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealJobs(axiosResponse.data.response as ResponseJobs);
      return response.printJobs.map(a => a.Map());
    }
    ErrorModule.ShowError(message);
    return [];
  }
  @Action({ rawError: true })
  async LoadAndAddJobArchievesForCompany([additions, companyId]: [ArchieveJobQueryAdditions, Guid?]): Promise<
    JobArchieve[]
  > {
    const jobArchieves = await this.JustLoadMoreJobsArchievesForCompany([additions, companyId]);
    if (jobArchieves == null || jobArchieves.empty()) return [];
    const jobArchieveIds = jobArchieves.map(a => a.Id);
    const inLst = this.JobArchieves.filter(a => GuidHelper.includes(jobArchieveIds, a.Id));
    const inLstIds = inLst.map(a => a.Id);
    const toAdd = jobArchieves.filter(a => !GuidHelper.includes(inLstIds, a.Id));
    for (const item of toAdd) {
      this.AddJobArchieveToModule(item);
    }
    return this.JobArchieves.filter(a => GuidHelper.includes(jobArchieveIds, a.Id));
  }
  @Action({ rawError: true })
  async JustLoadMoreJobsArchievesForCompany([additions]: [ArchieveJobQueryAdditions, Guid?]): Promise<JobArchieve[]> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.JOB_ARCHIEVE,
      routes.UriJobArchieve.READ_BY_MORE,
    );
    const request = new RequestReadMoreJobArchieves();
    request.pageSize = additions.pageSize;
    request.page = additions.page;
    request.sortBy = additions.sortBy;
    request.sortMode = additions.sortMode;
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealJobArchieves(
        axiosResponse.data.response as ResponseJobArchieves,
      );
      return response.jobArchieves.map(a => a.Map());
    }
    ErrorModule.ShowError(message);
    return [];
  }
  @Action({ rawError: true })
  async LoadAndAddJobsForPrinter([printerId, additions]: [Guid, JobQueryAdditions]): Promise<Job[]> {
    this._gcJobNew.Lock();

    const jobs = await this.JustLoadMoreForPrinter([printerId, additions]);
    if (jobs == null || jobs.empty()) return [];
    const jobIds = jobs.map(a => a.Id);
    const inLst = this.Jobs.filter(a => GuidHelper.includes(jobIds, a.Id));
    const inLstIds = inLst.map(a => a.Id);
    const toAdd = jobs.filter(a => !GuidHelper.includes(inLstIds, a.Id));
    for (const item of toAdd) {
      this.AddJobToModule(item);
    }

    Vue.nextTick(() => {
      this._gcJobNew.Unlock();
    });

    return this.Jobs.filter(a => GuidHelper.includes(jobIds, a.Id));
  }
  @Action({ rawError: true })
  async JustLoadMoreForPrinter([printerId, additions]: [Guid, JobQueryAdditions]): Promise<Job[]> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.JOB,
      routes.UriJob.READ_PRINTER_JOBS,
    );
    const requestOuter = new RequestReadMoreJobs();
    requestOuter.page = additions.page;
    requestOuter.pageSize = additions.pageSize;
    requestOuter.sortBy = additions.sortBy;
    requestOuter.sortMode = additions.sortMode;
    const request = new RequestReadMoreForPrinter();
    request.printerId = printerId.toString();
    request.outer = requestOuter;
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealJobs(axiosResponse.data.response as ResponseJobs);
      await Promise.all([
        PrinterModule.LoadPrinters(GuidHelper.distinct(response.printJobs.map(a => a.printerId))),
        SourceModule.LoadSources(
          GuidHelper.distinct(<Guid[]>response.printJobs.map(a => a.sourceId).filter(a => a != null)),
        ),
      ]);
      return response.printJobs.map(a => a.Map());
    }
    ErrorModule.ShowError(message);
    return [];
  }
  @Action({ rawError: true })
  async LoadAndAddJobArchievesForPrinter([printerId, additions]: [Guid, ArchieveJobQueryAdditions]): Promise<
    JobArchieve[]
  > {
    this._gcJobArchievesNew.Lock();

    const jobArchieves = await this.JustLoadMoreJobArchievesForPrinter([printerId, additions]);
    if (jobArchieves == null || jobArchieves.empty()) return [];
    const jobArchieveIds = jobArchieves.map(a => a.Id);
    const inLst = this.JobArchieves.filter(a => GuidHelper.includes(jobArchieveIds, a.Id));
    const inLstIds = inLst.map(a => a.Id);
    const toAdd = jobArchieves.filter(a => !GuidHelper.includes(inLstIds, a.Id));
    for (const item of toAdd) {
      this.AddJobArchieveToModule(item);
    }

    Vue.nextTick(() => {
      this._gcJobArchievesNew.Unlock();
    });

    return this.JobArchieves.filter(a => GuidHelper.includes(jobArchieveIds, a.Id));
  }
  @Action({ rawError: true })
  async JustLoadMoreJobArchievesForPrinter([printerId, additions]: [Guid, ArchieveJobQueryAdditions]): Promise<
    JobArchieve[]
  > {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.JOB_ARCHIEVE,
      routes.UriJobArchieve.READ_BY_PRINTER_ID,
    );
    const requestOuter = new RequestReadMoreJobArchieves();
    requestOuter.pageSize = additions.pageSize;
    requestOuter.page = additions.page;
    requestOuter.sortBy = additions.sortBy;
    requestOuter.sortMode = additions.sortMode;
    const request = new RequestReadJobArchievesByPrinterId();
    request.printerId = printerId.toString();
    request.outer = requestOuter;
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealJobArchieves(
        axiosResponse.data.response as ResponseJobArchieves,
      );
      return response.jobArchieves.map(a => a.Map());
    }
    ErrorModule.ShowError(message);
    return [];
  }
  @Action({ rawError: true })
  async GetJobsCountForSource(source: Source): Promise<number> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.JOB,
      routes.UriJob.READ_SOURCE_JOBS,
    );
    const request = new RequestReadByIdSource();
    request.sourceId = source.Id.toString();
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealJobs(axiosResponse.data.response as ResponseJobs);
      return response.printJobs.filter(
        a => a.jobState === JobState.START || a.jobState === JobState.PAUSE || a.jobState === JobState.RESUME,
      ).length;
    }
    ErrorModule.ShowError(message);
    return 0;
  }
  @Action({ rawError: true })
  async UpdateJobStatus([job, state]: [Job, JobState]): Promise<void> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.JOB,
      routes.UriJob.UPDATE_STATUS,
    );
    const request = new RequestChangeJobState();
    request.jobId = job.Id.toString();
    request.jobState = state;
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result !== RequestStatus.OK || axiosResponse == null) ErrorModule.ShowError(message);
  }
  @Action({ rawError: true })
  async UpdateJobOrder([job, newOrder]: [Job, number]): Promise<void> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.JOB,
      routes.UriJob.UPDATE_ORDER,
    );
    const request = new RequestUpdateJobOrder();
    request.jobId = job.Id.toString();
    request.newOrder = newOrder;
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result !== RequestStatus.OK || axiosResponse == null) ErrorModule.ShowError(message);
  }
  @Action({ rawError: true })
  async LooseJobs([jobs, componentId]: [Job[], Guid]) {
    this._gcJobNew.Loose(
      jobs.map(a => a.Id),
      componentId,
    );
  }
  @Action({ rawError: true })
  async CollectJobs() {
    const removed = this._gcJobNew.Collect();

    // console.log("Removing", removed.length);

    for (const job of this.jobs.filter(a => GuidHelper.includes(removed, a.Id))) {
      this.DeleteJobFromModule(job);
    }

    console.log(`Collected jobs. ${this.jobs.length} left`);
  }
  @Action({ rawError: true })
  async OccupyJobs([jobs, componentId]: [Job[], Guid]) {
    this._gcJobNew.Occupy(
      jobs.map(a => a.Id),
      componentId,
    );
  }

  @Action({ rawError: true })
  async LooseJobArchieves([jobArchieves, componentId]: [JobArchieve[], Guid]) {
    this._gcJobArchievesNew.Loose(
      jobArchieves.map(a => a.Id),
      componentId,
    );
  }
  @Action({ rawError: true })
  async CollectJobArchieves() {
    const removed = this._gcJobArchievesNew.Collect();

    for (const arch of this.jobArchieves.filter(a => GuidHelper.includes(removed, a.Id))) {
      this.DeleteJobArchieveFromModule(arch);
    }

    console.log(`Collected job archives. ${this.jobArchieves.length} left`);
  }
  @Action({ rawError: true })
  async OccupyJobArchieves([jobArchieves, componentId]: [JobArchieve[], Guid]) {
    this._gcJobArchievesNew.Occupy(
      jobArchieves.map(a => a.Id),
      componentId,
    );
  }
  @Action({ rawError: true })
  async LoadCountsForUsers(users: User[]) {
    const needToLoadIds = GuidHelper.except(
      users.map(a => a.Id),
      Array.from(this.JobCount.keys()).map(a => a.Id),
    );
    if (needToLoadIds.length === 0) return;
    const uriArchieveJob = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.JOB_ARCHIEVE,
      routes.UriJobArchieve.READ_USER_COUNTS,
    );
    const requestCurrent = new RequestReadJobCountForUserIds();
    requestCurrent.userIds = users.map(a => a.Id.toString());
    const requestArchieves = new RequestReadJobArchievesCount();
    requestArchieves.ids = users.map(a => a.Id.toString());
    const archieveCountsResult = await UtilModule.SHW.ProcessPost(uriArchieveJob, requestArchieves);
    let responseArchieves: ResponseJobArchieveCount[] = [];
    if (archieveCountsResult.result === RequestStatus.OK && archieveCountsResult.axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealJobArchieveCounts(
        archieveCountsResult.axiosResponse.data.response as ResponseJobArchieveCounts,
      );
      responseArchieves = response.responses;
    }
    const counts: { id: Guid; count: number }[] = [];
    for (const item of responseArchieves) {
      counts.push({ id: item.userId, count: item.count });
    }
    for (const item of counts) {
      const user = users.single(a => a.Id.equals(item.id));
      this.SetUserJobCount([user, item.count]);
    }
  }
  @Action({ rawError: true })
  async ForceStart(jobId: Guid): Promise<void> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.JOB,
      routes.UriJob.FORCE_START,
    );
    const request = new RequestJobForceStart();
    request.id = jobId.toString();
    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      if (axiosResponse.data.success) return;
    }
    ErrorModule.ShowError(en.cantStartJobWithForce);
  }
  @Action({ rawError: true })
  async ReadActiveJob(printerId: Guid): Promise<boolean | null> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.JOB,
      routes.UriJob.READ_ACTIVE_JOB,
    );
    const request = new RequestReadActiveJobsByPrinterId();
    request.printerId = printerId.toString();
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealJobActive(axiosResponse.data.response as ResponseJobActive);
      return response.hasActiveJob;
    }
    ErrorModule.ShowError(message);
    return null;
  }
  @Action({ rawError: true })
  JobsSubscribe(hubConnection: HubConnection) {
    hubConnection.on(hubActions.RECEIVE_CREATE_JOB, async (response: ResponseJob) => {
      const realResponse = ResponseMonolithHelper.createRealJob(response);
      if (this.Jobs.singleOrDefault(a => a.Id.equals(realResponse.id)) != null) return;
      await PrinterModule.LoadPrinters([realResponse.printerId]);
      if (realResponse.sourceId != null) await SourceModule.LoadSources([realResponse.sourceId]);
      this.AddJobToModule(realResponse.Map());
      if (realResponse.userId != null) {
        const user = UserModule.Users.singleOrDefault(a => a.Id.equals(realResponse.userId!));
        if (user != null) {
          this.IncreaseUserJobsCount(user);
        }
      }
    });
    hubConnection?.on(hubActions.RECEIVE_DELETE_JOB, async (response: ResponseJob) => {
      const realResponse = ResponseMonolithHelper.createRealJob(response);
      const job = this.Jobs.singleOrDefault(a => a.Id.equals(realResponse.id));
      if (job != null) {
        this.DeleteJobFromModule(job);
        if (job.UserId != null) {
          const userId = job.UserId;
          const user = UserModule.Users.singleOrDefault(a => a.Id.equals(userId));
          if (user != null) {
            this.DecreaseUserJobsCount(user);
          }
        }
      }
    });
    hubConnection?.on(hubActions.RECEIVE_UPDATE_JOB, async (response: ResponseJob) => {
      const realResponse = ResponseMonolithHelper.createRealJob(response);
      const job = this.Jobs.singleOrDefault(a => a.Id.equals(realResponse.id));
      if (job == null) return;

      if (!job.PrinterId.equals(realResponse.printerId)) {
        Vue.set(job, 'PrinterId', realResponse.printerId);
      }
      if (GuidHelper.equalsWithNull(realResponse.sourceId, job.SourceId)) {
        if (realResponse.sourceId != null) {
          await SourceModule.LoadSources([realResponse.sourceId]);
          Vue.set(job, 'JobSourceId', realResponse.sourceId);
        }
      }
      if (job.JobState !== realResponse.jobState) {
        Vue.set(job, 'JobState', realResponse.jobState);
      }
      const newActualStartTime =
        realResponse.actualStartTime == null ? null : TypeHelper.StringToDate(realResponse.actualStartTime);
      if (job.ActualStartTime !== newActualStartTime) {
        Vue.set(job, 'ActualStartTime', newActualStartTime);
      }
      if (job.Progress !== realResponse.progress) {
        Vue.set(job, 'Progress', realResponse.progress);
      }
      if (job.Order !== realResponse.order) {
        Vue.set(job, 'Order', realResponse.order);
      }
      if (
        (job.UserId && !realResponse.userId) ||
        (!job.UserId && realResponse.userId) ||
        (job.UserId && realResponse.userId && !job.UserId.equals(realResponse.userId))
      ) {
        Vue.set(job, 'UserId', realResponse.userId);
      }

      this.onJobUpdated.dispatch(job);
    });
    // Todo: RECEIVE UPDATE JOB ERROR COUNT
    hubConnection?.on(hubActions.RECEIVE_UPDATE_JOB_ERROR_COUNT, async (response: ResponseJobUpdateErrorCount) => {
      const realRes = ResponseMonolithHelper.createRealJobUpdateErrorCount(response);
      const job = this.jobs.firstOrDefault(a => a.Id.toString() == realRes.id.toString());
      if (job == null || job.ErrorCount == realRes.count) return;
      this.UpdateJobErrorCount([job, realRes.count]);

      this.onJobUpdated.dispatch(job);
    });
    // Todo: RECEIVE UPDATE JOB LAST ERROR
    hubConnection?.on(hubActions.RECEIVE_UPDATE_JOB_LAST_ERROR, async (response: ResponseJobUpdateLastError) => {
      const realRes = ResponseMonolithHelper.createRealJobUpdateLastError(response);
      const job = this.jobs.firstOrDefault(a => a.Id.toString() == realRes.id.toString());
      if (job == null || job.LastError == realRes.lastError) return;
      this.UpdateJobLastError([job, realRes.lastError]);

      this.onJobUpdated.dispatch(job);
    });
    hubConnection?.on(hubActions.RECEIVE_UPDATE_JOB_STATUS, async (response: ResponseJobStatus) => {
      const realResponse = ResponseMonolithHelper.createRealJobStatus(response);
      const job = this.Jobs.singleOrDefault(a => a.Id.equals(realResponse.jobId));
      if (job == null) return;
      if (job.JobState !== realResponse.jobState) {
        Vue.set(job, nameof<Job>('JobState'), realResponse.jobState);
        if (job.JobState === JobState.CANCEL || job.JobState === JobState.FINISH) {
          this.DeleteJobFromModule(job);
        }
      }

      this.onJobUpdated.dispatch(job);
    });
    hubConnection?.on(hubActions.RECEIVE_UPDATE_JOB_PROGRESS, async (response: ResponseJobProgress) => {
      const realResponse = ResponseMonolithHelper.createRealJobProgress(response);
      const job = this.Jobs.singleOrDefault(a => a.Id.equals(realResponse.jobId));
      if (job == null) return;
      if (job.Progress !== realResponse.progress) {
        Vue.set(job, nameof<Job>('Progress'), realResponse.progress);
      }

      this.onJobUpdated.dispatch(job);
    });
    hubConnection?.on(
      hubActions.RECEIVE_UPDATE_JOB_START_PROGRESS,
      async (response: ResponseJobStartFileTransferProgress) => {
        const realResponse = ResponseMonolithHelper.createRealJobStartFileTransferProgress(response);

        this.onJobFileTransferProgressUpdated.dispatch(realResponse);

        const job = this.Jobs.singleOrDefault(a => a.Id.toString() == realResponse.jobId.toString());
        if (job == null) return;

        this.UpdateJobStartFileTransferProgress([job, realResponse.progress]);

        this.onJobUpdated.dispatch(job);
      },
    );
    hubConnection?.on(hubActions.RECEIVE_UPDATE_JOB_START_TIME, async (response: ResponseJob) => {
      const realResponse = ResponseMonolithHelper.createRealJob(response);
      let job = this.Jobs.singleOrDefault(a => a.Id.equals(realResponse.id));
      if (job == null) {
        job = realResponse.Map();
        this.AddJobToModule(job);
      } else {
        const newActualStartTime =
          realResponse.actualStartTime == null ? null : TypeHelper.StringToDate(realResponse.actualStartTime);
        if (job.ActualStartTime !== newActualStartTime) {
          Vue.set(job, nameof<Job>('ActualStartTime'), newActualStartTime);
          Vue.set(job, 'Order', null);
        }
      }

      this.onJobUpdated.dispatch(job);
    });
    hubConnection?.on(hubActions.RECEIVE_UPDATE_JOB_INVOKED, async (response: ResponseJobInvoked) => {
      const realResponse = ResponseMonolithHelper.createRealJobInvoked(response);
      const job = this.Jobs.singleOrDefault(a => a.Id.equals(realResponse.id));
      if (!job) return;
      Vue.set(job, nameof<Job>('IsInvoked'), true);

      this.onJobUpdated.dispatch(job);
    });
    hubConnection?.on(hubActions.RECEIVE_CREATE_JOB_ARCHIEVE, async (response: ResponseJobArchieve) => {
      const realResponse = ResponseMonolithHelper.createRealJobArchieve(response);
      if (this.JobArchieves.singleOrDefault(a => a.Id.equals(realResponse.id)) != null) return;
      await PrinterModule.LoadPrinters([realResponse.printerId]);
      if (realResponse.sourceId != null) await SourceModule.LoadSources([realResponse.sourceId]);
      this.AddJobArchieveToModule(realResponse.Map());
      if (realResponse.userId != null) {
        const user = UserModule.Users.singleOrDefault(a => a.Id.equals(realResponse.userId!));
        if (user != null) {
          this.IncreaseUserJobsCount(user);
        }
      }
    });
  }
  @Action({ rawError: true })
  async SubscribeToCompanyJobsGroup() {
    if (HubConnectionModule.PrintingConnection == null) return;
    await HubConnectionModule.PrintingConnection.send(hubActions.ADD_TO_COMPANY_JOBS_GROUP);
    HubConnectionModule.AddPrintingConnectionGroup({
      func: this.SubscribeToCompanyJobsGroup,
    });
  }
  @Action({ rawError: true })
  async DeleteFromCompanyJobsGroup() {
    if (HubConnectionModule.PrintingConnection == null) return;
    await HubConnectionModule.PrintingConnection.send(hubActions.DELETE_FROM_COMPANY_JOBS_GROUP);
    HubConnectionModule.DeletePrintingConnectionGroup({
      func: this.SubscribeToCompanyJobsGroup,
    });
  }
  @Action({ rawError: true })
  async SubscribeToPrinterJobsGroup(printerId: Guid) {
    if (HubConnectionModule.PrintingConnection == null) return;
    await HubConnectionModule.PrintingConnection.send(
      hubActions.ADD_TO_COMPANY_PRINTER_JOBS_GROUP,
      printerId.toString(),
    );
    HubConnectionModule.AddPrintingConnectionGroup({
      func: this.SubscribeToPrinterJobsGroup,
      args: printerId,
    });
  }
  @Action({ rawError: true })
  async DeleteFromPrinterJobsGroup(printerId: Guid) {
    if (HubConnectionModule.PrintingConnection == null) return;
    await HubConnectionModule.PrintingConnection.send(
      hubActions.DELETE_FROW_COMPANY_PRINTER_JOBS_GROUP,
      printerId.toString(),
    );
    HubConnectionModule.DeletePrintingConnectionGroup({
      func: this.SubscribeToPrinterJobsGroup,
      args: printerId,
    });
  }
}
export const JobModule = getModule(jobModule);

export class JobQueryAdditions {
  pageSize!: number;
  page!: number;
  sortBy: JobSortBy | null = null;
  sortMode: SortMode | null = null;
}

export class ArchieveJobQueryAdditions {
  pageSize!: number;
  page!: number;
  sortBy: ArchieveJobSortBy | null = null;
  sortMode: SortMode | null = null;
}
