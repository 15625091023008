<template>
  <div class="machines-container px-3 py-3">
    <div class="machines">
      <div v-if="!isPROM" class="left-panel thin-scroll">
        <RegularButton
          v-if="IsCompanyAdmin || IsSuperAdmin"
          class="add-new-machine mb-3 mr-2"
          :label="AddNewMachineCaption"
          test-id="machines.addNewMachineButton"
          @clicked="AddNewMachineClicked"
        />
        <div class="machine-list overflow-auto thin-scroll">
          <MachineCard
            v-for="(printer, indx) of FullPrinters"
            :key="printer.printer.Id.toString()"
            :class="[indx == FullPrinters.length - 1 ? 'mr-2' : 'mb-3 mr-2']"
            :printer="printer"
            :isSelected="IsPrinterSelected(printer)"
            @clicked="PrinterCardClicked(printer)"
          />
          <div :class="['spinner', isLoadingPrinters ? '' : 'hidden']"></div>
        </div>
      </div>

      <div v-if="!isPROM" class="vertical-resizer"></div>

      <div class="right-panel thin-scroll" :class="[isPROM ? 'w-100' : '']">
        <ActionCard
          v-if="selectedMachine != null"
          :class="['h-100 main-panel']"
          :headerButtonDatas="PrinterActionCardButtonDatas"
          :collapsible="false"
          :closable="!isPROM"
          :fillContentVertically="true"
          test-id="machines.selectedMachine"
          @closed="DeselectPrinter"
          @more-header-button-clicked="showMoreButtonPopover = !showMoreButtonPopover"
          @more-header-button-popover-autohidden="showMoreButtonPopover = false"
          @edit-layout-header-button-clicked="ToggleLayoutEditing"
          @download-logs-header-button-clicked="DownloadLogsClicked"
        >
          <template slot="headerStart">
            <div class="printer-header">
              <span class="printer-name">{{ SelectedMachineName }}</span>
              <badge class="printer-status ml-2" :text="SelectedMachineStatus" />
            </div>
          </template>

          <template slot="headerCenter">
            <div class="printer-control-switches">
              <DarkButton
                v-for="control of AvailableControls"
                :key="control"
                :class="['printer-control-switch', IsActiveControlType(control) ? 'active' : '']"
                :label="TranslateControlType(control)"
                test-id="machines.selectedMachine.controls.control"
                @clicked="PrinterControlTypeClicked(control)"
              />
            </div>
          </template>

          <template slot="more-header-button-popover">
            <div class="printer-more-button-content">
              <!-- Todo: extract into component -->
              <div
                v-if="!isPROM"
                class="menu-item"
                data-testid="machines.selectedMachine.menu.updateConnection"
                @click="UpdateConnectionClicked"
              >
                Update connection
              </div>
              <div v-if="IsConnected && (IsCompanyAdmin || IsSuperAdmin)" class="menu-item" @click="ExportLogsClicked">
                Export logs
              </div>
              <div
                v-if="IsConnected && (IsCompanyAdmin || IsSuperAdmin)"
                class="menu-item"
                data-testid="machines.selectedMachine.menu.changeConfig"
                @click="ChangeLogConfigClicked"
              >
                Change log configuration
              </div>
              <div
                v-if="IsConnected && (IsCompanyAdmin || IsSuperAdmin)"
                class="menu-item"
                data-testid="machines.selectedMachine.menu.downloadConfig"
                @click="DownloadLogConfigClicked"
              >
                Download log configuration
              </div>
              <div
                v-if="(IsCompanyAdmin || IsSuperAdmin) && !isPROM"
                class="menu-item"
                data-testid="machines.selectedMachine.menu.deletePrinter"
                @click="DeletePrinterClicked"
              >
                Delete printer
              </div>
              <div
                v-if="IsCompanyAdmin || IsSuperAdmin"
                class="menu-item"
                data-testid="machines.selectedMachine.menu.editPrinter"
                @click="EditPrinterClicked"
              >
                Edit printer
              </div>
              <div class="menu-item" data-testid="machines.selectedMachine.menu.resetView" @click="ResetViewClicked">
                Reset view
              </div>
            </div>
          </template>

          <div class="printer-panel">
            <div class="printer-panel-top">
              <div class="printer-model">{{ SelectedMachineModel }}</div>
              <div v-if="selectedMachine" class="printer-id">
                {{ selectedMachine.printer.Id.toString() }}
              </div>
            </div>
            <div :class="['controls', isLoading ? 'disabled' : '']">
              <div class="flow-layout-container w-100 h-100">
                <FlowLayout
                  ref="printerControlsFlowLayout"
                  :cellWidthFraction="0.05"
                  :cellHeightFraction="0.085"
                  :isEditing="isEditingControlsLayout"
                  @child-cell-range-changed="FlowLayoutChildCellRangeChanged"
                >
                  <FlowLayoutChild
                    v-if="ShowCurrentJobsFlowChild"
                    :id="CurrentJobsFlowChildId"
                    :initialCellRange="CellRangeFor(CurrentJobsFlowChildId)"
                  >
                    <ActionCard
                      class="h-100"
                      headerText="Current jobs"
                      test-id="machines.selectedMachine.currentJobs"
                      :collapsible="false"
                      :closable="isEditingControlsLayout"
                      :headerButtonDatas="CurrentJobsHeaderButtonDatas"
                      headerMarginClass="mr-3"
                      @closed="ClosePrinterControlById(CurrentJobsFlowChildId)"
                      @queue-new-job-header-button-clicked="QueueNewJobButtonClicked"
                    >
                      <sortable-header
                        :header-items="PrintJobHeaders"
                        :by.sync="SortByJobHeader"
                        :mode.sync="JobSortMode"
                        test-id="machines.selectedMachine.currentJobs"
                        @sortBy="OnJobSortBy"
                      />
                      <div :class="['thin-scroll overflow-overlay']" @scroll="OnJobsScrolled">
                        <list-item-v-3
                          v-for="item in UserPrintJobs"
                          :id="item.Id"
                          :key="item.Id.toString()"
                          class="cur-pointer"
                          test-id="machines.selectedMachine.currentJobs"
                          :items="PrintJobItems(item)"
                          @openItem="!isPROM ? OpenJob(item) : undefined"
                        />
                      </div>

                      <div :class="['spinner-container', isLoadingJobs ? '' : 'hidden']">
                        <div :class="['spinner', isLoadingJobs ? '' : 'hidden']"></div>
                      </div>
                    </ActionCard>
                  </FlowLayoutChild>

                  <FlowLayoutChild
                    v-if="ShowArchiveJobsFlowChild"
                    :id="ArchiveJobsFlowChildId"
                    :initialCellRange="CellRangeFor(ArchiveJobsFlowChildId)"
                  >
                    <ActionCard
                      class="h-100"
                      headerText="Archive jobs"
                      :collapsible="false"
                      :closable="isEditingControlsLayout"
                      test-id="machines.selectedMachine.archiveJobs"
                      @closed="ClosePrinterControlById(ArchiveJobsFlowChildId)"
                    >
                      <sortable-header
                        :header-items="ArchieveJobHeaders"
                        :by.sync="SortByJobArchieveHeader"
                        :mode.sync="JobArchieveSortMode"
                        test-id="machines.selectedMachine.archiveJobs"
                        @sortBy="OnJobArchievesSortBy"
                      />
                      <div class="thin-scroll overflow-overlay" @scroll="OnJobArchievesScrolled">
                        <ListItemV3
                          v-for="item in FullJobArchieves"
                          :id="item.jobArchieve.Id"
                          :key="item.jobArchieve.Id.toString()"
                          class="cur-pointer"
                          test-id="machines.selectedMachine.archiveJobs"
                          :items="ArchieveJobItems(item)"
                          @openItem="!isPROM ? OpenArchieveJob(item) : undefined"
                        />
                      </div>

                      <div :class="['spinner-container', isLoadingArchiveJobs ? '' : 'hidden']">
                        <div :class="['spinner', isLoadingArchiveJobs ? '' : 'hidden']"></div>
                      </div>
                    </ActionCard>
                  </FlowLayoutChild>

                  <FlowLayoutChild
                    v-if="ShowErrorLogFlowChild"
                    :id="ErrorLogFlowChildId"
                    :initialCellRange="CellRangeFor(ErrorLogFlowChildId)"
                  >
                    <MachineErrors
                      class="h-100"
                      :closable="isEditingControlsLayout"
                      :printer="selectedMachine.printer"
                      @closed="ClosePrinterControlById(ErrorLogFlowChildId)"
                    />
                  </FlowLayoutChild>

                  <FlowLayoutChild
                    v-if="ShowTelemetryFlowChild"
                    :id="TelemetryFlowChildId"
                    :initialCellRange="CellRangeFor(TelemetryFlowChildId)"
                  >
                    <MachineTelemetry
                      class="h-100"
                      :closable="isEditingControlsLayout"
                      :printer="selectedMachine.printer"
                      @closed="ClosePrinterControlById(TelemetryFlowChildId)"
                    />
                  </FlowLayoutChild>

                  <FlowLayoutChild
                    v-if="ShowTerminalFlowChild"
                    :id="TerminalFlowChildId"
                    :initialCellRange="CellRangeFor(TerminalFlowChildId)"
                  >
                    <Terminal
                      class="h-100"
                      :closable="isEditingControlsLayout"
                      :printer="selectedMachine.printer"
                      @closed="ClosePrinterControlById(TerminalFlowChildId)"
                    />
                  </FlowLayoutChild>
                </FlowLayout>
              </div>
            </div>
            <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
          </div>
        </ActionCard>
      </div>
    </div>

    <div :class="['machine-editor-overlay mx-3 my-3', !isEditingPrinter ? 'hidden' : '']">
      <MachineEditor
        :printer="selectedMachine == null ? null : selectedMachine.printer"
        @close-clicked="CloseMachineEditor"
      />
    </div>

    <div
      v-if="!isPROM"
      :class="[
        'add-new-machine-overlay mx-3 my-3',
        !addingNewMachine ? 'hidden' : '',
        isEditingPrinter ? 'offset-bottom' : '',
      ]"
    >
      <AddNewMachine @close-clicked="CloseAddNewMachine" />
    </div>
  </div>
</template>

<script lang="ts">
import ActionCard, { HeaderButtonData } from '@/components/presentation/ActionCard.vue';
import AddNewMachine from '@/components/forms/AddNewMachine.vue';
import Badge from '@/components/presentation/Badge.vue';
import DarkButton from '@/components/buttons/DarkButton.vue';
import RegularButton from '@/components/buttons/RegularButton.vue';
import FlowLayout from '@/components/presentation/FlowLayout.vue';
import FlowLayoutChild from '@/components/presentation/FlowLayoutChild.vue';
import ListItemV3 from '@/components/presentation/ListItemV3.vue';
import MachineCard from '@/components/presentation/MachineCard.vue';
import MachineEditor from '@/components/forms/MachineEditor.vue';
import MachineErrors from '@/components/presentation/MachineErrors.vue';
import MachineTelemetry from '@/components/presentation/MachineTelemetry.vue';
import SortableHeader from '@/components/presentation/SortableHeader.vue';
import Terminal from '@/components/presentation/Terminal.vue';
import en from '@/localization/en';
import { toast } from '@/main';
import { FullJobArchieve, FullPrinter } from '@/models/CompositeEntities';
import { Printer, Job, UserJob, JobArchieve, Source, PrinterState } from '@/models/Entities';
import { ArchieveJobSortBy, JobSortBy } from '@/models/requests/RequestMonolith';
import { CellRange } from '@/models/util/FlowLayout';
import {
  SortFullJobArchieveByIdDESC,
  SortFullJobArchieveByFileASC,
  SortFullJobArchieveByFileDESC,
  SortFullJobArchieveByPrinterASC,
  SortFullJobArchieveByPrinterDESC,
  SortFullJobArchieveDateEndASC,
  SortFullJobArchieveDateEndDESC,
  SortFullJobArchieveByStatusASC,
  SortFullJobArchieveByStatusDESC,
} from '@/models/util/JobArchieveSortings';
import {
  SortUserJobByIdDESC,
  SortUserJobByFileASC,
  SortUserJobByFileDESC,
  SortUserJobByPrinterASC,
  SortUserJobByPrinterDESC,
  SortUserJobByDateStartASC,
  SortUserJobByDateStartDESC,
  SortUserJobByProgressASC,
  SortUserJobByProgressDESC,
  SortUserJobByDateCreatedDESC,
  SortUserJobByDateCreatedASC,
  SortUserJobByOrderASC,
  SortUserJobByOrderDESC,
  SortUserJobByAuthorASC,
  SortUserJobByAuthorDESC,
} from '@/models/util/UserJobSortings';
import { Routes } from '@/router/routes';
import { FlowLayoutChildViewState, GlobalDataModule } from '@/store/modules/globalDataModule';
import jobModule, { ArchieveJobQueryAdditions, JobModule, JobQueryAdditions } from '@/store/modules/jobModule';
import { PrinterModule } from '@/store/modules/printerModule';
import { SourceModule } from '@/store/modules/sourceModule';
import { UserModule } from '@/store/modules/userModule';
import ComponentHelper, { HeaderItem, ItemData, PrinterPanelControlType, SortMode } from '@/util/ComponentHelper';
import { GuidHelper } from '@/util/GuidHelper';
import { Guid } from 'guid-typescript';
import { delay } from 'lodash';
import { create } from 'vue-modal-dialogs';
import { Component, Vue, Watch, Emit, Ref } from 'vue-property-decorator';
import { POSITION } from 'vue-toastification';
import AuraMessageBoxDialog, { Result } from './dialogs/AuraMessageBoxDialog.vue';

import StartNewJobDialog, { StartNewJobDialogResult } from '@/views/dialogs/StartNewJobDialog.vue';
import UpdateConnectionDialog from './dialogs/UpdateConnectionDialog.vue';
import { ConnectorModule } from '@/store/modules/connectorModule';
import { AsyncBatchQueueSignalR } from '@/store/util/Globals';
import { sleep } from '@/util/TypeHelper';
import { isPROM } from '@/util/env';
import { LoginModule } from '@/store/modules/loginModule';
import ChangeLogConfigDialog from './dialogs/ChangeLogConfigDialog.vue';
import LogExportDialog from './dialogs/LogExportDialog.vue';
import { getHomePage, isRouteAllowed, safeNavigate } from '@/router';
const StartNewJob = create<Printer | null, boolean, StartNewJobDialogResult | null>(
  StartNewJobDialog,
  'printer',
  'backButtonAvailable',
);

const UpdatedConnection = create<string, Printer, {}>(UpdateConnectionDialog, 'code', 'printer');

const ChangeLogConfig = create<Printer, boolean>(ChangeLogConfigDialog, 'printer');

const ExportLogs = create<Printer, boolean>(LogExportDialog, 'printer');

const flowLayoutChildrenIds = {
  [PrinterPanelControlType.CurrentJobs]: 'CurrentJobs',
  [PrinterPanelControlType.ArchiveJobs]: 'ArchiveJobs',
  [PrinterPanelControlType.Terminal]: 'Terminal',
  [PrinterPanelControlType.ErrorLog]: 'ErrorLog',
  [PrinterPanelControlType.Telemetry]: 'Telemetry',
};

const DeletePrinterSure = create<Result, String, String, Result>(AuraMessageBoxDialog, 'results', 'header', 'mainText');

@Component({
  components: {
    ActionCard: ActionCard,
    RegularButton: RegularButton,
    Badge: Badge,
    DarkButton: DarkButton,
    MachineCard: MachineCard,
    AddNewMachine: AddNewMachine,
    FlowLayout: FlowLayout,
    FlowLayoutChild: FlowLayoutChild,
    Terminal: Terminal,
    SortableHeader: SortableHeader,
    ListItemV3: ListItemV3,
    MachineErrors: MachineErrors,
    MachineTelemetry: MachineTelemetry,
    MachineEditor: MachineEditor,
  },
  name: 'machines-new',
})
export default class MachinesNew extends Vue {
  created() {
    if (!isRouteAllowed(this.$route)) {
      return safeNavigate({ path: getHomePage() });
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  private async OnRouteChanged() {
    const { companyId, machineId, action } = this.$route.query;

    if (companyId && Guid.isGuid(companyId)) {
      this.CompanyId = Guid.parse(companyId as string);
    } else {
      this.CompanyId = undefined;
    }

    if (machineId && Guid.isGuid(machineId)) {
      this.setSelectedMachine(machineId.toString());
    } else {
      this.setSelectedMachine(null);
    }

    if (action === 'add-new-machine') {
      await sleep(250);
      this.addingNewMachine = true;
    }
  }

  @Watch('selectedMachine', { immediate: true })
  private async SelectedMachineChanged(newVal?: FullPrinter | null, oldVal?: FullPrinter | null) {
    const samePrinter =
      newVal != null && oldVal != null && newVal.printer.Id.toString() == oldVal.printer.Id.toString();

    if (samePrinter) {
      return;
    }

    if (oldVal != undefined) {
      if (newVal != undefined) {
        await GlobalDataModule.ChangeMachinesViewSelectedMachineId(newVal.printer.Id);
      } else {
        await GlobalDataModule.ChangeMachinesViewSelectedMachineId(null);
      }
    }

    // Selected a machine for the first time
    if (newVal != undefined && oldVal == undefined) {
      await this.SubscribeToPrinterGroups(newVal.printer);
    }
    // Selected a new machine
    else if (newVal != null && !samePrinter) {
      await this.UnsubscribeFromPrinterGroups(oldVal!.printer);
      await this.SubscribeToPrinterGroups(newVal!.printer);
    }

    // Todo: more coarse grained Signal-R needed to properly support this lol
    // Deselected a machine
    // else if (oldVal != null && newVal == null) {
    //   await this.UnsubscribeFromPrinterGroups(oldVal.printer);
    // }

    await SourceModule.LooseSources([SourceModule.Sources.map(a => a), this._currentJobsListId]);
    await SourceModule.CollectSources();

    await JobModule.LooseJobs([JobModule.Jobs.map(a => a), this._currentJobsListId]);
    await JobModule.CollectJobs();
    this.jobPage = 0;

    await SourceModule.LooseSources([SourceModule.Sources.map(a => a), this._archiveJobsListId]);
    await SourceModule.CollectSources();

    await JobModule.LooseJobArchieves([JobModule.JobArchieves.map(a => a), this._archiveJobsListId]);
    await JobModule.CollectJobArchieves();
    this.jobArchievePage = 0;

    if (newVal != undefined && newVal != null) {
      this.LoadMoreJobs();
      this.LoadMoreJobArchieves();
    }
  }

  private setSelectedMachine(machineId: string | null) {
    if (machineId === null) {
      this.selectedMachine = null;
    } else {
      this.selectedMachine = this.FullPrinters.firstOrDefault(a => a.printer.Id.toString() == machineId);
    }
  }

  //#region STATE
  // private _componentId!: Guid;
  private _currentJobsListId!: Guid;
  private _archiveJobsListId!: Guid;
  private _machinesId!: Guid;

  private CompanyId: Guid | undefined = undefined;

  private isLoading: boolean = false;
  private isLoadingPrinters: boolean = false;
  private isLoadingJobs: boolean = false;
  private isLoadingArchiveJobs: boolean = false;

  //#region CONNECTION PANEL
  private isEditingPrinter: boolean = false;

  //#endregion

  //#region PRINTER CONTROLS
  @Ref('printerControlsFlowLayout')
  printerControlsFlowLayout!: FlowLayout;

  private activeControls: PrinterPanelControlType[] = [];
  private isEditingControlsLayout: boolean = false;
  private showMoreButtonPopover: boolean = false;

  private get PrinterActionCardButtonDatas(): HeaderButtonData[] {
    const result: HeaderButtonData[] = [];

    if (this.selectedMachine != null && this.selectedMachine.printer.LogsReady) {
      result.push({
        iconType: 'fas',
        iconName: 'fa-arrow-down',
        text: 'Download logs',
        name: 'download-logs',
        testId: 'downloadLogsButton',
      });
    }

    result.push({
      iconType: this.isEditingControlsLayout ? 'fas' : 'far',
      iconName: this.isEditingControlsLayout ? 'fa-check' : 'fa-pen',
      text: this.isEditingControlsLayout ? 'Save layout' : 'Edit layout',
      name: 'edit-layout',
      testId: 'editLayoutButton',
    });

    result.push({
      iconType: 'fas',
      iconName: 'fa-ellipsis-v',
      text: '',
      name: 'more',
      showPopover: this.showMoreButtonPopover,
      testId: 'moreButton',
    });

    return result;
  }

  private get CurrentJobsHeaderButtonDatas(): HeaderButtonData[] {
    const result: HeaderButtonData[] = [];

    result.push({
      iconType: 'fas',
      iconName: 'fa-plus',
      text: 'Queue new job',
      name: 'queue-new-job',
      testId: 'queueNewJobButton',
    });

    return result;
  }

  private get AvailableControls(): PrinterPanelControlType[] {
    const result: PrinterPanelControlType[] = [];

    result.push(PrinterPanelControlType.CurrentJobs);
    result.push(PrinterPanelControlType.ArchiveJobs);
    result.push(PrinterPanelControlType.Terminal);
    result.push(PrinterPanelControlType.ErrorLog);
    result.push(PrinterPanelControlType.Telemetry);

    return result;
  }

  private get ActiveControls() {
    return this.activeControls;
  }

  //#region FLOW LAYOUT
  private get CurrentJobsFlowChildId() {
    return flowLayoutChildrenIds[PrinterPanelControlType.CurrentJobs];
  }

  private get ArchiveJobsFlowChildId() {
    return flowLayoutChildrenIds[PrinterPanelControlType.ArchiveJobs];
  }

  private get ErrorLogFlowChildId() {
    return flowLayoutChildrenIds[PrinterPanelControlType.ErrorLog];
  }

  private get TerminalFlowChildId() {
    return flowLayoutChildrenIds[PrinterPanelControlType.Terminal];
  }

  private get TelemetryFlowChildId() {
    return flowLayoutChildrenIds[PrinterPanelControlType.Telemetry];
  }

  private get ShowCurrentJobsFlowChild() {
    return this.IsActiveControlType(PrinterPanelControlType.CurrentJobs);
  }

  private get ShowArchiveJobsFlowChild() {
    return this.IsActiveControlType(PrinterPanelControlType.ArchiveJobs);
  }

  private get ShowErrorLogFlowChild() {
    return this.IsActiveControlType(PrinterPanelControlType.ErrorLog);
  }

  private get ShowTerminalFlowChild() {
    return this.IsActiveControlType(PrinterPanelControlType.Terminal);
  }

  private get ShowTelemetryFlowChild() {
    return this.IsActiveControlType(PrinterPanelControlType.Telemetry);
  }

  private CellRangeFor(id: string): CellRange | undefined {
    const found = GlobalDataModule.MachinesViewState.flowLayoutData.firstOrDefault(a => a.id == id);

    if (found == null) {
      return undefined;
    }

    return found.cellRange;
  }
  //#endregion
  //#endregion

  //#region PRINTERS
  private addingNewMachine: boolean = false;
  private selectedMachine: FullPrinter | null = null;

  private get IsCompanyAdmin() {
    return LoginModule.IsAdmin;
  }

  private get IsSuperAdmin() {
    return LoginModule.IsSuperAdmin;
  }

  private get SelectedMachineName() {
    if (this.selectedMachine == null) return 'N/A';

    return this.selectedMachine.printer.Name;
  }

  private get IsConnected() {
    if (this.selectedMachine == null) return false;

    const state = this.selectedMachine.printer.PrinterState;
    return (
      state == PrinterState.IDLE ||
      state == PrinterState.BUSY ||
      state == PrinterState.PRINTING ||
      state == PrinterState.PAUSING ||
      state == PrinterState.PAUSED ||
      state == PrinterState.RESUMING ||
      state == PrinterState.PRINT_FINISHED
    );
  }

  private get SelectedMachineStatus() {
    if (this.selectedMachine == null) return 'N/A';

    return ComponentHelper.GetPrinterStateReadableText(this.selectedMachine.printer.PrinterState);
  }

  private get SelectedMachineModel() {
    if (this.selectedMachine == null || this.selectedMachine.model == null) return 'N/A';

    return this.selectedMachine.model.Name;
  }

  private get Printers(): Printer[] {
    let printers = PrinterModule.Printers.sort((a: Printer, b: Printer) => (a.Name < b.Name ? -1 : 1));

    // Todo: add new GC here later
    PrinterModule.OccupyPrinters([printers, this._machinesId]);

    return printers;
  }

  private get FullPrinters(): FullPrinter[] {
    const fullPrinters: FullPrinter[] = [];

    for (let printer of this.Printers) {
      const model = PrinterModule.PrinterModels.firstOrDefault(
        a => a.Id.toString() == printer.PrinterModelId.toString(),
      )!;

      let connectorModel = ConnectorModule.PrinterModels.firstOrDefault(
        a => a.Id.toString() == printer.PrinterModelId.toString(),
      )!;

      let fullPrinter: FullPrinter = {
        printer: printer,
        model: model,
        connectorModel: connectorModel,
      };

      fullPrinters.push(fullPrinter);
    }

    return fullPrinters;
  }

  //#region CURRENT JOBS
  private sortByJobHeader: HeaderItem | null = null;
  private jobSortMode: SortMode | null = null;
  private jobPage: number = 0;
  private loadingJob: boolean = false;

  private get SortByJobHeader(): HeaderItem | null {
    return this.sortByJobHeader;
  }

  private set SortByJobHeader(value: HeaderItem | null) {
    this.sortByJobHeader = value;
  }

  private get JobSortMode(): SortMode | null {
    return this.jobSortMode;
  }

  private set JobSortMode(value: SortMode | null) {
    this.jobSortMode = value;
  }

  private get Jobs(): Job[] {
    return JobModule.Jobs.filter(a => a.PrinterId.toString() == this.selectedMachine?.printer.Id.toString()).map(
      a => a,
    );
  }

  private get Sources(): Source[] {
    return SourceModule.Sources.map(a => a);
  }

  private get UserPrintJobs(): UserJob[] {
    let userPrintJobs: UserJob[] = [];

    for (let printJob of this.Jobs) {
      let userPrintJob = new UserJob();
      userPrintJob.Job = printJob;
      userPrintJob.Id = printJob.Id;
      userPrintJob.Progress = printJob.Progress;
      userPrintJob.Status = printJob.JobState;
      userPrintJob.StartTime = printJob.ActualStartTime == null ? printJob.CreationDateTime : printJob.ActualStartTime;
      let printer = this.Printers.singleOrDefault(a => a.Id.equals(printJob.PrinterId));
      if (printer == null) continue;
      userPrintJob.Printer = printer;
      let printJobSource = this.Sources.singleOrDefault(a => GuidHelper.equalsWithNull(a.Id, printJob.SourceId));
      userPrintJob.Source = printJobSource;

      let user = UserModule.Users.firstOrDefault(a => a.Id.toString() == printJob.UserId?.toString());

      if (user != null) {
        userPrintJob.Author = user;
      }

      userPrintJobs.push(userPrintJob);
    }

    userPrintJobs = this.SortJobs(userPrintJobs);

    const maxCount = (this.jobPage + 1) * 25;

    const toDisplay = userPrintJobs.splice(0, maxCount);
    const toLoose = userPrintJobs.slice(maxCount);

    JobModule.LooseJobs([toLoose.map(a => a.Job), this._currentJobsListId]);
    JobModule.OccupyJobs([toDisplay.map(a => a.Job), this._currentJobsListId]);

    SourceModule.LooseSources([toLoose.filter(a => a.Source != null).map(a => a.Source!), this._currentJobsListId]);
    SourceModule.OccupySources([toDisplay.filter(a => a.Source != null).map(a => a.Source!), this._currentJobsListId]);

    if (toLoose.length > 0) {
      JobModule.CollectJobs();
      SourceModule.CollectSources();
    }

    return toDisplay;
  }

  //#region HEADERS & ITEMS
  private jobHeaderFile: HeaderItem = {
    caption: 'File',
    itemClass: ComponentHelper.GetWidthClass('grow-1'),
    isSortable: true,
  };
  private jobHeaderOwner: HeaderItem = {
    caption: 'Owner',
    itemClass: ComponentHelper.GetWidthClass('grow-1'),
    isSortable: true,
  };
  private jobHeaderDate: HeaderItem = {
    caption: 'Created',
    itemClass: ComponentHelper.GetWidthClass(165),
    isSortable: true,
    width: 165,
  };
  private jobHeaderOrder: HeaderItem = {
    caption: 'Order',
    itemClass: ComponentHelper.GetWidthClass(60),
    isSortable: true,
  };
  private get PrintJobHeaders(): HeaderItem[] {
    const result: HeaderItem[] = [];

    result.push(this.jobHeaderFile);
    result.push(this.jobHeaderOwner);
    result.push(this.jobHeaderDate);
    result.push(this.jobHeaderOrder);

    return result;
  }

  private PrintJobItems(value: UserJob): ItemData[] {
    const result: ItemData[] = [];

    let itemFile = new ItemData(
      'File',
      value.Job.LocalFilename != null
        ? value.Job.LocalFilename
        : value.Source == null
          ? '-'
          : value.Source.CodeFileName,
      'grow-1',
    );
    let itemPrinter = new ItemData(
      'Author',
      value.Author == null ? '—' : ComponentHelper.GetFullname(value.Author),
      'grow-1',
    );
    let itemStarted = new ItemData(
      'Started',
      value.Job.CreationDateTime == null
        ? '—'
        : // : ComponentHelper.GetReadableDateTime(value.Job.ActualStartTime, true),
          ComponentHelper.GetReadableDateTime(value.Job.CreationDateTime, true),
      165,
    );
    let itemProgress = new ItemData('Order', value.Job.Order == null ? 'Now' : value.Job.Order.toString(), 60);

    result.push(itemFile);
    result.push(itemPrinter);
    result.push(itemStarted);
    result.push(itemProgress);

    return result;
  }
  //#endregion
  //#endregion

  //#region ARCHIEVE JOBS
  private sortByJobArchieveHeader: HeaderItem | null = null;
  private jobArchieveSortMode: SortMode | null = null;
  private jobArchievePage: number = 0;
  private loadingJobArchieves: boolean = false;

  private get SortByJobArchieveHeader(): HeaderItem | null {
    return this.sortByJobArchieveHeader;
  }
  private set SortByJobArchieveHeader(value: HeaderItem | null) {
    this.sortByJobArchieveHeader = value;
  }
  private get JobArchieveSortMode(): SortMode | null {
    return this.jobArchieveSortMode;
  }
  private set JobArchieveSortMode(value: SortMode | null) {
    this.jobArchieveSortMode = value;
  }

  private get JobArchieves(): JobArchieve[] {
    return JobModule.JobArchieves.filter(a => a.PrinterId.toString() == this.selectedMachine?.printer.Id.toString());
  }

  private get FullJobArchieves(): FullJobArchieve[] {
    let fullJobArchieves: FullJobArchieve[] = [];

    for (let archieve of this.JobArchieves) {
      let printer = this.Printers.singleOrDefault(a => a.Id.toString() === archieve.PrinterId.toString());
      if (printer == null) continue;
      let source = this.Sources.singleOrDefault(a => GuidHelper.equalsWithNull(a.Id, archieve.SourceId));
      let user = UserModule.Users.singleOrDefault(a => a.Id.toString() == archieve.UserId?.toString());
      let fullJob: FullJobArchieve = {
        jobArchieve: archieve,
        printer: printer,
        source: source,
        owner: user,
      };
      fullJobArchieves.push(fullJob);
    }

    fullJobArchieves = this.SortFullJobArchieves(fullJobArchieves);

    const maxCount = (this.jobArchievePage + 1) * 25;

    const toDisplay = fullJobArchieves.splice(0, maxCount);
    const toLoose = fullJobArchieves.slice(maxCount);

    JobModule.LooseJobArchieves([toLoose.map(a => a.jobArchieve), this._archiveJobsListId]);
    JobModule.OccupyJobArchieves([toDisplay.map(a => a.jobArchieve), this._archiveJobsListId]);

    SourceModule.LooseSources([toLoose.filter(a => a.source != null).map(a => a.source!), this._archiveJobsListId]);
    SourceModule.OccupySources([toDisplay.filter(a => a.source != null).map(a => a.source!), this._archiveJobsListId]);

    if (toLoose.length > 0) {
      JobModule.CollectJobArchieves();
    }

    return toDisplay;
  }

  //#region HEADERS & ITEMS
  private jobArchHeaderFile: HeaderItem = {
    caption: 'File',
    itemClass: ComponentHelper.GetWidthClass('grow-1'),
    isSortable: true,
  };
  private jobArchHeaderPrinter: HeaderItem = {
    caption: 'Printer',
    itemClass: ComponentHelper.GetWidthClass('grow-1'),
    isSortable: true,
  };
  private jobArchHeaderFinished: HeaderItem = {
    caption: 'Finished',
    itemClass: ComponentHelper.GetWidthClass('grow-1'),
    isSortable: true,
  };
  private jobArchHeaderStatus: HeaderItem = {
    caption: 'Status',
    itemClass: ComponentHelper.GetWidthClass(80),
    isSortable: true,
    width: 80,
  };
  private get ArchieveJobHeaders(): HeaderItem[] {
    const result: HeaderItem[] = [];

    result.push(this.jobArchHeaderFile);
    result.push(this.jobArchHeaderPrinter);
    result.push(this.jobArchHeaderFinished);
    result.push(this.jobArchHeaderStatus);

    return result;
  }

  private ArchieveJobItems(value: FullJobArchieve): ItemData[] {
    const result: ItemData[] = [];

    let itemFile = new ItemData(
      'File',
      value.jobArchieve.LocalFilename != null
        ? value.jobArchieve.LocalFilename
        : value.source == null
          ? '—'
          : value.source.CodeFileName,
      'grow-1',
    );
    let itemPrinter = new ItemData('Printer', value.printer.Name, 'grow-1');
    let itemFinished = new ItemData(
      'Finished',
      value.jobArchieve.EndTime == null ? '—' : ComponentHelper.GetReadableDateTime(value.jobArchieve.EndTime, true),
      'grow-1',
    );
    let itemStatus = new ItemData(
      'Status',
      value.jobArchieve.EndStatus == null ? '—' : ComponentHelper.GetReadableEndStatus(value.jobArchieve.EndStatus),
      80,
    );

    result.push(itemFile);
    result.push(itemPrinter);
    result.push(itemFinished);
    result.push(itemStatus);

    return result;
  }
  //#endregion
  //#endregion

  //#region LOGIC
  //#region PRINTER CONTROLS
  private IsActiveControlType(controlType: PrinterPanelControlType) {
    return this.ActiveControls.firstOrDefault(a => a == controlType) != null;
  }

  private PatchFlowLayoutChildrenData() {
    const currData = GlobalDataModule.MachinesViewState.flowLayoutData;
    const printerControlsFlowLayoutData: FlowLayoutChildViewState[] = [];
    for (const child of this.printerControlsFlowLayout.layoutChildren) {
      printerControlsFlowLayoutData.push({
        id: child.id,
        cellRange: child.cellRange,
      });
    }

    for (const child of currData) {
      const found = printerControlsFlowLayoutData.firstOrDefault(a => a.id == child.id);

      if (found) continue;

      printerControlsFlowLayoutData.push(child);
    }

    GlobalDataModule.ChangeMachinesViewFlowLayoutChildrenData(printerControlsFlowLayoutData);
  }

  private ClosePrinterControlById(id: string) {
    if (id == this.ArchiveJobsFlowChildId) {
      this.PrinterControlTypeClicked(PrinterPanelControlType.ArchiveJobs);
    } else if (id == this.ErrorLogFlowChildId) {
      this.PrinterControlTypeClicked(PrinterPanelControlType.ErrorLog);
    } else if (id == this.TerminalFlowChildId) {
      this.PrinterControlTypeClicked(PrinterPanelControlType.Terminal);
    } else if (id == this.TelemetryFlowChildId) {
      this.PrinterControlTypeClicked(PrinterPanelControlType.Telemetry);
    } else if (id == this.CurrentJobsFlowChildId) {
      this.PrinterControlTypeClicked(PrinterPanelControlType.CurrentJobs);
    }
  }

  private PrinterControlTypeClicked(controlType: PrinterPanelControlType) {
    const found = this.ActiveControls.firstOrDefault(a => a == controlType);

    if (found == null) {
      this.activeControls.push(controlType);
    } else {
      this.activeControls.delete(found);
    }

    GlobalDataModule.ChangeMachinesViewActivatedPrinterControls(this.activeControls);

    this.PatchFlowLayoutChildrenData();
  }
  //#endregion

  //#region PRINTERS
  private async LoadPrinters() {
    this.isLoadingPrinters = true;

    await PrinterModule.CollectPrinters();
    await this.LoadPrinterModels();
    await this.LoadConnectionTypes();
    await PrinterModule.LoadPrintersForCompanyAll(this.CompanyId);

    this.isLoadingPrinters = false;
  }

  private async SubscripbeToCompanyPrinters() {
    AsyncBatchQueueSignalR.Queue({
      Batch: async () => {
        await JobModule.SubscribeToCompanyJobsGroup();
        await PrinterModule.SubscribeToCompanyPrintersGroup();
      },
    });
  }

  private async UnsubscribeFromCompanyPrinters() {
    AsyncBatchQueueSignalR.Queue({
      Batch: async () => {
        await JobModule.DeleteFromCompanyJobsGroup();
        await PrinterModule.DeleteFromCompanyPrintersGroup();
      },
    });
  }

  private async SubscribeToPrinterGroups(printer: Printer) {
    AsyncBatchQueueSignalR.Queue({
      Batch: async () => {
        await JobModule.SubscribeToPrinterJobsGroup(printer.Id);
        await PrinterModule.SubscribeToCompanyPrintersGroup();
      },
    });
  }

  private async UnsubscribeFromPrinterGroups(printer: Printer) {
    AsyncBatchQueueSignalR.Queue({
      Batch: async () => {
        await JobModule.DeleteFromPrinterJobsGroup(printer.Id);
        await PrinterModule.DeleteFromCompanyPrintersGroup();
      },
    });
  }

  private IsPrinterSelected(printer: FullPrinter): boolean {
    if (this.selectedMachine == null) return false;

    return this.selectedMachine.printer.Id.toString() == printer.printer.Id.toString();
  }

  private DeselectPrinter() {
    this.$router.push({
      name: Routes.MACHINES,
      query: {},
    });
  }

  private PrinterCardClicked(printer: FullPrinter) {
    if (this.selectedMachine?.printer.Id.equals(printer.printer.Id)) {
      return;
    }

    this.$router.push({
      name: Routes.MACHINES,
      query: { machineId: printer.printer.Id.toString() },
    });
  }

  private AddNewMachineClicked() {
    this.addingNewMachine = true;
  }

  private CloseAddNewMachine() {
    this.addingNewMachine = false;
  }

  private CloseMachineEditor() {
    this.isEditingPrinter = false;
  }

  private async UpdateConnectionClicked() {
    this.showMoreButtonPopover = false;

    if (this.selectedMachine == null) {
      return;
    }

    const code = await PrinterModule.UpdateConnection(this.selectedMachine.printer.Id);

    await UpdatedConnection(code, this.selectedMachine.printer);
  }

  private async ExportLogsClicked() {
    this.showMoreButtonPopover = false;

    if (this.selectedMachine == null) {
      return;
    }

    await ExportLogs(this.selectedMachine.printer);
  }

  private async ChangeLogConfigClicked() {
    this.showMoreButtonPopover = false;

    if (this.selectedMachine == null) {
      return;
    }

    await ChangeLogConfig(this.selectedMachine.printer);
  }

  private async DownloadLogConfigClicked() {
    this.showMoreButtonPopover = false;

    if (this.selectedMachine == null) {
      return;
    }

    await ConnectorModule.DownloadLogConfiguration(this.selectedMachine.printer.Id);

    toast.success('Log configuration download started', {
      position: POSITION.BOTTOM_RIGHT,
    });
  }

  private async DownloadLogsClicked() {
    this.showMoreButtonPopover = false;

    if (this.selectedMachine == null) {
      return;
    }

    await ConnectorModule.DownloadLogs(this.selectedMachine.printer.Id);

    toast.success('Logs download started', {
      position: POSITION.BOTTOM_RIGHT,
    });
  }

  private async DeletePrinterClicked() {
    this.showMoreButtonPopover = false;

    if (this.selectedMachine == null) {
      return;
    }

    const userConfirmation = await DeletePrinterSure(
      Result.Yes | Result.No,
      'Delete printer',
      `This action will delete printer ${this.selectedMachine.printer.Name}. Are you sure?`,
    );

    if (userConfirmation == Result.No || userConfirmation == Result.Cancel) {
      return;
    }

    this.isLoading = true;

    const result = await PrinterModule.DeletePrinter(this.selectedMachine.printer.Id);

    if (result != null) {
      toast.success(`Printer has been successfully deleted`, {
        position: POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error(`Could not delete printer`, {
        position: POSITION.BOTTOM_RIGHT,
      });
    }

    this.$router.replace({
      name: Routes.MACHINES,
      query: {},
    });

    this.isLoading = false;
  }

  private EditPrinterClicked() {
    this.isEditingPrinter = true;
  }

  private async ResetViewClicked() {
    await GlobalDataModule.ResetMachinesViewFlowLayoutState();
    const layoutData = GlobalDataModule.MachinesViewState.flowLayoutData;

    for (const child of this.printerControlsFlowLayout.layoutChildren) {
      const found = layoutData.firstOrDefault(a => a.id == child.id);

      if (found == null) continue;

      child.cellRange = found.cellRange;
    }

    this.printerControlsFlowLayout.DrawFlowLayoutChildren();
  }

  private ToggleLayoutEditing() {
    this.isEditingControlsLayout = !this.isEditingControlsLayout;
    this.showMoreButtonPopover = false;
  }

  //#endregion

  //#region JOB RELATED
  async QueueNewJobButtonClicked() {
    if (this.selectedMachine == null) {
      return;
    }

    await StartNewJob(this.selectedMachine.printer, false);
  }

  async OnJobSortBy() {
    await SourceModule.LooseSources([
      this.UserPrintJobs.filter(a => a.Source != null).map(a => a.Source!),
      this._archiveJobsListId,
    ]);
    await SourceModule.CollectSources();

    await JobModule.LooseJobs([this.UserPrintJobs.map(a => a.Job), this._currentJobsListId]);
    await JobModule.CollectJobs();

    this.jobPage = 0;
    await this.LoadMoreJobs();
  }

  private GetJobSortBy(headerItem: HeaderItem | null): JobSortBy | null {
    if (headerItem === this.jobHeaderFile) return JobSortBy.BY_FILE;
    else if (headerItem === this.jobHeaderOwner) return JobSortBy.BY_AUTHOR;
    else if (headerItem === this.jobHeaderDate) return JobSortBy.BY_CREATION_DATE;
    else if (headerItem === this.jobHeaderOrder) return JobSortBy.BY_ORDER;

    return null;
  }

  async LoadMoreJobs() {
    if (this.selectedMachine == null) {
      return;
    }

    this.isLoadingJobs = true;

    let jobsAdditions = new JobQueryAdditions();
    jobsAdditions.pageSize = 25;
    jobsAdditions.page = this.jobPage;
    jobsAdditions.sortBy = this.GetJobSortBy(this.SortByJobHeader);
    jobsAdditions.sortMode = this.JobSortMode;

    await JobModule.LoadAndAddJobsForPrinter([this.selectedMachine.printer.Id, jobsAdditions]);

    const userJobs = JobModule.Jobs.filter(a => a.PrinterId?.toString() == this.selectedMachine?.printer.Id.toString());

    if (userJobs.any()) {
      let printerIds = GuidHelper.distinct(userJobs.map(a => a.PrinterId));
      let sourceIds = jobModule.GetSourceIdsByJobs(userJobs);
      let userIds = jobModule.GetUserIdsByJobs(userJobs);

      await Promise.all([
        PrinterModule.LoadPrinters(printerIds),
        SourceModule.LoadSources(sourceIds),
        UserModule.LoadUsers(userIds),
      ]);
    }

    this.isLoadingJobs = false;
  }

  async OnJobsScrolled(e: any) {
    const currScroll = e.target.scrollTop;
    const endScroll = e.target.scrollHeight - e.target.clientHeight;

    if (currScroll === endScroll && currScroll > 0 && !this.loadingJob) {
      this.loadingJob = true;
      ++this.jobPage;
      await this.LoadMoreJobs();
      delay(() => {
        this.loadingJob = false;
      }, 500);
    }
  }

  SortJobs(jobs: UserJob[]): UserJob[] {
    let sortBy = this.SortByJobHeader == null ? undefined : this.GetJobSortBy(this.SortByJobHeader);

    if (
      (this.JobSortMode == null && sortBy !== undefined) ||
      (this.JobSortMode != null && sortBy === undefined) ||
      jobs.length == 0
    )
      return jobs;

    if (sortBy === JobSortBy.BY_FILE) {
      if (this.JobSortMode === SortMode.ASC) return jobs.sort(SortUserJobByIdDESC).sort(SortUserJobByFileASC);
      if (this.JobSortMode === SortMode.DESC) return jobs.sort(SortUserJobByIdDESC).sort(SortUserJobByFileDESC);
    } else if (sortBy === JobSortBy.BY_PRINTER) {
      if (this.JobSortMode === SortMode.ASC) return jobs.sort(SortUserJobByIdDESC).sort(SortUserJobByPrinterASC);
      if (this.JobSortMode === SortMode.DESC) return jobs.sort(SortUserJobByIdDESC).sort(SortUserJobByPrinterDESC);
    } else if (sortBy === JobSortBy.BY_DATE) {
      if (this.JobSortMode === SortMode.ASC) return jobs.sort(SortUserJobByIdDESC).sort(SortUserJobByDateStartASC);
      if (this.JobSortMode === SortMode.DESC) return jobs.sort(SortUserJobByIdDESC).sort(SortUserJobByDateStartDESC);
    } else if (sortBy === JobSortBy.BY_CREATION_DATE) {
      if (this.JobSortMode === SortMode.ASC) return jobs.sort(SortUserJobByIdDESC).sort(SortUserJobByDateCreatedASC);
      if (this.JobSortMode === SortMode.DESC) return jobs.sort(SortUserJobByIdDESC).sort(SortUserJobByDateCreatedDESC);
    } else if (sortBy === JobSortBy.BY_PROGRESS) {
      if (this.JobSortMode === SortMode.ASC) return jobs.sort(SortUserJobByIdDESC).sort(SortUserJobByProgressASC);
      if (this.JobSortMode === SortMode.DESC) return jobs.sort(SortUserJobByIdDESC).sort(SortUserJobByProgressDESC);
    } else if (sortBy == JobSortBy.BY_ORDER) {
      if (this.JobSortMode === SortMode.ASC) return jobs.sort(SortUserJobByIdDESC).sort(SortUserJobByOrderASC);
      if (this.JobSortMode === SortMode.DESC) return jobs.sort(SortUserJobByIdDESC).sort(SortUserJobByOrderDESC);
    } else if (sortBy == JobSortBy.BY_AUTHOR) {
      if (this.JobSortMode === SortMode.ASC) return jobs.sort(SortUserJobByIdDESC).sort(SortUserJobByAuthorASC);
      if (this.JobSortMode === SortMode.DESC) return jobs.sort(SortUserJobByIdDESC).sort(SortUserJobByAuthorDESC);
    }

    return jobs;
  }
  //#endregion

  //#region JOB ARCHIEVE RELATED
  async OnJobArchievesSortBy() {
    await SourceModule.LooseSources([
      this.FullJobArchieves.filter(a => a.source != null).map(a => a.source!),
      this._archiveJobsListId,
    ]);
    await SourceModule.CollectSources();

    await JobModule.LooseJobArchieves([this.FullJobArchieves.map(a => a.jobArchieve), this._archiveJobsListId]);
    await JobModule.CollectJobArchieves();

    this.jobArchievePage = 0;
    await this.LoadMoreJobArchieves();
  }

  private GetJobArchieveSortBy(headerItem: HeaderItem | null): ArchieveJobSortBy | null {
    if (headerItem === this.jobArchHeaderFile) return ArchieveJobSortBy.BY_FILE;
    else if (headerItem === this.jobArchHeaderPrinter) return ArchieveJobSortBy.BY_PRINTER;
    else if (headerItem === this.jobArchHeaderFinished) return ArchieveJobSortBy.BY_DATE_END;
    else if (headerItem === this.jobArchHeaderStatus) return ArchieveJobSortBy.BY_STATUS;

    return null;
  }

  async LoadMoreJobArchieves() {
    if (this.selectedMachine == null) return;

    let archJobAdditions = new ArchieveJobQueryAdditions();
    archJobAdditions.pageSize = 25;
    archJobAdditions.page = this.jobArchievePage;
    archJobAdditions.sortBy = this.GetJobArchieveSortBy(this.SortByJobArchieveHeader);
    archJobAdditions.sortMode = this.JobArchieveSortMode;

    this.isLoadingArchiveJobs = true;

    await JobModule.LoadAndAddJobArchievesForPrinter([this.selectedMachine.printer.Id, archJobAdditions]);

    const archives = JobModule.JobArchieves.filter(
      a => a.PrinterId?.toString() == this.selectedMachine?.printer.Id.toString(),
    );

    if (archives.any()) {
      let printerIds = GuidHelper.distinct(archives.map(a => a.PrinterId));
      let sourceIds = jobModule.GetSourceIdsByArchieves(archives);
      let userIds = jobModule.GetUserIdsByArchieves(archives);

      await Promise.all([
        PrinterModule.LoadPrinters(printerIds),
        SourceModule.LoadSources(sourceIds),
        UserModule.LoadUsers(userIds),
      ]);
    }

    this.isLoadingArchiveJobs = false;
  }

  async OnJobArchievesScrolled(e: any) {
    const currScroll = e.target.scrollTop;
    const endScroll = e.target.scrollHeight - e.target.clientHeight;

    if (currScroll === endScroll && currScroll > 0 && !this.loadingJobArchieves) {
      this.loadingJobArchieves = true;
      ++this.jobArchievePage;
      await this.LoadMoreJobArchieves();
      delay(() => {
        this.loadingJobArchieves = false;
      }, 500);
    }
  }

  SortFullJobArchieves(jobArchs: FullJobArchieve[]): FullJobArchieve[] {
    let sortBy =
      this.SortByJobArchieveHeader == null ? undefined : this.GetJobArchieveSortBy(this.SortByJobArchieveHeader);

    if (
      (this.JobArchieveSortMode == null && sortBy !== undefined) ||
      (this.JobArchieveSortMode != null && sortBy === undefined) ||
      jobArchs.length == 0
    )
      return jobArchs;

    if (sortBy === ArchieveJobSortBy.BY_FILE) {
      if (this.JobArchieveSortMode === SortMode.ASC)
        return jobArchs.sort(SortFullJobArchieveByIdDESC).sort(SortFullJobArchieveByFileASC);
      if (this.JobArchieveSortMode === SortMode.DESC)
        return jobArchs.sort(SortFullJobArchieveByIdDESC).sort(SortFullJobArchieveByFileDESC);
    } else if (sortBy === ArchieveJobSortBy.BY_PRINTER) {
      if (this.JobArchieveSortMode === SortMode.ASC)
        return jobArchs.sort(SortFullJobArchieveByIdDESC).sort(SortFullJobArchieveByPrinterASC);
      if (this.JobArchieveSortMode === SortMode.DESC)
        return jobArchs.sort(SortFullJobArchieveByIdDESC).sort(SortFullJobArchieveByPrinterDESC);
    } else if (sortBy === ArchieveJobSortBy.BY_DATE_END) {
      if (this.JobArchieveSortMode === SortMode.ASC)
        return jobArchs.sort(SortFullJobArchieveByIdDESC).sort(SortFullJobArchieveDateEndASC);
      if (this.JobArchieveSortMode === SortMode.DESC)
        return jobArchs.sort(SortFullJobArchieveByIdDESC).sort(SortFullJobArchieveDateEndDESC);
    } else if (sortBy === ArchieveJobSortBy.BY_STATUS) {
      if (this.JobArchieveSortMode === SortMode.ASC)
        return jobArchs.sort(SortFullJobArchieveByIdDESC).sort(SortFullJobArchieveByStatusASC);
      if (this.JobArchieveSortMode === SortMode.DESC)
        return jobArchs.sort(SortFullJobArchieveByIdDESC).sort(SortFullJobArchieveByStatusDESC);
    }

    return jobArchs;
  }
  //#endregion

  private async LoadPrinterModels() {
    await PrinterModule.LoadAllPrinterModels();
    await ConnectorModule.LoadAllPrinterModels();
  }

  private async LoadConnectionTypes() {
    await PrinterModule.ReadAllConnectionTypes();
  }

  FlowLayoutChildCellRangeChanged() {
    // const oldStart = e.oldCellRange.startPosition;
    // const oldEnd = e.oldCellRange.endPosition;

    // const newStart = e.newCellRange.startPosition;
    // const newEnd = e.newCellRange.endPosition;

    // console.log(
    //   `From (${oldStart.x};${oldStart.y}), (${oldEnd.x};${oldEnd.y}) to (${newStart.x};${newStart.y}), (${newEnd.x};${newEnd.y})`
    // );

    this.PatchFlowLayoutChildrenData();
  }
  //#endregion

  //#region HOOKS
  async beforeCreate() {
    this._currentJobsListId = Guid.create();
    this._archiveJobsListId = Guid.create();
    this._machinesId = Guid.create();
  }

  async mounted() {
    await this.SubscripbeToCompanyPrinters();
    await this.LoadPrinters();

    this.activeControls = GlobalDataModule.MachinesViewState.activatedPrinterControls;

    this.JobSortMode = SortMode.ASC;
    this.SortByJobHeader = this.jobHeaderOrder;

    this.JobArchieveSortMode = SortMode.DESC;
    this.SortByJobArchieveHeader = this.jobArchHeaderFinished;

    const { machineId, action } = this.$route.query;

    const printer = PrinterModule.Printers[0];
    if (isPROM && printer) {
      this.setSelectedMachine(printer.Id.toString());
      document.title = printer.Name;
      return;
    }

    if (GlobalDataModule.MachinesViewState.selectedMachineId != null && !machineId && !action) {
      this.$router.push({
        name: Routes.MACHINES,
        query: {
          machineId: GlobalDataModule.MachinesViewState.selectedMachineId,
        },
      });
    } else if (machineId) {
      this.setSelectedMachine(machineId.toString());
    }
  }

  async beforeDestroy() {
    await this.UnsubscribeFromCompanyPrinters();

    await JobModule.LooseJobs([this.Jobs, this._currentJobsListId]);
    await JobModule.CollectJobs();

    await JobModule.LooseJobArchieves([this.JobArchieves, this._archiveJobsListId]);
    await JobModule.CollectJobArchieves();

    await PrinterModule.LoosePrintersNew([this.Printers, this._machinesId]);
    await PrinterModule.CollectPrinters();

    await SourceModule.LooseSources([this.Sources, this._currentJobsListId]);
    await SourceModule.LooseSources([this.Sources, this._archiveJobsListId]);
    await SourceModule.CollectSources();
  }
  //#endregion

  //#region EVENTS
  @Emit('openJob')
  private OpenJob(job: UserJob) {
    return job.Id;
  }

  @Emit('openArchive')
  private OpenArchieveJob(archieveJob: FullJobArchieve) {
    return archieveJob.jobArchieve.Id;
  }
  //#endregion

  //#region TRANSLATIONS
  private TranslateControlType(controlType: PrinterPanelControlType) {
    return ComponentHelper.GetReadableControlType(controlType);
  }

  private get AddNewMachineCaption() {
    return en.addNewMachine.growFirst();
  }

  private get isPROM() {
    return isPROM;
  }
}
</script>

<style lang="scss" scoped>
.machines-container {
  flex-grow: 1;
  height: 100%;
  position: relative;
}

.spinner-container {
  min-height: 40px;
  margin-top: 20px;
  position: relative;
  opacity: 1;

  &.hidden {
    opacity: 0;
    min-height: 0px;
    margin-top: 0px;
  }

  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);
}

.machines {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: auto;

  .left-panel {
    width: 15%;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .machine-list {
      flex: 1;
      position: relative;
      overflow: overlay !important;
    }

    .add-new-machine {
      padding: 8px 8px;
      text-transform: uppercase;
    }
  }

  .vertical-resizer {
    cursor: col-resize;
    width: 4px;
    margin: 0 3px;
  }

  .right-panel {
    width: 85%;
    display: flex;
    flex-direction: column;
    min-width: 400px;
    overflow: auto;
    position: relative;
  }

  .main-panel {
    transition: opacity 0.3s cubic-bezier(0.2, 0, 0, 1);
  }

  .printer-header {
    display: flex;
    white-space: nowrap;

    .printer-name {
      color: #e1e1e1;
      font-size: 20px;
      font-weight: 400;
    }
  }

  .printer-panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    .printer-model {
      color: #b0b0b0;
      font-size: 13px;
      text-align: left;
    }

    .controls {
      flex: 1;
    }
  }

  .printer-panel-top {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
  }

  .printer-id {
    color: #cecece;
    font-size: 13px;
  }

  .printer-control-switches {
    display: flex;
    justify-content: center;
    white-space: nowrap;

    .printer-control-switch {
      margin: 0 0.5rem;
      border-radius: 18px;

      transition: all 0.2s ease-out;
      padding: 2px 12px;

      &.active {
        outline: 1px solid #9b9b9b;
      }

      &:not(.active) {
        outline: 1px solid #313131;
        opacity: 0.5;
      }
    }
  }
}

.add-new-machine-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  min-width: 400px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);

  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);

  &.hidden {
    transform: translateX(-125%);

    &.offset-bottom {
      transform: translateX(-125%) translateY(-130px);
    }
  }

  &.offset-bottom {
    transform: translateY(-130px);
  }
}

.machine-editor-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  min-width: 400px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);

  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);

  &.hidden {
    transform: translateX(-125%);
  }
}
</style>

<style lang="scss">
.printer-more-button-content {
  display: flex;
  flex-direction: column;

  .menu-item {
    text-align: left;

    padding: 4px 18px;

    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }
  }
}
</style>
