import Vue from 'vue';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import * as ips from '@/util/api/ips';
import * as routes from '@/util/api/routes';
import * as hubActions from '@/util/api/hubActions';
import { Guid } from 'guid-typescript';
import { RequestStatus } from '@/models/enums/RequestStatus';
import { UtilModule } from './utilModule';
import store from '@/store';
import { CompanyUser, LoginSession, Role, User } from '@/models/Entities';
import '@/models/responses/ResponseIdentityExtensions';
import {
  ResponseIdentityHelper,
  ResponseUser,
  ResponseUserRole,
  ResponseUsers,
} from '@/models/responses/ResponseIdentity';
import {
  RequestAddUserRole,
  RequestCreateUser,
  RequestDeleteUser,
  RequestDeleteUserRole,
  RequestReadCompanyAdmins,
  RequestReadMoreUsers,
  RequestReadUserById,
  RequestReadUserByIds,
  RequestUpdateEmail,
  RequestUpdateUserInfo,
  RequestUpdateUserPassword,
  RequestUpdateUserPasswordInUser,
  RequestChangeBillingAccountOwner,
  RequestUpdateMyEmail,
  UserSortBy,
  RequestReadUsersPaged,
  RequestReadCompanyAccountOwner,
} from '@/models/requests/RequestIdentity';
import { RoleModule } from './roleModule';
import { LoginModule } from './loginModule';
import { HubConnectionModule } from './hubConnectionModule';
import { HubConnection } from '@microsoft/signalr';
import { New_GC } from '../util/GC';
import { LoginSessionModule } from './loginSessionModule';
import { JobModule } from './jobModule';
import { GuidHelper } from '@/util/GuidHelper';
import { ErrorModule } from '@/store/modules/errorsModule';
import { mockUser } from '@/store/mockData';
import { SortMode } from '@/util/ComponentHelper';

import {
  SortCompanyUserByEmailDESC,
  SortCompanyUserByEmailASC,
  SortCompanyUserByPrintsDESC,
  SortCompanyUserByPrintsASC,
  SortCompanyUserByLastSeenDESC,
  SortCompanyUserByLastSeenASC,
  SortCompanyUserByFullNameDESC,
  SortCompanyUserByFullNameASC,
  SortCompanyUserByIdDESC,
} from '@/models/util/UserSortings';
import { isPROM } from '@/util/env';

export interface LoadUsersPageData {
  companyId: Guid;
  sortBy: UserSortBy | null;
  sortMode: SortMode | null;
  page: number;
  includeRoles: Role[];
  excludeRoles: Role[];
}

export const UserPageSize = 25;

@Module({ dynamic: true, name: 'users', store: store, namespaced: true })
export default class userModule extends VuexModule {
  private users: User[] = [];
  private _newGC = new New_GC();

  get Users() {
    return this.users;
  }

  @Mutation
  ClearUsers() {
    this.users = [];
  }

  @Mutation
  AddUserToModule(value: User) {
    if (this.users.find(a => a.Id.equals(value.Id))) return;
    this.users.push(value);
    this._newGC.Add([value.Id]);
  }
  @Mutation
  DeleteUserFromModule(value: User) {
    this._newGC.Remove([value.Id]);
    this.users.delete(value);
    JobModule.DeleteUserJobCount(value);
  }
  @Mutation
  UpdateUserEmail([user, email]: [User, string]) {
    user.Email = email;
  }
  @Mutation
  UpdateUserFirstName([user, firstName]: [User, string]) {
    user.FirstName = firstName;
  }
  @Mutation
  UpdateUserSecondName([user, secondName]: [User, string]) {
    user.SecondName = secondName;
  }
  @Mutation
  UpdateUserPhone([user, phone]: [User, string]) {
    user.Phone = phone;
  }
  @Mutation
  UpdateAddRole([user, role]: [User, Role]) {
    user.Roles.push(role);
  }
  @Mutation
  UpdateDeleteRole([user, role]: [User, Role]) {
    user.Roles.delete(role);
  }
  @Action({ rawError: true })
  CollectUsers() {
    let removed = this._newGC.Collect();

    // Never delete current user. It's global
    if (LoginModule.Me) {
      removed = removed.filter(a => !a.equals(LoginModule.Me!.Id));
    }

    for (const user of this.users.filter(a => GuidHelper.includes(removed, a.Id))) {
      this.DeleteUserFromModule(user);
    }

    // console.log("CURRENT USERS COUNT", this.users.length);
  }

  @Action({ rawError: true })
  OccupyUsers([userIds, componentId]: [Guid[], Guid]) {
    // console.log("Occupying users", userIds);

    this._newGC.Occupy(userIds, componentId);
  }

  @Action({ rawError: true })
  LooseUsersNew([userIds, componentId]: [Guid[], Guid]) {
    this._newGC.Loose(userIds, componentId);
  }

  @Action({ rawError: true })
  async LoadUser(id: Guid): Promise<User | null> {
    const inLst = this.Users.find(a => a.Id.equals(id));
    if (inLst) return inLst;
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.READ_BY_ID_DEEP,
    );
    const request = new RequestReadUserById();
    request.id = id.toString();
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseIdentityHelper.createRealUser(axiosResponse.data.response as ResponseUser);
      const user = response.Map(RoleModule.Roles);
      this.AddUserToModule(user);
      const loginSessions = response.loginSessions.map(a => a.Map());
      for (const loginSession of loginSessions) {
        LoginSessionModule.AddLoginSession(loginSession);
      }
      return user;
    } else ErrorModule.ShowError(message);
    return null;
  }
  @Action({ rawError: true })
  UsersSync() {
    // this._gc.Occupy(users.map((a) => a.Id), componentId);
  }
  @Action({ rawError: true })
  async LooseUsers() {
    // let toDeleteUserIds = this._gc.Loose(userIds, componentId);
    // let usersToDelete = this.users.filter(a => GuidHelper.includes(toDeleteUserIds, a.Id));
    // // Never delete current user. It's global
    // if (LoginModule.Me) {
    //   usersToDelete = usersToDelete.filter(a => !a.Id.equals(LoginModule.Me!.Id));
    // }
    // for (let user of usersToDelete) {
    //   this.DeleteUserFromModule(user);
    // }
  }
  @Action({ rawError: true })
  async ChangeEmail([userId, newEmail]: [Guid, string]): Promise<[RequestStatus, string]> {
    const user = this.Users.single(a => a.Id.equals(userId));
    let uri: string;
    let request: RequestUpdateMyEmail | RequestUpdateEmail;
    if (userId.equals(LoginModule.UserId)) {
      request = new RequestUpdateMyEmail();
      uri = UtilModule.SHW.GetUri(
        ips.PROTOCOL(),
        ips.IP(),
        ips.PORT(),
        routes.UriServices.IDENTITY,
        routes.UriController.USER,
        routes.UriUser.UPDATE_MY_EMAIL,
      );
    } else {
      uri = UtilModule.SHW.GetUri(
        ips.PROTOCOL(),
        ips.IP(),
        ips.PORT(),
        routes.UriServices.IDENTITY,
        routes.UriController.USER,
        routes.UriUser.UPDATE_EMAIL,
      );
      const requestUpdate = new RequestUpdateEmail();
      requestUpdate.userId = userId.toString();
      request = requestUpdate;
    }
    request.newEmail = newEmail;
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      this.UpdateUserEmail([user, newEmail]);
    }
    return [result, message];
  }
  @Action({ rawError: true })
  async ChangeInfo([userId, firstName, secondName, phone]: [Guid, string, string, string]): Promise<
    [RequestStatus, string]
  > {
    const user = this.Users.single(a => a.Id.equals(userId));
    const request = new RequestUpdateUserInfo();
    request.firstName = firstName;
    request.secondName = secondName;
    request.phone = phone;
    request.userId = userId.toString();
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.UPDATE_INFO,
    );
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseIdentityHelper.createRealUser(axiosResponse.data.response as ResponseUser);
      if (user.FirstName !== response.firstName) this.UpdateUserFirstName([user, response.firstName]);
      if (user.SecondName !== response.secondName) this.UpdateUserSecondName([user, response.secondName]);
      if (user.Phone !== response.phone) this.UpdateUserPhone([user, response.phone]);
    }
    return [result, message];
  }
  @Action({ rawError: true })
  async ChangeMyPassword([newPassword, currentPassword]: [string, string]): Promise<[RequestStatus, string]> {
    const request = new RequestUpdateUserPasswordInUser();
    request.newPassword = newPassword;
    request.currentPassword = currentPassword;
    request.loginSessionId = LoginModule.LoginSessionId.toString();
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.UPDATE_MY_PASSWORD,
    );
    const { result, message } = await UtilModule.SHW.ProcessPost(uri, request);
    return [result, message];
  }

  @Action({ rawError: true })
  async ChangeUserPassword([userId, newPassword]: [Guid, string]): Promise<[RequestStatus, string]> {
    const requestData = new RequestUpdateUserPasswordInUser();
    requestData.newPassword = newPassword;
    const request = new RequestUpdateUserPassword();
    request.data = requestData;
    request.userId = userId.toString();
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.UPDATE_PASSWORD,
    );
    const { result, message } = await UtilModule.SHW.ProcessPost(uri, request);
    return [result, message];
  }

  @Action({ rawError: true })
  async AddRole([user, role]: [User, Role]): Promise<[RequestStatus, string]> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.ADD_ROLE,
    );
    const request = new RequestAddUserRole();
    request.userId = user.Id.toString();
    request.roleId = role.Id.toString();
    const { result, message } = await UtilModule.SHW.ProcessPost(uri, request);
    return [result, message];
  }

  @Action({ rawError: true })
  async DeleteRole([user, role]: [User, Role]): Promise<[RequestStatus, string]> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.DELETE_ROLE,
    );
    const request = new RequestDeleteUserRole();
    request.userId = user.Id.toString();
    request.roleId = role.Id.toString();
    const { result, message } = await UtilModule.SHW.ProcessPost(uri, request);
    return [result, message];
  }
  @Action({ rawError: true })
  async ChangeBillingAccountOwner([from, to]: [User, User]) {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.CHANGE_BILLING_ACCOUNT_OWNER,
    );
    const request = new RequestChangeBillingAccountOwner();
    request.fromUserId = from.Id.toString();
    request.toUserId = to.Id.toString();

    const { result } = await UtilModule.SHW.ProcessPost(uri, request);

    return result === RequestStatus.OK;
  }
  @Action({ rawError: true })
  async DeleteUser(user: User): Promise<[RequestStatus, string]> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.DELETE_USER,
    );
    const request = new RequestDeleteUser();
    request.id = user.Id.toString();
    const { result, message } = await UtilModule.SHW.ProcessPost(uri, request);
    return [result, message];
  }
  @Action({ rawError: true })
  async RegisterUser([email, password, firstName, secondName]: [string, string, string?, string?]): Promise<
    [RequestStatus, string]
  > {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.SIGNUP,
    );
    const request = new RequestCreateUser();
    request.email = email;
    if (firstName !== undefined) request.firstName = firstName;
    if (secondName !== undefined) request.secondName = secondName;
    request.password = password;
    const { result, message } = await UtilModule.SHW.ProcessPost(uri, request);
    return [result, message];
  }
  @Action({ rawError: true })
  async LoadUsersForCompany([from, count]: [number?, number?]): Promise<User[] | null> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.READ_MORE_USERS_IN_MY_COMPANY,
    );
    const request = new RequestReadMoreUsers();
    if (from !== undefined) request.from = from;
    if (count !== undefined) request.count = count;
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseIdentityHelper.createRealUsers(axiosResponse.data.response as ResponseUsers);
      const responseIds = response.users.map(a => a.id);
      const inLst = this.Users.filter(a => GuidHelper.includes(responseIds, a.Id));
      const inLstIds = inLst.map(a => a.Id);
      const toAdd = response.users.filter(a => !GuidHelper.includes(inLstIds, a.id));
      for (const item of toAdd) {
        this.AddUserToModule(item.Map(RoleModule.Roles));
        for (const loginSession of item.loginSessions) {
          LoginSessionModule.AddLoginSession(loginSession.Map());
        }
      }
      return this.Users.filter(a => GuidHelper.includes(responseIds, a.Id));
    } else {
      ErrorModule.ShowError(message);
      return [];
    }
  }
  @Action({ rawError: true })
  async LoadUsersForCompanyPaged(data: LoadUsersPageData) {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.READ_BY_COMPANY_ID_PAGED,
    );
    const request = new RequestReadUsersPaged();
    request.companyId = data.companyId.toString();
    request.page = data.page;
    request.pageSize = UserPageSize;
    request.sortBy = data.sortBy;
    request.sortMode = data.sortMode;
    request.includeRoles = data.includeRoles.map(a => a.Id.toString());
    request.excludeRoles = data.excludeRoles.map(a => a.Id.toString());

    this._newGC.Lock();

    // Todo: printing sorting not working yet
    // const usersByPrintCount: ResponseUser[] = [];
    // if (data.sortBy === UserSortBy.BY_PRINTS) {
    //   // In order to sort by prints we need to communicate with Monolith, because it
    //   // has all the job information
    //   let uriMonolith = UtilModule.SHW.GetUri(ips.PROTOCOL(), ips.IP(), ips.PORT(), routes.UriServices.MONOLITH, routes.UriController.JOB, routes.UriJob.READ_USERS_BY_JOB_COUNT_PAGED);

    //   let request = new RequestReadUsersByJobCountPaged();
    //   request.companyId = data.companyId.toString();
    //   request.page = data.page;
    //   request.pageSize = UserPageSize;
    //   request.sortMode = data.sortMode!;

    //   let { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uriMonolith, request);

    //   if (result === RequestStatus.OK && axiosResponse != null) {
    //   }
    // }

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseIdentityHelper.createRealUsers(axiosResponse.data.response as ResponseUsers);
      for (const user of response.users) {
        this.AddUserToModule(user.Map(RoleModule.Roles));
        for (const loginSession of user.loginSessions) {
          LoginSessionModule.AddLoginSession(loginSession.Map());
        }
      }

      Vue.nextTick(() => {
        this._newGC.Unlock();
      });

      return response.users.length;
    }

    Vue.nextTick(() => {
      this._newGC.Unlock();
    });

    return 0;
  }
  @Action({ rawError: true })
  async LoadUsers(ids: Guid[]): Promise<User[]> {
    const needToLoad = GuidHelper.except(
      ids,
      this.Users.map(a => a.Id),
    );
    if (needToLoad.any()) {
      const userAndLSs = await this.JustLoadUsers(needToLoad);
      for (const [user, lss] of userAndLSs) {
        this.AddUserToModule(user);
        for (const loginSession of lss) {
          LoginSessionModule.AddLoginSession(loginSession);
        }
      }
    }
    return this.Users.filter(a => GuidHelper.includes(ids, a.Id));
  }
  @Action({ rawError: true })
  async JustLoadCompanyAdmins(companyId: Guid): Promise<User[]> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.READ_ADMINS,
    );
    const request = new RequestReadCompanyAdmins();
    request.companyId = companyId.toString();
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseIdentityHelper.createRealUsers(axiosResponse.data.response as ResponseUsers);
      return response.users.map(a => a.Map(RoleModule.Roles));
    } else ErrorModule.ShowError(message);
    return [];
  }
  @Action({ rawError: true })
  async JustLoadUsers(ids: Guid[]): Promise<[User, LoginSession[]][]> {
    if (isPROM) {
      const response = ResponseIdentityHelper.createRealUsers({ users: [mockUser] } as unknown as ResponseUsers);
      const userAndLSs: [User, LoginSession[]][] = [];
      for (const item of response.users) {
        userAndLSs.push([item.Map(RoleModule.Roles), item.loginSessions.map(a => a.Map())]);
      }

      return userAndLSs;
    }

    if (ids.empty()) return [];
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.READ_BY_IDS_DEEP,
    );
    const request = new RequestReadUserByIds();
    request.ids = ids.map(a => a.toString());
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseIdentityHelper.createRealUsers(axiosResponse.data.response as ResponseUsers);
      const userAndLSs: [User, LoginSession[]][] = [];
      for (const item of response.users) {
        userAndLSs.push([item.Map(RoleModule.Roles), item.loginSessions.map(a => a.Map())]);
      }
      return userAndLSs;
    } else ErrorModule.ShowError(message);
    return [];
  }
  @Action({ rawError: true })
  async LoadCompanyAccountOwner(companyId: Guid) {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.IDENTITY,
      routes.UriController.USER,
      routes.UriUser.READ_ACCOUNT_OWNER,
    );
    const request = new RequestReadCompanyAccountOwner();
    request.companyId = companyId.toString();
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseIdentityHelper.createRealUser(axiosResponse.data.response as ResponseUser);
      return response.Map(RoleModule.Roles);
    } else ErrorModule.ShowError(message);
    return null;
  }
  @Action({ rawError: true })
  UserSubscribe(hubConnection: HubConnection) {
    hubConnection.on(hubActions.RECEIVE_UPDATE_USER_EMAIL, (response: ResponseUser) => {
      const realUser = ResponseIdentityHelper.createRealUser(response);
      const user = this.Users.find(a => a.Id.equals(realUser.id));
      if (!user) return;
      this.UpdateUserEmail([user, realUser.email]);
    });
    hubConnection.on(hubActions.RECEIVE_UPDATE_INFO_USER, async (response: ResponseUser) => {
      const realUser = ResponseIdentityHelper.createRealUser(response);
      const user = this.Users.find(a => a.Id.equals(realUser.id));
      if (!user) return;
      if (user.FirstName !== realUser.firstName) this.UpdateUserFirstName([user, realUser.firstName]);
      if (user.SecondName !== realUser.secondName) this.UpdateUserSecondName([user, realUser.secondName]);
      if (user.Phone !== realUser.phone) this.UpdateUserPhone([user, realUser.phone]);
    });
    hubConnection.on(hubActions.RECEIVE_ADD_USER_ROLE, async (response: ResponseUserRole) => {
      const realUserRole = ResponseIdentityHelper.createRealUserRole(response);
      const user = this.Users.find(a => a.Id.equals(realUserRole.userId));
      if (!user) return;
      let role = user.Roles.find(a => a.Id.equals(realUserRole.roleId));
      if (role) return;
      role = RoleModule.Roles.single(a => a.Id.equals(realUserRole.roleId));
      this.UpdateAddRole([user, role]);
      if (user.Id.equals(LoginModule.UserId)) {
        LoginModule.addMyRole(role);
        await LoginModule.updateToken();
      }
    });
    hubConnection.on(hubActions.RECEIVE_DELETE_USER_ROLE, async (response: ResponseUserRole) => {
      const realUserRole = ResponseIdentityHelper.createRealUserRole(response);
      const user = this.Users.find(a => a.Id.equals(realUserRole.userId));
      if (!user) return;
      const role = user.Roles.singleOrDefault(a => a.Id.equals(realUserRole.roleId));
      if (!role) return;
      this.UpdateDeleteRole([user, role]);
      if (user.Id.equals(LoginModule.UserId)) {
        LoginModule.deleteMyRole(role);
        await LoginModule.updateToken();
      }
    });
    hubConnection.on(hubActions.RECEIVE_ADD_USER, (response: ResponseUser) => {
      const realUser = ResponseIdentityHelper.createRealUser(response);
      const userInLst = this.Users.find(a => a.Id.equals(realUser.id));
      if (userInLst) return;
      const user = realUser.Map(RoleModule.Roles);
      this.AddUserToModule(user);
    });
    hubConnection.on(hubActions.RECEIVE_DELETE_USER, async (response: ResponseUser) => {
      const realUser = ResponseIdentityHelper.createRealUser(response);
      const userInLst = this.Users.find(a => a.Id.equals(realUser.id));
      if (!userInLst) return;
      this.DeleteUserFromModule(userInLst);
      if (LoginModule.UserId.equals(realUser.id)) await LoginModule.resetAllData();
    });
  }
  @Action({ rawError: true })
  async SubscribeToCompanyUsersGroup() {
    if (!HubConnectionModule.IdentityConnection) return;
    await HubConnectionModule.IdentityConnection.send(hubActions.ADD_TO_COMPANY_USERS_GROUP);
  }
  @Action({ rawError: true })
  async DeleteFromCompanyUsersGroup() {
    if (!HubConnectionModule.IdentityConnection) return;
    await HubConnectionModule.IdentityConnection.send(hubActions.DELETE_FROM_COMPANY_USERS_GROUP);
  }
  @Action({ rawError: true })
  async SubscribeToUserGroup(userId: Guid) {
    if (!HubConnectionModule.IdentityConnection) return;

    await HubConnectionModule.IdentityConnection.send(hubActions.ADD_TO_USER_GROUP, userId.toString());
  }
  @Action({ rawError: true })
  async DeleteFromUserGroup(userId: Guid) {
    if (!HubConnectionModule.IdentityConnection) return;
    await HubConnectionModule.IdentityConnection.send(hubActions.DELETE_FROM_USER_GROUP, userId);
  }
}

export const UserModule = getModule(userModule);

export interface IRegisterUserData {
  name: string;
  secondName: string;
  password: string;
  email: string;
}

export function SortUsers(users: CompanyUser[], sortBy: UserSortBy | null, sortMode: SortMode | null): CompanyUser[] {
  if (sortMode == null || sortBy === undefined || users.length == 0) {
    return users;
  }

  if (sortBy === UserSortBy.BY_EMAIL) {
    if (sortMode === SortMode.ASC) return users.sort(SortCompanyUserByIdDESC).sort(SortCompanyUserByEmailASC);
    if (sortMode === SortMode.DESC) return users.sort(SortCompanyUserByIdDESC).sort(SortCompanyUserByEmailDESC);
  } else if (sortBy === UserSortBy.BY_PRINTS) {
    if (sortMode === SortMode.ASC) return users.sort(SortCompanyUserByIdDESC).sort(SortCompanyUserByPrintsASC);
    if (sortMode === SortMode.DESC) return users.sort(SortCompanyUserByIdDESC).sort(SortCompanyUserByPrintsDESC);
  } else if (sortBy === UserSortBy.BY_LAST_SEEN) {
    if (sortMode === SortMode.ASC) return users.sort(SortCompanyUserByIdDESC).sort(SortCompanyUserByLastSeenASC);
    if (sortMode === SortMode.DESC) return users.sort(SortCompanyUserByIdDESC).sort(SortCompanyUserByLastSeenDESC);
  } else if (sortBy === UserSortBy.BY_FULL_NAME) {
    if (sortMode === SortMode.ASC) return users.sort(SortCompanyUserByIdDESC).sort(SortCompanyUserByFullNameASC);
    if (sortMode === SortMode.DESC) return users.sort(SortCompanyUserByIdDESC).sort(SortCompanyUserByFullNameDESC);
  }

  return users;
}
