export const mockRoles = [
  {
    id: '3c790fb0-a210-451f-b3a7-ddd4ef284fb8',
    name: 'CompanyAdmin',
    isAdmin: true,
    isSuperAdmin: false,
    isBillingAccountAdmin: false,
    isBillingAccountOwner: false,
    isConnectUser: false,
  },
  {
    id: 'ff9ec549-4de0-4123-883d-5869701bcbe0',
    name: 'User',
    isAdmin: false,
    isSuperAdmin: false,
    isBillingAccountAdmin: false,
    isBillingAccountOwner: false,
    isConnectUser: false,
  },
  {
    id: '74090198-88c3-46a4-a5f6-7d196c55cdce',
    name: 'AuraAdmin',
    isAdmin: false,
    isSuperAdmin: true,
    isBillingAccountAdmin: false,
    isBillingAccountOwner: false,
    isConnectUser: false,
  },
  {
    id: '478777e7-371c-4bcf-833c-b8db62af016d',
    name: 'BillingAccountOwner',
    isAdmin: false,
    isSuperAdmin: false,
    isBillingAccountAdmin: false,
    isBillingAccountOwner: true,
    isConnectUser: false,
  },
  {
    id: '46b306a7-e28a-424c-a9bd-ef516cb714e4',
    name: 'BillingAccountAdmin',
    isAdmin: false,
    isSuperAdmin: false,
    isBillingAccountAdmin: true,
    isBillingAccountOwner: false,
    isConnectUser: false,
  },
  {
    id: '3b69ec64-caa2-4ba2-a01f-34599e6ff2c1',
    name: 'ConnectUser',
    isAdmin: false,
    isSuperAdmin: false,
    isBillingAccountAdmin: false,
    isBillingAccountOwner: false,
    isConnectUser: true,
  },
];

export const mockUser = {
  id: '36a8bee3-183c-43cc-9c34-769539b23c38',
  idCompany: 'aa72c649-84fa-4e8f-8369-fc7ff85827ec',
  idsRole: [
    '3b69ec64-caa2-4ba2-a01f-34599e6ff2c1',
    '3c790fb0-a210-451f-b3a7-ddd4ef284fb8',
    '46b306a7-e28a-424c-a9bd-ef516cb714e4',
    '478777e7-371c-4bcf-833c-b8db62af016d',
    '74090198-88c3-46a4-a5f6-7d196c55cdce',
    'ff9ec549-4de0-4123-883d-5869701bcbe0',
  ],
  loginSessions: [],
  firstName: 'Cleotilde',
  secondName: 'Dawson',
  email: 'cleo@ANISOPRINT.COM',
  phone: null,
};
