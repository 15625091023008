<template>
  <div class="login-form-container d-flex align-items-center">
    <form id="loginForm" @submit.prevent="SignIn">
      <text-box-alt
        id="username"
        v-model="UserName"
        class="mx-auto w-100"
        testId="login.emailInput"
        :placeholder="UserNamePlaceholder"
        :hasError="error.hasUsernameError"
      />
      <password-text-box
        id="password"
        v-model="Password"
        class="mx-auto w-100"
        testId="login.passwordInput"
        :placeholder="PasswordPlaceholder"
        :hasError="error.hasPasswordError"
      />
      <div id="forgot-password-container" class="mx-auto w-100">
        <div id="forgot-password" class="d-flex justify-content-end">
          <button
            id="forgot-password-caption"
            data-testid="login.forgotPasswordButton"
            type="button"
            @click.prevent="ForgotPassword"
          >
            {{ ForgotPasswordCaption }}
          </button>
        </div>
      </div>
      <div id="errors" data-testid="login.errors" class="mx-auto d-flex w-100 justify-content-start">
        <p id="error-text">{{ error.text }}</p>
      </div>
      <div class="mx-auto w-100 mt-6">
        <transparent-button
          id="signin"
          :disabled="isLoading"
          :text="isLoading ? SigningInCaption : SigninCaption"
          testId="login.signInButton"
          type="submit"
        ></transparent-button>
      </div>
    </form>

    <div v-if="!insturctionClosed" id="instruction-container" class="mx-auto">
      <div><b>How to get Aura.Connect Account</b></div>
      <div class="instruction">
        <b>Current users:</b> If you already own Anisoprint Composer A4 or A3, please write to
        <a href="mailto:support@anisoprint.com">support</a> to get an account.
      </div>

      <div class="instruction">
        <b>New users:</b> If you purchased the machine after March 1st, 2022, you will receive Aura.Connect credentials
        by e-mail. In case of any problems, please get in touch with
        <a href="mailto:support@anisoprint.com">customer support</a>.
      </div>

      <div class="close-button" @click="OnCloseButtonClicked">
        <i class="fas fa-times"></i>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import TextBoxAlt from '@/components/inputs/TextBoxAlt.vue';
import PasswordTextBox from '@/components/buttons/PasswordTextBox.vue';
import TransparentButton from '@/components/buttons/TransparentButton.vue';
import en from '../../localization/en';
import { LoginModule } from '@/store/modules/loginModule';
import { RequestStatus } from '@/models/enums/RequestStatus';
import IconButton from '@/components/buttons/IconButton.vue';

@Component({
  components: {
    IconButton,
    TextBoxAlt: TextBoxAlt,
    passwordTextBox: PasswordTextBox,
    transparentButton: TransparentButton,
  },
})
export default class LoginPassForm extends Vue {
  private insturctionClosed = false;

  private username: string = '';
  private password: string = '';
  private error: {
    hasUsernameError: boolean;
    hasPasswordError: boolean;
    text: string;
  } = {
    hasUsernameError: false,
    hasPasswordError: false,
    text: '',
  };

  private isLoading: boolean = false;

  created() {
    this.username = '';
    this.password = '';
  }

  private get UserName(): string {
    return this.username;
  }
  private set UserName(username: string) {
    this.username = username;
    if (this.username.length > 0) {
      this.error.hasUsernameError = false;
      if (this.password.length > 0) this.error.text = '';
    }
  }
  private get Password(): string {
    return this.password;
  }
  private set Password(password: string) {
    this.password = password;
    if (this.password.length > 0) {
      this.error.hasPasswordError = false;
      if (this.username.length > 0) this.error.text = '';
    }
  }

  private async SignIn() {
    if (this.UserName.length === 0 || this.Password.length === 0) {
      this.error.text = en.fillRequired;
      if (this.UserName.length === 0) this.error.hasUsernameError = true;
      if (this.Password.length === 0) this.error.hasPasswordError = true;
      return;
    }
    this.isLoading = true;
    let [result, message] = await LoginModule.login([this.UserName, this.Password]);
    this.isLoading = false;
    switch (result) {
      case RequestStatus.OK: {
        this.$emit('success');
        break;
      }
      case RequestStatus.CANT_REACH_SERVER:
      case RequestStatus.EMPTY_RESPONSE:
      case RequestStatus.INTERNAL_SERVER_ERROR: {
        if (message.any()) this.error.text = message;
        else this.error.text = en.internalServerError;
        break;
      }
      default: {
        if (message.any()) this.error.text = message;
        else this.error.text = en.unknownError;
      }
    }
  }
  private ForgotPassword() {
    this.$emit('forgot');
  }

  OnCloseButtonClicked() {
    this.insturctionClosed = true;
  }

  //#region CAPTIONS

  private get UserNamePlaceholder(): string {
    return en.usernamePlaceholder;
  }
  private get PasswordPlaceholder(): string {
    return en.passwordPlaceholder;
  }
  private get ForgotPasswordCaption(): string {
    return en.forgotPassword;
  }
  private get SigninCaption(): string {
    return en.signIn;
  }
  private get SigningInCaption(): string {
    return en.signingIn;
  }

  //#endregion
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono');
@import url('https://fonts.googleapis.com/css?family=Roboto');

#password {
  margin-top: 25px;
}
@media (min-width: 576px) {
  div#forgot-password-container {
    max-width: 480px;
  }
  div#errors {
    max-width: 480px;
  }
}
@media (max-width: 576px) {
  div#forgot-password-container {
    max-width: 380px;
  }
  div#errors {
    max-width: 380px;
  }
}
div#forgot-password-container {
  min-width: 200px;
}
div#forgot-password {
  width: auto;
}
button#forgot-password-caption {
  background-color: transparent;
  border-width: 0;
  color: var(--login-fields-text);
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  line-height: 32px;
  padding: 0;
  outline: none;
}
button#forgot-password-caption::first-letter {
  text-transform: uppercase;
}
button#forgot-password-caption:hover {
  color: white;
}
div#errors {
  margin-top: 0;
  height: 22px;
  color: var(--login-errors);
  white-space: nowrap;
  text-align: left;
}
div#errors p::first-letter {
  text-transform: uppercase;
}
.mt-6 {
  margin-top: 3rem !important;
}

.login-form-container {
  position: relative;
}

div#instruction-container {
  min-width: 200px;
  color: #c0c0c0;
  border: 1px solid #818181;
  border-radius: 5px;
  padding: 20px;
  background: #3d3d3d;
  position: absolute;
  bottom: 2rem;
}

div#instruction-container > div.close-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.5rem 1rem;
  cursor: pointer;
}

.instruction {
  text-align: justify;
  margin-top: 0.5rem;
}

.instruction a {
  text-decoration: underline;
}

.instruction a:hover {
  color: #ffffff;
}

@media (min-width: 576px) {
  div#instruction-container {
  }
}

@media (max-width: 576px) {
  div#instruction-container {
    max-width: 480px;
  }
}
</style>
