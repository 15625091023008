import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import {
  CompanyStat,
  MaterialConsumptionCompanyStat,
  MaterialConsumptionPrinterStat,
  PrinterStat,
  TotalPrintTimeByWeekDayCompanyStat,
  UserStat,
} from '@/models/Entities';
import GC from '../util/GC';
import { Guid } from 'guid-typescript';
import { UtilModule } from '@/store/modules/utilModule';
import * as ips from '@/util/api/ips';
import * as routes from '@/util/api/routes';
import {
  RequestReadCompanyStatById,
  RequestReadMaterialConsumptionCompanyStatByCompanyId,
  RequestReadMaterialConsumptionPrinterStatByPrinterId,
  RequestReadPrinterStatById,
  RequestReadTotalPrintTimeByWeekDayCompanyStatByCompanyId,
  RequestReadUserStatByUserId,
  RequestReadUserStatByUserIds,
} from '@/models/requests/RequestMonolith';
import { RequestStatus } from '@/models/enums/RequestStatus';
import {
  ResponseCompanyStat,
  ResponseMaterialConsumptionCompanyStat,
  ResponseMaterialConsumptionCompanyStats,
  ResponseMaterialConsumptionPrinterStat,
  ResponseMaterialConsumptionPrinterStats,
  ResponseMonolithHelper,
  ResponsePrinterStat,
  ResponseTotalPrintTimeByWeekDayCompanyStat,
  ResponseUserStat,
  ResponseUserStats,
} from '@/models/responses/ResponseMonolith';
import { ErrorModule } from '@/store/modules/errorsModule';
import { GuidHelper } from '@/util/GuidHelper';
import { HubConnection } from '@microsoft/signalr';
import * as hubActions from '@/util/api/hubActions';
import { HubConnectionModule } from '@/store/modules/hubConnectionModule';

@Module({ dynamic: true, name: 'stat', store: store, namespaced: true })
export default class statModule extends VuexModule {
  private userStats: UserStat[] = [];
  private companyStats: CompanyStat[] = [];
  private printerStats: PrinterStat[] = [];

  private totalPrintTimeByWeekDayCompanyStat: TotalPrintTimeByWeekDayCompanyStat[] = [];
  private materialConsumptionCompanyStats: MaterialConsumptionCompanyStat[] = [];
  private materialConsumptionPrinterStats: MaterialConsumptionPrinterStat[] = [];

  private _gcUsers = new GC();
  private _gcCompanies = new GC();
  private _gcPrinters = new GC();

  get UserStats(): UserStat[] {
    return this.userStats;
  }
  get CompanyStats(): CompanyStat[] {
    return this.companyStats;
  }
  get PrinterStats(): PrinterStat[] {
    return this.printerStats;
  }

  get TotalPrintTimeByWeekDayCompanyStat() {
    return this.totalPrintTimeByWeekDayCompanyStat;
  }
  get MaterialConsumptionCompanyStats() {
    return this.materialConsumptionCompanyStats;
  }
  get MaterialConsumptionPrinterStats() {
    return this.materialConsumptionPrinterStats;
  }

  //#region ADD-DELETE

  @Mutation
  AddUserStatToModule(value: UserStat) {
    const userStat = this.userStats.find(a => a.id.equals(value.id));
    if (userStat) return;
    this.userStats.push(value);
  }
  @Mutation
  DeleteUserStatFromModule(value: UserStat) {
    this.userStats.delete(value);
  }
  @Mutation
  AddCompanyStatToModule(value: CompanyStat) {
    const companyStat = this.companyStats.find(a => a.id.equals(value.id));
    if (companyStat) return;
    this.companyStats.push(value);
  }
  @Mutation
  DeleteCompanyStatFromModule(value: CompanyStat) {
    this.companyStats.delete(value);
  }
  @Mutation
  AddPrinterStatToModule(value: PrinterStat) {
    const printerStat = this.printerStats.find(a => a.id.equals(value.id));
    if (printerStat) return;
    this.printerStats.push(value);
  }
  @Mutation
  DeletePrinterStatFromModule(value: PrinterStat) {
    this.printerStats.delete(value);
  }

  @Mutation
  AddMaterialConsumptionPrinterStatToModule(val: MaterialConsumptionPrinterStat) {
    const found = this.materialConsumptionPrinterStats.find(a => a.Id.equals(val.Id));
    if (found) return;

    this.materialConsumptionPrinterStats.push(val);
  }
  @Mutation
  UpdateMaterialConsumptionPrinterStat(val: MaterialConsumptionPrinterStat) {
    this.materialConsumptionPrinterStats = this.materialConsumptionPrinterStats.map(a => {
      if (a.Id.toString() == val.Id.toString()) {
        return val;
      }

      return a;
    });
  }
  @Mutation
  DeleteMaterialConsumptionPrinterStatFromModule(val: MaterialConsumptionPrinterStat) {
    this.materialConsumptionPrinterStats.delete(val);
  }

  @Mutation
  AddMaterialConsumptionCompanyStatToModule(val: MaterialConsumptionCompanyStat) {
    const found = this.materialConsumptionCompanyStats.find(a => a.Id.equals(val.Id));
    if (found) return;

    this.materialConsumptionCompanyStats.push(val);
  }
  @Mutation
  UpdateMaterialConsumptionCompanyStat(val: MaterialConsumptionCompanyStat) {
    this.materialConsumptionCompanyStats = this.materialConsumptionCompanyStats.map(a => {
      if (a.Id.toString() == val.Id.toString()) {
        return val;
      }

      return a;
    });
  }
  @Mutation
  DeleteMaterialConsumptionCompanyStatFromModule(val: MaterialConsumptionCompanyStat) {
    this.materialConsumptionCompanyStats.delete(val);
  }

  @Mutation
  AddTotalPrintTimeByWeekDayCompanyStatToModule(val: TotalPrintTimeByWeekDayCompanyStat) {
    const found = this.totalPrintTimeByWeekDayCompanyStat.find(a => a.Id.equals(val.Id));
    if (found) return;

    this.totalPrintTimeByWeekDayCompanyStat.push(val);
  }
  @Mutation
  UpdateTotalPrintTimeByWeekDayCompanyStat(val: TotalPrintTimeByWeekDayCompanyStat) {
    this.totalPrintTimeByWeekDayCompanyStat = this.totalPrintTimeByWeekDayCompanyStat.map(a => {
      if (a.Id.toString() == val.Id.toString()) {
        return val;
      }

      return a;
    });
  }
  @Mutation
  DeleteTotalPrintTimeByWeekDayCompanyStatFromModule(val: TotalPrintTimeByWeekDayCompanyStat) {
    this.totalPrintTimeByWeekDayCompanyStat.delete(val);
  }

  //#endregion

  //#region UPDATE FIELDS

  @Mutation
  UpdateUserStatJobCountWeek([userStat, jobCountWeek]: [UserStat, number]) {
    userStat.jobCountWeek = jobCountWeek;
  }
  @Mutation
  UpdateUserStatJobCountMonth([userStat, jobCountMonth]: [UserStat, number]) {
    userStat.jobCountMonth = jobCountMonth;
  }
  @Mutation
  UpdateUserStatJobCountYear([userStat, jobCountYear]: [UserStat, number]) {
    userStat.jobCountYear = jobCountYear;
  }
  @Mutation
  UpdateUserStatJobCountWhole([userStat, jobCountWhole]: [UserStat, number]) {
    userStat.jobCountWhole = jobCountWhole;
  }
  @Mutation
  UpdateUserStatSourceCount([userStat, sourceCount]: [UserStat, number]) {
    userStat.sourceCount = sourceCount;
  }
  @Mutation
  UpdateUserStatPrintTimeWeekS([userStat, printTimeWeekS]: [UserStat, number]) {
    userStat.printTimeWeekS = printTimeWeekS;
  }
  @Mutation
  UpdateUserStatPrintTimeMonthS([userStat, printTimeMonthS]: [UserStat, number]) {
    userStat.printTimeMonthS = printTimeMonthS;
  }
  @Mutation
  UpdateUserStatPrintTimeYearS([userStat, printTimeYearS]: [UserStat, number]) {
    userStat.printTimeYearS = printTimeYearS;
  }
  @Mutation
  UpdateUserStatPrintTimeWholeS([userStat, printTimeWholeS]: [UserStat, number]) {
    userStat.printTimeWholeS = printTimeWholeS;
  }
  @Mutation
  UpdateUserStatPlasticConsumedGr([userStat, plasticConsumedGr]: [UserStat, number]) {
    userStat.plasticConsumedGr = plasticConsumedGr;
  }
  @Mutation
  UpdateUserStatCompositeConsumedM([userStat, compositeConsumedM]: [UserStat, number]) {
    userStat.compositeConsumedM = compositeConsumedM;
  }

  @Mutation
  UpdateCompanyStatJobCountWeek([companyStat, jobCountWeek]: [CompanyStat, number]) {
    companyStat.jobCountWeek = jobCountWeek;
  }
  @Mutation
  UpdateCompanyStatJobCountMonth([companyStat, jobCountMonth]: [CompanyStat, number]) {
    companyStat.jobCountMonth = jobCountMonth;
  }
  @Mutation
  UpdateCompanyStatJobCountYear([companyStat, jobCountYear]: [CompanyStat, number]) {
    companyStat.jobCountYear = jobCountYear;
  }
  @Mutation
  UpdateCompanyStatJobCountWhole([companyStat, jobCountWhole]: [CompanyStat, number]) {
    companyStat.jobCountWhole = jobCountWhole;
  }
  @Mutation
  UpdateCompanyStatSourceCount([companyStat, sourceCount]: [CompanyStat, number]) {
    companyStat.sourceCount = sourceCount;
  }
  @Mutation
  UpdateCompanyStatPrintTimeWeekS([companyStat, printTimeWeekS]: [CompanyStat, number]) {
    companyStat.printTimeWeekS = printTimeWeekS;
  }
  @Mutation
  UpdateCompanyStatPrintTimeMonthS([companyStat, printTimeMonthS]: [CompanyStat, number]) {
    companyStat.printTimeMonthS = printTimeMonthS;
  }
  @Mutation
  UpdateCompanyStatPrintTimeYearS([companyStat, printTimeYearS]: [CompanyStat, number]) {
    companyStat.printTimeYearS = printTimeYearS;
  }
  @Mutation
  UpdateCompanyStatPrintTimeWholeS([companyStat, printTimeWholeS]: [CompanyStat, number]) {
    companyStat.printTimeWholeS = printTimeWholeS;
  }
  @Mutation
  UpdateCompanyStatPlasticConsumedGr([companyStat, plasticConsumedGr]: [CompanyStat, number]) {
    companyStat.plasticConsumedGr = plasticConsumedGr;
  }
  @Mutation
  UpdateCompanyStatCompositeConsumedM([companyStat, compositeConsumedM]: [CompanyStat, number]) {
    companyStat.compositeConsumedM = compositeConsumedM;
  }
  @Mutation
  UpdateCompanyWorkloadAvg([companyStat, workloadAvg]: [CompanyStat, number]) {
    companyStat.workloadAvg = workloadAvg;
  }
  @Mutation
  UpdateCompanyUsersCount([companyStat, usersCount]: [CompanyStat, number]) {
    companyStat.usersCount = usersCount;
  }
  @Mutation
  UpdateCompanyPrintersCount([companyStat, printersCount]: [CompanyStat, number]) {
    companyStat.printersCount = printersCount;
  }
  @Mutation
  UpdatePrinterJobCountWeek([printerStat, printerJobCountWeek]: [PrinterStat, number]) {
    printerStat.jobCountWeek = printerJobCountWeek;
  }
  @Mutation
  UpdatePrinterJobCountMonth([printerStat, printerJobCountMonth]: [PrinterStat, number]) {
    printerStat.jobCountMonth = printerJobCountMonth;
  }
  @Mutation
  UpdatePrinterJobCountYear([printerStat, printerJobCountYear]: [PrinterStat, number]) {
    printerStat.jobCountYear = printerJobCountYear;
  }
  @Mutation
  UpdatePrinterJobCountWhole([printerStat, printerJobCountWhole]: [PrinterStat, number]) {
    printerStat.jobCountWhole = printerJobCountWhole;
  }
  @Mutation
  UpdatePrinterPrintTimeWeekS([printerStat, printTimeWeekS]: [PrinterStat, number]) {
    printerStat.printTimeWeekS = printTimeWeekS;
  }
  @Mutation
  UpdatePrinterPrintTimeMonthS([printerStat, printTimeMonthS]: [PrinterStat, number]) {
    printerStat.printTimeMonthS = printTimeMonthS;
  }
  @Mutation
  UpdatePrinterPrintTimeYearS([printerStat, printTimeYearS]: [PrinterStat, number]) {
    printerStat.printTimeYearS = printTimeYearS;
  }
  @Mutation
  UpdatePrinterPrintTimeWholeS([printerStat, printTimeWholeS]: [PrinterStat, number]) {
    printerStat.printTimeWholeS = printTimeWholeS;
  }
  @Mutation
  UpdatePrinterPlasticConsumedGr([printerStat, plasticConsumedGr]: [PrinterStat, number]) {
    printerStat.plasticConsumedGr = plasticConsumedGr;
  }
  @Mutation
  UpdatePrinterCompositeConsumedM([printerStat, compositeConsumedM]: [PrinterStat, number]) {
    printerStat.compositeConsumedM = compositeConsumedM;
  }
  @Mutation
  UpdatePrinterCreationDate([printerStat, creationDate]: [PrinterStat, Date]) {
    printerStat.creationDate = creationDate;
  }
  @Mutation
  UpdatePrinterWorkloadAvg([printerStat, workloadAvg]: [PrinterStat, number]) {
    printerStat.workloadAvg = workloadAvg;
  }

  //#endregion

  //#region SYNC-LOOSE

  @Action({ rawError: true })
  UserStatsSync([userStats, componentId]: [UserStat[], Guid]) {
    this._gcUsers.Occupy(
      userStats.map(a => a.id),
      componentId,
    );
  }
  @Action({ rawError: true })
  LooseUserStats([userStatIds, componentId]: [Guid[], Guid]) {
    const toDeleteIds = this._gcUsers.Loose(userStatIds, componentId);
    const toDeletes = this.UserStats.filter(a => GuidHelper.includes(toDeleteIds, a.id));
    for (const userStat of toDeletes) {
      this.DeleteUserStatFromModule(userStat);
    }
  }
  @Action({ rawError: true })
  CompanyStatsSync([companyStats, componentId]: [CompanyStat[], Guid]) {
    this._gcCompanies.Occupy(
      companyStats.map(a => a.id),
      componentId,
    );
  }
  @Action({ rawError: true })
  LooseCompanyStats([companyStatIds, componentId]: [Guid[], Guid]) {
    const toDeleteIds = this._gcCompanies.Loose(companyStatIds, componentId);
    const toDeletes = this.CompanyStats.filter(a => GuidHelper.includes(toDeleteIds, a.id));
    for (const companyStat of toDeletes) {
      this.DeleteCompanyStatFromModule(companyStat);
    }
  }
  @Action({ rawError: true })
  PrinterStatsSync([printerStats, componentId]: [PrinterStat[], Guid]) {
    this._gcPrinters.Occupy(
      printerStats.map(a => a.id),
      componentId,
    );
  }
  @Action({ rawError: true })
  LoosePrinterStats([printerStatsIds, componentId]: [Guid[], Guid]) {
    const toDeleteIds = this._gcPrinters.Loose(printerStatsIds, componentId);
    const toDeletes = this.PrinterStats.filter(a => GuidHelper.includes(toDeleteIds, a.id));
    for (const printerStat of toDeletes) {
      this.DeletePrinterStatFromModule(printerStat);
    }
  }

  //#endregion

  //#region REST

  @Action({ rawError: true })
  async LoadUserStat(userId: Guid): Promise<UserStat | null> {
    const inLst = this.UserStats.find(a => a.id.equals(userId));
    if (inLst) return inLst;
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.STAT,
      routes.UriUserStat.READ_BY_USER_ID,
    );
    const request = new RequestReadUserStatByUserId();
    request.userId = userId.toString();
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealUserStat(axiosResponse.data.response as ResponseUserStat);
      const userStat = response.Map();
      this.AddUserStatToModule(userStat);
      return userStat;
    }
    ErrorModule.ShowError(message);
    return null;
  }
  @Action({ rawError: true })
  async LoadUserStats(ids: Guid[]): Promise<UserStat[] | null> {
    const inLst = this.UserStats.filter(a => GuidHelper.includes(ids, a.id));
    if (inLst.length == ids.length) return inLst;
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.STAT,
      routes.UriUserStat.READ_BY_USER_IDS,
    );
    const request = new RequestReadUserStatByUserIds();
    request.ids = ids.map(a => a.toString());
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealUserStats(axiosResponse.data.response as ResponseUserStats);
      const stats: UserStat[] = [];
      for (const stat of response.stats) {
        const userStat = stat.Map();
        this.AddUserStatToModule(userStat);
      }
      return stats;
    }
    ErrorModule.ShowError(message);
    return null;
  }
  @Action({ rawError: true })
  async LoadCompanyStat(companyId: Guid): Promise<CompanyStat | null> {
    const inLst = this.CompanyStats.find(a => a.id.equals(companyId));
    if (inLst) return inLst;
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.STAT,
      routes.UriCompanyStat.READ_COMPANY_STAT_BY_ID,
    );
    const request = new RequestReadCompanyStatById();
    request.companyId = companyId.toString();
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealCompanyStat(axiosResponse.data.response as ResponseCompanyStat);
      const companyStat = response.Map();
      this.AddCompanyStatToModule(companyStat);
      return companyStat;
    }
    ErrorModule.ShowError(message);
    return null;
  }
  @Action({ rawError: true })
  async LoadPrinterStat(printerId: Guid): Promise<PrinterStat | null> {
    const inLst = this.PrinterStats.find(a => a.id.equals(printerId));
    if (inLst) return inLst;
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.STAT,
      routes.UriPrinterStat.READ_PRINTER_STAT_BY_PRINTER_ID,
    );
    const request = new RequestReadPrinterStatById();
    request.printerId = printerId.toString();
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealPrinterStat(axiosResponse.data.response as ResponsePrinterStat);
      const printerStat = response.Map();
      this.AddPrinterStatToModule(printerStat);
      return printerStat;
    }
    ErrorModule.ShowError(message);
    return null;
  }
  @Action({ rawError: true })
  async LoadTotalPrintTimeByWeekDayCompanyStat(companyId: Guid) {
    const inLst = this.TotalPrintTimeByWeekDayCompanyStat.find(a => a.CompanyId.equals(companyId));

    if (inLst) return inLst;

    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.STAT,
      routes.UriTotalPrintTimeByWeekdaystat.READ_BY_COMPANY_ID,
    );

    const request = new RequestReadTotalPrintTimeByWeekDayCompanyStatByCompanyId();
    request.companyId = companyId.toString();

    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealTotalPrintTimeByWeekDayCompanyStat(
        axiosResponse.data.response as ResponseTotalPrintTimeByWeekDayCompanyStat,
      );
      const printerStat = response.Map();
      this.AddTotalPrintTimeByWeekDayCompanyStatToModule(printerStat);
      return printerStat;
    }
    ErrorModule.ShowError(message);

    return null;
  }
  @Action({ rawError: true })
  async LoadMaterialConsumptionCompanyStat(companyId: Guid) {
    const inLst = this.MaterialConsumptionCompanyStats.find(a => a.CompanyId.equals(companyId));

    if (inLst) return inLst;

    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.STAT,
      routes.UriMaterialConsumptionCompanyStat.READ_BY_COMPANY_ID,
    );

    const request = new RequestReadMaterialConsumptionCompanyStatByCompanyId();
    request.companyId = companyId.toString();

    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealMaterialConsumptionCompanyStats(
        axiosResponse.data.response as ResponseMaterialConsumptionCompanyStats,
      );
      const result = [];
      for (const statRes of response.stats) {
        const stat = statRes.Map();
        result.push(stat);
        this.AddMaterialConsumptionCompanyStatToModule(stat);
      }
      return result;
    }
    ErrorModule.ShowError(message);

    return null;
  }
  @Action({ rawError: true })
  async LoadMaterialConsumptionPrinterStat(printerId: Guid) {
    const inLst = this.MaterialConsumptionPrinterStats.find(a => a.PrinterId.equals(printerId));

    if (inLst) return inLst;

    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.MONOLITH,
      routes.UriController.STAT,
      routes.UriMaterialConsumptionPrinterStat.READ_BY_PRINTER_ID,
    );

    const request = new RequestReadMaterialConsumptionPrinterStatByPrinterId();
    request.printerId = printerId.toString();

    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseMonolithHelper.createRealMaterialConsumptionPrinterStats(
        axiosResponse.data.response as ResponseMaterialConsumptionPrinterStats,
      );
      const result = [];
      for (const statRes of response.stats) {
        const stat = statRes.Map();
        result.push(stat);
        this.AddMaterialConsumptionPrinterStatToModule(stat);
      }
      return result;
    }
    ErrorModule.ShowError(message);

    return null;
  }

  // Todo: add material consumption stuff

  //#endregion

  //#region SUBSCRIBE

  @Action({ rawError: true })
  async SubscribeToCompanyGroup() {
    await HubConnectionModule.PrintingConnection?.send(hubActions.ADD_TO_MONOLITH_COMPANY_STAT_GROUP);
  }

  @Action({ rawError: true })
  async DeleteFromCompanyGroup() {
    await HubConnectionModule.PrintingConnection?.send(hubActions.DELETE_FROM_MONOLITH_COMPANY_STAT_GROUP);
  }

  @Action({ rawError: true })
  async SubscribeToUserGroup(userId: Guid) {
    await HubConnectionModule.PrintingConnection?.send(hubActions.ADD_TO_MONOLITH_USER_GROUP, userId.toString());
  }

  @Action({ rawError: true })
  async DeleteFromUserGroup(userId: Guid) {
    await HubConnectionModule.PrintingConnection?.send(hubActions.DELETE_FROM_MONOLITH_USER_GROUP, userId.toString());
  }

  @Action({ rawError: true })
  async UserStatSubscribe(hubConnection: HubConnection) {
    hubConnection?.on(hubActions.RECEIVE_UPDATE_USER_STAT, (response: ResponseUserStat) => {
      const realResponse = ResponseMonolithHelper.createRealUserStat(response);
      const userStat = this.UserStats.find(a => a.id.equals(realResponse.id));
      if (!userStat) return;
      if (userStat.jobCountWeek !== realResponse.jobCountWeek)
        this.UpdateUserStatJobCountWeek([userStat, realResponse.jobCountWeek]);
      if (userStat.jobCountMonth !== realResponse.jobCountMonth)
        this.UpdateUserStatJobCountMonth([userStat, realResponse.jobCountMonth]);
      if (userStat.jobCountYear !== realResponse.jobCountYear)
        this.UpdateUserStatJobCountYear([userStat, realResponse.jobCountYear]);
      if (userStat.jobCountWhole !== realResponse.jobCountWhole)
        this.UpdateUserStatJobCountWhole([userStat, realResponse.jobCountWhole]);
      if (userStat.sourceCount !== realResponse.sourceCount)
        this.UpdateUserStatSourceCount([userStat, realResponse.sourceCount]);
      if (userStat.printTimeWeekS !== realResponse.printTimeWeekS)
        this.UpdateUserStatPrintTimeWeekS([userStat, realResponse.printTimeWeekS]);
      if (userStat.printTimeMonthS !== realResponse.printTimeMonthS)
        this.UpdateUserStatPrintTimeMonthS([userStat, realResponse.printTimeMonthS]);
      if (userStat.printTimeYearS !== realResponse.printTimeYearS)
        this.UpdateUserStatPrintTimeYearS([userStat, realResponse.printTimeYearS]);
      if (userStat.printTimeWholeS !== realResponse.printTimeWholeS)
        this.UpdateUserStatPrintTimeWholeS([userStat, realResponse.printTimeWholeS]);
      if (userStat.plasticConsumedGr !== realResponse.plasticConsumedGr)
        this.UpdateUserStatPlasticConsumedGr([userStat, realResponse.plasticConsumedGr]);
      if (userStat.compositeConsumedM !== realResponse.compositeConsumedM)
        this.UpdateUserStatCompositeConsumedM([userStat, realResponse.compositeConsumedM]);
    });
    hubConnection?.on(hubActions.RECEIVE_DELETE_USER_STAT, (response: ResponseUserStat) => {
      const realResponse = ResponseMonolithHelper.createRealUserStat(response);
      const inModule = this.UserStats.find(a => a.id.equals(realResponse.id));
      if (!inModule) return;
      this.DeleteUserStatFromModule(inModule);
    });
  }
  @Action({ rawError: true })
  async CompanyStatSubscribe(hubConnection: HubConnection) {
    hubConnection?.on(hubActions.RECEIVE_UPDATE_COMPANY_STAT, (response: ResponseCompanyStat) => {
      const realResponse = ResponseMonolithHelper.createRealCompanyStat(response);
      const companyStat = this.CompanyStats.find(a => a.id.equals(realResponse.id));
      if (!companyStat) return;
      if (companyStat.jobCountWeek !== realResponse.jobCountWeek)
        this.UpdateCompanyStatJobCountWeek([companyStat, realResponse.jobCountWeek]);
      if (companyStat.jobCountMonth !== realResponse.jobCountMonth)
        this.UpdateCompanyStatJobCountMonth([companyStat, realResponse.jobCountMonth]);
      if (companyStat.jobCountYear !== realResponse.jobCountYear)
        this.UpdateCompanyStatJobCountYear([companyStat, realResponse.jobCountYear]);
      if (companyStat.jobCountWhole !== realResponse.jobCountWhole)
        this.UpdateCompanyStatJobCountWhole([companyStat, realResponse.jobCountWhole]);
      if (companyStat.sourceCount !== realResponse.sourceCount)
        this.UpdateCompanyStatSourceCount([companyStat, realResponse.sourceCount]);
      if (companyStat.printTimeWeekS !== realResponse.printTimeWeekS)
        this.UpdateCompanyStatPrintTimeWeekS([companyStat, realResponse.printTimeWeekS]);
      if (companyStat.printTimeMonthS !== realResponse.printTimeMonthS)
        this.UpdateCompanyStatPrintTimeMonthS([companyStat, realResponse.printTimeMonthS]);
      if (companyStat.printTimeYearS !== realResponse.printTimeYearS)
        this.UpdateCompanyStatPrintTimeYearS([companyStat, realResponse.printTimeYearS]);
      if (companyStat.printTimeWholeS !== realResponse.printTimeWholeS)
        this.UpdateCompanyStatPrintTimeWholeS([companyStat, realResponse.printTimeWholeS]);
      if (companyStat.plasticConsumedGr !== realResponse.plasticConsumedGr)
        this.UpdateCompanyStatPlasticConsumedGr([companyStat, realResponse.plasticConsumedGr]);
      if (companyStat.compositeConsumedM !== realResponse.compositeConsumedM)
        this.UpdateCompanyStatCompositeConsumedM([companyStat, realResponse.compositeConsumedM]);
      if (companyStat.workloadAvg !== realResponse.workloadAvg)
        this.UpdateCompanyWorkloadAvg([companyStat, realResponse.workloadAvg]);
      if (companyStat.usersCount !== realResponse.usersCount)
        this.UpdateCompanyUsersCount([companyStat, realResponse.usersCount]);
      if (companyStat.printersCount !== realResponse.printersCount)
        this.UpdateCompanyPrintersCount([companyStat, realResponse.printersCount]);
    });
    hubConnection?.on(hubActions.RECEIVE_DELETE_COMPANY_STAT, (response: ResponseCompanyStat) => {
      const realResponse = ResponseMonolithHelper.createRealCompanyStat(response);
      const inModule = this.CompanyStats.find(a => a.id.equals(realResponse.id));
      if (!inModule) return;
      this.DeleteCompanyStatFromModule(inModule);
    });

    hubConnection?.on(
      hubActions.RECEIVE_MATERIALCONSUMPTIONCOMPANYSTAT_UPDATE,
      (res: ResponseMaterialConsumptionCompanyStat) => {
        const realResponse = ResponseMonolithHelper.createRealMaterialConsumptionCompanyStat(res);
        const consumption = realResponse.Map();

        // Todo: add this :)
        this.UpdateMaterialConsumptionCompanyStat(consumption);
      },
    );
    hubConnection?.on(
      hubActions.RECEIVE_TOTALPRINTTIMEBYWEEKDAYCOMPANYSTAT_UPDATE,
      (res: ResponseTotalPrintTimeByWeekDayCompanyStat) => {
        const realResponse = ResponseMonolithHelper.createRealTotalPrintTimeByWeekDayCompanyStat(res);
        const totalTime = realResponse.Map();

        this.UpdateTotalPrintTimeByWeekDayCompanyStat(totalTime);
      },
    );
  }
  @Action({ rawError: true })
  async PrinterStatSubscribe(hubConnection: HubConnection) {
    hubConnection?.on(hubActions.RECEIVE_UPDATE_PRINTER_STAT, (response: ResponsePrinterStat) => {
      const realResponse = ResponseMonolithHelper.createRealPrinterStat(response);
      const printerStat = this.PrinterStats.find(a => a.id.equals(realResponse.id));
      if (!printerStat) return;
      if (printerStat.jobCountWeek !== realResponse.jobCountWeek)
        this.UpdatePrinterJobCountWeek([printerStat, realResponse.jobCountWeek]);
      if (printerStat.jobCountMonth !== realResponse.jobCountMonth)
        this.UpdatePrinterJobCountMonth([printerStat, realResponse.jobCountMonth]);
      if (printerStat.jobCountYear !== realResponse.jobCountYear)
        this.UpdatePrinterJobCountYear([printerStat, realResponse.jobCountYear]);
      if (printerStat.jobCountWhole !== realResponse.jobCountWhole)
        this.UpdatePrinterJobCountWhole([printerStat, realResponse.jobCountWhole]);
      if (printerStat.printTimeWeekS !== realResponse.printTimeWeekS)
        this.UpdatePrinterPrintTimeWeekS([printerStat, realResponse.printTimeWeekS]);
      if (printerStat.printTimeMonthS !== realResponse.printTimeMonthS)
        this.UpdatePrinterPrintTimeMonthS([printerStat, realResponse.printTimeMonthS]);
      if (printerStat.printTimeYearS !== realResponse.printTimeYearS)
        this.UpdatePrinterPrintTimeYearS([printerStat, realResponse.printTimeYearS]);
      if (printerStat.printTimeWholeS !== realResponse.printTimeWholeS)
        this.UpdatePrinterPrintTimeWholeS([printerStat, realResponse.printTimeWholeS]);
      if (printerStat.plasticConsumedGr !== realResponse.plasticConsumedGr)
        this.UpdatePrinterPlasticConsumedGr([printerStat, realResponse.plasticConsumedGr]);
      if (printerStat.compositeConsumedM !== realResponse.compositeConsumedM)
        this.UpdatePrinterCompositeConsumedM([printerStat, realResponse.compositeConsumedM]);
      if (printerStat.workloadAvg !== realResponse.workloadAvg)
        this.UpdatePrinterWorkloadAvg([printerStat, realResponse.workloadAvg]);
    });
    hubConnection?.on(hubActions.RECEIVE_DELETE_PRINTER_STAT, (response: ResponsePrinterStat) => {
      const realResponse = ResponseMonolithHelper.createRealPrinterStat(response);
      const inModule = this.PrinterStats.find(a => a.id.equals(realResponse.id));
      if (!inModule) return;
      this.DeletePrinterStatFromModule(inModule);
    });
    hubConnection?.on(
      hubActions.RECEIVE_MATERIALCONSUMPTIONPRINTERSTAT_UPDATE,
      (res: ResponseMaterialConsumptionPrinterStat) => {
        const realResponse = ResponseMonolithHelper.createRealMaterialConsumptionPrinterStat(res);
        const consumption = realResponse.Map();

        // Todo: add this :)
        this.UpdateMaterialConsumptionPrinterStat(consumption);
      },
    );
  }

  //#endregion
}

export const StatModule = getModule(statModule);
