export enum Routes {
  DASHBOARD = 'dashboard',
  MY_ACCOUNT = 'my-account',
  USER = 'user',
  COMPANY = 'company',
  FILE_LIBRARY = 'file-library',
  MACHINES = 'machines',
  JOBS = 'jobs',
  ADMIN = 'admin',
  LOGIN = 'login',
  UNAUTHORIZED = 'unauthorized',
  NOT_FOUND = 'not-found',
  HOME = 'home',
}

export enum CompanyRouteTab {
  STORE = 'store',
  PRODUCTS = 'products',
  INFO = 'info',
  BILLING = 'billing',
}

export enum JobsRouteTab {
  CURRENT = 'current',
  ARCHIVE = 'archive',
}
