import * as ips from '../../util/api/ips';
import * as routes from '../../util/api/routes';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { UtilModule } from '@/store/modules/utilModule';
import {
  RequestAcknowledgeAllErrorsForPrinter,
  RequestAcknowledgeErrors,
  RequestGetLogs,
  RequestLogConfiguration,
  RequestRawCommand,
  RequestReadAllPrinterModels,
  RequestReadByClientId,
  RequestReadErrorsByPrinterForTimeRange,
  RequestReadErrorsByPrinterIdPaged,
  RequestReadPrinterModelById,
  RequestReadTelemetryForPrinterForTimeRange,
  RequestReadUnacknowledgedCountByPrinter,
} from '../../models/requests/RequestConnector';
import { Guid } from 'guid-typescript';
import { RequestStatus } from '@/models/enums/RequestStatus';
import {
  ResponseConnectorHelper,
  ResponseConnectorPrinterModel,
  ResponseConnectorPrinterModels,
  ResponseErrors,
  ResponseErrorsPaged,
  ResponseGetLogs,
  ResponseRaw,
  ResponseRawCommand,
  ResponseTelemetryFiltered,
  ResponseUnacknowledgedErrorsCount,
  ResponseUploadLogConfiguration,
} from '@/models/responses/ResponseConnector';
import { ErrorModule } from '@/store/modules/errorsModule';
import { LoginModule } from '@/store/modules/loginModule';
import * as hubActions from '@/util/api/hubActions';
import { ErrorType, PrinterErrors, PrinterError, ConnectorPrinterModel } from '@/models/Entities';
import { ISimpleEvent, SimpleEventDispatcher } from 'strongly-typed-events';
import { TelemetryType } from '@/models/enums/TelemetryType';
import { StoreHelper } from '../util/storeHelpers';
import { HubConnectionModule } from '@/store/modules/hubConnectionModule';

const COMMAND_RAW_ON = '@ac_raw 1';
const COMMAND_RAW_OFF = '@ac_raw 0';

export interface ErrorsPagedData {
  errors: PrinterErrors;
  total: number;
}

@Module({ dynamic: true, name: 'connector', store: store, namespaced: true })
export default class connectorModule extends VuexModule {
  private onErrorsAcknowledged = new SimpleEventDispatcher<Guid[]>();
  public get OnErrorsAcknowledged(): ISimpleEvent<Guid[]> {
    return this.onErrorsAcknowledged.asEvent();
  }

  private onRawCommandReceived = new SimpleEventDispatcher<ResponseRaw>();
  public get OnRawCommandReceived() {
    return this.onRawCommandReceived.asEvent();
  }

  private onErrorsReceived = new SimpleEventDispatcher<PrinterErrors>();
  public get OnErrorsReceived() {
    return this.onErrorsReceived.asEvent();
  }

  private onTelemetryReceived = new SimpleEventDispatcher<ResponseTelemetryFiltered>();
  public get OnTelemetryReceived() {
    return this.onTelemetryReceived.asEvent();
  }

  private printerModels: ConnectorPrinterModel[] = [];
  public get PrinterModels(): ConnectorPrinterModel[] {
    return this.printerModels;
  }

  @Mutation
  AddConnectorPrinterModelToModule(model: ConnectorPrinterModel) {
    if (this.printerModels.firstOrDefault(a => a.Id.toString() == model.Id.toString()) != null) return;

    this.printerModels.push(model);
  }

  @Action({ rawError: true })
  async ReadConnectorPrinterModel(printerId: Guid): Promise<ConnectorPrinterModel | null> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.CONNECTOR,
      routes.UriController.CONNECTOR_PRINTER_MODEL,
      routes.UriConnectorPrinterModel.READ_BY_ID,
    );
    const request = new RequestReadPrinterModelById();
    request.id = printerId.toString();
    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse && axiosResponse.data.response) {
      return ResponseConnectorHelper.createRealConnectorPrinterModel(
        axiosResponse.data.response as ResponseConnectorPrinterModel,
      ).Map();
    }

    return null;
  }

  @Action({ rawError: true })
  async DownloadLogConfiguration(printerId: Guid) {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.CONNECTOR,
      routes.UriController.LOG,
      routes.UriLog.REQUEST_LOG_CONFIGURATION,
    );

    const request = new RequestLogConfiguration();
    request.printerId = printerId.toString();

    const res = await UtilModule.SHW.ProcessDownloadFile(uri, request, () => {});

    if (res) {
      StoreHelper.DownloadBlob(res.blob, res.fileName);
    }
  }

  @Action({ rawError: true })
  async DownloadLogs(printerId: Guid) {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.CONNECTOR,
      routes.UriController.LOG,
      routes.UriLog.DOWNLOAD_LOGS,
    );

    const request = new RequestLogConfiguration();
    request.printerId = printerId.toString();

    // Todo: progress of file download can be reflected somewhere
    const res = await UtilModule.SHW.ProcessDownloadFile(uri, request, () => {});

    if (res) {
      StoreHelper.DownloadBlob(res.blob, res.fileName);
    }
  }

  @Action({ rawError: true })
  async GetLogs(data: { printerId: Guid; from: Date | null; to: Date | null }) {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.CONNECTOR,
      routes.UriController.LOG,
      routes.UriLog.GET_LOGS,
    );

    const request = new RequestGetLogs();
    request.printerId = data.printerId.toString();
    request.from = data.from;
    request.to = data.to;

    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);

    if (result === RequestStatus.OK && axiosResponse && axiosResponse.data.response) {
      return (axiosResponse.data.response as ResponseGetLogs).success;
    }

    return false;
  }

  @Action({ rawError: true })
  async UploadLogConfiguration(formData: FormData) {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.CONNECTOR,
      routes.UriController.LOG,
      routes.UriLog.UPLOAD_LOG_CONFIGURATION,
    );

    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPostFile(uri, formData);

    let response: ResponseUploadLogConfiguration | null = null;

    if (result === RequestStatus.OK && axiosResponse != null) {
      response = ResponseConnectorHelper.createRealUploadLogConfiguration(
        axiosResponse.data.response as ResponseUploadLogConfiguration,
      );
    }

    return { result, message, response };
  }

  @Action({ rawError: true })
  async LoadAllPrinterModels() {
    if (this.printerModels.length != 0) {
      return this.printerModels;
    }

    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.CONNECTOR,
      routes.UriController.CONNECTOR_PRINTER_MODEL,
      routes.UriConnectorPrinterModel.READ_ALL,
    );
    const request = new RequestReadAllPrinterModels();
    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse && axiosResponse.data.response) {
      const models = ResponseConnectorHelper.createRealConnectorPrinterModels(
        axiosResponse.data.response as ResponseConnectorPrinterModels,
      ).models.map(a => a.Map());

      // console.log("?");

      for (const model of models) {
        this.AddConnectorPrinterModelToModule(model);
      }

      return models;
    }

    return null;
  }

  @Action({ rawError: true })
  async AcknowledgeErrors([errors, printerId]: [PrinterError[], string]) {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.CONNECTOR,
      routes.UriController.ERROR,
      routes.UriError.ACKNOWLEDGE,
    );
    const request = new RequestAcknowledgeErrors();
    request.errorIds = errors.map(err => err.Id.toString());
    request.printerId = printerId;

    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse && axiosResponse.data.response) {
      // Cool, nice
    } else {
      ErrorModule.ShowError(message);
    }
  }

  @Action({ rawError: true })
  async AcknowledgeAllPrinterErrors(printerId: Guid) {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.CONNECTOR,
      routes.UriController.ERROR,
      routes.UriError.ACKNOWLEDGE_ALL_FOR_PRINTER,
    );
    const request = new RequestAcknowledgeAllErrorsForPrinter();
    request.printerId = printerId.toString();

    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse && axiosResponse.data.response) {
      // Cool, nice
    } else {
      ErrorModule.ShowError(message);
    }
  }

  @Action({ rawError: true })
  async SendRawCommand([printerId, command]: [Guid, string]) {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.CONNECTOR,
      routes.UriController.CONNECTION,
      routes.UriConnection.EXECUTE_COMMAND,
    );
    const request = new RequestRawCommand();
    request.printerId = printerId.toString();
    request.command = command;
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse != null) {
      const response = ResponseConnectorHelper.createRealRawCommand(axiosResponse.data.response as ResponseRawCommand);

      return response;
    } else ErrorModule.ShowError(message);
    return null;
  }

  @Action({ rawError: true })
  async ReadTelemetryByPrinterDate([printerId, dateFrom, dateTo, interval, types, from, count]: [
    Guid,
    Date,
    Date,
    number,
    TelemetryType[],
    number?,
    number?,
  ]): Promise<ResponseTelemetryFiltered | null> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.CONNECTOR,
      routes.UriController.TELEMETRY,
      routes.UriTelemetry.READ_BY_PRINTER_DATE,
    );
    const request = new RequestReadTelemetryForPrinterForTimeRange();
    request.printerId = printerId.toString();
    request.timeIntervalBottom = dateFrom.toJSON();
    request.timeIntervalTop = dateTo.toJSON();
    request.intervalTicks = interval;
    request.types = types;

    if (from) request.from = from;
    if (count) request.count = count;
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse && axiosResponse.data.response) {
      return ResponseConnectorHelper.createRealTelemetryFiltered(
        axiosResponse.data.response as ResponseTelemetryFiltered,
      );
    } else {
      ErrorModule.ShowError(message);
    }
    return null;
  }

  @Action({ rawError: true })
  async ReadErrorsByPrinterDate([printerId, dateFrom, dateTo, before, pageSize, from, count]: [
    Guid,
    Date,
    Date,
    Date,
    number,
    number?,
    number?,
  ]): Promise<PrinterErrors | null> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.CONNECTOR,
      routes.UriController.ERROR,
      routes.UriError.READ_BY_PRINTER,
    );
    const request = new RequestReadErrorsByPrinterForTimeRange();
    request.printerId = printerId.toString();
    request.timeIntervalBottom = dateFrom.toJSON();
    request.timeIntervalTop = dateTo.toJSON();
    request.before = before.toJSON();
    request.pageSize = pageSize;

    if (from) request.from = from;
    if (count) request.count = count;
    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse && axiosResponse.data.response) {
      return ResponseConnectorHelper.createRealErrors(axiosResponse.data.response as ResponseErrors).Map();
    } else {
      ErrorModule.ShowError(message);
    }
    return null;
  }

  @Action({ rawError: true })
  async ReadErrorsByPrinterPaged([printerId, dateFrom, dateTo, pageSize, page, errorTypes, onlyUnacknowledged]: [
    Guid,
    Date,
    Date,
    number,
    number,
    ErrorType[],
    boolean,
  ]): Promise<ErrorsPagedData | null> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.CONNECTOR,
      routes.UriController.ERROR,
      routes.UriError.READ_BY_PRINTER_PAGED,
    );
    const request = new RequestReadErrorsByPrinterIdPaged();
    request.printerId = printerId.toString();
    request.dateFrom = dateFrom.toJSON();
    request.dateTo = dateTo.toJSON();
    request.page = page;
    request.pageSize = pageSize;
    request.errorTypes = errorTypes;
    request.onlyUnacknowledged = onlyUnacknowledged;

    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse && axiosResponse.data.response) {
      const temp = ResponseConnectorHelper.createRealErrorsPaged(axiosResponse.data.response as ResponseErrorsPaged);
      const result: ErrorsPagedData = {
        errors: temp.errors.Map(),
        total: temp.total,
      };

      return result;
    } else {
      ErrorModule.ShowError(message);
    }
    return null;
  }

  @Action({ rawError: true })
  async ReadUnackknowledgedCountByPrinter(printerId: Guid): Promise<ResponseUnacknowledgedErrorsCount | null> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.CONNECTOR,
      routes.UriController.ERROR,
      routes.UriError.READ_UNACKNOWLEDGED_COUNT_BY_PRINTER,
    );
    const request = new RequestReadUnacknowledgedCountByPrinter();
    request.printerId = printerId.toString();

    const { result, message, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    if (result === RequestStatus.OK && axiosResponse && axiosResponse.data.response) {
      return ResponseConnectorHelper.createRealUnacknowledgedErrorsCount(
        axiosResponse.data.response as ResponseUnacknowledgedErrorsCount,
      );
    } else {
      ErrorModule.ShowError(message);
    }
    return null;
  }

  @Action({ rawError: true })
  async ReadByClientId(clientId: string): Promise<boolean> {
    const uri = UtilModule.SHW.GetUri(
      ips.PROTOCOL(),
      ips.IP(),
      ips.PORT(),
      routes.UriServices.CONNECTOR,
      routes.UriController.CONNECTION,
      routes.UriConnection.READ_BY_CLIENT_ID,
    );
    const request = new RequestReadByClientId();
    request.clientId = clientId;
    const { result, axiosResponse } = await UtilModule.SHW.ProcessPost(uri, request);
    return !!(result === RequestStatus.OK && axiosResponse && axiosResponse.data.response);
  }

  @Action({ rawError: true })
  async SubscribeToRaw(printerId: Guid) {
    if (HubConnectionModule.ConnectorConnection == null) return;
    const success = await this.SendRawCommand([printerId, COMMAND_RAW_ON]);
    if (!success) return;
    await HubConnectionModule.ConnectorConnection.send(hubActions.ADD_TO_PRINTER_INFO_GROUP, printerId.toString());
  }
  @Action({ rawError: true })
  async UnsubscribeFromRaw(printerId: Guid) {
    if (HubConnectionModule.ConnectorConnection == null) return;
    const success = await this.SendRawCommand([printerId, COMMAND_RAW_OFF]);
    if (!success) return;
    await HubConnectionModule.ConnectorConnection.send(hubActions.DELETE_FROM_PRINTER_INFO_GROUP, printerId.toString());
  }

  @Action({ rawError: true })
  async SubscribeToError(printerId: Guid) {
    if (HubConnectionModule.ConnectorConnection == null) return;

    await HubConnectionModule.ConnectorConnection.send(hubActions.ADD_TO_PRINTER_ERROR_GROUP, printerId.toString());
  }
  @Action({ rawError: true })
  async UnsubscribeFromError(printerId: Guid) {
    if (HubConnectionModule.ConnectorConnection == null) return;

    await HubConnectionModule.ConnectorConnection.send(
      hubActions.DELETE_FROM_PRINTER_ERROR_GROUP,
      printerId.toString(),
    );
  }

  @Action({ rawError: true })
  async SubscribeToTelemetry(printerId: Guid) {
    if (HubConnectionModule.ConnectorConnection == null) return;

    await HubConnectionModule.ConnectorConnection.send(hubActions.ADD_TO_PRINTER_TELEMETRY_GROUP, printerId.toString());

    // console.log("Adding " + printerId.toString());

    HubConnectionModule.AddConnectorConnectionGroup({
      func: this.SubscribeToTelemetry,
      args: printerId,
    });
  }
  @Action({ rawError: true })
  async UnsubscribeFromTelemetry(printerId: Guid) {
    if (HubConnectionModule.ConnectorConnection == null) return;

    const connectionGroups = HubConnectionModule.ConnectorConnectionGroups.filter(
      a => a.func == this.SubscribeToTelemetry && (<Guid>a.args).toString() == printerId.toString(),
    );

    // console.log("Removing " + printerId.toString());

    if (connectionGroups.length == 1) {
      await HubConnectionModule.ConnectorConnection.send(
        hubActions.DELETE_FROM_PRINTER_TELEMETRY_GROUP,
        printerId.toString(),
      );
    }

    if (connectionGroups.length > 0) {
      HubConnectionModule.DeleteConnectorConnectionGroup(connectionGroups[0]);
    }
  }

  @Action({ rawError: true })
  async ConnectorSubscribe() {
    if (HubConnectionModule.ConnectorConnection == null) return;

    HubConnectionModule.ConnectorConnection.on(hubActions.RECEIVE_TELEMETRY, (response: ResponseTelemetryFiltered) => {
      const telemetry = ResponseConnectorHelper.createRealTelemetryFiltered(response);
      // this.telemetryTransporter.Update(telemetry);
      this.onTelemetryReceived.dispatch(telemetry);
    });

    HubConnectionModule.ConnectorConnection.on(hubActions.RECEIVE_ERROR, (response: ResponseErrors) => {
      const errors = ResponseConnectorHelper.createRealErrors(response).Map();
      this.onErrorsReceived.dispatch(errors);
    });
    HubConnectionModule.ConnectorConnection.on(hubActions.RECEIVE_ACKNOWLEDGE_ERRORS, (ackdErrorIds: string[]) => {
      const toDispatch = ackdErrorIds.map(id => Guid.parse(id));
      this.onErrorsAcknowledged.dispatch(toDispatch);
    });

    HubConnectionModule.ConnectorConnection.on(hubActions.RECEIVE_RAW, (response: ResponseRaw) => {
      const realRaw = ResponseConnectorHelper.createRealRaw(response);
      // console.log(realRaw);
      // const value = new ValueRaw(realRaw.raw);
      this.onRawCommandReceived.dispatch(realRaw);
    });
  }
}

export const ConnectorModule = getModule(connectorModule);
