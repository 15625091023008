export default function getEnv(name: string): string {
  return import.meta.env[name] ?? '';
}

export function isDevMode() {
  return Boolean(getEnv('DEV'));
}

export function getAuraUrl() {
  return getEnv('VITE_AURA_APP_URL') || window.location.origin + '/app/aura';
}

export function getBaseAppPath() {
  return getEnv('VITE_BASE_APP_PATH');
}

export function isBullingEnabled() {
  return getEnv('VITE_BILLING_ENABLED');
}

export function isAura3Visible() {
  return getEnv('VITE_AURA3_VISIBLE');
}

export function canAddNewProduct() {
  return getEnv('VITE_CAN_ADD_NEW_PRODUCT');
}

export const isPROM = getEnv('VITE_PLATFORM') === 'PROM';
