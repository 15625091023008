<template>
  <div id="app">
    <router-view />
    <dialogs-wrapper />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'App',
})
export default class App extends Vue {}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  min-height: 100vh;
  height: 100vh;
}
</style>

<style lang="scss">
@import './../scss/custom.scss';
</style>
