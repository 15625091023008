<template>
  <div data-testid="jobCard" :class="['job-card', selected ? 'selected' : '']" @click="Clicked">
    <div class="progress-underlay" :style="progressUnderlayStyle"></div>
    <div class="status-ribbon mr-2" :style="{ 'background-color': ribbonColor }"></div>
    <div class="content px-2 py-2">
      <div class="header">
        <div class="source-name-container mr-3">
          <span class="source-name mb-2">{{ source }}</span>
        </div>
        <div class="controls mb-2">
          <div class="buttons">
            <IconButton
              v-if="canStart"
              test-id="jobCard.startButton"
              icon-name="fa-play"
              :icon-size="14"
              @clicked="startJob"
            />
            <IconButton
              v-if="canResume"
              test-id="jobCard.resumeButton"
              icon-name="fa-play"
              :icon-size="14"
              @clicked="resumeJob"
            />
            <IconButton
              v-if="canPause"
              test-id="jobCard.pauseButton"
              icon-name="fa-pause"
              :icon-size="14"
              @clicked="pauseJob"
            />
            <IconButton
              v-if="canStop"
              test-id="jobCard.stopButton"
              icon-name="fa-stop"
              :icon-size="14"
              @clicked="stopJob"
            />
          </div>
          <span class="status mx-2">{{ status }}</span>
        </div>
      </div>
      <div class="info">
        <TextInput class="info-item" label="Printer" :value="printer" :readonly="true" />
        <TextInput v-if="!isStarted" class="info-item" label="Duration" :value="duration" :readonly="true" />
        <TextInput class="info-item" label="Order" :value="order" :readonly="true" />
        <TextInput v-if="isStarted" class="info-item" label="Progress" :value="progress" :readonly="true" />
        <TextInput v-if="isStarted" class="info-item" label="Estimated" :value="estimated" :readonly="true" />
      </div>
      <div :class="['spinner', isLoading ? '' : 'hidden']"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { create } from 'vue-modal-dialogs';
import moment from 'moment';

import en from '@/localization/en';
import { FullJob } from '@/models/CompositeEntities';
import { JobState, PrinterState } from '@/models/Entities';
import { JobModule } from '@/store/modules/jobModule';
import ComponentHelper from '@/util/ComponentHelper';
import AuraMessageBoxDialog, { Result } from '@/views/dialogs/AuraMessageBoxDialog.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import IconButton from '@/components/buttons/IconButton.vue';

const StopJobSure = create<Result, String, String, Result>(AuraMessageBoxDialog, 'results', 'header', 'mainText');

@Component({
  components: {
    TextInput,
    IconButton,
  },
})
export default class JobCard extends Vue {
  @Prop() fullJob!: FullJob;
  @Prop({ default: false }) selected!: boolean;

  isLoading = false;

  get isStarted() {
    return this.fullJob.job.JobState == JobState.START;
  }

  get isResume() {
    return this.fullJob.job.JobState == JobState.RESUME;
  }

  get isPaused() {
    return this.fullJob.job.JobState == JobState.PAUSE;
  }

  get isInProgress() {
    return this.fullJob.job.Order == null;
  }

  get canStart() {
    return this.fullJob.printer.PrinterState == PrinterState.IDLE && !this.isStarted;
  }

  get canResume() {
    return this.fullJob.printer.PrinterState == PrinterState.PAUSED && this.isPaused;
  }

  get canPause() {
    return this.fullJob.printer.PrinterState == PrinterState.PRINTING && (this.isStarted || this.isResume);
  }

  get canStop() {
    return (
      (this.fullJob.printer.PrinterState == PrinterState.PRINTING ||
        this.fullJob.printer.PrinterState == PrinterState.PAUSED) &&
      (this.isStarted || this.isPaused || this.isResume)
    );
  }

  get source() {
    if (this.fullJob.job.LocalFilename != null) {
      return this.fullJob.job.LocalFilename;
    }

    if (this.fullJob.source == null) {
      return '—';
    }

    return this.fullJob.source.CodeFileName;
  }

  get printer() {
    return this.fullJob.printer.Name;
  }

  get progress() {
    if (this.fullJob.job.Progress == null) {
      return '—';
    }

    return this.fullJob.job.Progress.toFixed(2) + '%';
  }

  get estimated() {
    if (
      this.fullJob.source == null ||
      this.fullJob.job.ActualStartTime == null ||
      this.fullJob.source.PrintDuration == null
    ) {
      return '—';
    }

    const startTime = this.fullJob.job.ActualStartTime;
    let endDate = moment(startTime).add(this.fullJob.source.PrintDuration).toDate();

    return ComponentHelper.GetDateTime(endDate);
  }

  get duration() {
    if (this.fullJob.source == null || this.fullJob.source.PrintDuration == null) {
      return '—';
    }

    return `${this.fullJob.source.PrintDuration.totalHours.toFixed(0)} hours ${
      this.fullJob.source.PrintDuration.minutes
    } minutes`;
  }

  get order() {
    if (this.fullJob.job.Order == null) {
      return en.current.growFirst();
    }

    return this.fullJob.job.Order.toString();
  }

  get isTransferringFile() {
    return (
      this.fullJob.printer.PrinterState == PrinterState.BUSY &&
      this.fullJob.job.StartFileTransferProgress > 0 &&
      this.fullJob.job.StartFileTransferProgress < 1
    );
  }

  get status() {
    if (this.isTransferringFile) {
      return `Uploading file (${(this.fullJob.job.StartFileTransferProgress * 100).toFixed(2)}%)`;
    }

    switch (this.fullJob.job.JobState) {
      case JobState.NOT_STARTED:
        return this.translations.notStarted;
      case JobState.START:
      case JobState.RESUME:
        return `${this.translations.printing} (${this.fullJob.job.Progress?.toFixed(2)}%)`;
      case JobState.CANCEL:
        return `${this.translations.cancelled} (${this.fullJob.job.Progress?.toFixed(2)}%)`;
      case JobState.PAUSE:
        return `${this.translations.paused} (${this.fullJob.job.Progress?.toFixed(2)}%)`;
      case JobState.FINISH:
        return `${this.translations.finished} (100%)`;
      default:
        return this.translations.notStarted;
    }
  }

  get ribbonColor() {
    if (this.isInProgress) {
      return '#00F654';
    }

    return '#A4A4A4';
  }

  get progressUnderlayStyle() {
    if (this.isTransferringFile) {
      return {
        width: (this.fullJob.job.StartFileTransferProgress * 100).toFixed(2) + '%',
      };
    } else if (this.isInProgress && this.fullJob.job.Progress != null) {
      return {
        width: this.fullJob.job.Progress.toFixed(2) + '%',
      };
    }

    return {
      width: '0%',
    };
  }

  async startJob() {
    this.isLoading = true;
    await JobModule.ForceStart(this.fullJob.job.Id);
    this.isLoading = false;
  }

  async resumeJob() {
    this.isLoading = true;
    await JobModule.UpdateJobStatus([this.fullJob.job, JobState.RESUME]);
    this.isLoading = false;
  }

  async pauseJob() {
    this.isLoading = true;
    await JobModule.UpdateJobStatus([this.fullJob.job, JobState.PAUSE]);
    this.isLoading = false;
  }

  async stopJob() {
    const userConfirmation = await StopJobSure(
      Result.Yes | Result.No,
      'Cancel job',
      'Are you sure you want to cancel this job?',
    );

    if (userConfirmation == Result.No || userConfirmation == Result.Cancel) {
      return;
    }

    this.isLoading = true;
    await JobModule.UpdateJobStatus([this.fullJob.job, JobState.CANCEL]);
    this.isLoading = false;
  }

  @Emit('clicked')
  Clicked() {}

  get translations() {
    return {
      notStarted: en.notStarted.growFirst(),
      printing: en.printing.growFirst(),
      cancelled: en.cancelled.growFirst(),
      paused: en.paused.growFirst(),
      finished: en.finished.growFirst(),
    };
  }
}
</script>

<style lang="scss" scoped>
.job-card {
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;

  .progress-underlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    opacity: 0.25;
    background-color: var(--main-orange);
    transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);
  }

  // todo: replace colors with variables

  background: var(--job-card-background);
  border: var(--job-card-border);
  border-radius: 6px;

  &.selected {
    border: var(--job-card-border-selected);
  }

  &:not(.selected) {
    cursor: pointer;
    user-select: none;
  }

  &:hover {
    background: var(--job-card-background-hover);
  }

  .status-ribbon {
    width: 8px;
    min-width: 8px;
    max-width: 8px;
    position: relative;
    height: 100%;
  }

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;

    .header {
      display: flex;
      justify-content: space-between;

      .source-name-container {
        text-overflow: ellipsis;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        flex: 1;

        .source-name {
          font-size: 16px;
          color: var(--job-card-source-name-text);
          text-align: left;
          text-overflow: ellipsis;
          display: block;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .controls {
        display: flex;
        justify-content: flex-end;
        font-size: 14px;

        .buttons {
          display: flex;
          align-items: center;
          gap: 8px;
          color: var(--job-card-control-color);

          i {
            cursor: pointer;

            &:hover {
              opacity: 0.75;
            }
          }
        }

        .status {
          display: flex;
          align-items: center;
          color: var(--job-card-status-color);
        }
      }
    }

    .info {
      align-items: flex-start;
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      .info-item {
        width: 50%;
      }
    }
  }
}
</style>
