//#region SERVICES

export class UriServices {
  static IDENTITY = 'auth';
  static MONOLITH = 'monolith';
  static CONNECTOR = 'connector';
  static BILLING = 'billing';
}

//#endregion

//#region HUBS

export class UriHubs {
  static HUBS = 'hubs';
  static IDENTITY_HUB = 'identity';
  static PRINTING_HUB = 'printing';
  static MONOLITH_HUB = 'monolith';
  static CONNECTOR_HUB = 'connector';
  static BILLING_HUB = 'billing';
}

//#endregion

//#region CONTROLLER

export class UriController {
  static USER = 'user';
  static COMPANY = 'company';
  static JOB = 'job';
  static JOB_ARCHIEVE = 'job-archieve';
  static SOURCE = 'source';
  static SOURCE_GROUP = 'source-group';
  static PRINTER = 'printer';
  static CONNECTION = 'connection';
  static LOG = 'log';
  static ERROR = 'error';
  static TELEMETRY = 'telemetry';
  static CONNECTOR_PRINTER_MODEL = 'printer-model';
  static DATA = 'data';
  static STAT = 'stat';
  static GLOBAL_NOTIFICATIONS = 'global-notifications';

  // Billing
  static ACCOUNT = 'account';
  static PAYMENT_METHOD = 'payment-method';
  static INVOICE = 'invoice';
  static PURCHASED_ITEM = 'purchased-item';
  static PRODUCT = 'product';
  static PRODUCT_PRICE = 'product-price';
  static PURCHASED_SUBSCRIPTION = 'purchased-subscription';
  static PENDING_SUBSCRIPTION_UPDATE = 'pending-subscription-update';
  static TAX_RATE = 'tax-rate';
}

//#endregion

//requests

//#region USER

export class UriUser {
  static LOGIN = 'login';
  static LOGOUT = 'logout';
  static FORGOT_PASSWORD = 'forgot-password';
  static FORGOT_PASSWORD_NEW = 'forgot-password-new';
  static FORGOT_PASSWORD_CHECK = 'forgot-password-check-code';
  static UPDATE_TOKEN = 'update-token';
  static READ_BY_ID_DEEP = 'read-by-id-deep';
  static READ_BY_IDS_DEEP = 'read-by-ids-deep';
  static READ_ADMINS = 'read-admins';
  static READ_ACCOUNT_OWNER = 'read-account-owner';
  static UPDATE_MY_EMAIL = 'update-my-email';
  static UPDATE_EMAIL = 'update-email';
  static UPDATE_INFO = 'update-info';
  static READ_ALL_MY_SESSIONS = 'read-all-my-sessions';
  static TERMINATE_SESSION = 'terminate-session';
  static TERMINATE_SESSIONS = 'terminate-sessions';
  static UPDATE_MY_PASSWORD = 'update-my-password';
  static UPDATE_PASSWORD = 'update-password';
  static READ_ALL_ROLES = 'role-all';
  static READ_MORE_USERS_IN_MY_COMPANY = 'read-more-users-in-my-company';
  static READ_BY_COMPANY_ID_PAGED = 'read-by-company-id-paged';
  static DELETE_USER = 'delete-user';
  static SIGNUP = 'sign-up';
  static ADD_ROLE = 'add-role';
  static DELETE_ROLE = 'delete-role';
  static CHANGE_BILLING_ACCOUNT_OWNER = 'change-billing-account-owner';
}

//#endregion

//#region COMPANY

export class UriCompany {
  static READ_MY_COMPANY_BY_ID_NOT_DEEP = 'read-my-company-by-id-not-deep';
  static UPDATE_COMPANY = 'update-company';
  static READ_ALL = 'read-all';
  static READ_COUNTS = 'read-counts';
  static CREATE_NEW_COMPANY = 'create-new-company';
  static DELETE = 'delete';
}

//#endregion

//#region PRINTER

export class UriPrinter {
  static READ_ALL_COMPANY_PRINTERS = 'read-all-company-printers';
  static READ_BY_IDS = 'read-by-ids';
  static READ_BY_ID = 'read-by-id';
  static READ_MODEL_ALL = 'read-model-all';
  static READ_PAGED = 'read-paged';
  static CREATE = 'create';
  static DELETE = 'delete';
  static UPDATE_HARD = 'update-hard';
  static UPDATE_NAMING = 'update-naming';
}

//#endregion

//#region JOB

export class UriJob {
  static READ_MORE_MY_JOBS = 'read-more-my-jobs';
  static READ_MORE_USER_JOBS = 'read-more-user-jobs';
  static READ_USER_JOB_COUNTS = 'read-user-job-counts';
  static READ_SOURCE_JOBS = 'read-by-id-source';
  static READ_PRINTER_JOBS = 'read-by-id-printer';
  static READ_MORE = 'read-more-jobs';
  static READ_ADJACENT = 'read-adjacent';
  static READ_BY_ID = 'read-by-id';
  static READ_ACTIVE_JOB = 'read-active-job';
  static CREATE = 'create';
  static DELETE = 'delete';
  static UPDATE_STATUS = 'update-status';
  static UPDATE_ORDER = 'update-order';
  static FORCE_START = 'force-start';
  static READ_USERS_BY_JOB_COUNT_PAGED = 'read-users-by-job-count-paged';
}

//#endregion

//#region JOB ARCHIEVE

export class UriJobArchieve {
  static READ_BY_ID = 'read-by-id';
  static READ_BY_USER_ID = 'read-by-user-id';
  static READ_BY_PRINTER_ID = 'read-by-printer-id';
  static READ_BY_SOURCE_ID = 'read-by-source-id';
  static READ_BY_MORE = 'read-more';
  static READ_USER_COUNTS = 'read-user-counts';
  static READ_MATERIAL_CONSUMPTIONS_BY_ARCHIVE_ID = 'read-material-consumptions-by-archive-id';
}

//#endregion

//#region SOURCE

export class UriSource {
  static READ_ALL_COMPANY_SOURCES = 'read-all-company-sources';
  static READ_SOURCES_BY_IDS = 'read-sources-by-ids';
  static READ_MORE = 'read-more';
  static READ_MORE_BY_GROUP_ID = 'read-by-group-id';
  static READ_BY_ID = 'read-by-id';
  static CREATE = 'create';
  static DELETE = 'delete';
  static UPDATE_CODE = 'update-code';
  static UPDATE_PROJECT = 'update-project';
  static UPDATE_CATEGORY = 'update-category';
  static SIZE_LIMIT = 'size-limit';
  static TOTAL_SIZE = 'total-size';
  static DOWNLOAD_G_CODE = 'download-g-code';
  static DOWNLOAD_PROJECT = 'download-project';
}

//#endregion

//#region SOURCE GROUP

export class UriJobSourcesGroup {
  static READ_BY_IDS = 'read-by-ids';
  static READ_ALL = 'read-all';
  static CREATE = 'create';
  static DELETE = 'delete';
  static UPDATE = 'update';
}

//#endregion

//#region USER STAT

export class UriUserStat {
  static READ_BY_USER_ID = 'read-user-stat-by-user-id';
  static READ_BY_USER_IDS = 'read-user-stat-by-user-ids';
}

//#endregion

//#region COMPANY STAT

export class UriCompanyStat {
  static READ_COMPANY_STAT_BY_ID = 'read-company-stat-by-company-id';
}

//#endregion

//#region PRINTER STAT

export class UriPrinterStat {
  static READ_PRINTER_STAT_BY_PRINTER_ID = 'read-printer-stat-by-printer-id';
}

//#endregion

//#region MATERIAL CONSUMPTION PRINTER STAT

export class UriMaterialConsumptionPrinterStat {
  static READ_BY_PRINTER_ID = 'read-material-consumption-printer-stat-by-printer-id';
}

//#endregion

//#region MATERIAL CONSUMPTION COMPANY STAT

export class UriMaterialConsumptionCompanyStat {
  static READ_BY_COMPANY_ID = 'read-material-consumption-company-stat-by-company-id';
}

//#endregion

//#region TOTAL PRINT TIME BY WEEKDAY STAT

export class UriTotalPrintTimeByWeekdaystat {
  static READ_BY_COMPANY_ID = 'read-total-print-time-by-week-day-by-company-id';
}

//#endregion

//#region CONNECTION

export class UriConnection {
  static CREATE = 'create';
  static EXECUTE_COMMAND = 'execute-command';
  static READ_CONNECTION_TYPES = 'read-connection-types';
  static READ_BY_CLIENT_ID = 'read-by-client-id';
  static UPDATE = 'update';
}

//#endregion

//#region LOG

export class UriLog {
  static REQUEST_LOG_CONFIGURATION = 'request-log-configuration';
  static UPLOAD_LOG_CONFIGURATION = 'upload-log-configuration';
  static GET_LOGS = 'get-logs';
  static DOWNLOAD_LOGS = 'download-logs';
}

//#endregion

//#region ERRORS
export class UriError {
  static READ_BY_PRINTER = 'read-by-printer';
  static READ_BY_PRINTER_PAGED = 'read-by-printer-paged';
  static ACKNOWLEDGE = 'acknowledge';
  static ACKNOWLEDGE_ALL_FOR_PRINTER = 'acknowledge-all-for-printer';
  static READ_UNACKNOWLEDGED_COUNT_BY_PRINTER = 'read-unacknowledged-count-by-printer';
}
//#endregion

//#region TELEMETRY
export class UriTelemetry {
  static READ_BY_PRINTER_DATE = 'read-by-printer-date';
}
//#endregion

//#region CONNECTOR PRINTER MODEL
export class UriConnectorPrinterModel {
  static READ_BY_ID = 'read-by-id';
  static READ_ALL = 'read-all';
}
//#endregion

//#region GLOBAL NOTIFICATIONS
export class UriGlobalNotifications {
  static READ_ALL = 'read-all';
  static READ_ALL_RELEVANT = 'read-all-relevant';
  static CREATE = 'create';
  static UPDATE = 'update';
  static DELETE = 'delete';
}
//#endregion

//#region BILLING
export class UriAccount {
  static READ_MY_ACCOUNT = 'read-my-account';
  static READ_BY_ID = 'read-by-id';
  static UPDATE = 'update';
}

export class UriPaymentMethod {
  static READ_ALL_BY_ACCOUNT_ID = 'read-all-by-account-id';
  static PREPARE_CREATE = 'prepare-create';
  static MAKE_DEFAULT = 'make-default';
  static DELETE = 'delete';
}

export class UriInvoice {
  static READ_ALL_BY_ACCOUNT_ID = 'read-all-by-account-id';
  static READ_PAGED_BY_ACCOUNT_ID = 'read-paged-by-account-id';
  static READ_PAYMENT_INTENT_SECRET = 'read-payment-intent-secret';
}

export class UriPurchasedItem {
  static READ_ALL_BY_ACCOUNT_ID = 'read-all-by-account-id';
  static ADMIN_PURCHASE_ITEMS = 'admin-purchase-items';
  static PURCHASE_ITEMS = 'purchase-items';
  static ATTACH = 'attach';
  static DETACH = 'detach';
  static ACTIVATE_OFFLINE = 'activate-offline';
  static DEACTIVATE_OFFLINE = 'deactivate-offline';
  static DOWNLOAD_OFFLINE_ACTIVATION_RESPONSE = 'download-offline-activation-response';
}

export class UriProduct {
  static READ_ALL = 'read-all';
}

export class UriProductPrice {
  static READ_ALL = 'read-all';
}

export class UriPurchasedSubscription {
  static READ_ALL_BY_ACCOUNT_ID = 'read-all-by-account-id';
  static SUBSCRIBE = 'subscribe';
  static CANCEL = 'cancel';
  static PREVIEW_PRICE_CHANGES = 'preview-changes';
  static UPDATE = 'update';
  static CANCEL_PENDING_UPDATE = 'cancel-pending-update';
  static SCHEDULE_CANCELLATION = 'schedule-cancellation';
  static REACTIVATE = 'reactivate';
}

export class UriPendingSubscriptionUpdate {
  static READ_ALL = 'read-all-by-account-id';
}

export class UriTaxRate {
  static READ_BY_ACCOUNT_ID = 'read-by-account-id';
}
//#endregion
