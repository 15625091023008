import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import { Guid } from 'guid-typescript';

import store from '@/store';
import { CompanyRouteTab, JobsRouteTab } from '@/router/routes';
import { ErrorType } from '@/models/Entities';
import { TelemetryType } from '@/models/enums/TelemetryType';
import { CellRange } from '@/models/util/FlowLayout';

import { PrinterPanelControlType } from '@/util/ComponentHelper';
import { TypeHelper } from '@/util/TypeHelper';
import { isPortrait } from '@/util/utils';

export enum DeliveryVersions {
  Local,
  Cloud,
}

const THEME_SETTING_LOCAL_STORAGE_KEY = 'ThemeSetting';
export enum Theme {
  Regular,
  Dark,
}

function GetThemeClassName(theme: Theme): string {
  if (theme === Theme.Dark) {
    return 'dark-theme';
  } else if (theme === Theme.Regular) {
    return 'regular-theme';
  }

  return 'unknown-theme';
}

const CURRENT_VERSION: string = '0.0.14';
const CURRENT_DELIVERY_VERSION: DeliveryVersions = DeliveryVersions.Cloud;
const APP_NAME: string = 'Aura';

const JOBS_VIEW_STATE_LOCAL_STORAGE_KEY = 'JobsViewState';
const COMPANY_VIEW_STATE_LOCAL_STORAGE_KEY = 'CompanyViewState';

export class CompanyShopTabViewState {}

export class CompanyProductsTabViewState {
  auraKeysCollapsed!: boolean;
  addonsCollapsed!: boolean;

  constructor(auraKeysCollapsed: boolean = false, addonsCollapsed: boolean = false) {
    this.auraKeysCollapsed = auraKeysCollapsed;
    this.addonsCollapsed = addonsCollapsed;
  }
}
export class CompanyInfoTabViewState {
  infoCollapsed!: boolean;
  usersCollapsed!: boolean;
  adminsCollapsed!: boolean;

  constructor(infoCollapsed: boolean = false, usersCollapsed: boolean = false, adminsCollapsed: boolean = false) {
    this.infoCollapsed = infoCollapsed;
    this.usersCollapsed = usersCollapsed;
    this.adminsCollapsed = adminsCollapsed;
  }
}
export class CompanyBillingTabViewState {
  accountInfoCollapsed!: boolean;
  accountAdminsCollapsed!: boolean;
  paymentMethodsCollapsed!: boolean;
  balanceCollapsed!: boolean;
  invoicesCollapsed!: boolean;

  constructor(
    accountInfoCollapsed: boolean = false,
    accountAdminsCollapsed: boolean = false,
    paymentMethodsCollapsed: boolean = false,
    balanceCollapsed: boolean = false,
    invoicesCollapsed: boolean = false,
  ) {
    this.accountInfoCollapsed = accountInfoCollapsed;
    this.accountAdminsCollapsed = accountAdminsCollapsed;
    this.paymentMethodsCollapsed = paymentMethodsCollapsed;
    this.balanceCollapsed = balanceCollapsed;
    this.invoicesCollapsed = invoicesCollapsed;
  }
}

export class CompanyViewState {
  selectedTab: CompanyRouteTab = CompanyRouteTab.INFO;

  shopTabViewState: CompanyShopTabViewState = new CompanyShopTabViewState();
  productsTabViewState: CompanyProductsTabViewState = new CompanyProductsTabViewState();
  infoTabViewState: CompanyInfoTabViewState = new CompanyInfoTabViewState();
  billingTabViewState: CompanyBillingTabViewState = new CompanyBillingTabViewState();
}

export class TimelineViewState {
  dateFrom!: number | null;
  dateTo!: number | null;
  timeLocked!: boolean;
  verticalOffset!: number;

  constructor(
    dateFrom: number | null = null,
    dateTo: number | null = null,
    timeLocked: boolean = false,
    verticalOffset: number = 5,
  ) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.timeLocked = timeLocked;
    this.verticalOffset = verticalOffset;
  }
}

export class JobCurrentTabViewState {
  timelineHeight: number | null = null;
  hiddenPrinterIds: string[] = [];
  timelineViewState: TimelineViewState = new TimelineViewState();
}

export class JobArchiveTabViewState {
  archiveJobsCollapsed: boolean;

  constructor(archiveJobsCollapsed: boolean = false) {
    this.archiveJobsCollapsed = archiveJobsCollapsed;
  }
}

export class JobsViewState {
  selectedTab: JobsRouteTab = JobsRouteTab.CURRENT;

  currentTabViewState: JobCurrentTabViewState = new JobCurrentTabViewState();
  archiveTabViewState: JobArchiveTabViewState = new JobArchiveTabViewState();
}

const USER_INFO_VIEW_STATE_LOCAL_STORAGE_KEY = 'UserInfoViewState';

export class UserInfoViewState {
  infoCollapsed!: boolean;
  statsCollapsed!: boolean;
  activeDevicesCollapsed!: boolean;
  currentJobsCollapsed!: boolean;
  archiveJobsCollapsed!: boolean;

  constructor(
    infoCollapsed: boolean = false,
    statsCollapsed: boolean = false,
    activeDevicesCollapsed: boolean = false,
    currentJobsCollapsed: boolean = false,
    archiveJobsCollapsed: boolean = false,
  ) {
    this.infoCollapsed = infoCollapsed;
    this.statsCollapsed = statsCollapsed;
    this.activeDevicesCollapsed = activeDevicesCollapsed;
    this.currentJobsCollapsed = currentJobsCollapsed;
    this.archiveJobsCollapsed = archiveJobsCollapsed;
  }
}

const FILE_LIBRARY_VIEW_STATE_LOCAL_STORAGE_KEY = 'FileLibraryViewState';

export class FileLibraryViewState {
  categoriesCollapsed!: boolean;
  filesCollapsed!: boolean;

  constructor(categoriesCollapsed: boolean = false, filesCollapsed: boolean = false) {
    this.categoriesCollapsed = categoriesCollapsed;
    this.filesCollapsed = filesCollapsed;
  }
}

const SOURCE_VIEWER_VIEW_STATE_LOCAL_STORAGE_KEY = 'SourceViewerViewState';

export class SourceViewerViewState {
  materialConsumptionsCollapsed!: boolean;
  printingHistoryCollapsed!: boolean;
  downloadsCollapsed!: boolean;

  constructor(
    materialConsumptionsCollapsed: boolean = false,
    printingHistoryCollapsed: boolean = false,
    downloadsCollapsed: boolean = false,
  ) {
    this.materialConsumptionsCollapsed = materialConsumptionsCollapsed;
    this.printingHistoryCollapsed = printingHistoryCollapsed;
    this.downloadsCollapsed = downloadsCollapsed;
  }
}

export interface FlowLayoutChildViewState {
  id: string;
  cellRange: CellRange;
}

const MACHINES_VIEW_STATE_LOCAL_STORAGE_KEY = 'MachinesViewState';

export class MachinesViewState {
  flowLayoutData: FlowLayoutChildViewState[];
  activatedPrinterControls: PrinterPanelControlType[];
  selectedMachineId: string | null;

  constructor() {
    this.flowLayoutData = [];
    this.activatedPrinterControls = [];
    this.selectedMachineId = null;

    this.InitializeDefaultFlowLayout();
  }

  InitializeDefaultFlowLayout() {
    this.activatedPrinterControls = [];
    this.flowLayoutData = [];

    this.activatedPrinterControls.push(PrinterPanelControlType.ArchiveJobs);
    this.activatedPrinterControls.push(PrinterPanelControlType.CurrentJobs);
    this.activatedPrinterControls.push(PrinterPanelControlType.ErrorLog);
    this.activatedPrinterControls.push(PrinterPanelControlType.Telemetry);
    this.activatedPrinterControls.push(PrinterPanelControlType.Terminal);

    if (isPortrait() && window.innerHeight >= 1280) {
      this.flowLayoutData.push(
        {
          id: 'CurrentJobs',
          cellRange: CellRange.FromPointCoordinates(0, 0, 19, 1),
        },
        {
          id: 'ArchiveJobs',
          cellRange: CellRange.FromPointCoordinates(0, 2, 19, 3),
        },
        {
          id: 'Telemetry',
          cellRange: CellRange.FromPointCoordinates(0, 4, 19, 6),
        },
        {
          id: 'Terminal',
          cellRange: CellRange.FromPointCoordinates(0, 7, 19, 8),
        },
        {
          id: 'ErrorLog',
          cellRange: CellRange.FromPointCoordinates(0, 9, 19, 11),
        },
      );
    } else {
      this.flowLayoutData.push(
        {
          id: 'CurrentJobs',
          cellRange: CellRange.FromPointCoordinates(0, 0, 10, 2),
        },
        {
          id: 'ArchiveJobs',
          cellRange: CellRange.FromPointCoordinates(11, 0, 19, 2),
        },
        {
          id: 'ErrorLog',
          cellRange: CellRange.FromPointCoordinates(0, 8, 19, 11),
        },
        {
          id: 'Telemetry',
          cellRange: CellRange.FromPointCoordinates(0, 3, 13, 7),
        },
        {
          id: 'Terminal',
          cellRange: CellRange.FromPointCoordinates(14, 3, 19, 7),
        },
      );
    }
  }
}

const MACHINE_TELEMETRY_VIEW_STATE_LOCAL_STORAGE_KEY = 'MachineTelemetryViewState';

export class MachineTelemetryViewState {
  showFiltersPanel: boolean;
  showLegendPanel: boolean;
  selectedTelemetryTypes: TelemetryType[];
  hiddenTelemetryLineKeys: string[];

  constructor() {
    this.showFiltersPanel = true;
    this.showLegendPanel = true;
    this.selectedTelemetryTypes = [TelemetryType.BuildPlateTemperature, TelemetryType.ChamberTemperature];
    this.hiddenTelemetryLineKeys = [];
  }
}

const MACHINE_ERRORS_VIEW_STATE_LOCAL_STORAGE_KEY = 'MachineErrorsViewState';

export class MachineErrorsViewState {
  activatedErrorTypes: ErrorType[];
  groupSimilarErrors: boolean;
  showOnlyUnacknowledged: boolean;

  constructor() {
    this.activatedErrorTypes = [];
    this.groupSimilarErrors = true;
    this.showOnlyUnacknowledged = false;
  }
}

// Todo: combine all states and UI related data into one class
@Module({ dynamic: true, name: 'globalData', store: store })
export default class globalDataModule extends VuexModule {
  private themeSetting: Theme = Theme.Regular;
  get ThemeSetting(): Theme {
    return this.themeSetting;
  }

  private companyViewState: CompanyViewState = new CompanyViewState();
  get CompanyViewState() {
    return this.companyViewState;
  }

  private jobsViewState: JobsViewState = new JobsViewState();
  get JobsViewState() {
    return this.jobsViewState;
  }

  private userInfoViewState: UserInfoViewState = new UserInfoViewState();
  get UserInfoViewState() {
    return this.userInfoViewState;
  }

  private fileLibraryViewState: FileLibraryViewState = new FileLibraryViewState();
  get FileLibraryViewState() {
    return this.fileLibraryViewState;
  }

  private sourceViewerViewState: SourceViewerViewState = new SourceViewerViewState();
  get SourceViewerViewState() {
    return this.sourceViewerViewState;
  }

  private machinesViewState: MachinesViewState = new MachinesViewState();
  get MachinesViewState() {
    return this.machinesViewState;
  }

  private machineTelemetryViewState: MachineTelemetryViewState = new MachineTelemetryViewState();
  get MachineTelemetryViewState() {
    return this.machineTelemetryViewState;
  }

  private machineErrorsViewState: MachineErrorsViewState = new MachineErrorsViewState();
  get MachineErrorsViewState() {
    return this.machineErrorsViewState;
  }

  private version: string = CURRENT_VERSION;
  get Version(): string {
    return this.version;
  }

  private deliveryVersion: DeliveryVersions = CURRENT_DELIVERY_VERSION;
  get DeliveryVersion(): DeliveryVersions {
    return this.deliveryVersion;
  }

  private appName: string = APP_NAME;
  get AppName(): string {
    return this.appName;
  }

  private isLoadingBillingData: boolean = false;
  get IsLoadingBillingData() {
    return this.isLoadingBillingData;
  }

  @Mutation
  SetIsLoadingBillingData(newVal: boolean) {
    this.isLoadingBillingData = newVal;
  }

  @Mutation
  SetThemeSetting(newVal: Theme) {
    // Remove previous theme
    window.document.body.classList.remove(GetThemeClassName(this.themeSetting));

    this.themeSetting = newVal;

    localStorage.setItem(THEME_SETTING_LOCAL_STORAGE_KEY, JSON.stringify(this.themeSetting));

    // Add new theme
    window.document.body.classList.add(GetThemeClassName(this.themeSetting));
  }

  @Mutation
  SetCompanyViewState(newVal: CompanyViewState) {
    this.companyViewState = newVal;

    localStorage.setItem(COMPANY_VIEW_STATE_LOCAL_STORAGE_KEY, JSON.stringify(this.companyViewState));
  }

  @Mutation
  SetJobsViewState(newVal: JobsViewState) {
    this.jobsViewState = newVal;

    localStorage.setItem(JOBS_VIEW_STATE_LOCAL_STORAGE_KEY, JSON.stringify(this.jobsViewState));
  }

  @Mutation
  SetUserInfoViewState(newVal: UserInfoViewState) {
    this.userInfoViewState = newVal;

    localStorage.setItem(USER_INFO_VIEW_STATE_LOCAL_STORAGE_KEY, JSON.stringify(this.userInfoViewState));
  }

  @Mutation
  SetFileLibraryViewState(newVal: FileLibraryViewState) {
    this.fileLibraryViewState = newVal;

    localStorage.setItem(FILE_LIBRARY_VIEW_STATE_LOCAL_STORAGE_KEY, JSON.stringify(this.fileLibraryViewState));
  }

  @Mutation
  SetSourceViewerViewState(newVal: SourceViewerViewState) {
    this.sourceViewerViewState = newVal;

    localStorage.setItem(SOURCE_VIEWER_VIEW_STATE_LOCAL_STORAGE_KEY, JSON.stringify(this.sourceViewerViewState));
  }

  @Mutation
  SetMachinesViewState(newVal: MachinesViewState) {
    this.machinesViewState = newVal;

    localStorage.setItem(MACHINES_VIEW_STATE_LOCAL_STORAGE_KEY, JSON.stringify(this.machinesViewState));
  }

  @Mutation
  SetMachineTelemetryViewState(newVal: MachineTelemetryViewState) {
    this.machineTelemetryViewState = newVal;

    localStorage.setItem(
      MACHINE_TELEMETRY_VIEW_STATE_LOCAL_STORAGE_KEY,
      JSON.stringify(this.machineTelemetryViewState),
    );
  }

  @Mutation
  SetMachineErrorsViewState(newVal: MachineErrorsViewState) {
    this.machineErrorsViewState = newVal;

    localStorage.setItem(MACHINE_ERRORS_VIEW_STATE_LOCAL_STORAGE_KEY, JSON.stringify(this.machineErrorsViewState));
  }

  @Action({ rawError: true })
  async Initialize() {
    // Load user settings from local storage
    await this.InitializeThemeSetting();
    await this.InitializeCompanyViewState();
    await this.InitializeJobsViewState();
    await this.InitializeUserInfoViewState();
    await this.InitializeFileLibraryViewState();
    await this.InitializeSourceViewerViewState();
    await this.InitializeMachinesViewState();
    await this.InitializeMachineTelemetryViewState();
    await this.InitializeMachineErrorsViewState();
  }

  //#region INITIALIZERS
  @Action({ rawError: true })
  private async InitializeThemeSetting() {
    const themeSettingStr = localStorage.getItem(THEME_SETTING_LOCAL_STORAGE_KEY);

    if (themeSettingStr != null) {
      const themeSettingFromLS = JSON.parse(themeSettingStr) as Theme;
      this.SetThemeSetting(themeSettingFromLS);
    }
  }

  @Action({ rawError: true })
  private async InitializeCompanyViewState() {
    const stateStr = localStorage.getItem(COMPANY_VIEW_STATE_LOCAL_STORAGE_KEY);

    if (stateStr != null) {
      // Todo: replace code below with something better

      // The idea is to check the validity of JSON object that's currently
      // stored in Local Storage by comparing all the fields, their types
      // If any of the fields are not present or some field has an incorrect
      // type, we should revert back to default object
      const state = new CompanyViewState();
      const stateFromLS = JSON.parse(stateStr) as CompanyViewState;

      if (stateFromLS.billingTabViewState !== undefined) {
        state.billingTabViewState = stateFromLS.billingTabViewState;
      }

      if (stateFromLS.infoTabViewState !== undefined) {
        state.infoTabViewState = stateFromLS.infoTabViewState;
      }

      if (stateFromLS.productsTabViewState !== undefined) {
        state.productsTabViewState = stateFromLS.productsTabViewState;
      }

      if (stateFromLS.shopTabViewState !== undefined) {
        state.shopTabViewState = stateFromLS.shopTabViewState;
      }

      if (stateFromLS.selectedTab !== undefined) {
        state.selectedTab = stateFromLS.selectedTab;
      }

      this.SetCompanyViewState(state);
    } else {
      this.SetCompanyViewState(this.companyViewState);
    }
  }

  @Action({ rawError: true })
  private async InitializeJobsViewState() {
    const stateStr = localStorage.getItem(JOBS_VIEW_STATE_LOCAL_STORAGE_KEY);

    if (stateStr != null) {
      const state = JSON.parse(stateStr) as JobsViewState;
      this.SetJobsViewState(state);
    } else {
      this.SetJobsViewState(this.jobsViewState);
    }
  }

  @Action({ rawError: true })
  private async InitializeUserInfoViewState() {
    const stateStr = localStorage.getItem(USER_INFO_VIEW_STATE_LOCAL_STORAGE_KEY);

    if (stateStr != null) {
      const state = JSON.parse(stateStr) as UserInfoViewState;
      this.SetUserInfoViewState(state);
    } else {
      this.SetUserInfoViewState(this.userInfoViewState);
    }
  }

  @Action({ rawError: true })
  private async InitializeFileLibraryViewState() {
    const stateStr = localStorage.getItem(FILE_LIBRARY_VIEW_STATE_LOCAL_STORAGE_KEY);

    if (stateStr != null) {
      const state = JSON.parse(stateStr) as FileLibraryViewState;
      this.SetFileLibraryViewState(state);
    } else {
      this.SetFileLibraryViewState(this.fileLibraryViewState);
    }
  }

  @Action({ rawError: true })
  private async InitializeSourceViewerViewState() {
    const stateStr = localStorage.getItem(SOURCE_VIEWER_VIEW_STATE_LOCAL_STORAGE_KEY);

    if (stateStr != null) {
      const state = JSON.parse(stateStr) as SourceViewerViewState;
      this.SetSourceViewerViewState(state);
    } else {
      this.SetSourceViewerViewState(this.sourceViewerViewState);
    }
  }

  @Action({ rawError: true })
  private async InitializeMachinesViewState() {
    const stateStr = localStorage.getItem(MACHINES_VIEW_STATE_LOCAL_STORAGE_KEY);

    if (stateStr != null) {
      const state = new MachinesViewState();
      Object.assign(state, JSON.parse(stateStr));

      for (let i = 0; i < state.flowLayoutData.length; ++i) {
        state.flowLayoutData[i].cellRange = CellRange.DeepCopyFrom(state.flowLayoutData[i].cellRange);
      }
      this.SetMachinesViewState(state);
    } else {
      this.SetMachinesViewState(this.machinesViewState);
    }
  }

  @Action({ rawError: true })
  public async ResetMachinesViewFlowLayoutState() {
    this.machinesViewState.InitializeDefaultFlowLayout();
    this.SetMachinesViewState(this.machinesViewState);
  }

  @Action({ rawError: true })
  async InitializeMachineTelemetryViewState() {
    const stateStr = localStorage.getItem(MACHINE_TELEMETRY_VIEW_STATE_LOCAL_STORAGE_KEY);

    if (stateStr != null) {
      const state = JSON.parse(stateStr) as MachineTelemetryViewState;
      this.SetMachineTelemetryViewState(state);
    } else {
      this.SetMachineTelemetryViewState(this.machineTelemetryViewState);
    }
  }

  @Action({ rawError: true })
  async InitializeMachineErrorsViewState() {
    const stateStr = localStorage.getItem(MACHINE_ERRORS_VIEW_STATE_LOCAL_STORAGE_KEY);

    if (stateStr != null) {
      const state = JSON.parse(stateStr) as MachineErrorsViewState;
      this.SetMachineErrorsViewState(state);
    } else {
      this.SetMachineErrorsViewState(this.machineErrorsViewState);
    }
  }
  //#endregion

  //#region COMPANY VIEW
  @Action({ rawError: true })
  async ChangeCompanyViewTab(newTab: CompanyRouteTab) {
    const newState = TypeHelper.DeepCopyFrom(this.companyViewState, CompanyViewState);
    newState.selectedTab = newTab;

    this.SetCompanyViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeCompanyViewProductsTabAuraKeysCollapsed(newValue: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.companyViewState, CompanyViewState);
    newState.productsTabViewState.auraKeysCollapsed = newValue;

    this.SetCompanyViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeCompanyViewProductsTabAddonsCollapsed(newValue: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.companyViewState, CompanyViewState);
    newState.productsTabViewState.addonsCollapsed = newValue;

    this.SetCompanyViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeCompanyViewInfoTabInfoCollapsed(newValue: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.companyViewState, CompanyViewState);
    newState.infoTabViewState.infoCollapsed = newValue;

    this.SetCompanyViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeCompanyViewInfoTabUsersCollapsed(newValue: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.companyViewState, CompanyViewState);
    newState.infoTabViewState.usersCollapsed = newValue;

    this.SetCompanyViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeCompanyViewInfoTabAdminsCollapsed(newValue: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.companyViewState, CompanyViewState);
    newState.infoTabViewState.adminsCollapsed = newValue;

    this.SetCompanyViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeCompanyViewBillingTabAccountInfoCollapsed(newValue: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.companyViewState, CompanyViewState);
    newState.billingTabViewState.accountInfoCollapsed = newValue;

    this.SetCompanyViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeCompanyViewBillingTabAccountAdminsCollapsed(newValue: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.companyViewState, CompanyViewState);
    newState.billingTabViewState.accountAdminsCollapsed = newValue;

    this.SetCompanyViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeCompanyViewBillingTabPaymentMethodsCollapsed(newValue: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.companyViewState, CompanyViewState);
    newState.billingTabViewState.paymentMethodsCollapsed = newValue;

    this.SetCompanyViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeCompanyViewBillingTabBalanceCollapsed(newValue: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.companyViewState, CompanyViewState);
    newState.billingTabViewState.balanceCollapsed = newValue;

    this.SetCompanyViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeCompanyViewBillingTabInvoicesCollapsed(newValue: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.companyViewState, CompanyViewState);
    newState.billingTabViewState.invoicesCollapsed = newValue;

    this.SetCompanyViewState(newState);
  }
  //#endregion

  //#region JOBS VIEW
  @Action({ rawError: true })
  async ChangeJobsViewTab(newTab: JobsRouteTab) {
    const newState = TypeHelper.DeepCopyFrom(this.jobsViewState, JobsViewState);
    newState.selectedTab = newTab;

    this.SetJobsViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeJobsViewCurrentTabTimelineHeight(newVal: number) {
    const newState = TypeHelper.DeepCopyFrom(this.jobsViewState, JobsViewState);
    newState.currentTabViewState.timelineHeight = newVal;

    this.SetJobsViewState(newState);
  }

  @Action({ rawError: true })
  async AddHiddenJobsViewTimelinePrinter(printer: Guid) {
    const newState = TypeHelper.DeepCopyFrom(this.jobsViewState, JobsViewState);
    newState.currentTabViewState.hiddenPrinterIds.push(printer.toString());

    this.SetJobsViewState(newState);
  }

  @Action({ rawError: true })
  async RemoveHiddenJobsViewTimelinePrinter(printer: Guid) {
    const newState = TypeHelper.DeepCopyFrom(this.jobsViewState, JobsViewState);
    newState.currentTabViewState.hiddenPrinterIds.delete(printer.toString());

    this.SetJobsViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeJobsViewCurrentTabTimelineDateRange(dateRange: { from: Date; to: Date }) {
    const newState = TypeHelper.DeepCopyFrom(this.jobsViewState, JobsViewState);
    newState.currentTabViewState.timelineViewState.dateFrom = dateRange.from.getTime();
    newState.currentTabViewState.timelineViewState.dateTo = dateRange.to.getTime();

    this.SetJobsViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeJobsViewCurrentTabTimelineVerticalOffset(newVal: number) {
    const newState = TypeHelper.DeepCopyFrom(this.jobsViewState, JobsViewState);
    newState.currentTabViewState.timelineViewState.verticalOffset = newVal;

    this.SetJobsViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeJobsViewCurrentTabTimelineTimeLocked(newVal: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.jobsViewState, JobsViewState);
    newState.currentTabViewState.timelineViewState.timeLocked = newVal;

    this.SetJobsViewState(newState);
  }
  //#endregion

  //#region USER INFO VIEW
  @Action({ rawError: true })
  async ChangeUserInfoViewInfoCollapsed(newVal: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.userInfoViewState, UserInfoViewState);
    newState.infoCollapsed = newVal;

    this.SetUserInfoViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeUserInfoViewStatsCollapsed(newVal: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.userInfoViewState, UserInfoViewState);
    newState.statsCollapsed = newVal;

    this.SetUserInfoViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeUserInfoViewActiveDevicesCollapsed(newVal: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.userInfoViewState, UserInfoViewState);
    newState.activeDevicesCollapsed = newVal;

    this.SetUserInfoViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeUserInfoViewCurrentJobsCollapsed(newVal: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.userInfoViewState, UserInfoViewState);
    newState.currentJobsCollapsed = newVal;

    this.SetUserInfoViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeUserInfoViewArchiveJobsCollapsed(newVal: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.userInfoViewState, UserInfoViewState);
    newState.archiveJobsCollapsed = newVal;

    this.SetUserInfoViewState(newState);
  }
  //#endregion

  //#region FILE LIBRARY VIEW
  @Action({ rawError: true })
  async ChangeFileLibraryViewCategoriesCollapsed(newVal: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.fileLibraryViewState, FileLibraryViewState);
    newState.categoriesCollapsed = newVal;

    this.SetFileLibraryViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeFileLibraryViewFilesCollapsed(newVal: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.fileLibraryViewState, FileLibraryViewState);
    newState.filesCollapsed = newVal;

    this.SetFileLibraryViewState(newState);
  }
  //#endregion

  //#region SOURCE VIEWER VIEW
  @Action({ rawError: true })
  async ChangeSourceViewerMaterialConsumptionsCollapsed(newVal: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.sourceViewerViewState, SourceViewerViewState);
    newState.materialConsumptionsCollapsed = newVal;

    this.SetSourceViewerViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeSourceViewerPrintingHistoryCollapsed(newVal: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.sourceViewerViewState, SourceViewerViewState);
    newState.printingHistoryCollapsed = newVal;

    this.SetSourceViewerViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeSourceViewerDownloadsCollapsed(newVal: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.sourceViewerViewState, SourceViewerViewState);
    newState.downloadsCollapsed = newVal;

    this.SetSourceViewerViewState(newState);
  }
  //#endregion

  //#region MACHINES VIEW
  @Action({ rawError: true })
  async ChangeMachinesViewFlowLayoutChildrenData(newVal: FlowLayoutChildViewState[]) {
    const newState = TypeHelper.DeepCopyFrom(this.machinesViewState, MachinesViewState);
    newState.flowLayoutData = newVal;

    this.SetMachinesViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeMachinesViewActivatedPrinterControls(newVal: PrinterPanelControlType[]) {
    const newState = TypeHelper.DeepCopyFrom(this.machinesViewState, MachinesViewState);
    newState.activatedPrinterControls = newVal;

    this.SetMachinesViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeMachinesViewSelectedMachineId(newId: Guid | null) {
    const newState = TypeHelper.DeepCopyFrom(this.machinesViewState, MachinesViewState);
    newState.selectedMachineId = newId == null ? null : newId.toString();

    this.SetMachinesViewState(newState);
  }
  //#endregion

  //#region MACHINE TELEMETRY
  @Action({ rawError: true })
  async ChangeMachineTelemetryViewStateShowFiltersPanel(newVal: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.machineTelemetryViewState, MachineTelemetryViewState);

    newState.showFiltersPanel = newVal;

    this.SetMachineTelemetryViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeMachineTelemetryViewStateShowLegendPanel(newVal: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.machineTelemetryViewState, MachineTelemetryViewState);

    newState.showLegendPanel = newVal;

    this.SetMachineTelemetryViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeMachineTelemetryViewStateTelemetryTypes(types: TelemetryType[]) {
    const newState = TypeHelper.DeepCopyFrom(this.machineTelemetryViewState, MachineTelemetryViewState);

    newState.selectedTelemetryTypes = types;

    this.SetMachineTelemetryViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeMachineTelemetryViewStateHiddenTelemetryKeys(keys: string[]) {
    const newState = TypeHelper.DeepCopyFrom(this.machineTelemetryViewState, MachineTelemetryViewState);

    newState.hiddenTelemetryLineKeys = keys;

    this.SetMachineTelemetryViewState(newState);
  }
  //#endregion

  //#region MACHINE ERRORS
  @Action({ rawError: true })
  async ChangeMachineErrorsViewStateActivatedErrorTypes(types: ErrorType[]) {
    const newState = TypeHelper.DeepCopyFrom(this.machineErrorsViewState, MachineErrorsViewState);

    newState.activatedErrorTypes = types;

    this.SetMachineErrorsViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeMachineErrorsViewStateGroupSimilarErrors(newVal: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.machineErrorsViewState, MachineErrorsViewState);

    newState.groupSimilarErrors = newVal;

    this.SetMachineErrorsViewState(newState);
  }

  @Action({ rawError: true })
  async ChangeMachineErrorsViewStateShowOnlyUnacknowledged(newVal: boolean) {
    const newState = TypeHelper.DeepCopyFrom(this.machineErrorsViewState, MachineErrorsViewState);

    newState.showOnlyUnacknowledged = newVal;

    this.SetMachineErrorsViewState(newState);
  }
  //#endregion
}

export const GlobalDataModule = getModule(globalDataModule);
