<template>
  <div class="machine-card-container">
    <div :class="['machine-card', isSelected ? 'selected' : '']" data-testid="machineCard" @click="Clicked">
      <div class="progress-underlay" :style="ProgressUnderlayStyle"></div>

      <div class="info">
        <div class="model-name-container">
          <div class="model-badge mr-2">
            <span>{{ PrinterModel }}</span>
          </div>

          <div class="name">
            <span>{{ PrinterName }}</span>
          </div>

          <div v-if="HasErrors" class="errors-summary ml-2">
            <span v-tooltip="{ content: LastError }" class="errors-count"> {{ ErrorCount }} <span> errors</span> </span>
          </div>
        </div>

        <div class="status">
          <span class="mr-1">ID:</span>
          <span class="value">{{ printer.printer.Id.toString() }}</span>
        </div>

        <div class="status">
          <span class="mr-1">{{ StatusCaption }}:</span>
          <span class="value">{{ PrinterStatus }}</span>
        </div>
      </div>

      <div ref="progressInfo" class="progress-info" :style="ProgressInfoStyle">
        <span v-if="IsTransferingFile" class="progress-text"
          >Uploading file ({{ (FileTransferProgress * 100).toFixed(2) }} %)</span
        >
        <div v-else class="job-progress progress-text">
          <span style="color: #878787">Job: </span>
          <div class="g-code-container">
            <span
              data-testid="machineCard.gCode"
              :class="['g-code', JobAvailable ? 'clickable' : '']"
              @click="JobAvailable ? GCodeFileNameClicked() : undefined"
              >{{ GCodeFileName }}</span
            >
          </div>
          <span> ({{ JobProgress }})</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import en from '@/localization/en';
import { FullPrinter } from '@/models/CompositeEntities';
import { Job, PrinterState, Source } from '@/models/Entities';
import { ResponseJobStartFileTransferProgress } from '@/models/responses/ResponseMonolith';
import { Routes } from '@/router/routes';
import { JobModule } from '@/store/modules/jobModule';
import { SourceModule } from '@/store/modules/sourceModule';
import { Guid } from 'guid-typescript';
import { Component, Prop, Vue, Watch, Emit, Ref } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class MachineCard extends Vue {
  @Prop() printer!: FullPrinter;
  @Prop({ default: false }) isSelected!: boolean;

  @Ref('progressInfo') progressInfo!: HTMLDivElement;

  @Watch('CurrentJobId', { immediate: true })
  async OnCurrentJobIdChanged(newVal: Guid | null, oldValue: Guid | null) {
    const sameId = newVal != null && oldValue != null && newVal.toString() == oldValue.toString();

    if (sameId) return;

    this.currentJob = null;
    this.currentJobSource = null;

    await this.LoadCurrentJob();
  }

  //#region STATE
  private _componentId!: Guid;

  private isLoadingCurrentJob = false;
  private currentJob: Job | null = null;
  private currentJobSource: Source | null = null;
  private fileTransferProgress: number = 0;

  private get PrinterModel() {
    if (this.printer.model == null) return '?';

    // Todo: add short name
    return this.printer.model.Name.split(' ')[1];
  }

  private get JobAvailable() {
    return this.currentJob != null;
  }

  private get GCodeFileName() {
    if (this.isLoadingCurrentJob) {
      return 'loading...';
    }

    if (this.currentJob != null && this.currentJob.LocalFilename != null) {
      return this.currentJob.LocalFilename;
    }

    if (this.currentJobSource == null) {
      return 'Not available';
    }

    return this.currentJobSource.CodeFileName;
  }

  private get JobProgress() {
    if (this.currentJob == null || this.currentJob.Progress == null) return '';

    return parseFloat(this.currentJob.Progress.toFixed(2)) + '%';
  }

  private get CurrentJobId() {
    return this.printer.printer.CurrentJobId;
  }

  private get PrinterName() {
    return this.printer.printer.Name;
  }

  private get PrinterStatus() {
    return this.TranslateStatus(this.printer.printer.PrinterState);
  }

  private get HasErrors() {
    return this.printer.printer.ErrorCount > 0;
  }

  private get ErrorCount() {
    return this.printer.printer.ErrorCount;
  }

  private get LastError() {
    return this.printer.printer.LastError;
  }

  private get FileTransferProgress() {
    if (this.currentJob == null) return this.fileTransferProgress;

    return this.currentJob.StartFileTransferProgress;
  }

  private get IsTransferingFile() {
    const fileProgress = this.FileTransferProgress;

    return this.printer.printer.PrinterState == PrinterState.BUSY && fileProgress > 0 && fileProgress < 1;
  }

  private get IsPrintingProgressState() {
    const state = this.printer.printer.PrinterState;

    return (
      state == PrinterState.PRINTING ||
      state == PrinterState.PAUSING ||
      state == PrinterState.PAUSED ||
      state == PrinterState.RESUMING
    );
  }

  private get ProgressUnderlayStyle() {
    if (this.IsTransferingFile) {
      return {
        width: (this.FileTransferProgress * 100).toFixed(2) + '%',
      };
    } else if (this.IsPrintingProgressState && this.currentJob != null && this.currentJob.Progress != null) {
      return {
        width: this.currentJob.Progress.toFixed(2) + '%',
      };
    }

    return {
      width: '0%',
    };
  }

  private get ProgressInfoStyle() {
    if (
      this.IsTransferingFile ||
      (this.IsPrintingProgressState && this.currentJob != null && this.currentJob.Progress != null)
    ) {
      return {
        'max-height': this.progressInfo?.scrollHeight + 'px',
      };
    }

    return {
      'max-height': 0 + 'px',
    };
  }
  //#endregion

  //#region LOGIC
  async LoadCurrentJob() {
    this.isLoadingCurrentJob = true;

    const jobId = this.printer.printer.CurrentJobId;

    console.log('LOADING CURRENT JOB, JOB ID IS ' + jobId?.toString());

    if (jobId != null) {
      this.currentJob = await JobModule.GetJob(jobId);

      if (this.currentJob != null && this.currentJob.SourceId != null) {
        await JobModule.OccupyJobs([[this.currentJob], this._componentId]);

        this.currentJobSource = await SourceModule.GetSource(this.currentJob.SourceId);

        if (this.currentJobSource != null) {
          await SourceModule.OccupySources([[this.currentJobSource], this._componentId]);
        }
      }
    }

    this.isLoadingCurrentJob = false;
  }

  async GCodeFileNameClicked() {
    if (this.currentJob == null) return;

    this.$router.push({
      name: Routes.JOBS,
      query: {
        jobId: this.currentJob.Id.toString(),
      },
    });
  }

  async JobUpdated(job: Job) {
    if (
      job.PrinterId.toString() == this.printer.printer.Id.toString() &&
      (this.currentJob == null || this.currentJob.Id.toString() != job.Id.toString())
    ) {
      if (this.currentJob != null) {
        await JobModule.LooseJobs([[this.currentJob], this._componentId]);
        await JobModule.CollectJobs();
      }

      this.currentJob = job;
      JobModule.AddJobToModule(this.currentJob);
      await JobModule.OccupyJobs([[this.currentJob], this._componentId]);
    }

    if (this.currentJob?.Id.toString() != job.Id.toString()) return;

    this.currentJob = job;
  }

  async JobFileTransferProgressUpdated(res: ResponseJobStartFileTransferProgress) {
    console.log(res);

    if (res.printerId.toString() != this.printer.printer.Id.toString()) {
      return;
    }

    this.fileTransferProgress = res.progress;
  }
  //#endregion

  //#region EVENTS
  @Emit('clicked')
  Clicked() {}
  //#endregion

  //#region HOOKS
  beforeCreate() {
    this._componentId = Guid.create();
  }

  mounted() {
    JobModule.OnJobUpdated.subscribe(this.JobUpdated);
    JobModule.OnJobFileTransferProgressUpdated.subscribe(this.JobFileTransferProgressUpdated);
  }

  async beforeDestroy() {
    JobModule.OnJobUpdated.unsubscribe(this.JobUpdated);
    JobModule.OnJobFileTransferProgressUpdated.unsubscribe(this.JobFileTransferProgressUpdated);

    if (this.currentJob != null) {
      await JobModule.LooseJobs([[this.currentJob], this._componentId]);
      await JobModule.CollectJobs();
    }

    if (this.currentJobSource != null) {
      await SourceModule.LooseSources([[this.currentJobSource], this._componentId]);
      await SourceModule.CollectSources();
    }
  }
  //#endregion

  //#region TRANSLATIONS
  private TranslateStatus(printerState: PrinterState): string {
    switch (printerState) {
      case PrinterState.UNKNOWN:
        return this.UnknownCaption;
      case PrinterState.IDLE:
        return this.IdleCaption;
      case PrinterState.BUSY:
        return this.BusyCaption;
      case PrinterState.PRINTING:
        return this.PrintingCaption;
      case PrinterState.PAUSING:
        return this.PausingCaption;
      case PrinterState.PAUSED:
        return this.PausedCaption;
      case PrinterState.RESUMING:
        return this.ResumingCaption;
      case PrinterState.PRINT_FINISHED:
        return this.PrintFinishedCaption;
      case PrinterState.HALTED:
        return this.HaltedCaption;
      case PrinterState.UPDATING_FIRMWARE:
        return this.UpdatingFirmwareCaption;
      case PrinterState.DISCONNECTED:
        return this.DisconnectedCaption;
    }
  }

  private get StatusCaption(): string {
    return en.status.growFirst();
  }
  private get UnknownCaption(): string {
    return en.unknown.growFirst();
  }
  private get IdleCaption(): string {
    return en.idle.growFirst();
  }
  private get BusyCaption(): string {
    return en.busy.growFirst();
  }
  private get PrintingCaption(): string {
    return en.printing.growFirst();
  }
  private get PausingCaption(): string {
    return en.pausing.growFirst();
  }
  private get PausedCaption(): string {
    return en.paused.growFirst();
  }
  private get ResumingCaption(): string {
    return en.resuming.growFirst();
  }
  private get PrintFinishedCaption(): string {
    return en.printFinished.growFirst();
  }
  private get HaltedCaption(): string {
    return en.halted.growFirst();
  }
  private get UpdatingFirmwareCaption(): string {
    return en.updatingFirmware.growFirst();
  }
  private get DisconnectedCaption(): string {
    return en.disconnected.growFirst();
  }
  //#endregion
}
</script>

<style lang="scss" scoped>
.machine-card {
  display: block;
  background-color: var(--machine-card-background);
  border: var(--machine-card-border);
  border-radius: 6px;
  padding: 10px 12px;
  color: var(--machine-card-text);
  position: relative;
  overflow: hidden;

  .progress-underlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    opacity: 0.25;
    background-color: var(--main-orange);
    transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);
  }

  &:not(.selected) {
    cursor: pointer;
    user-select: none;
  }

  &.selected {
    border: var(--machine-card-border-selected);
  }

  &:hover {
    background-color: var(--machine-card-background-hover);
  }

  .info {
    position: relative;
    flex: 4;
    display: flex;
    flex-direction: column;
    min-width: 0;

    .model-name-container {
      display: flex;
      text-align: left;
      margin-bottom: 10px;

      .model-badge {
        background: var(--machine-card-model-badge-background);
        border-radius: 2px;
        padding: 0 6px;
        color: var(--machine-card-model-badge-text);
        user-select: none;
      }

      .name {
        flex: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 16px;
      }
    }

    .status {
      display: flex;
      margin-bottom: 4px;
      text-align: left;
      color: var(--machine-card-status-text);
      font-size: 12px;

      :not(.value) {
        user-select: none;
      }

      .value {
        flex: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: var(--machine-card-status-value);
        font-weight: 700;
      }
    }
  }

  .errors-summary {
    display: flex;
    user-select: none;

    .errors-count {
      color: var(--main-orange);
      font-size: 12px;
      font-weight: 700;
    }
  }
}

.progress-info {
  color: white;
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1);
  overflow: hidden;
  border-radius: 6px;
  text-align: left;
  font-size: 12px;
  position: relative;

  .progress-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .job-progress {
    display: flex;

    .g-code-container {
      text-overflow: ellipsis;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      margin: 0 3px;

      .g-code {
        font-weight: 700;

        &.clickable {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
