import { Countries, States } from '@/models/responses/Countries';

export default class en {
  //#region LOGIN

  static local: string = 'local';
  static cloud: string = 'cloud';
  static support: string = 'support';
  static login: string = 'login';
  static version: string = 'version';
  static usernamePlaceholder: string = 'enter your email here';
  static passwordPlaceholder: string = 'enter your password here';
  static passwordConfirmPlaceholder: string = 'enter confirmation password';
  static codePlaceholder: string = 'enter code from email here';
  static forgotPassword: string = 'forgot password?';
  static signIn: string = 'sign in';
  static signingIn = 'signing in';
  static send = 'send';
  static sending = 'sending';
  static connectToCloud: string = 'connect to cloud';
  static signup: string = 'sign up';
  static next = 'next';
  static back = 'back';

  //#endregion

  //#region SIDE MENU

  static dashboard = 'dashboard';
  static account = 'profile';
  static company = 'company';

  //#endregion

  //#region GENERAL

  static printCount = 'print count';
  static forPrinter = 'for printer';
  static actions = 'actions';
  static nameCaption = 'name';
  static taxRate = 'tax rate';
  static taxId = 'tax ID';
  static save = 'save';
  static saving = 'saving';
  static cancel = 'cancel';
  static online = 'online';
  static today = 'today';
  static longAgo = 'long ago';
  static week = 'week';
  static day = 'day';
  static month = 'month';
  static year = 'year';
  static all = 'all';
  static total = 'total';
  static range = 'range';
  static at = 'at';
  static idle = 'idle';
  static active = 'active';
  static inProcess = 'in process';
  static paused = 'paused';
  static waiting = 'waiting';
  static finished = 'finished';
  static cancelled = 'cancelled';
  static date = 'date';
  static source = 'source';
  static printer = 'printer';
  static filename = 'filename';
  static stats = 'stats';
  static status = 'status';
  static empty = 'empty';
  static more = 'more';
  static showMore = 'show more';
  static done = 'done';
  static lastSeen = 'last seen';
  static email = 'email';
  static companyEmail = 'company email';
  static phone = 'phone';
  static password = 'password';
  static changeInfo = 'change info';
  static users = 'users';
  static printers = 'printers';
  static files = 'files';
  static author = 'author';
  static category = 'category';
  static addNewFile = 'add new file';
  static categories = 'categories';
  static upload = 'upload';
  static uploading = 'uploading';
  static delete = 'delete';
  static duration = 'duration';
  static hoursShort = 'h';
  static minutesShort = 'min';
  static gr = 'g';
  static meter = 'm';
  static cm = 'cm';
  static cmCubed = 'cm³';
  static plastic = 'plastic';
  static composite = 'composite';
  static materialConsumptions = 'material consumptions';
  static printingHistory = 'printing history';
  static file = 'file';
  static machines = 'machines';
  static model = 'model';
  static disconnected = 'disconnected';
  static unknown = 'unknown';
  static busy = 'busy';
  static pausing = 'pausing';
  static resuming = 'resuming';
  static halted = 'halted';
  static connectionType = 'connection type';
  static mqtt = 'mqtt';
  static jobs = 'jobs';
  static or = 'or';
  static step = 'step';
  static materials = 'materials';
  static progress = 'progress';
  static started = 'started';
  static estimated = 'estimated';
  static notStarted = 'not started';
  static order = 'order';
  static yes = 'yes';
  static no = 'no';
  static printing = 'printing';
  static clearAll = 'clear all';
  static tracking = 'live';
  static from = 'from';
  static to = 'to';
  static success = 'success';
  static admin = 'admin';
  static now = 'now';

  //#endregion

  //#region USER ACCOUNT INFO

  static logout = 'logout';
  static enterEmail = 'enter email';
  static firstName = 'first name';
  static enterFirstName = 'enter first name';
  static secondName = 'second name';
  static enterSecondName = 'enter second name';
  static enterPhone = 'enter phone number';
  static changeEmail = 'change email';
  static changePassword = 'change password';
  static enterPassword = 'enter password';
  static confirmPassword = 'confirm password';
  static currentPassword = 'current password';
  static enterConfirmationPassword = 'confirm password';
  static maxLength = 'max length = ';
  static activeSessions = 'active sessions';
  static currentJobs = 'current jobs';
  static archieveJobs = 'archieve jobs';
  static activeDevices = 'active devices';
  static terminateAll = 'terminate all';
  static terminating = 'terminating';
  static hoursPerWeek = 'hours/week';
  static printsPerWeek = 'prints/week';
  static hoursPerMonth = 'hours/month';
  static printsPerMonth = 'prints/month';
  static noSessions = 'no active devices';
  static changeRole = 'change privilegies';
  static roles = 'priviligies';
  static deleteUser = 'delete user';
  static deleteUserAsk = 'Do you really want to delete the user?';
  static sessionsTermination = 'sessions termination';
  static sessionsTerminationsCaption = 'Do you really want to terminate all sessions on other devices?';

  //#endregion

  //#region COMPANY

  static enterCompanyName = 'enter company name';
  static enterCompanyAddress = 'enter company address';
  static address = 'address';
  static country = 'country';
  static addNewUser = 'register new user';
  static changeName = 'change name';
  static newUser = 'new user';
  static totalPrints = 'total prints';
  static userCount = 'user count';

  //#endregion

  //#region FILE LIBRARY

  static fileLibrary = 'library';
  static loadNewFile = 'load new file';
  static newFile = 'new file';
  static gcode = '.g-code';
  static auproj = '.auprojx';
  static addNewCategory = 'add new category';
  static newCategory = 'new category';
  static enterCategoryName = 'enter category name';
  static deleteCategory = 'delete category';
  static nothingToDelete = 'nothing to delete';
  static owner = 'owner';
  static createdAt = 'created at';
  static updateCodeFile = 'update .gcode file';
  static updateAuprojFile = 'update .auprojx file';
  static updateGroup = 'update group';
  static deleteSource = 'delete file';
  static fileHasActiveJobs = "the file {0} has active jobs and can't be deleted";
  static fileMaxSize = 'the file size should be less than {0} mb';
  static fileLimitExceeds = "you're exceeding your file limit; please select a smaller file or delete others";

  //#endregion

  //#region MACHINES

  static printAgain = 'print again';
  static addNewMachine = 'add new machine';
  static editMachine = 'edit machine';
  static enterPrinterName = 'enter printer name';
  static updateMachineNaming = 'update info';
  static deleteMachine = 'delete machine';
  static printFinished = 'print finished';
  static updatingFirmware = 'updating firmware';
  static machineHasActiveJobs = "the machine {0} has active jobs and can't be deleted";
  static clientId = 'machine id';
  static enterMachineIdPlaceholder = 'enter machine id';
  static machineCode = 'security code';
  static machineDeleteAsk = 'Do you really want to delete the machine?';
  static showRaw = 'show raw data';
  static showErrors = 'show errors';
  static terminal = 'terminal';
  static telemetry = 'telemetry';
  static printHeads = 'print heads';
  static buildPlate = 'buildplate';
  static errorLog = 'error log';
  static showInfo = 'show info';
  static showWarning = 'show warning';
  static showError = 'show error';
  static fromShouldBeLessThanTo = 'time From should be less than time To';
  static clientIdAlreadyExists = 'machine with the same client ID already exists';
  static machineIsBusy = 'machine is busy';
  static updateConnection = 'update connection';
  static dontGroupSimilarErrors = "don't group similar errors";
  static groupSimilarErrors = 'group similar errors';
  static showOnlyUnacknowledgedErrors = 'show only unacknowledged errors';
  static acknowledgeErrors = 'Confirm';

  //#endregion

  //#region JOBS

  static printJobs = 'printing sessions';
  static deleteJob = 'delete job';
  static deleteJobAsk = 'do you really want to delete the job?';
  static startNewJob = 'queue new job';
  static chooseFile = 'choose file';
  static takeTheItem = 'take the item to print it';
  static addNewJob = 'queue new job';
  static chooseFileFromTheLibrary = 'choose a file from the library';
  static choosePrinter = 'choose printer';
  static fileToPrint = 'file to be printed';
  static chooseMachine = 'choose a machine from the library';
  static selectedMachine = 'selected machine';
  static pauseJob = 'pause';
  static resumeJob = 'resume';
  static stopJob = 'stopJob the job';
  static current = 'current';
  static archieve = 'archieve';
  static forceStart = 'force start';
  static dateFinish = 'finish date';

  //#endregion

  //#region COMPANY VIEW
  static weHaveProductsForEveryone = 'We have products for everyone';

  //#region SHOP
  static alreadyPurchased = 'Already purchased';

  static myCart = 'My cart';
  static noDefaultPaymentMethod = 'No default payment method';
  static noPaymentMethods = 'No payment methods';
  static select = 'Select';
  static buy = 'Buy';
  static subscribe = 'Subscribe';

  static quantity = 'Quantity';

  static purchaseSuccessful = 'Purchase successful';
  static purchaseFailed = 'Purchase failed';

  static someSubsCouldNotBePurchased = 'Some of the subscriptions could not be purchased';
  static xSubsPurchasedSuccessfully = 'subscription(s) have been purchased successfully';
  //#endregion

  //#region PRODUCTS
  static auraKeys = 'Aura Keys';
  static free = 'free';
  static addons = 'Addons';
  static validBefore = 'Valid before';
  static machineId = 'Machine ID';
  static nextPaymentDue = 'Next payment due';
  static on = 'on';
  static add = 'add';
  static ok = 'ok';
  static manage = 'manage';
  static cancelSubscription = 'Cancel subscription';
  static scheduleCancellation = 'Schedule cancellation';
  static reactivate = 'Reactivate';
  static key = 'key';
  static activation = 'activation';
  static offlineActivation = 'offline activation';
  static deactivateOffile = 'deactivateOffline';
  static deactivate = 'deactivate';
  static activate = 'activate';
  static activated = 'activated';
  static thereAreNoMoreAddons = 'There are no more free addons left for';
  static addonAttachedSuccessfully = 'Addon attached succesfuly';
  static couldNotAttachAddon = 'Could not attach addon';
  static addonDetachededSuccessfully = 'Addon detached succesfuly';
  static couldNotDetachedAddon = 'Could not detached addon';
  static plan = 'plan';
  static change = 'change';
  static nextPayment = 'next payment';
  static changePlan = 'change plan';
  static subWasCancelledSuccessfully = 'Subscription was cancelled successfully';
  static couldNotCancellSubscription = 'Could not cancel subscription';

  static availableSubscriptions = 'available subscriptions';
  static subscriptionName = 'subscription name';
  static thereAreNoMoreSubs = 'There are no more free addon left for this subscription';

  static manageSeatsCount = 'Manage seats count';
  static currentSeatCountIs = 'Current seats count is';
  static submit = 'submit';
  static youWillBeChargedXForToday = 'You will be charged for [AMOUNT] today';
  static xWillBeAddeToYourCreditToday = '[AMOUNT] will be added to your credit today';
  static nextPaymentWillBe = 'Next payment will be';
  static keyCopiedToClipboard = 'Key copied to clipboard';
  static yourCartHasBeebCleared = 'Your cart has been cleared';
  static productXHasBeenAddedToCart = '[PRODUCT_NAME] has been added to cart';
  static paymentMethod = 'payment method';
  static changedSubscriptionSuccessfully = 'Changed subscription successfully';
  static couldNotChangeSubcripton = 'Could not change subscription';
  //#endregion

  //#region INFO
  static info = 'info';
  static warning = 'warning';
  static error = 'error';
  static edit = 'edit';
  static registerNewUser = 'register new user';
  static admins = 'admins';
  static prints = 'prints';

  static addCompanyAdminHeader = 'Add company admin';
  //#endregion

  //#region BILLING
  static accountInfo = 'Account info';
  static stateOrRegion = 'State/Region';
  static postalCode = 'Zip-code';
  static city = 'City';
  static addNew = 'add new';
  static changeOwner = 'change owner';
  static deleteAdmins = 'delete admins';
  static paymentMethods = 'payment methods';
  static balance = 'balance';
  static accountBalance = 'Account balance';
  static default = 'default';
  static confirm = 'confirm';
  static invoices = 'invoices';
  static accountAdmins = 'account admins';
  static sum = 'sum';
  static addAccountAdmin = 'add account admin';
  static selectAdminToRemove = 'select admin to remove';
  static selectNewOwner = 'select new owner';
  static changeDefault = 'change default';
  static paymentMethodMadeDefault = 'successfully changed default payment method';
  static couldNotMakePaymentMethodDefault = 'could not change default payment method';
  static paymentMethodDeleted = 'successfully deleted payment method';
  static couldNotDeletePaymentMethod = 'could not delete payment method';
  static invoiceIsNotReady = 'invoice is not ready yet';
  static userDeleted = 'user deleted successfully';
  static couldNotDeleteUser = 'could not delete user';
  static couldNotDeleteCompanyAdmin = 'could not delete company admin';
  static keyAddonsLockedCaption = 'Deactivate key to add/remove addons';
  static createdNewUser = 'User created successfully';
  static couldNotCreateNewUser = 'Could not create new user';
  static HasSameEmailUser = 'User email already exists';
  static SameNameGroup = 'Category name already exists';
  static couldNotCreateNewCategory = 'An error occurred while creating category';
  static changedCompanyInfo = 'Company info changed successfully';
  static couldNotChangeCompanyInfo = 'Could not change company info';
  static changedBillingCompanyInfo = 'Billing account info changed successfully';
  static couldNotChangeBillingCompanyInfo = 'Could not change billing account info';
  static couldNotDeleteBillingAdmin = 'Could not delete billing account admin';
  static invoicePaymentConfirmed = 'Payment has been confirmed successfully';
  static couldNotConfirmInvoicePayment = 'Could not confirm payment';
  static invoiceNeedsPaymentConfirmation =
    'Invoice [0] requires payment confirmation.\nNavigate to billing tab and confirm its payment, or click on this notification';
  static pendingUpdateCanceledSuccessfully = 'Pending update canceled successfully';
  static couldNotCancelPendingupdate = 'Could not cancel pending update';
  static subscriptionWillBeCanceledOnPeriodEnd = 'Subscription cancellation scheduled successfully';
  static couldNotScheduleSubscriptionCancellation = 'Could not schedule cancellation for subscription';
  static subscriptionReactivated = 'Subscription reactivated successfully';
  static couldNotReactivateSubscription = 'Could not reactivate subscription';
  static subscriptionNotEditableReasonHasPendingUpdate =
    'This subscription cannot be changed, because it has a pending update';
  static subscriptionNotEditableReasonCancellationScheduled =
    'This subscription cannot be changed, because it has been scheduled for cancellation';
  static subscriptionWillBeCanceledOn = 'this subscription will be automatically cancelled on';
  //#endregion

  static billingTabCaption = 'Billing';
  static infoTabCaption = 'Info';
  static productsTabCaption = 'Products';
  static shopTabCaption = 'Store';
  static incomplete = 'incomplete';
  static incompleteExpired = 'incomplete expired';
  static pastDue = 'past due';
  static unpaid = 'unpaid';
  static daily = 'daily';
  static weekly = 'weekly';
  static monthly = 'monthly';
  static yearly = 'yearly';

  static invoiceDraft = 'draft';
  static invoiceOpen = 'waiting for pay';
  static invoiceFinished = 'finished';
  static invoiceCancelled = 'cancelled';
  static invoiceVoided = 'voided';
  static paymentStateFailed = 'failed';

  static selectDefaultPaymentMethod = 'Select payment method';
  static occupied = 'occupied';
  //#endregion

  //#region ERRORS

  static fillRequired = 'please fill out all required fields';
  static passwordsShouldBeSame = 'the password confirmation does not match';
  static internalServerError = 'server error. Please try again later';
  static unknownError = 'Unknown error. Please try again later';
  static invalidEmail = 'invalid email format';
  static terminationUnsuccess = 'error during termination';
  static passwordTooShort = 'password is too short';
  static companyNameTooLong = 'company name is too long';
  static companyNameAlreadyExists = 'Company name already exists';
  static cantStartJobWithForce = "can't start job";

  //#endregion

  //#region COUNTRIES, STATES

  static CountryCodes: Record<Countries, string> = {
    [Countries.Afghanistan]: 'AF',
    [Countries.Albania]: 'AL',
    [Countries.Algeria]: 'DZ',
    [Countries.Andorra]: 'AD',
    [Countries.Angola]: 'AO',
    [Countries.AntiguaAndDeps]: 'AG',
    [Countries.Argentina]: 'AR',
    [Countries.Armenia]: 'AM',
    [Countries.Australia]: 'AU',
    [Countries.Austria]: 'AT',
    [Countries.Azerbaijan]: 'AZ',
    [Countries.Bahamas]: 'BS',
    [Countries.Bahrain]: 'BH',
    [Countries.Bangladesh]: 'BD',
    [Countries.Barbados]: 'BB',
    [Countries.Belarus]: 'BY',
    [Countries.Belgium]: 'BE',
    [Countries.Belize]: 'BZ',
    [Countries.Benin]: 'BJ',
    [Countries.Bhutan]: 'BT',
    [Countries.Bolivia]: 'BO',
    [Countries.BosniaHerzegovina]: 'BA',
    [Countries.Botswana]: 'BW',
    [Countries.Brazil]: 'BR',
    [Countries.Brunei]: 'BN',
    [Countries.Bulgaria]: 'BG',
    [Countries.Burkina]: 'BF',
    [Countries.Burundi]: 'BI',
    [Countries.Cambodia]: 'KH',
    [Countries.Cameroon]: 'CM',
    [Countries.Canada]: 'CA',
    [Countries.CapeVerde]: 'CV',
    [Countries.CentralfricanRep]: 'CF',
    [Countries.Chad]: 'TD',
    [Countries.Chile]: 'CL',
    [Countries.China]: 'CN',
    [Countries.Colombia]: 'CO',
    [Countries.Comoros]: 'KM',
    [Countries.DemocraticRepCostaRica]: 'CR',
    [Countries.Croatia]: 'HR',
    [Countries.Cuba]: 'CU',
    [Countries.Cyprus]: 'CY',
    [Countries.CzechRepublic]: 'CZ',
    [Countries.Denmark]: 'DK',
    [Countries.Dominica]: 'DM',
    [Countries.DominicanRepublic]: 'DO',
    [Countries.Ecuador]: 'EC',
    [Countries.Egypt]: 'EG',
    [Countries.ElSalvador]: 'SV',
    [Countries.EquatorialGuinea]: 'GQ',
    [Countries.Eritrea]: 'ER',
    [Countries.Estonia]: 'EE',
    [Countries.Ethiopia]: 'ET',
    [Countries.Fiji]: 'FJ',
    [Countries.Finland]: 'FI',
    [Countries.France]: 'FR',
    [Countries.Gabon]: 'GA',
    [Countries.Gambia]: 'GM',
    [Countries.Georgia]: 'GE',
    [Countries.Germany]: 'DE',
    [Countries.Ghana]: 'GH',
    [Countries.Greece]: 'GR',
    [Countries.Grenada]: 'GD',
    [Countries.Guatemala]: 'GT',
    [Countries.Guinea]: 'GN',
    [Countries.Guyana]: 'GY',
    [Countries.Haiti]: 'HT',
    [Countries.Honduras]: 'HN',
    [Countries.Hungary]: 'HU',
    [Countries.Iceland]: 'IS',
    [Countries.India]: 'IN',
    [Countries.Indonesia]: 'ID',
    [Countries.Iran]: 'IR',
    [Countries.Iraq]: 'IQ',
    [Countries.Ireland]: 'IE',
    [Countries.Israel]: 'IL',
    [Countries.Italy]: 'IT',
    [Countries.Jamaica]: 'JM',
    [Countries.Japan]: 'JP',
    [Countries.Jordan]: 'JO',
    [Countries.Kazakhstan]: 'KZ',
    [Countries.Kenya]: 'KE',
    [Countries.Kiribati]: 'KI',
    [Countries.KoreaNorth]: 'KP',
    [Countries.KoreaSouth]: 'KR',
    [Countries.Kuwait]: 'KW',
    [Countries.Kyrgyzstan]: 'KG',
    [Countries.Laos]: 'LA',
    [Countries.Latvia]: 'LV',
    [Countries.Lebanon]: 'LB',
    [Countries.Lesotho]: 'LS',
    [Countries.Liberia]: 'LR',
    [Countries.Libya]: 'LY',
    [Countries.Liechtenstein]: 'LI',
    [Countries.Lithuania]: 'LT',
    [Countries.Luxembourg]: 'LU',
    [Countries.Madagascar]: 'MG',
    [Countries.Malawi]: 'MW',
    [Countries.Malaysia]: 'MY',
    [Countries.Maldives]: 'MV',
    [Countries.Mali]: 'ML',
    [Countries.Malta]: 'MT',
    [Countries.MarshallIslands]: 'MH',
    [Countries.Mauritania]: 'MR',
    [Countries.Mauritius]: 'MU',
    [Countries.Mexico]: 'MX',
    [Countries.Micronesia]: 'FM',
    [Countries.Moldova]: 'MD',
    [Countries.Monaco]: 'MC',
    [Countries.Mongolia]: 'MN',
    [Countries.Montenegro]: 'ME',
    [Countries.Morocco]: 'MA',
    [Countries.Mozambique]: 'MZ',
    [Countries.Myanmar]: 'MM',
    [Countries.Namibia]: 'NA',
    [Countries.Nauru]: 'NR',
    [Countries.Nepal]: 'NP',
    [Countries.Netherlands]: 'NL',
    [Countries.NewZealand]: 'NZ',
    [Countries.Nicaragua]: 'NI',
    [Countries.Niger]: 'NE',
    [Countries.Nigeria]: 'NG',
    [Countries.Macedonia]: 'MK',
    [Countries.Norway]: 'NO',
    [Countries.Oman]: 'OM',
    [Countries.Pakistan]: 'PK',
    [Countries.Palau]: 'PW',
    [Countries.Panama]: 'PA',
    [Countries.PapuaNewGuinea]: 'PG',
    [Countries.Paraguay]: 'PY',
    [Countries.Peru]: 'PE',
    [Countries.Philippines]: 'PH',
    [Countries.Poland]: 'PL',
    [Countries.Portugal]: 'PT',
    [Countries.Qatar]: 'QA',
    [Countries.Congo]: 'CG',
    [Countries.Romania]: 'RO',
    [Countries.RussianFederation]: 'RU',
    [Countries.Rwanda]: 'RW',
    [Countries.StKittsNevis]: 'KN',
    [Countries.StLucia]: 'LC',
    [Countries.SaintVincenttheGrenadines]: 'VC',
    [Countries.Samoa]: 'WS',
    [Countries.SanMarino]: 'SM',
    [Countries.SaoTomePrincipe]: 'ST',
    [Countries.SaudiArabia]: 'SA',
    [Countries.Senegal]: 'SN',
    [Countries.Serbia]: 'RS',
    [Countries.Seychelles]: 'SC',
    [Countries.SierraLeone]: 'SL',
    [Countries.Singapore]: 'SG',
    [Countries.Slovakia]: 'SK',
    [Countries.Slovenia]: 'SI',
    [Countries.SolomonIslands]: 'SB',
    [Countries.Somalia]: 'SO',
    [Countries.SouthAfrica]: 'ZA',
    [Countries.SouthSudan]: 'SS',
    [Countries.Spain]: 'ES',
    [Countries.SriLanka]: 'LK',
    [Countries.Sudan]: 'SD',
    [Countries.Suriname]: 'SR',
    [Countries.Swaziland]: 'SZ',
    [Countries.Sweden]: 'SE',
    [Countries.Switzerland]: 'CH',
    [Countries.Syria]: 'SY',
    [Countries.Taiwan]: 'TW',
    [Countries.Tajikistan]: 'TJ',
    [Countries.Tanzania]: 'TZ',
    [Countries.Thailand]: 'TH',
    [Countries.Togo]: 'TG',
    [Countries.Tonga]: 'TO',
    [Countries.TrinidadTobago]: 'TT',
    [Countries.Tunisia]: 'TN',
    [Countries.Turkey]: 'TR',
    [Countries.Turkmenistan]: 'TM',
    [Countries.Tuvalu]: 'TV',
    [Countries.Uganda]: 'UG',
    [Countries.Ukraine]: 'UA',
    [Countries.UnitedArabEmirates]: 'AE',
    [Countries.UnitedKingdom]: 'GB',
    [Countries.UnitedStates]: 'US',
    [Countries.Uruguay]: 'UY',
    [Countries.Uzbekistan]: 'UZ',
    [Countries.Vanuatu]: 'VU',
    [Countries.VaticanCity]: 'VA',
    [Countries.Venezuela]: 'VE',
    [Countries.Vietnam]: 'VN',
    [Countries.Yemen]: 'YE',
    [Countries.Zambia]: 'ZM',
    [Countries.Zimbabwe]: 'ZW',
    [Countries.Djibouti]: 'DJ',
    [Countries.EastTimor]: 'TL',
    [Countries.GuineaBissau]: 'GW',
    [Countries.IvoryCoast]: 'CI',
    [Countries.Kosovo]: 'KO',
  };

  static CountryNames: Record<Countries, string> = {
    [Countries.Afghanistan]: 'Afganistan',
    [Countries.Albania]: 'Albania',
    [Countries.Algeria]: 'Algeria',
    [Countries.Andorra]: 'Andorra',
    [Countries.Angola]: 'Angola',
    [Countries.AntiguaAndDeps]: 'Antigua And Deps',
    [Countries.Argentina]: 'Argentina',
    [Countries.Armenia]: 'Armenia',
    [Countries.Australia]: 'Australia',
    [Countries.Austria]: 'Austria',
    [Countries.Azerbaijan]: 'Azerbaijan',
    [Countries.Bahamas]: 'Bahamas',
    [Countries.Bahrain]: 'Bahrain',
    [Countries.Bangladesh]: 'Bangladesh',
    [Countries.Barbados]: 'Barbados',
    [Countries.Belarus]: 'Belarus',
    [Countries.Belgium]: 'Belgium',
    [Countries.Belize]: 'Belize',
    [Countries.Benin]: 'Benin',
    [Countries.Bhutan]: 'Bhutan',
    [Countries.Bolivia]: 'Bolivia',
    [Countries.BosniaHerzegovina]: 'Bosnia and Herzegovina',
    [Countries.Botswana]: 'Botswana',
    [Countries.Brazil]: 'Brazil',
    [Countries.Brunei]: 'Brunei',
    [Countries.Bulgaria]: 'Bulgaria',
    [Countries.Burkina]: 'Burkina',
    [Countries.Burundi]: 'Burundi',
    [Countries.Cambodia]: 'Cambodia',
    [Countries.Cameroon]: 'Cameroon',
    [Countries.Canada]: 'Canada',
    [Countries.CapeVerde]: 'CapeVerde',
    [Countries.CentralfricanRep]: 'Central African Republic',
    [Countries.Chad]: 'Chad',
    [Countries.Chile]: 'Chile',
    [Countries.China]: 'China',
    [Countries.Colombia]: 'Colombia',
    [Countries.Comoros]: 'Comoros',
    [Countries.Congo]: 'Congo',
    [Countries.Croatia]: 'Croatia',
    [Countries.Cuba]: 'Cuba',
    [Countries.Cyprus]: 'Cyprus',
    [Countries.CzechRepublic]: 'Czech Republic',
    [Countries.DemocraticRepCostaRica]: 'Democratic Republic of Costa-Rica',
    [Countries.Denmark]: 'Denmark',
    [Countries.Djibouti]: 'Djibouti',
    [Countries.Dominica]: 'Dominica',
    [Countries.DominicanRepublic]: 'Dominican Republic',
    [Countries.EastTimor]: 'East Timor',
    [Countries.Ecuador]: 'Ecuador',
    [Countries.Egypt]: 'Egypt',
    [Countries.ElSalvador]: 'El Salvador',
    [Countries.EquatorialGuinea]: 'Equatorial Guinea',
    [Countries.Eritrea]: 'Eritrea',
    [Countries.Estonia]: 'Estonia',
    [Countries.Ethiopia]: 'Ethiopia',
    [Countries.Fiji]: 'Fiji',
    [Countries.Finland]: 'Finland',
    [Countries.France]: 'France',
    [Countries.Gabon]: 'Gabon',
    [Countries.Gambia]: 'Gambia',
    [Countries.Georgia]: 'Georgia',
    [Countries.Germany]: 'Germany',
    [Countries.Ghana]: 'Ghana',
    [Countries.Greece]: 'Greece',
    [Countries.Grenada]: 'Grenada',
    [Countries.Guatemala]: 'Guatemala',
    [Countries.Guinea]: 'Guinea',
    [Countries.GuineaBissau]: 'Guinea Bissau',
    [Countries.Guyana]: 'Guyana',
    [Countries.Haiti]: 'Haiti',
    [Countries.Honduras]: 'Honduras',
    [Countries.Hungary]: 'Hungary',
    [Countries.Iceland]: 'Iceland',
    [Countries.India]: 'India',
    [Countries.Indonesia]: 'Indonesia',
    [Countries.Iran]: 'Iran',
    [Countries.Iraq]: 'Iraq',
    [Countries.Ireland]: 'Ireland',
    [Countries.Israel]: 'Israel',
    [Countries.Italy]: 'Italy',
    [Countries.IvoryCoast]: 'Ivory Coast',
    [Countries.Jamaica]: 'Jamaica',
    [Countries.Japan]: 'Japan',
    [Countries.Jordan]: 'Jordan',
    [Countries.Kazakhstan]: 'Kazakhstan',
    [Countries.Kenya]: 'Kenya',
    [Countries.Kiribati]: 'Kiribati',
    [Countries.KoreaNorth]: 'North Korea',
    [Countries.KoreaSouth]: 'South Korea',
    [Countries.Kosovo]: 'Kosovo',
    [Countries.Kuwait]: 'Kuwait',
    [Countries.Kyrgyzstan]: 'Kyrgyzstan',
    [Countries.Laos]: 'Laos',
    [Countries.Latvia]: 'Latvia',
    [Countries.Lebanon]: 'Lebanon',
    [Countries.Lesotho]: 'Lesotho',
    [Countries.Liberia]: 'Liberia',
    [Countries.Libya]: 'Libya',
    [Countries.Liechtenstein]: 'Liechtenstein',
    [Countries.Lithuania]: 'Lithuania',
    [Countries.Luxembourg]: 'Luxembourg',
    [Countries.Macedonia]: 'Macedonia',
    [Countries.Madagascar]: 'Madagascar',
    [Countries.Malawi]: 'Malawi',
    [Countries.Malaysia]: 'Malaysia',
    [Countries.Maldives]: 'Maldives',
    [Countries.Mali]: 'Mali',
    [Countries.Malta]: 'Malta',
    [Countries.MarshallIslands]: 'MarshallIslands',
    [Countries.Mauritania]: 'Mauritania',
    [Countries.Mauritius]: 'Mauritius',
    [Countries.Mexico]: 'Mexico',
    [Countries.Micronesia]: 'Micronesia',
    [Countries.Moldova]: 'Moldova',
    [Countries.Monaco]: 'Monaco',
    [Countries.Mongolia]: 'Mongolia',
    [Countries.Montenegro]: 'Montenegro',
    [Countries.Morocco]: 'Morocco',
    [Countries.Mozambique]: 'Mozambique',
    [Countries.Myanmar]: 'Myanmar',
    [Countries.Namibia]: 'Namibia',
    [Countries.Nauru]: 'Nauru',
    [Countries.Nepal]: 'Nepal',
    [Countries.Netherlands]: 'Netherlands',
    [Countries.NewZealand]: 'New Zealand',
    [Countries.Nicaragua]: 'Nicaragua',
    [Countries.Niger]: 'Niger',
    [Countries.Nigeria]: 'Nigeria',
    [Countries.Norway]: 'Norway',
    [Countries.Oman]: 'Oman',
    [Countries.Pakistan]: 'Pakistan',
    [Countries.Palau]: 'Palau',
    [Countries.Panama]: 'Panama',
    [Countries.PapuaNewGuinea]: 'Papua New Guinea',
    [Countries.Paraguay]: 'Paraguay',
    [Countries.Peru]: 'Peru',
    [Countries.Philippines]: 'Philippines',
    [Countries.Poland]: 'Poland',
    [Countries.Portugal]: 'Portugal',
    [Countries.Qatar]: 'Qatar',
    [Countries.Romania]: 'Romania',
    [Countries.RussianFederation]: 'Russian Federation',
    [Countries.Rwanda]: 'Rwanda',
    [Countries.SaintVincenttheGrenadines]: 'Saint Vincentthe Grenadines',
    [Countries.Samoa]: 'Samoa',
    [Countries.SanMarino]: 'SanMarino',
    [Countries.SaoTomePrincipe]: 'SaoTomePrincipe',
    [Countries.SaudiArabia]: 'SaudiArabia',
    [Countries.Senegal]: 'Senegal',
    [Countries.Serbia]: 'Serbia',
    [Countries.Seychelles]: 'Seychelles',
    [Countries.SierraLeone]: 'Sierra Leone',
    [Countries.Singapore]: 'Singapore',
    [Countries.Slovakia]: 'Slovakia',
    [Countries.Slovenia]: 'Slovenia',
    [Countries.SolomonIslands]: 'Solomon Islands',
    [Countries.Somalia]: 'Somalia',
    [Countries.SouthAfrica]: 'South Africa',
    [Countries.SouthSudan]: 'South Sudan',
    [Countries.Spain]: 'Spain',
    [Countries.SriLanka]: 'Sri Lanka',
    [Countries.StKittsNevis]: 'St Kitts Nevis',
    [Countries.StLucia]: 'St Lucia',
    [Countries.Sudan]: 'Sudan',
    [Countries.Suriname]: 'Suriname',
    [Countries.Swaziland]: 'Swaziland',
    [Countries.Sweden]: 'Sweden',
    [Countries.Switzerland]: 'Switzerland',
    [Countries.Syria]: 'Syria',
    [Countries.Taiwan]: 'Taiwan',
    [Countries.Tajikistan]: 'Tajikistan',
    [Countries.Tanzania]: 'Tanzania',
    [Countries.Thailand]: 'Thailand',
    [Countries.Togo]: 'Togo',
    [Countries.Tonga]: 'Tonga',
    [Countries.TrinidadTobago]: 'Trinidad Tobago',
    [Countries.Tunisia]: 'Tunisia',
    [Countries.Turkey]: 'Turkey',
    [Countries.Turkmenistan]: 'Turkmenistan',
    [Countries.Tuvalu]: 'Tuvalu',
    [Countries.Uganda]: 'Uganda',
    [Countries.Ukraine]: 'Ukraine',
    [Countries.UnitedArabEmirates]: 'United Arab Emirates',
    [Countries.UnitedKingdom]: 'United Kingdom',
    [Countries.UnitedStates]: 'United States',
    [Countries.Uruguay]: 'Uruguay',
    [Countries.Uzbekistan]: 'Uzbekistan',
    [Countries.Vanuatu]: 'Vanuatu',
    [Countries.VaticanCity]: 'VaticanCity',
    [Countries.Venezuela]: 'Venezuela',
    [Countries.Vietnam]: 'Vietnam',
    [Countries.Yemen]: 'Yemen',
    [Countries.Zambia]: 'Zambia',
    [Countries.Zimbabwe]: 'Zimbabwe',
  };

  static StateNames: Record<States, string> = {
    [States.AD_07]: 'Andorra la Vella',
    [States.AD_02]: 'Canillo',
    [States.AD_03]: 'Encamp',
    [States.AD_08]: 'Escaldes-Engordany',
    [States.AD_04]: 'La Massana',
    [States.AD_05]: 'Ordino',
    [States.AD_06]: 'Sant Julia de Loria',
    [States.AE_AJ]: 'Ajman',
    [States.AE_AZ]: 'Abu Zaby',
    [States.AE_FU]: 'Al Fujayrah',
    [States.AE_SH]: 'Ash Shariqah',
    [States.AE_DU]: 'Dubayy',
    [States.AE_RK]: "Ra's al Khaymah",
    [States.AE_UQ]: 'Umm al Qaywayn',
    [States.AF_BDG]: 'Badghis',
    [States.AF_BGL]: 'Baghlan',
    [States.AF_BAL]: 'Balkh',
    [States.AF_BAM]: 'Bamyan',
    [States.AF_DAY]: 'Daykundi',
    [States.AF_FRA]: 'Farah',
    [States.AF_FYB]: 'Faryab',
    [States.AF_GHA]: 'Ghazni',
    [States.AF_GHO]: 'Ghor',
    [States.AF_HEL]: 'Helmand',
    [States.AF_HER]: 'Herat',
    [States.AF_JOW]: 'Jowzjan',
    [States.AF_KAB]: 'Kabul',
    [States.AF_KAN]: 'Kandahar',
    [States.AF_KHO]: 'Khost',
    [States.AF_KDZ]: 'Kunduz',
    [States.AF_LAG]: 'Laghman',
    [States.AF_LOG]: 'Logar',
    [States.AF_NAN]: 'Nangarhar',
    [States.AF_NIM]: 'Nimroz',
    [States.AF_PIA]: 'Paktiya',
    [States.AF_PAN]: 'Panjshayr',
    [States.AF_PAR]: 'Parwan',
    [States.AF_SAR]: 'Sar-e Pul',
    [States.AF_TAK]: 'Takhar',
    [States.AF_URU]: 'Uruzgan',
    [States.AG_10]: 'Barbuda',
    [States.AG_11]: 'Redonda',
    [States.AG_03]: 'Saint George',
    [States.AG_04]: 'Saint John',
    [States.AG_06]: 'Saint Paul',
    [States.AG_07]: 'Saint Peter',
    [States.AG_08]: 'Saint Philip',
    [States.AL_01]: 'Berat',
    [States.AL_09]: 'Diber',
    [States.AL_02]: 'Durres',
    [States.AL_03]: 'Elbasan',
    [States.AL_04]: 'Fier',
    [States.AL_05]: 'Gjirokaster',
    [States.AL_06]: 'Korce',
    [States.AL_07]: 'Kukes',
    [States.AL_08]: 'Lezhe',
    [States.AL_10]: 'Shkoder',
    [States.AL_11]: 'Tirane',
    [States.AL_12]: 'Vlore',
    [States.AM_AG]: 'Aragacotn',
    [States.AM_AR]: 'Ararat',
    [States.AM_AV]: 'Armavir',
    [States.AM_ER]: 'Erevan',
    [States.AM_GR]: "Gegark'unik'",
    [States.AM_KT]: "Kotayk'",
    [States.AM_LO]: 'Lori',
    [States.AM_SH]: 'Sirak',
    [States.AM_SU]: "Syunik'",
    [States.AM_TV]: 'Tavus',
    [States.AM_VD]: 'Vayoc Jor',
    [States.AO_BGO]: 'Bengo',
    [States.AO_BGU]: 'Benguela',
    [States.AO_BIE]: 'Bie',
    [States.AO_CAB]: 'Cabinda',
    [States.AO_CCU]: 'Cuando Cubango',
    [States.AO_CNO]: 'Cuanza-Norte',
    [States.AO_CUS]: 'Cuanza-Sul',
    [States.AO_CNN]: 'Cunene',
    [States.AO_HUA]: 'Huambo',
    [States.AO_HUI]: 'Huila',
    [States.AO_LUA]: 'Luanda',
    [States.AO_LNO]: 'Lunda-Norte',
    [States.AO_LSU]: 'Lunda-Sul',
    [States.AO_MAL]: 'Malange',
    [States.AO_MOX]: 'Moxico',
    [States.AO_NAM]: 'Namibe',
    [States.AO_UIG]: 'Uige',
    [States.AO_ZAI]: 'Zaire',
    [States.AR_B]: 'Buenos Aires',
    [States.AR_K]: 'Catamarca',
    [States.AR_H]: 'Chaco',
    [States.AR_U]: 'Chubut',
    [States.AR_C]: 'Ciudad Autonoma de Buenos Aires',
    [States.AR_X]: 'Cordoba',
    [States.AR_W]: 'Corrientes',
    [States.AR_E]: 'Entre Rios',
    [States.AR_P]: 'Formosa',
    [States.AR_Y]: 'Jujuy',
    [States.AR_L]: 'La Pampa',
    [States.AR_F]: 'La Rioja',
    [States.AR_M]: 'Mendoza',
    [States.AR_N]: 'Misiones',
    [States.AR_Q]: 'Neuquen',
    [States.AR_R]: 'Rio Negro',
    [States.AR_A]: 'Salta',
    [States.AR_J]: 'San Juan',
    [States.AR_D]: 'San Luis',
    [States.AR_Z]: 'Santa Cruz',
    [States.AR_S]: 'Santa Fe',
    [States.AR_G]: 'Santiago del Estero',
    [States.AR_V]: 'Tierra del Fuego',
    [States.AR_T]: 'Tucuman',
    [States.AT_1]: 'Burgenland',
    [States.AT_2]: 'Karnten',
    [States.AT_3]: 'Niederosterreich',
    [States.AT_4]: 'Oberosterreich',
    [States.AT_5]: 'Salzburg',
    [States.AT_6]: 'Steiermark',
    [States.AT_7]: 'Tirol',
    [States.AT_8]: 'Vorarlberg',
    [States.AT_9]: 'Wien',
    [States.AU_ACT]: 'Australian Capital Territory',
    [States.AU_NSW]: 'New South Wales',
    [States.AU_NT]: 'Northern Territory',
    [States.AU_QLD]: 'Queensland',
    [States.AU_SA]: 'South Australia',
    [States.AU_TAS]: 'Tasmania',
    [States.AU_VIC]: 'Victoria',
    [States.AU_WA]: 'Western Australia',
    [States.AZ_ABS]: 'Abseron',
    [States.AZ_AGC]: 'Agcabadi',
    [States.AZ_AGU]: 'Agsu',
    [States.AZ_BA]: 'Baki',
    [States.AZ_BAL]: 'Balakan',
    [States.AZ_BAR]: 'Barda',
    [States.AZ_BEY]: 'Beylaqan',
    [States.AZ_BIL]: 'Bilasuvar',
    [States.AZ_CAL]: 'Calilabad',
    [States.AZ_FUZ]: 'Fuzuli',
    [States.AZ_GAD]: 'Gadabay',
    [States.AZ_GA]: 'Ganca',
    [States.AZ_GOR]: 'Goranboy',
    [States.AZ_GOY]: 'Goycay',
    [States.AZ_GYG]: 'Goygol',
    [States.AZ_IMI]: 'Imisli',
    [States.AZ_ISM]: 'Ismayilli',
    [States.AZ_KUR]: 'Kurdamir',
    [States.AZ_LA]: 'Lankaran',
    [States.AZ_MAS]: 'Masalli',
    [States.AZ_MI]: 'Mingacevir',
    [States.AZ_NA]: 'Naftalan',
    [States.AZ_NX]: 'Naxcivan',
    [States.AZ_NEF]: 'Neftcala',
    [States.AZ_OGU]: 'Oguz',
    [States.AZ_QAB]: 'Qabala',
    [States.AZ_QAX]: 'Qax',
    [States.AZ_QAZ]: 'Qazax',
    [States.AZ_QBA]: 'Quba',
    [States.AZ_QUS]: 'Qusar',
    [States.AZ_SAT]: 'Saatli',
    [States.AZ_SAB]: 'Sabirabad',
    [States.AZ_SAK]: 'Saki',
    [States.AZ_SAL]: 'Salyan',
    [States.AZ_SMI]: 'Samaxi',
    [States.AZ_SKR]: 'Samkir',
    [States.AZ_SMX]: 'Samux',
    [States.AZ_SR]: 'Sirvan',
    [States.AZ_SIY]: 'Siyazan',
    [States.AZ_SM]: 'Sumqayit',
    [States.AZ_TAR]: 'Tartar',
    [States.AZ_XAC]: 'Xacmaz',
    [States.AZ_XVD]: 'Xocavand',
    [States.AZ_YAR]: 'Yardimli',
    [States.AZ_YEV]: 'Yevlax',
    [States.AZ_ZAQ]: 'Zaqatala',
    [States.AZ_ZAR]: 'Zardab',
    [States.BA_BRC]: 'Brcko distrikt',
    [States.BA_BIH]: 'Federacija Bosne i Hercegovine',
    [States.BA_SRP]: 'Republika Srpska',
    [States.BB_01]: 'Christ Church',
    [States.BB_02]: 'Saint Andrew',
    [States.BB_03]: 'Saint George',
    [States.BB_04]: 'Saint James',
    [States.BB_05]: 'Saint John',
    [States.BB_07]: 'Saint Lucy',
    [States.BB_08]: 'Saint Michael',
    [States.BB_09]: 'Saint Peter',
    [States.BB_10]: 'Saint Philip',
    [States.BB_11]: 'Saint Thomas',
    [States.BD_A]: 'Barishal',
    [States.BD_B]: 'Chattogram',
    [States.BD_C]: 'Dhaka',
    [States.BD_D]: 'Khulna',
    [States.BD_E]: 'Rajshahi',
    [States.BD_F]: 'Rangpur',
    [States.BD_G]: 'Sylhet',
    [States.BE_VAN]: 'Antwerpen',
    [States.BE_WBR]: 'Brabant wallon',
    [States.BE_BRU]: 'Brussels Hoofdstedelijk Gewest',
    [States.BE_WHT]: 'Hainaut',
    [States.BE_WLG]: 'Liege',
    [States.BE_VLI]: 'Limburg',
    [States.BE_WLX]: 'Luxembourg',
    [States.BE_WNA]: 'Namur',
    [States.BE_VOV]: 'Oost-Vlaanderen',
    [States.BE_VBR]: 'Vlaams-Brabant',
    [States.BE_VWV]: 'West-Vlaanderen',
    [States.BF_BAM]: 'Bam',
    [States.BF_BAZ]: 'Bazega',
    [States.BF_BLG]: 'Boulgou',
    [States.BF_BLK]: 'Boulkiemde',
    [States.BF_COM]: 'Comoe',
    [States.BF_GAN]: 'Ganzourgou',
    [States.BF_GNA]: 'Gnagna',
    [States.BF_GOU]: 'Gourma',
    [States.BF_HOU]: 'Houet',
    [States.BF_KAD]: 'Kadiogo',
    [States.BF_KEN]: 'Kenedougou',
    [States.BF_KMP]: 'Kompienga',
    [States.BF_KOS]: 'Kossi',
    [States.BF_KOT]: 'Kouritenga',
    [States.BF_KOW]: 'Kourweogo',
    [States.BF_LER]: 'Leraba',
    [States.BF_LOR]: 'Loroum',
    [States.BF_MOU]: 'Mouhoun',
    [States.BF_NAO]: 'Nahouri',
    [States.BF_NAM]: 'Namentenga',
    [States.BF_NAY]: 'Nayala',
    [States.BF_OUB]: 'Oubritenga',
    [States.BF_OUD]: 'Oudalan',
    [States.BF_PAS]: 'Passore',
    [States.BF_PON]: 'Poni',
    [States.BF_SNG]: 'Sanguie',
    [States.BF_SMT]: 'Sanmatenga',
    [States.BF_SEN]: 'Seno',
    [States.BF_SIS]: 'Sissili',
    [States.BF_SOM]: 'Soum',
    [States.BF_SOR]: 'Sourou',
    [States.BF_TAP]: 'Tapoa',
    [States.BF_TUI]: 'Tuy',
    [States.BF_YAT]: 'Yatenga',
    [States.BF_ZIR]: 'Ziro',
    [States.BF_ZON]: 'Zondoma',
    [States.BF_ZOU]: 'Zoundweogo',
    [States.BG_01]: 'Blagoevgrad',
    [States.BG_02]: 'Burgas',
    [States.BG_08]: 'Dobrich',
    [States.BG_07]: 'Gabrovo',
    [States.BG_26]: 'Haskovo',
    [States.BG_09]: 'Kardzhali',
    [States.BG_10]: 'Kyustendil',
    [States.BG_11]: 'Lovech',
    [States.BG_12]: 'Montana',
    [States.BG_13]: 'Pazardzhik',
    [States.BG_14]: 'Pernik',
    [States.BG_15]: 'Pleven',
    [States.BG_16]: 'Plovdiv',
    [States.BG_17]: 'Razgrad',
    [States.BG_18]: 'Ruse',
    [States.BG_27]: 'Shumen',
    [States.BG_19]: 'Silistra',
    [States.BG_20]: 'Sliven',
    [States.BG_21]: 'Smolyan',
    [States.BG_23]: 'Sofia',
    [States.BG_22]: 'Sofia (stolitsa)',
    [States.BG_24]: 'Stara Zagora',
    [States.BG_25]: 'Targovishte',
    [States.BG_03]: 'Varna',
    [States.BG_04]: 'Veliko Tarnovo',
    [States.BG_05]: 'Vidin',
    [States.BG_06]: 'Vratsa',
    [States.BG_28]: 'Yambol',
    [States.BH_13]: "Al 'Asimah",
    [States.BH_14]: 'Al Janubiyah',
    [States.BH_15]: 'Al Muharraq',
    [States.BH_17]: 'Ash Shamaliyah',
    [States.BI_BM]: 'Bujumbura Mairie',
    [States.BI_CI]: 'Cibitoke',
    [States.BI_GI]: 'Gitega',
    [States.BI_KI]: 'Kirundo',
    [States.BI_MY]: 'Muyinga',
    [States.BI_MW]: 'Mwaro',
    [States.BI_NG]: 'Ngozi',
    [States.BI_RM]: 'Rumonge',
    [States.BI_RT]: 'Rutana',
    [States.BI_RY]: 'Ruyigi',
    [States.BJ_AK]: 'Atacora',
    [States.BJ_AQ]: 'Atlantique',
    [States.BJ_BO]: 'Borgou',
    [States.BJ_CO]: 'Collines',
    [States.BJ_DO]: 'Donga',
    [States.BJ_LI]: 'Littoral',
    [States.BJ_MO]: 'Mono',
    [States.BJ_OU]: 'Oueme',
    [States.BJ_PL]: 'Plateau',
    [States.BJ_ZO]: 'Zou',
    [States.BN_BE]: 'Belait',
    [States.BN_BM]: 'Brunei-Muara',
    [States.BN_TE]: 'Temburong',
    [States.BN_TU]: 'Tutong',
    [States.BO_H]: 'Chuquisaca',
    [States.BO_C]: 'Cochabamba',
    [States.BO_B]: 'El Beni',
    [States.BO_L]: 'La Paz',
    [States.BO_O]: 'Oruro',
    [States.BO_N]: 'Pando',
    [States.BO_P]: 'Potosi',
    [States.BO_S]: 'Santa Cruz',
    [States.BO_T]: 'Tarija',
    [States.BQ_BO]: 'Bonaire',
    [States.BQ_SA]: 'Saba',
    [States.BQ_SE]: 'Sint Eustatius',
    [States.BR_AC]: 'Acre',
    [States.BR_AL]: 'Alagoas',
    [States.BR_AP]: 'Amapa',
    [States.BR_AM]: 'Amazonas',
    [States.BR_BA]: 'Bahia',
    [States.BR_CE]: 'Ceara',
    [States.BR_DF]: 'Distrito Federal',
    [States.BR_ES]: 'Espirito Santo',
    [States.BR_GO]: 'Goias',
    [States.BR_MA]: 'Maranhao',
    [States.BR_MT]: 'Mato Grosso',
    [States.BR_MS]: 'Mato Grosso do Sul',
    [States.BR_MG]: 'Minas Gerais',
    [States.BR_PA]: 'Para',
    [States.BR_PB]: 'Paraiba',
    [States.BR_PR]: 'Parana',
    [States.BR_PE]: 'Pernambuco',
    [States.BR_PI]: 'Piaui',
    [States.BR_RN]: 'Rio Grande do Norte',
    [States.BR_RS]: 'Rio Grande do Sul',
    [States.BR_RJ]: 'Rio de Janeiro',
    [States.BR_RO]: 'Rondonia',
    [States.BR_RR]: 'Roraima',
    [States.BR_SC]: 'Santa Catarina',
    [States.BR_SP]: 'Sao Paulo',
    [States.BR_SE]: 'Sergipe',
    [States.BR_TO]: 'Tocantins',
    [States.BS_BP]: 'Black Point',
    [States.BS_CO]: 'Central Abaco',
    [States.BS_FP]: 'City of Freeport',
    [States.BS_EG]: 'East Grand Bahama',
    [States.BS_HI]: 'Harbour Island',
    [States.BS_LI]: 'Long Island',
    [States.BS_NP]: 'New Providence',
    [States.BS_NO]: 'North Abaco',
    [States.BS_NS]: 'North Andros',
    [States.BS_NE]: 'North Eleuthera',
    [States.BS_SE]: 'South Eleuthera',
    [States.BS_WG]: 'West Grand Bahama',
    [States.BT_33]: 'Bumthang',
    [States.BT_12]: 'Chhukha',
    [States.BT_22]: 'Dagana',
    [States.BT_GA]: 'Gasa',
    [States.BT_44]: 'Lhuentse',
    [States.BT_42]: 'Monggar',
    [States.BT_11]: 'Paro',
    [States.BT_43]: 'Pema Gatshel',
    [States.BT_23]: 'Punakha',
    [States.BT_45]: 'Samdrup Jongkhar',
    [States.BT_14]: 'Samtse',
    [States.BT_31]: 'Sarpang',
    [States.BT_15]: 'Thimphu',
    [States.BT_41]: 'Trashigang',
    [States.BT_32]: 'Trongsa',
    [States.BT_21]: 'Tsirang',
    [States.BT_24]: 'Wangdue Phodrang',
    [States.BW_CE]: 'Central',
    [States.BW_CH]: 'Chobe',
    [States.BW_GH]: 'Ghanzi',
    [States.BW_KG]: 'Kgalagadi',
    [States.BW_KL]: 'Kgatleng',
    [States.BW_KW]: 'Kweneng',
    [States.BW_NE]: 'North East',
    [States.BW_NW]: 'North West',
    [States.BW_SE]: 'South East',
    [States.BW_SO]: 'Southern',
    [States.BY_BR]: "Brestskaya voblasts'",
    [States.BY_HO]: "Homyel'skaya voblasts'",
    [States.BY_HM]: 'Horad Minsk',
    [States.BY_HR]: "Hrodzyenskaya voblasts'",
    [States.BY_MA]: "Mahilyowskaya voblasts'",
    [States.BY_MI]: "Minskaya voblasts'",
    [States.BY_VI]: "Vitsyebskaya voblasts'",
    [States.BZ_BZ]: 'Belize',
    [States.BZ_CY]: 'Cayo',
    [States.BZ_CZL]: 'Corozal',
    [States.BZ_OW]: 'Orange Walk',
    [States.BZ_SC]: 'Stann Creek',
    [States.BZ_TOL]: 'Toledo',
    [States.CA_AB]: 'Alberta',
    [States.CA_BC]: 'British Columbia',
    [States.CA_MB]: 'Manitoba',
    [States.CA_NB]: 'New Brunswick',
    [States.CA_NL]: 'Newfoundland and Labrador',
    [States.CA_NT]: 'Northwest Territories',
    [States.CA_NS]: 'Nova Scotia',
    [States.CA_NU]: 'Nunavut',
    [States.CA_ON]: 'Ontario',
    [States.CA_PE]: 'Prince Edward Island',
    [States.CA_QC]: 'Quebec',
    [States.CA_SK]: 'Saskatchewan',
    [States.CA_YT]: 'Yukon',
    [States.CD_EQ]: 'Equateur',
    [States.CD_HK]: 'Haut-Katanga',
    [States.CD_HL]: 'Haut-Lomami',
    [States.CD_IT]: 'Ituri',
    [States.CD_KC]: 'Kasai Central',
    [States.CD_KE]: 'Kasai Oriental',
    [States.CD_KN]: 'Kinshasa',
    [States.CD_BC]: 'Kongo Central',
    [States.CD_KG]: 'Kwango',
    [States.CD_KL]: 'Kwilu',
    [States.CD_LU]: 'Lualaba',
    [States.CD_NK]: 'Nord-Kivu',
    [States.CD_SA]: 'Sankuru',
    [States.CD_SK]: 'Sud-Kivu',
    [States.CD_TA]: 'Tanganyika',
    [States.CD_TO]: 'Tshopo',
    [States.CD_TU]: 'Tshuapa',
    [States.CF_BB]: 'Bamingui-Bangoran',
    [States.CF_BGF]: 'Bangui',
    [States.CF_KB]: 'Gribingui',
    [States.CF_KG]: 'Kemo-Gribingui',
    [States.CF_NM]: 'Nana-Mambere',
    [States.CF_AC]: 'Ouham',
    [States.CF_OP]: 'Ouham-Pende',
    [States.CG_BZV]: 'Brazzaville',
    [States.CG_8]: 'Cuvette',
    [States.CG_9]: 'Niari',
    [States.CG_16]: 'Pointe-Noire',
    [States.CG_13]: 'Sangha',
    [States.CH_AG]: 'Aargau',
    [States.CH_AR]: 'Appenzell Ausserrhoden',
    [States.CH_AI]: 'Appenzell Innerrhoden',
    [States.CH_BL]: 'Basel-Landschaft',
    [States.CH_BS]: 'Basel-Stadt',
    [States.CH_BE]: 'Bern',
    [States.CH_FR]: 'Fribourg',
    [States.CH_GE]: 'Geneve',
    [States.CH_GL]: 'Glarus',
    [States.CH_GR]: 'Graubunden',
    [States.CH_JU]: 'Jura',
    [States.CH_LU]: 'Luzern',
    [States.CH_NE]: 'Neuchatel',
    [States.CH_NW]: 'Nidwalden',
    [States.CH_OW]: 'Obwalden',
    [States.CH_SG]: 'Sankt Gallen',
    [States.CH_SH]: 'Schaffhausen',
    [States.CH_SZ]: 'Schwyz',
    [States.CH_SO]: 'Solothurn',
    [States.CH_TG]: 'Thurgau',
    [States.CH_TI]: 'Ticino',
    [States.CH_UR]: 'Uri',
    [States.CH_VS]: 'Valais',
    [States.CH_VD]: 'Vaud',
    [States.CH_ZG]: 'Zug',
    [States.CH_ZH]: 'Zurich',
    [States.CI_AB]: 'Abidjan',
    [States.CI_BS]: 'Bas-Sassandra',
    [States.CI_CM]: 'Comoe',
    [States.CI_DN]: 'Denguele',
    [States.CI_GD]: 'Goh-Djiboua',
    [States.CI_LC]: 'Lacs',
    [States.CI_LG]: 'Lagunes',
    [States.CI_MG]: 'Montagnes',
    [States.CI_SM]: 'Sassandra-Marahoue',
    [States.CI_SV]: 'Savanes',
    [States.CI_VB]: 'Vallee du Bandama',
    [States.CI_WR]: 'Woroba',
    [States.CI_YM]: 'Yamoussoukro',
    [States.CI_ZZ]: 'Zanzan',
    [States.CL_AI]: 'Aisen del General Carlos Ibanez del Campo',
    [States.CL_AN]: 'Antofagasta',
    [States.CL_AP]: 'Arica y Parinacota',
    [States.CL_AT]: 'Atacama',
    [States.CL_BI]: 'Biobio',
    [States.CL_CO]: 'Coquimbo',
    [States.CL_AR]: 'La Araucania',
    [States.CL_LI]: "Libertador General Bernardo O'Higgins",
    [States.CL_LL]: 'Los Lagos',
    [States.CL_LR]: 'Los Rios',
    [States.CL_MA]: 'Magallanes',
    [States.CL_ML]: 'Maule',
    [States.CL_NB]: 'Nuble',
    [States.CL_RM]: 'Region Metropolitana de Santiago',
    [States.CL_TA]: 'Tarapaca',
    [States.CL_VS]: 'Valparaiso',
    [States.CM_AD]: 'Adamaoua',
    [States.CM_CE]: 'Centre',
    [States.CM_ES]: 'Est',
    [States.CM_EN]: 'Extreme-Nord',
    [States.CM_LT]: 'Littoral',
    [States.CM_NO]: 'Nord',
    [States.CM_NW]: 'Nord-Ouest',
    [States.CM_OU]: 'Ouest',
    [States.CM_SU]: 'Sud',
    [States.CM_SW]: 'Sud-Ouest',
    [States.CN_AH]: 'Anhui',
    [States.CN_BJ]: 'Beijing',
    [States.CN_CQ]: 'Chongqing',
    [States.CN_FJ]: 'Fujian',
    [States.CN_GS]: 'Gansu',
    [States.CN_GD]: 'Guangdong',
    [States.CN_GX]: 'Guangxi Zhuangzu',
    [States.CN_GZ]: 'Guizhou',
    [States.CN_HI]: 'Hainan',
    [States.CN_HE]: 'Hebei',
    [States.CN_HL]: 'Heilongjiang',
    [States.CN_HA]: 'Henan',
    [States.CN_HB]: 'Hubei',
    [States.CN_HN]: 'Hunan',
    [States.CN_JS]: 'Jiangsu',
    [States.CN_JX]: 'Jiangxi',
    [States.CN_JL]: 'Jilin',
    [States.CN_LN]: 'Liaoning',
    [States.CN_NM]: 'Nei Mongol',
    [States.CN_NX]: 'Ningxia Huizu',
    [States.CN_QH]: 'Qinghai',
    [States.CN_SN]: 'Shaanxi',
    [States.CN_SD]: 'Shandong',
    [States.CN_SH]: 'Shanghai',
    [States.CN_SX]: 'Shanxi',
    [States.CN_SC]: 'Sichuan',
    [States.CN_TJ]: 'Tianjin',
    [States.CN_XJ]: 'Xinjiang Uygur',
    [States.CN_XZ]: 'Xizang',
    [States.CN_YN]: 'Yunnan',
    [States.CN_ZJ]: 'Zhejiang',
    [States.CO_AMA]: 'Amazonas',
    [States.CO_ANT]: 'Antioquia',
    [States.CO_ARA]: 'Arauca',
    [States.CO_ATL]: 'Atlantico',
    [States.CO_BOL]: 'Bolivar',
    [States.CO_BOY]: 'Boyaca',
    [States.CO_CAL]: 'Caldas',
    [States.CO_CAQ]: 'Caqueta',
    [States.CO_CAS]: 'Casanare',
    [States.CO_CAU]: 'Cauca',
    [States.CO_CES]: 'Cesar',
    [States.CO_CHO]: 'Choco',
    [States.CO_COR]: 'Cordoba',
    [States.CO_CUN]: 'Cundinamarca',
    [States.CO_DC]: 'Distrito Capital de Bogota',
    [States.CO_GUA]: 'Guainia',
    [States.CO_GUV]: 'Guaviare',
    [States.CO_HUI]: 'Huila',
    [States.CO_LAG]: 'La Guajira',
    [States.CO_MAG]: 'Magdalena',
    [States.CO_MET]: 'Meta',
    [States.CO_NAR]: 'Narino',
    [States.CO_NSA]: 'Norte de Santander',
    [States.CO_PUT]: 'Putumayo',
    [States.CO_QUI]: 'Quindio',
    [States.CO_RIS]: 'Risaralda',
    [States.CO_SAP]: 'San Andres, Providencia y Santa Catalina',
    [States.CO_SAN]: 'Santander',
    [States.CO_SUC]: 'Sucre',
    [States.CO_TOL]: 'Tolima',
    [States.CO_VAC]: 'Valle del Cauca',
    [States.CO_VID]: 'Vichada',
    [States.CR_A]: 'Alajuela',
    [States.CR_C]: 'Cartago',
    [States.CR_G]: 'Guanacaste',
    [States.CR_H]: 'Heredia',
    [States.CR_L]: 'Limon',
    [States.CR_P]: 'Puntarenas',
    [States.CR_SJ]: 'San Jose',
    [States.CU_15]: 'Artemisa',
    [States.CU_09]: 'Camaguey',
    [States.CU_08]: 'Ciego de Avila',
    [States.CU_06]: 'Cienfuegos',
    [States.CU_12]: 'Granma',
    [States.CU_14]: 'Guantanamo',
    [States.CU_11]: 'Holguin',
    [States.CU_03]: 'La Habana',
    [States.CU_10]: 'Las Tunas',
    [States.CU_04]: 'Matanzas',
    [States.CU_16]: 'Mayabeque',
    [States.CU_01]: 'Pinar del Rio',
    [States.CU_07]: 'Sancti Spiritus',
    [States.CU_13]: 'Santiago de Cuba',
    [States.CU_05]: 'Villa Clara',
    [States.CV_BV]: 'Boa Vista',
    [States.CV_BR]: 'Brava',
    [States.CV_MO]: 'Mosteiros',
    [States.CV_PN]: 'Porto Novo',
    [States.CV_PR]: 'Praia',
    [States.CV_RS]: 'Ribeira Grande de Santiago',
    [States.CV_SL]: 'Sal',
    [States.CV_CR]: 'Santa Cruz',
    [States.CV_SO]: 'Sao Lourenco dos Orgaos',
    [States.CV_SV]: 'Sao Vicente',
    [States.CV_TA]: 'Tarrafal',
    [States.CV_TS]: 'Tarrafal de Sao Nicolau',
    [States.CY_04]: 'Ammochostos',
    [States.CY_06]: 'Keryneia',
    [States.CY_03]: 'Larnaka',
    [States.CY_01]: 'Lefkosia',
    [States.CY_02]: 'Lemesos',
    [States.CY_05]: 'Pafos',
    [States.CZ_31]: 'Jihocesky kraj',
    [States.CZ_64]: 'Jihomoravsky kraj',
    [States.CZ_41]: 'Karlovarsky kraj',
    [States.CZ_63]: 'Kraj Vysocina',
    [States.CZ_52]: 'Kralovehradecky kraj',
    [States.CZ_51]: 'Liberecky kraj',
    [States.CZ_80]: 'Moravskoslezsky kraj',
    [States.CZ_71]: 'Olomoucky kraj',
    [States.CZ_53]: 'Pardubicky kraj',
    [States.CZ_32]: 'Plzensky kraj',
    [States.CZ_10]: 'Praha, Hlavni mesto',
    [States.CZ_20]: 'Stredocesky kraj',
    [States.CZ_42]: 'Ustecky kraj',
    [States.CZ_72]: 'Zlinsky kraj',
    [States.DE_BW]: 'Baden-Wurttemberg',
    [States.DE_BY]: 'Bayern',
    [States.DE_BE]: 'Berlin',
    [States.DE_BB]: 'Brandenburg',
    [States.DE_HB]: 'Bremen',
    [States.DE_HH]: 'Hamburg',
    [States.DE_HE]: 'Hessen',
    [States.DE_MV]: 'Mecklenburg-Vorpommern',
    [States.DE_NI]: 'Niedersachsen',
    [States.DE_NW]: 'Nordrhein-Westfalen',
    [States.DE_RP]: 'Rheinland-Pfalz',
    [States.DE_SL]: 'Saarland',
    [States.DE_SN]: 'Sachsen',
    [States.DE_ST]: 'Sachsen-Anhalt',
    [States.DE_SH]: 'Schleswig-Holstein',
    [States.DE_TH]: 'Thuringen',
    [States.DJ_AR]: 'Arta',
    [States.DJ_DJ]: 'Djibouti',
    [States.DK_84]: 'Hovedstaden',
    [States.DK_82]: 'Midtjylland',
    [States.DK_81]: 'Nordjylland',
    [States.DK_85]: 'Sjaelland',
    [States.DK_83]: 'Syddanmark',
    [States.DM_02]: 'Saint Andrew',
    [States.DM_04]: 'Saint George',
    [States.DM_05]: 'Saint John',
    [States.DM_07]: 'Saint Luke',
    [States.DM_09]: 'Saint Patrick',
    [States.DM_10]: 'Saint Paul',
    [States.DO_02]: 'Azua',
    [States.DO_03]: 'Baoruco',
    [States.DO_04]: 'Barahona',
    [States.DO_05]: 'Dajabon',
    [States.DO_01]: 'Distrito Nacional (Santo Domingo)',
    [States.DO_06]: 'Duarte',
    [States.DO_08]: 'El Seibo',
    [States.DO_07]: 'Elias Pina',
    [States.DO_09]: 'Espaillat',
    [States.DO_30]: 'Hato Mayor',
    [States.DO_19]: 'Hermanas Mirabal',
    [States.DO_10]: 'Independencia',
    [States.DO_11]: 'La Altagracia',
    [States.DO_12]: 'La Romana',
    [States.DO_13]: 'La Vega',
    [States.DO_14]: 'Maria Trinidad Sanchez',
    [States.DO_28]: 'Monsenor Nouel',
    [States.DO_15]: 'Monte Cristi',
    [States.DO_29]: 'Monte Plata',
    [States.DO_17]: 'Peravia',
    [States.DO_18]: 'Puerto Plata',
    [States.DO_20]: 'Samana',
    [States.DO_21]: 'San Cristobal',
    [States.DO_31]: 'San Jose de Ocoa',
    [States.DO_22]: 'San Juan',
    [States.DO_23]: 'San Pedro de Macoris',
    [States.DO_24]: 'Sanchez Ramirez',
    [States.DO_25]: 'Santiago',
    [States.DO_26]: 'Santiago Rodriguez',
    [States.DO_27]: 'Valverde',
    [States.DZ_01]: 'Adrar',
    [States.DZ_44]: 'Ain Defla',
    [States.DZ_46]: 'Ain Temouchent',
    [States.DZ_16]: 'Alger',
    [States.DZ_23]: 'Annaba',
    [States.DZ_05]: 'Batna',
    [States.DZ_08]: 'Bechar',
    [States.DZ_06]: 'Bejaia',
    [States.DZ_07]: 'Biskra',
    [States.DZ_09]: 'Blida',
    [States.DZ_34]: 'Bordj Bou Arreridj',
    [States.DZ_10]: 'Bouira',
    [States.DZ_35]: 'Boumerdes',
    [States.DZ_02]: 'Chlef',
    [States.DZ_25]: 'Constantine',
    [States.DZ_17]: 'Djelfa',
    [States.DZ_32]: 'El Bayadh',
    [States.DZ_39]: 'El Oued',
    [States.DZ_36]: 'El Tarf',
    [States.DZ_47]: 'Ghardaia',
    [States.DZ_24]: 'Guelma',
    [States.DZ_33]: 'Illizi',
    [States.DZ_18]: 'Jijel',
    [States.DZ_40]: 'Khenchela',
    [States.DZ_03]: 'Laghouat',
    [States.DZ_28]: "M'sila",
    [States.DZ_29]: 'Mascara',
    [States.DZ_26]: 'Medea',
    [States.DZ_43]: 'Mila',
    [States.DZ_27]: 'Mostaganem',
    [States.DZ_45]: 'Naama',
    [States.DZ_31]: 'Oran',
    [States.DZ_30]: 'Ouargla',
    [States.DZ_04]: 'Oum el Bouaghi',
    [States.DZ_48]: 'Relizane',
    [States.DZ_20]: 'Saida',
    [States.DZ_19]: 'Setif',
    [States.DZ_22]: 'Sidi Bel Abbes',
    [States.DZ_21]: 'Skikda',
    [States.DZ_41]: 'Souk Ahras',
    [States.DZ_11]: 'Tamanrasset',
    [States.DZ_12]: 'Tebessa',
    [States.DZ_14]: 'Tiaret',
    [States.DZ_37]: 'Tindouf',
    [States.DZ_42]: 'Tipaza',
    [States.DZ_38]: 'Tissemsilt',
    [States.DZ_15]: 'Tizi Ouzou',
    [States.DZ_13]: 'Tlemcen',
    [States.EC_A]: 'Azuay',
    [States.EC_B]: 'Bolivar',
    [States.EC_F]: 'Canar',
    [States.EC_C]: 'Carchi',
    [States.EC_H]: 'Chimborazo',
    [States.EC_X]: 'Cotopaxi',
    [States.EC_O]: 'El Oro',
    [States.EC_E]: 'Esmeraldas',
    [States.EC_W]: 'Galapagos',
    [States.EC_G]: 'Guayas',
    [States.EC_I]: 'Imbabura',
    [States.EC_L]: 'Loja',
    [States.EC_R]: 'Los Rios',
    [States.EC_M]: 'Manabi',
    [States.EC_S]: 'Morona Santiago',
    [States.EC_N]: 'Napo',
    [States.EC_D]: 'Orellana',
    [States.EC_Y]: 'Pastaza',
    [States.EC_P]: 'Pichincha',
    [States.EC_SE]: 'Santa Elena',
    [States.EC_SD]: 'Santo Domingo de los Tsachilas',
    [States.EC_U]: 'Sucumbios',
    [States.EC_T]: 'Tungurahua',
    [States.EC_Z]: 'Zamora Chinchipe',
    [States.EE_37]: 'Harjumaa',
    [States.EE_39]: 'Hiiumaa',
    [States.EE_45]: 'Ida-Virumaa',
    [States.EE_52]: 'Jarvamaa',
    [States.EE_50]: 'Jogevamaa',
    [States.EE_60]: 'Laane-Virumaa',
    [States.EE_56]: 'Laanemaa',
    [States.EE_68]: 'Parnumaa',
    [States.EE_64]: 'Polvamaa',
    [States.EE_71]: 'Raplamaa',
    [States.EE_74]: 'Saaremaa',
    [States.EE_79]: 'Tartumaa',
    [States.EE_81]: 'Valgamaa',
    [States.EE_84]: 'Viljandimaa',
    [States.EE_87]: 'Vorumaa',
    [States.EG_DK]: 'Ad Daqahliyah',
    [States.EG_BA]: 'Al Bahr al Ahmar',
    [States.EG_BH]: 'Al Buhayrah',
    [States.EG_FYM]: 'Al Fayyum',
    [States.EG_GH]: 'Al Gharbiyah',
    [States.EG_ALX]: 'Al Iskandariyah',
    [States.EG_IS]: "Al Isma'iliyah",
    [States.EG_GZ]: 'Al Jizah',
    [States.EG_MNF]: 'Al Minufiyah',
    [States.EG_MN]: 'Al Minya',
    [States.EG_C]: 'Al Qahirah',
    [States.EG_KB]: 'Al Qalyubiyah',
    [States.EG_LX]: 'Al Uqsur',
    [States.EG_WAD]: 'Al Wadi al Jadid',
    [States.EG_SUZ]: 'As Suways',
    [States.EG_SHR]: 'Ash Sharqiyah',
    [States.EG_ASN]: 'Aswan',
    [States.EG_AST]: 'Asyut',
    [States.EG_BNS]: 'Bani Suwayf',
    [States.EG_PTS]: "Bur Sa'id",
    [States.EG_DT]: 'Dumyat',
    [States.EG_JS]: "Janub Sina'",
    [States.EG_KFS]: 'Kafr ash Shaykh',
    [States.EG_MT]: 'Matruh',
    [States.EG_KN]: 'Qina',
    [States.EG_SIN]: "Shamal Sina'",
    [States.EG_SHG]: 'Suhaj',
    [States.ER_MA]: 'Al Awsat',
    [States.ER_DK]: 'Janubi al Bahri al Ahmar',
    [States.ES_AN]: 'Andalucia',
    [States.ES_AR]: 'Aragon',
    [States.ES_AS]: 'Asturias, Principado de',
    [States.ES_CN]: 'Canarias',
    [States.ES_CB]: 'Cantabria',
    [States.ES_CL]: 'Castilla y Leon',
    [States.ES_CM]: 'Castilla-La Mancha',
    [States.ES_CT]: 'Catalunya',
    [States.ES_CE]: 'Ceuta',
    [States.ES_EX]: 'Extremadura',
    [States.ES_GA]: 'Galicia',
    [States.ES_IB]: 'Illes Balears',
    [States.ES_RI]: 'La Rioja',
    [States.ES_MD]: 'Madrid, Comunidad de',
    [States.ES_ML]: 'Melilla',
    [States.ES_MC]: 'Murcia, Region de',
    [States.ES_NC]: 'Navarra, Comunidad Foral de',
    [States.ES_PV]: 'Pais Vasco',
    [States.ES_VC]: 'Valenciana, Comunidad',
    [States.ET_AA]: 'Adis Abeba',
    [States.ET_AF]: 'Afar',
    [States.ET_AM]: 'Amara',
    [States.ET_BE]: 'Binshangul Gumuz',
    [States.ET_DD]: 'Dire Dawa',
    [States.ET_GA]: 'Gambela Hizboch',
    [States.ET_HA]: 'Hareri Hizb',
    [States.ET_OR]: 'Oromiya',
    [States.ET_SO]: 'Sumale',
    [States.ET_TI]: 'Tigray',
    [States.ET_SN]: 'YeDebub Biheroch Bihereseboch na Hizboch',
    [States.FI_02]: 'Etela-Karjala',
    [States.FI_03]: 'Etela-Pohjanmaa',
    [States.FI_04]: 'Etela-Savo',
    [States.FI_05]: 'Kainuu',
    [States.FI_06]: 'Kanta-Hame',
    [States.FI_07]: 'Keski-Pohjanmaa',
    [States.FI_08]: 'Keski-Suomi',
    [States.FI_09]: 'Kymenlaakso',
    [States.FI_10]: 'Lappi',
    [States.FI_16]: 'Paijat-Hame',
    [States.FI_11]: 'Pirkanmaa',
    [States.FI_12]: 'Pohjanmaa',
    [States.FI_13]: 'Pohjois-Karjala',
    [States.FI_14]: 'Pohjois-Pohjanmaa',
    [States.FI_15]: 'Pohjois-Savo',
    [States.FI_17]: 'Satakunta',
    [States.FI_18]: 'Uusimaa',
    [States.FI_19]: 'Varsinais-Suomi',
    [States.FJ_C]: 'Central',
    [States.FJ_E]: 'Eastern',
    [States.FJ_N]: 'Northern',
    [States.FJ_R]: 'Rotuma',
    [States.FJ_W]: 'Western',
    [States.FM_TRK]: 'Chuuk',
    [States.FM_KSA]: 'Kosrae',
    [States.FM_PNI]: 'Pohnpei',
    [States.FM_YAP]: 'Yap',
    [States.FR_ARA]: 'Auvergne-Rhone-Alpes',
    [States.FR_BFC]: 'Bourgogne-Franche-Comte',
    [States.FR_BRE]: 'Bretagne',
    [States.FR_CVL]: 'Centre-Val de Loire',
    [States.FR_20R]: 'Corse',
    [States.FR_GES]: 'Grand-Est',
    [States.FR_HDF]: 'Hauts-de-France',
    [States.FR_IDF]: 'Ile-de-France',
    [States.FR_NOR]: 'Normandie',
    [States.FR_NAQ]: 'Nouvelle-Aquitaine',
    [States.FR_OCC]: 'Occitanie',
    [States.FR_PDL]: 'Pays-de-la-Loire',
    [States.FR_PAC]: "Provence-Alpes-Cote-d'Azur",
    [States.GA_1]: 'Estuaire',
    [States.GA_2]: 'Haut-Ogooue',
    [States.GA_4]: 'Ngounie',
    [States.GA_8]: 'Ogooue-Maritime',
    [States.GA_9]: 'Woleu-Ntem',
    [States.GB_ENG]: 'England',
    [States.GB_NIR]: 'Northern Ireland',
    [States.GB_SCT]: 'Scotland',
    [States.GB_WLS]: 'Wales',
    [States.GD_01]: 'Saint Andrew',
    [States.GD_02]: 'Saint David',
    [States.GD_03]: 'Saint George',
    [States.GD_04]: 'Saint John',
    [States.GD_05]: 'Saint Mark',
    [States.GD_06]: 'Saint Patrick',
    [States.GD_10]: 'Southern Grenadine Islands',
    [States.GE_AB]: 'Abkhazia',
    [States.GE_AJ]: 'Ajaria',
    [States.GE_GU]: 'Guria',
    [States.GE_IM]: 'Imereti',
    [States.GE_KA]: "K'akheti",
    [States.GE_KK]: 'Kvemo Kartli',
    [States.GE_MM]: 'Mtskheta-Mtianeti',
    [States.GE_RL]: "Rach'a-Lechkhumi-Kvemo Svaneti",
    [States.GE_SZ]: 'Samegrelo-Zemo Svaneti',
    [States.GE_SJ]: 'Samtskhe-Javakheti',
    [States.GE_SK]: 'Shida Kartli',
    [States.GE_TB]: 'Tbilisi',
    [States.GH_AF]: 'Ahafo',
    [States.GH_AH]: 'Ashanti',
    [States.GH_BO]: 'Bono',
    [States.GH_BE]: 'Bono East',
    [States.GH_CP]: 'Central',
    [States.GH_EP]: 'Eastern',
    [States.GH_AA]: 'Greater Accra',
    [States.GH_NP]: 'Northern',
    [States.GH_UE]: 'Upper East',
    [States.GH_UW]: 'Upper West',
    [States.GH_TV]: 'Volta',
    [States.GH_WP]: 'Western',
    [States.GL_AV]: 'Avannaata Kommunia',
    [States.GL_KU]: 'Kommune Kujalleq',
    [States.GL_QT]: 'Kommune Qeqertalik',
    [States.GL_SM]: 'Kommuneqarfik Sermersooq',
    [States.GL_QE]: 'Qeqqata Kommunia',
    [States.GM_B]: 'Banjul',
    [States.GM_M]: 'Central River',
    [States.GM_L]: 'Lower River',
    [States.GM_N]: 'North Bank',
    [States.GM_U]: 'Upper River',
    [States.GM_W]: 'Western',
    [States.GN_BF]: 'Boffa',
    [States.GN_B]: 'Boke',
    [States.GN_C]: 'Conakry',
    [States.GN_CO]: 'Coyah',
    [States.GN_DB]: 'Dabola',
    [States.GN_DU]: 'Dubreka',
    [States.GN_K]: 'Kankan',
    [States.GN_L]: 'Labe',
    [States.GN_LA]: 'Labe',
    [States.GN_MC]: 'Macenta',
    [States.GN_N]: 'Nzerekore',
    [States.GN_SI]: 'Siguiri',
    [States.GQ_BN]: 'Bioko Norte',
    [States.GQ_KN]: 'Kie-Ntem',
    [States.GQ_LI]: 'Litoral',
    [States.GQ_WN]: 'Wele-Nzas',
    [States.GR_A]: 'Anatoliki Makedonia kai Thraki',
    [States.GR_I]: 'Attiki',
    [States.GR_G]: 'Dytiki Ellada',
    [States.GR_C]: 'Dytiki Makedonia',
    [States.GR_F]: 'Ionia Nisia',
    [States.GR_D]: 'Ipeiros',
    [States.GR_B]: 'Kentriki Makedonia',
    [States.GR_M]: 'Kriti',
    [States.GR_L]: 'Notio Aigaio',
    [States.GR_J]: 'Peloponnisos',
    [States.GR_H]: 'Sterea Ellada',
    [States.GR_E]: 'Thessalia',
    [States.GR_K]: 'Voreio Aigaio',
    [States.GT_16]: 'Alta Verapaz',
    [States.GT_15]: 'Baja Verapaz',
    [States.GT_04]: 'Chimaltenango',
    [States.GT_20]: 'Chiquimula',
    [States.GT_02]: 'El Progreso',
    [States.GT_05]: 'Escuintla',
    [States.GT_01]: 'Guatemala',
    [States.GT_13]: 'Huehuetenango',
    [States.GT_18]: 'Izabal',
    [States.GT_21]: 'Jalapa',
    [States.GT_22]: 'Jutiapa',
    [States.GT_17]: 'Peten',
    [States.GT_09]: 'Quetzaltenango',
    [States.GT_14]: 'Quiche',
    [States.GT_11]: 'Retalhuleu',
    [States.GT_03]: 'Sacatepequez',
    [States.GT_12]: 'San Marcos',
    [States.GT_06]: 'Santa Rosa',
    [States.GT_07]: 'Solola',
    [States.GT_10]: 'Suchitepequez',
    [States.GT_08]: 'Totonicapan',
    [States.GT_19]: 'Zacapa',
    [States.GW_BS]: 'Bissau',
    [States.GW_GA]: 'Gabu',
    [States.GY_BA]: 'Barima-Waini',
    [States.GY_CU]: 'Cuyuni-Mazaruni',
    [States.GY_DE]: 'Demerara-Mahaica',
    [States.GY_EB]: 'East Berbice-Corentyne',
    [States.GY_ES]: 'Essequibo Islands-West Demerara',
    [States.GY_MA]: 'Mahaica-Berbice',
    [States.GY_PT]: 'Potaro-Siparuni',
    [States.GY_UD]: 'Upper Demerara-Berbice',
    [States.HN_AT]: 'Atlantida',
    [States.HN_CH]: 'Choluteca',
    [States.HN_CL]: 'Colon',
    [States.HN_CM]: 'Comayagua',
    [States.HN_CP]: 'Copan',
    [States.HN_CR]: 'Cortes',
    [States.HN_EP]: 'El Paraiso',
    [States.HN_FM]: 'Francisco Morazan',
    [States.HN_GD]: 'Gracias a Dios',
    [States.HN_IN]: 'Intibuca',
    [States.HN_IB]: 'Islas de la Bahia',
    [States.HN_LP]: 'La Paz',
    [States.HN_LE]: 'Lempira',
    [States.HN_OC]: 'Ocotepeque',
    [States.HN_OL]: 'Olancho',
    [States.HN_SB]: 'Santa Barbara',
    [States.HN_VA]: 'Valle',
    [States.HN_YO]: 'Yoro',
    [States.HR_07]: 'Bjelovarsko-bilogorska zupanija',
    [States.HR_12]: 'Brodsko-posavska zupanija',
    [States.HR_19]: 'Dubrovacko-neretvanska zupanija',
    [States.HR_21]: 'Grad Zagreb',
    [States.HR_18]: 'Istarska zupanija',
    [States.HR_04]: 'Karlovacka zupanija',
    [States.HR_06]: 'Koprivnicko-krizevacka zupanija',
    [States.HR_02]: 'Krapinsko-zagorska zupanija',
    [States.HR_09]: 'Licko-senjska zupanija',
    [States.HR_20]: 'Medimurska zupanija',
    [States.HR_14]: 'Osjecko-baranjska zupanija',
    [States.HR_11]: 'Pozesko-slavonska zupanija',
    [States.HR_08]: 'Primorsko-goranska zupanija',
    [States.HR_15]: 'Sibensko-kninska zupanija',
    [States.HR_03]: 'Sisacko-moslavacka zupanija',
    [States.HR_17]: 'Splitsko-dalmatinska zupanija',
    [States.HR_05]: 'Varazdinska zupanija',
    [States.HR_10]: 'Viroviticko-podravska zupanija',
    [States.HR_16]: 'Vukovarsko-srijemska zupanija',
    [States.HR_13]: 'Zadarska zupanija',
    [States.HR_01]: 'Zagrebacka zupanija',
    [States.HT_AR]: 'Artibonite',
    [States.HT_CE]: 'Centre',
    [States.HT_GA]: "Grande'Anse",
    [States.HT_NI]: 'Nippes',
    [States.HT_ND]: 'Nord',
    [States.HT_NE]: 'Nord-Est',
    [States.HT_OU]: 'Ouest',
    [States.HT_SD]: 'Sud',
    [States.HT_SE]: 'Sud-Est',
    [States.HU_BK]: 'Bacs-Kiskun',
    [States.HU_BA]: 'Baranya',
    [States.HU_BE]: 'Bekes',
    [States.HU_BZ]: 'Borsod-Abauj-Zemplen',
    [States.HU_BU]: 'Budapest',
    [States.HU_CS]: 'Csongrad-Csanad',
    [States.HU_FE]: 'Fejer',
    [States.HU_GS]: 'Gyor-Moson-Sopron',
    [States.HU_HB]: 'Hajdu-Bihar',
    [States.HU_HE]: 'Heves',
    [States.HU_JN]: 'Jasz-Nagykun-Szolnok',
    [States.HU_KE]: 'Komarom-Esztergom',
    [States.HU_NO]: 'Nograd',
    [States.HU_PE]: 'Pest',
    [States.HU_SO]: 'Somogy',
    [States.HU_SZ]: 'Szabolcs-Szatmar-Bereg',
    [States.HU_TO]: 'Tolna',
    [States.HU_VA]: 'Vas',
    [States.HU_VE]: 'Veszprem',
    [States.HU_ZA]: 'Zala',
    [States.ID_AC]: 'Aceh',
    [States.ID_BA]: 'Bali',
    [States.ID_BT]: 'Banten',
    [States.ID_BE]: 'Bengkulu',
    [States.ID_GO]: 'Gorontalo',
    [States.ID_JK]: 'Jakarta Raya',
    [States.ID_JA]: 'Jambi',
    [States.ID_JB]: 'Jawa Barat',
    [States.ID_JT]: 'Jawa Tengah',
    [States.ID_JI]: 'Jawa Timur',
    [States.ID_KB]: 'Kalimantan Barat',
    [States.ID_KS]: 'Kalimantan Selatan',
    [States.ID_KT]: 'Kalimantan Tengah',
    [States.ID_KI]: 'Kalimantan Timur',
    [States.ID_KU]: 'Kalimantan Utara',
    [States.ID_BB]: 'Kepulauan Bangka Belitung',
    [States.ID_KR]: 'Kepulauan Riau',
    [States.ID_LA]: 'Lampung',
    [States.ID_ML]: 'Maluku',
    [States.ID_MU]: 'Maluku Utara',
    [States.ID_NB]: 'Nusa Tenggara Barat',
    [States.ID_NT]: 'Nusa Tenggara Timur',
    [States.ID_PP]: 'Papua',
    [States.ID_PB]: 'Papua Barat',
    [States.ID_RI]: 'Riau',
    [States.ID_SR]: 'Sulawesi Barat',
    [States.ID_SN]: 'Sulawesi Selatan',
    [States.ID_ST]: 'Sulawesi Tengah',
    [States.ID_SG]: 'Sulawesi Tenggara',
    [States.ID_SA]: 'Sulawesi Utara',
    [States.ID_SB]: 'Sumatera Barat',
    [States.ID_SS]: 'Sumatera Selatan',
    [States.ID_SU]: 'Sumatera Utara',
    [States.ID_YO]: 'Yogyakarta',
    [States.IE_CW]: 'Carlow',
    [States.IE_CN]: 'Cavan',
    [States.IE_CE]: 'Clare',
    [States.IE_CO]: 'Cork',
    [States.IE_DL]: 'Donegal',
    [States.IE_D]: 'Dublin',
    [States.IE_G]: 'Galway',
    [States.IE_KY]: 'Kerry',
    [States.IE_KE]: 'Kildare',
    [States.IE_KK]: 'Kilkenny',
    [States.IE_LS]: 'Laois',
    [States.IE_LM]: 'Leitrim',
    [States.IE_LK]: 'Limerick',
    [States.IE_LD]: 'Longford',
    [States.IE_LH]: 'Louth',
    [States.IE_MO]: 'Mayo',
    [States.IE_MH]: 'Meath',
    [States.IE_MN]: 'Monaghan',
    [States.IE_OY]: 'Offaly',
    [States.IE_RN]: 'Roscommon',
    [States.IE_SO]: 'Sligo',
    [States.IE_TA]: 'Tipperary',
    [States.IE_WD]: 'Waterford',
    [States.IE_WH]: 'Westmeath',
    [States.IE_WX]: 'Wexford',
    [States.IE_WW]: 'Wicklow',
    [States.IL_D]: 'HaDarom',
    [States.IL_M]: 'HaMerkaz',
    [States.IL_Z]: 'HaTsafon',
    [States.IL_HA]: 'Hefa',
    [States.IL_TA]: 'Tel Aviv',
    [States.IL_JM]: 'Yerushalayim',
    [States.IN_AN]: 'Andaman and Nicobar Islands',
    [States.IN_AP]: 'Andhra Pradesh',
    [States.IN_AR]: 'Arunachal Pradesh',
    [States.IN_AS]: 'Assam',
    [States.IN_BR]: 'Bihar',
    [States.IN_CH]: 'Chandigarh',
    [States.IN_CT]: 'Chhattisgarh',
    [States.IN_DN]: 'Dadra and Nagar Haveli',
    [States.IN_DH]: 'Dadra and Nagar Haveli and Daman and Diu',
    [States.IN_DL]: 'Delhi',
    [States.IN_GA]: 'Goa',
    [States.IN_GJ]: 'Gujarat',
    [States.IN_HR]: 'Haryana',
    [States.IN_HP]: 'Himachal Pradesh',
    [States.IN_JK]: 'Jammu and Kashmir',
    [States.IN_JH]: 'Jharkhand',
    [States.IN_KA]: 'Karnataka',
    [States.IN_KL]: 'Kerala',
    [States.IN_LD]: 'Lakshadweep',
    [States.IN_MP]: 'Madhya Pradesh',
    [States.IN_MH]: 'Maharashtra',
    [States.IN_MN]: 'Manipur',
    [States.IN_ML]: 'Meghalaya',
    [States.IN_MZ]: 'Mizoram',
    [States.IN_NL]: 'Nagaland',
    [States.IN_OR]: 'Odisha',
    [States.IN_PY]: 'Puducherry',
    [States.IN_PB]: 'Punjab',
    [States.IN_RJ]: 'Rajasthan',
    [States.IN_SK]: 'Sikkim',
    [States.IN_TN]: 'Tamil Nadu',
    [States.IN_TG]: 'Telangana',
    [States.IN_TR]: 'Tripura',
    [States.IN_UP]: 'Uttar Pradesh',
    [States.IN_UT]: 'Uttarakhand',
    [States.IN_WB]: 'West Bengal',
    [States.IQ_AN]: 'Al Anbar',
    [States.IQ_BA]: 'Al Basrah',
    [States.IQ_MU]: 'Al Muthanna',
    [States.IQ_QA]: 'Al Qadisiyah',
    [States.IQ_NA]: 'An Najaf',
    [States.IQ_AR]: 'Arbil',
    [States.IQ_SU]: 'As Sulaymaniyah',
    [States.IQ_BB]: 'Babil',
    [States.IQ_BG]: 'Baghdad',
    [States.IQ_DA]: 'Dahuk',
    [States.IQ_DQ]: 'Dhi Qar',
    [States.IQ_DI]: 'Diyala',
    [States.IQ_KA]: "Karbala'",
    [States.IQ_KI]: 'Kirkuk',
    [States.IQ_MA]: 'Maysan',
    [States.IQ_NI]: 'Ninawa',
    [States.IQ_SD]: 'Salah ad Din',
    [States.IQ_WA]: 'Wasit',
    [States.IR_30]: 'Alborz',
    [States.IR_24]: 'Ardabil',
    [States.IR_04]: 'Azarbayjan-e Gharbi',
    [States.IR_03]: 'Azarbayjan-e Sharqi',
    [States.IR_18]: 'Bushehr',
    [States.IR_14]: 'Chahar Mahal va Bakhtiari',
    [States.IR_10]: 'Esfahan',
    [States.IR_07]: 'Fars',
    [States.IR_01]: 'Gilan',
    [States.IR_27]: 'Golestan',
    [States.IR_13]: 'Hamadan',
    [States.IR_22]: 'Hormozgan',
    [States.IR_16]: 'Ilam',
    [States.IR_08]: 'Kerman',
    [States.IR_05]: 'Kermanshah',
    [States.IR_29]: 'Khorasan-e Jonubi',
    [States.IR_09]: 'Khorasan-e Razavi',
    [States.IR_28]: 'Khorasan-e Shomali',
    [States.IR_06]: 'Khuzestan',
    [States.IR_17]: 'Kohgiluyeh va Bowyer Ahmad',
    [States.IR_12]: 'Kordestan',
    [States.IR_15]: 'Lorestan',
    [States.IR_00]: 'Markazi',
    [States.IR_02]: 'Mazandaran',
    [States.IR_26]: 'Qazvin',
    [States.IR_25]: 'Qom',
    [States.IR_20]: 'Semnan',
    [States.IR_11]: 'Sistan va Baluchestan',
    [States.IR_23]: 'Tehran',
    [States.IR_21]: 'Yazd',
    [States.IR_19]: 'Zanjan',
    [States.IS_7]: 'Austurland',
    [States.IS_1]: 'Hofudborgarsvaedi',
    [States.IS_6]: 'Nordurland eystra',
    [States.IS_5]: 'Nordurland vestra',
    [States.IS_8]: 'Sudurland',
    [States.IS_2]: 'Sudurnes',
    [States.IS_4]: 'Vestfirdir',
    [States.IS_3]: 'Vesturland',
    [States.IT_65]: 'Abruzzo',
    [States.IT_77]: 'Basilicata',
    [States.IT_78]: 'Calabria',
    [States.IT_72]: 'Campania',
    [States.IT_45]: 'Emilia-Romagna',
    [States.IT_36]: 'Friuli-Venezia Giulia',
    [States.IT_62]: 'Lazio',
    [States.IT_42]: 'Liguria',
    [States.IT_25]: 'Lombardia',
    [States.IT_57]: 'Marche',
    [States.IT_67]: 'Molise',
    [States.IT_21]: 'Piemonte',
    [States.IT_75]: 'Puglia',
    [States.IT_88]: 'Sardegna',
    [States.IT_82]: 'Sicilia',
    [States.IT_52]: 'Toscana',
    [States.IT_32]: 'Trentino-Alto Adige',
    [States.IT_55]: 'Umbria',
    [States.IT_23]: "Valle d'Aosta",
    [States.IT_34]: 'Veneto',
    [States.JM_13]: 'Clarendon',
    [States.JM_09]: 'Hanover',
    [States.JM_01]: 'Kingston',
    [States.JM_12]: 'Manchester',
    [States.JM_04]: 'Portland',
    [States.JM_02]: 'Saint Andrew',
    [States.JM_06]: 'Saint Ann',
    [States.JM_14]: 'Saint Catherine',
    [States.JM_11]: 'Saint Elizabeth',
    [States.JM_08]: 'Saint James',
    [States.JM_05]: 'Saint Mary',
    [States.JM_03]: 'Saint Thomas',
    [States.JM_07]: 'Trelawny',
    [States.JM_10]: 'Westmoreland',
    [States.JO_AJ]: 'Ajlun',
    [States.JO_AQ]: "Al 'Aqabah",
    [States.JO_AM]: "Al 'Asimah",
    [States.JO_BA]: "Al Balqa'",
    [States.JO_KA]: 'Al Karak',
    [States.JO_MA]: 'Al Mafraq',
    [States.JO_AT]: 'At Tafilah',
    [States.JO_AZ]: "Az Zarqa'",
    [States.JO_IR]: 'Irbid',
    [States.JO_JA]: 'Jarash',
    [States.JO_MN]: "Ma'an",
    [States.JO_MD]: 'Madaba',
    [States.JP_23]: 'Aichi',
    [States.JP_05]: 'Akita',
    [States.JP_02]: 'Aomori',
    [States.JP_12]: 'Chiba',
    [States.JP_38]: 'Ehime',
    [States.JP_18]: 'Fukui',
    [States.JP_40]: 'Fukuoka',
    [States.JP_07]: 'Fukushima',
    [States.JP_21]: 'Gifu',
    [States.JP_10]: 'Gunma',
    [States.JP_34]: 'Hiroshima',
    [States.JP_01]: 'Hokkaido',
    [States.JP_28]: 'Hyogo',
    [States.JP_08]: 'Ibaraki',
    [States.JP_17]: 'Ishikawa',
    [States.JP_03]: 'Iwate',
    [States.JP_37]: 'Kagawa',
    [States.JP_46]: 'Kagoshima',
    [States.JP_14]: 'Kanagawa',
    [States.JP_39]: 'Kochi',
    [States.JP_43]: 'Kumamoto',
    [States.JP_26]: 'Kyoto',
    [States.JP_24]: 'Mie',
    [States.JP_04]: 'Miyagi',
    [States.JP_45]: 'Miyazaki',
    [States.JP_20]: 'Nagano',
    [States.JP_42]: 'Nagasaki',
    [States.JP_29]: 'Nara',
    [States.JP_15]: 'Niigata',
    [States.JP_44]: 'Oita',
    [States.JP_33]: 'Okayama',
    [States.JP_47]: 'Okinawa',
    [States.JP_27]: 'Osaka',
    [States.JP_41]: 'Saga',
    [States.JP_11]: 'Saitama',
    [States.JP_25]: 'Shiga',
    [States.JP_32]: 'Shimane',
    [States.JP_22]: 'Shizuoka',
    [States.JP_09]: 'Tochigi',
    [States.JP_36]: 'Tokushima',
    [States.JP_13]: 'Tokyo',
    [States.JP_31]: 'Tottori',
    [States.JP_16]: 'Toyama',
    [States.JP_30]: 'Wakayama',
    [States.JP_06]: 'Yamagata',
    [States.JP_35]: 'Yamaguchi',
    [States.JP_19]: 'Yamanashi',
    [States.KE_01]: 'Baringo',
    [States.KE_02]: 'Bomet',
    [States.KE_03]: 'Bungoma',
    [States.KE_04]: 'Busia',
    [States.KE_05]: 'Elgeyo/Marakwet',
    [States.KE_06]: 'Embu',
    [States.KE_07]: 'Garissa',
    [States.KE_08]: 'Homa Bay',
    [States.KE_09]: 'Isiolo',
    [States.KE_10]: 'Kajiado',
    [States.KE_11]: 'Kakamega',
    [States.KE_12]: 'Kericho',
    [States.KE_13]: 'Kiambu',
    [States.KE_14]: 'Kilifi',
    [States.KE_15]: 'Kirinyaga',
    [States.KE_16]: 'Kisii',
    [States.KE_17]: 'Kisumu',
    [States.KE_18]: 'Kitui',
    [States.KE_19]: 'Kwale',
    [States.KE_20]: 'Laikipia',
    [States.KE_21]: 'Lamu',
    [States.KE_22]: 'Machakos',
    [States.KE_23]: 'Makueni',
    [States.KE_24]: 'Mandera',
    [States.KE_25]: 'Marsabit',
    [States.KE_26]: 'Meru',
    [States.KE_27]: 'Migori',
    [States.KE_28]: 'Mombasa',
    [States.KE_29]: "Murang'a",
    [States.KE_30]: 'Nairobi City',
    [States.KE_31]: 'Nakuru',
    [States.KE_32]: 'Nandi',
    [States.KE_33]: 'Narok',
    [States.KE_34]: 'Nyamira',
    [States.KE_35]: 'Nyandarua',
    [States.KE_36]: 'Nyeri',
    [States.KE_37]: 'Samburu',
    [States.KE_38]: 'Siaya',
    [States.KE_39]: 'Taita/Taveta',
    [States.KE_40]: 'Tana River',
    [States.KE_41]: 'Tharaka-Nithi',
    [States.KE_42]: 'Trans Nzoia',
    [States.KE_43]: 'Turkana',
    [States.KE_44]: 'Uasin Gishu',
    [States.KE_45]: 'Vihiga',
    [States.KE_46]: 'Wajir',
    [States.KE_47]: 'West Pokot',
    [States.KG_B]: 'Batken',
    [States.KG_GB]: 'Bishkek Shaary',
    [States.KG_C]: 'Chuy',
    [States.KG_J]: 'Jalal-Abad',
    [States.KG_N]: 'Naryn',
    [States.KG_GO]: 'Osh Shaary',
    [States.KG_T]: 'Talas',
    [States.KG_Y]: 'Ysyk-Kol',
    [States.KH_2]: 'Baat Dambang',
    [States.KH_1]: 'Banteay Mean Choay',
    [States.KH_23]: 'Kaeb',
    [States.KH_3]: 'Kampong Chaam',
    [States.KH_4]: 'Kampong Chhnang',
    [States.KH_5]: 'Kampong Spueu',
    [States.KH_6]: 'Kampong Thum',
    [States.KH_7]: 'Kampot',
    [States.KH_8]: 'Kandaal',
    [States.KH_10]: 'Kracheh',
    [States.KH_11]: 'Mondol Kiri',
    [States.KH_24]: 'Pailin',
    [States.KH_12]: 'Phnom Penh',
    [States.KH_15]: 'Pousaat',
    [States.KH_18]: 'Preah Sihanouk',
    [States.KH_14]: 'Prey Veaeng',
    [States.KH_16]: 'Rotanak Kiri',
    [States.KH_17]: 'Siem Reab',
    [States.KH_19]: 'Stueng Traeng',
    [States.KH_20]: 'Svaay Rieng',
    [States.KH_21]: 'Taakaev',
    [States.KI_G]: 'Gilbert Islands',
    [States.KM_G]: 'Grande Comore',
    [States.KN_01]: 'Christ Church Nichola Town',
    [States.KN_02]: 'Saint Anne Sandy Point',
    [States.KN_03]: 'Saint George Basseterre',
    [States.KN_05]: 'Saint James Windward',
    [States.KN_06]: 'Saint John Capisterre',
    [States.KN_07]: 'Saint John Figtree',
    [States.KN_08]: 'Saint Mary Cayon',
    [States.KN_09]: 'Saint Paul Capisterre',
    [States.KN_10]: 'Saint Paul Charlestown',
    [States.KN_11]: 'Saint Peter Basseterre',
    [States.KN_12]: 'Saint Thomas Lowland',
    [States.KN_13]: 'Saint Thomas Middle Island',
    [States.KN_15]: 'Trinity Palmetto Point',
    [States.KP_01]: "P'yongyang",
    [States.KR_26]: 'Busan-gwangyeoksi',
    [States.KR_43]: 'Chungcheongbuk-do',
    [States.KR_44]: 'Chungcheongnam-do',
    [States.KR_27]: 'Daegu-gwangyeoksi',
    [States.KR_30]: 'Daejeon-gwangyeoksi',
    [States.KR_42]: 'Gangwon-do',
    [States.KR_29]: 'Gwangju-gwangyeoksi',
    [States.KR_41]: 'Gyeonggi-do',
    [States.KR_47]: 'Gyeongsangbuk-do',
    [States.KR_48]: 'Gyeongsangnam-do',
    [States.KR_28]: 'Incheon-gwangyeoksi',
    [States.KR_49]: 'Jeju-teukbyeoljachido',
    [States.KR_45]: 'Jeollabuk-do',
    [States.KR_46]: 'Jeollanam-do',
    [States.KR_11]: 'Seoul-teukbyeolsi',
    [States.KR_31]: 'Ulsan-gwangyeoksi',
    [States.KW_KU]: "Al 'Asimah",
    [States.KW_AH]: 'Al Ahmadi',
    [States.KW_FA]: 'Al Farwaniyah',
    [States.KW_JA]: "Al Jahra'",
    [States.KW_HA]: 'Hawalli',
    [States.KW_MU]: 'Mubarak al Kabir',
    [States.KZ_ALA]: 'Almaty',
    [States.KZ_ALM]: 'Almaty oblysy',
    [States.KZ_AKM]: 'Aqmola oblysy',
    [States.KZ_AKT]: 'Aqtobe oblysy',
    [States.KZ_ATY]: 'Atyrau oblysy',
    [States.KZ_ZAP]: 'Batys Qazaqstan oblysy',
    [States.KZ_MAN]: 'Mangghystau oblysy',
    [States.KZ_AST]: 'Nur-Sultan',
    [States.KZ_YUZ]: 'Ongtustik Qazaqstan oblysy',
    [States.KZ_PAV]: 'Pavlodar oblysy',
    [States.KZ_KAR]: 'Qaraghandy oblysy',
    [States.KZ_KUS]: 'Qostanay oblysy',
    [States.KZ_KZY]: 'Qyzylorda oblysy',
    [States.KZ_VOS]: 'Shyghys Qazaqstan oblysy',
    [States.KZ_SHY]: 'Shymkent',
    [States.KZ_SEV]: 'Soltustik Qazaqstan oblysy',
    [States.KZ_ZHA]: 'Zhambyl oblysy',
    [States.LA_BL]: 'Bolikhamxai',
    [States.LA_CH]: 'Champasak',
    [States.LA_KH]: 'Khammouan',
    [States.LA_OU]: 'Oudomxai',
    [States.LA_PH]: 'Phongsali',
    [States.LA_SV]: 'Savannakhet',
    [States.LA_VI]: 'Viangchan',
    [States.LA_XA]: 'Xaignabouli',
    [States.LA_XE]: 'Xekong',
    [States.LA_XI]: 'Xiangkhouang',
    [States.LB_AK]: 'Aakkar',
    [States.LB_BH]: 'Baalbek-Hermel',
    [States.LB_BI]: 'Beqaa',
    [States.LB_BA]: 'Beyrouth',
    [States.LB_AS]: 'Liban-Nord',
    [States.LB_JA]: 'Liban-Sud',
    [States.LB_JL]: 'Mont-Liban',
    [States.LB_NA]: 'Nabatiye',
    [States.LC_01]: 'Anse la Raye',
    [States.LC_02]: 'Castries',
    [States.LC_03]: 'Choiseul',
    [States.LC_05]: 'Dennery',
    [States.LC_06]: 'Gros Islet',
    [States.LC_07]: 'Laborie',
    [States.LC_08]: 'Micoud',
    [States.LC_10]: 'Soufriere',
    [States.LC_11]: 'Vieux Fort',
    [States.LI_01]: 'Balzers',
    [States.LI_02]: 'Eschen',
    [States.LI_03]: 'Gamprin',
    [States.LI_04]: 'Mauren',
    [States.LI_05]: 'Planken',
    [States.LI_06]: 'Ruggell',
    [States.LI_07]: 'Schaan',
    [States.LI_09]: 'Triesen',
    [States.LI_10]: 'Triesenberg',
    [States.LI_11]: 'Vaduz',
    [States.LK_2]: 'Central Province',
    [States.LK_5]: 'Eastern Province',
    [States.LK_7]: 'North Central Province',
    [States.LK_6]: 'North Western Province',
    [States.LK_4]: 'Northern Province',
    [States.LK_9]: 'Sabaragamuwa Province',
    [States.LK_3]: 'Southern Province',
    [States.LK_8]: 'Uva Province',
    [States.LK_1]: 'Western Province',
    [States.LR_BM]: 'Bomi',
    [States.LR_GB]: 'Grand Bassa',
    [States.LR_GG]: 'Grand Gedeh',
    [States.LR_MG]: 'Margibi',
    [States.LR_MO]: 'Montserrado',
    [States.LR_SI]: 'Sinoe',
    [States.LS_D]: 'Berea',
    [States.LS_B]: 'Botha-Bothe',
    [States.LS_C]: 'Leribe',
    [States.LS_E]: 'Mafeteng',
    [States.LS_A]: 'Maseru',
    [States.LS_F]: "Mohale's Hoek",
    [States.LS_J]: 'Mokhotlong',
    [States.LS_H]: "Qacha's Nek",
    [States.LS_G]: 'Quthing',
    [States.LS_K]: 'Thaba-Tseka',
    [States.LT_AL]: 'Alytaus apskritis',
    [States.LT_KU]: 'Kauno apskritis',
    [States.LT_KL]: 'Klaipedos apskritis',
    [States.LT_MR]: 'Marijampoles apskritis',
    [States.LT_PN]: 'Panevezio apskritis',
    [States.LT_SA]: 'Siauliu apskritis',
    [States.LT_TA]: 'Taurages apskritis',
    [States.LT_TE]: 'Telsiu apskritis',
    [States.LT_UT]: 'Utenos apskritis',
    [States.LT_VL]: 'Vilniaus apskritis',
    [States.LU_CA]: 'Capellen',
    [States.LU_CL]: 'Clervaux',
    [States.LU_DI]: 'Diekirch',
    [States.LU_EC]: 'Echternach',
    [States.LU_ES]: 'Esch-sur-Alzette',
    [States.LU_GR]: 'Grevenmacher',
    [States.LU_LU]: 'Luxembourg',
    [States.LU_ME]: 'Mersch',
    [States.LU_RD]: 'Redange',
    [States.LU_RM]: 'Remich',
    [States.LU_VD]: 'Vianden',
    [States.LU_WI]: 'Wiltz',
    [States.LV_011]: 'Adazu novads',
    [States.LV_002]: 'Aizkraukles novads',
    [States.LV_007]: 'Aluksnes novads',
    [States.LV_111]: 'Augsdaugavas novads',
    [States.LV_015]: 'Balvu novads',
    [States.LV_016]: 'Bauskas novads',
    [States.LV_022]: 'Cesu novads',
    [States.LV_DGV]: 'Daugavpils',
    [States.LV_112]: 'Dienvidkurzemes novads',
    [States.LV_026]: 'Dobeles novads',
    [States.LV_033]: 'Gulbenes novads',
    [States.LV_042]: 'Jekabpils novads',
    [States.LV_JEL]: 'Jelgava',
    [States.LV_041]: 'Jelgavas novads',
    [States.LV_JUR]: 'Jurmala',
    [States.LV_052]: 'Kekavas novads',
    [States.LV_047]: 'Kraslavas novads',
    [States.LV_050]: 'Kuldigas novads',
    [States.LV_LPX]: 'Liepaja',
    [States.LV_054]: 'Limbazu novads',
    [States.LV_056]: 'Livanu novads',
    [States.LV_058]: 'Ludzas novads',
    [States.LV_059]: 'Madonas novads',
    [States.LV_062]: 'Marupes novads',
    [States.LV_067]: 'Ogres novads',
    [States.LV_068]: 'Olaines novads',
    [States.LV_073]: 'Preilu novads',
    [States.LV_077]: 'Rezeknes novads',
    [States.LV_RIX]: 'Riga',
    [States.LV_080]: 'Ropazu novads',
    [States.LV_087]: 'Salaspils novads',
    [States.LV_088]: 'Saldus novads',
    [States.LV_089]: 'Saulkrastu novads',
    [States.LV_091]: 'Siguldas novads',
    [States.LV_094]: 'Smiltenes novads',
    [States.LV_097]: 'Talsu novads',
    [States.LV_099]: 'Tukuma novads',
    [States.LV_101]: 'Valkas novads',
    [States.LV_113]: 'Valmieras novads',
    [States.LV_102]: 'Varaklanu novads',
    [States.LV_106]: 'Ventspils novads',
    [States.LY_BU]: 'Al Butnan',
    [States.LY_JA]: 'Al Jabal al Akhdar',
    [States.LY_JG]: 'Al Jabal al Gharbi',
    [States.LY_JI]: 'Al Jafarah',
    [States.LY_JU]: 'Al Jufrah',
    [States.LY_KF]: 'Al Kufrah',
    [States.LY_MJ]: 'Al Marj',
    [States.LY_MB]: 'Al Marqab',
    [States.LY_WA]: 'Al Wahat',
    [States.LY_NQ]: 'An Nuqat al Khams',
    [States.LY_ZA]: 'Az Zawiyah',
    [States.LY_BA]: 'Banghazi',
    [States.LY_DR]: 'Darnah',
    [States.LY_MI]: 'Misratah',
    [States.LY_NL]: 'Nalut',
    [States.LY_SB]: 'Sabha',
    [States.LY_SR]: 'Surt',
    [States.LY_TB]: 'Tarabulus',
    [States.LY_WS]: "Wadi ash Shati'",
    [States.MA_05]: 'Beni-Mellal-Khenifra',
    [States.MA_06]: 'Casablanca-Settat',
    [States.MA_08]: 'Draa-Tafilalet',
    [States.MA_03]: 'Fes- Meknes',
    [States.MA_10]: 'Guelmim-Oued Noun (EH-partial)',
    [States.MA_02]: "L'Oriental",
    [States.MA_11]: 'Laayoune-Sakia El Hamra (EH-partial)',
    [States.MA_07]: 'Marrakech-Safi',
    [States.MA_04]: 'Rabat-Sale-Kenitra',
    [States.MA_09]: 'Souss-Massa',
    [States.MA_01]: 'Tanger-Tetouan-Al Hoceima',
    [States.MC_FO]: 'Fontvieille',
    [States.MC_CO]: 'La Condamine',
    [States.MC_MO]: 'Monaco-Ville',
    [States.MC_MC]: 'Monte-Carlo',
    [States.MC_SR]: 'Saint-Roman',
    [States.MD_AN]: 'Anenii Noi',
    [States.MD_BA]: 'Balti',
    [States.MD_BS]: 'Basarabeasca',
    [States.MD_BD]: 'Bender',
    [States.MD_BR]: 'Briceni',
    [States.MD_CA]: 'Cahul',
    [States.MD_CL]: 'Calarasi',
    [States.MD_CT]: 'Cantemir',
    [States.MD_CS]: 'Causeni',
    [States.MD_CU]: 'Chisinau',
    [States.MD_CM]: 'Cimislia',
    [States.MD_CR]: 'Criuleni',
    [States.MD_DO]: 'Donduseni',
    [States.MD_DR]: 'Drochia',
    [States.MD_DU]: 'Dubasari',
    [States.MD_ED]: 'Edinet',
    [States.MD_FA]: 'Falesti',
    [States.MD_FL]: 'Floresti',
    [States.MD_GA]: 'Gagauzia, Unitatea teritoriala autonoma',
    [States.MD_GL]: 'Glodeni',
    [States.MD_HI]: 'Hincesti',
    [States.MD_IA]: 'Ialoveni',
    [States.MD_LE]: 'Leova',
    [States.MD_NI]: 'Nisporeni',
    [States.MD_OC]: 'Ocnita',
    [States.MD_OR]: 'Orhei',
    [States.MD_RE]: 'Rezina',
    [States.MD_RI]: 'Riscani',
    [States.MD_SI]: 'Singerei',
    [States.MD_SD]: 'Soldanesti',
    [States.MD_SO]: 'Soroca',
    [States.MD_SV]: 'Stefan Voda',
    [States.MD_SN]: 'Stinga Nistrului, unitatea teritoriala din',
    [States.MD_ST]: 'Straseni',
    [States.MD_TA]: 'Taraclia',
    [States.MD_TE]: 'Telenesti',
    [States.MD_UN]: 'Ungheni',
    [States.ME_01]: 'Andrijevica',
    [States.ME_02]: 'Bar',
    [States.ME_03]: 'Berane',
    [States.ME_04]: 'Bijelo Polje',
    [States.ME_05]: 'Budva',
    [States.ME_06]: 'Cetinje',
    [States.ME_07]: 'Danilovgrad',
    [States.ME_08]: 'Herceg-Novi',
    [States.ME_10]: 'Kotor',
    [States.ME_12]: 'Niksic',
    [States.ME_13]: 'Plav',
    [States.ME_14]: 'Pljevlja',
    [States.ME_15]: 'Pluzine',
    [States.ME_16]: 'Podgorica',
    [States.ME_17]: 'Rozaje',
    [States.ME_19]: 'Tivat',
    [States.ME_24]: 'Tuzi',
    [States.ME_20]: 'Ulcinj',
    [States.ME_21]: 'Zabljak',
    [States.MG_T]: 'Antananarivo',
    [States.MG_D]: 'Antsiranana',
    [States.MG_F]: 'Fianarantsoa',
    [States.MG_M]: 'Mahajanga',
    [States.MG_A]: 'Toamasina',
    [States.MG_U]: 'Toliara',
    [States.MH_KIL]: 'Bikini & Kili',
    [States.MH_KWA]: 'Kwajalein',
    [States.MH_MAJ]: 'Majuro',
    [States.MK_802]: 'Aracinovo',
    [States.MK_201]: 'Berovo',
    [States.MK_501]: 'Bitola',
    [States.MK_401]: 'Bogdanci',
    [States.MK_601]: 'Bogovinje',
    [States.MK_402]: 'Bosilovo',
    [States.MK_602]: 'Brvenica',
    [States.MK_803]: 'Butel',
    [States.MK_109]: 'Caska',
    [States.MK_814]: 'Centar',
    [States.MK_210]: 'Cesinovo-Oblesevo',
    [States.MK_816]: 'Cucer Sandevo',
    [States.MK_303]: 'Debar',
    [States.MK_203]: 'Delcevo',
    [States.MK_502]: 'Demir Hisar',
    [States.MK_406]: 'Dojran',
    [States.MK_503]: 'Dolneni',
    [States.MK_804]: 'Gazi Baba',
    [States.MK_405]: 'Gevgelija',
    [States.MK_604]: 'Gostivar',
    [States.MK_102]: 'Gradsko',
    [States.MK_807]: 'Ilinden',
    [States.MK_606]: 'Jegunovce',
    [States.MK_205]: 'Karbinci',
    [States.MK_104]: 'Kavadarci',
    [States.MK_307]: 'Kicevo',
    [States.MK_809]: 'Kisela Voda',
    [States.MK_206]: 'Kocani',
    [States.MK_701]: 'Kratovo',
    [States.MK_702]: 'Kriva Palanka',
    [States.MK_505]: 'Krusevo',
    [States.MK_703]: 'Kumanovo',
    [States.MK_704]: 'Lipkovo',
    [States.MK_105]: 'Lozovo',
    [States.MK_207]: 'Makedonska Kamenica',
    [States.MK_308]: 'Makedonski Brod',
    [States.MK_607]: 'Mavrovo i Rostusa',
    [States.MK_506]: 'Mogila',
    [States.MK_106]: 'Negotino',
    [States.MK_507]: 'Novaci',
    [States.MK_408]: 'Novo Selo',
    [States.MK_310]: 'Ohrid',
    [States.MK_208]: 'Pehcevo',
    [States.MK_810]: 'Petrovec',
    [States.MK_311]: 'Plasnica',
    [States.MK_508]: 'Prilep',
    [States.MK_209]: 'Probistip',
    [States.MK_409]: 'Radovis',
    [States.MK_705]: 'Rankovce',
    [States.MK_509]: 'Resen',
    [States.MK_107]: 'Rosoman',
    [States.MK_811]: 'Saraj',
    [States.MK_812]: 'Sopiste',
    [States.MK_211]: 'Stip',
    [States.MK_312]: 'Struga',
    [States.MK_410]: 'Strumica',
    [States.MK_813]: 'Studenicani',
    [States.MK_108]: 'Sveti Nikole',
    [States.MK_608]: 'Tearce',
    [States.MK_609]: 'Tetovo',
    [States.MK_403]: 'Valandovo',
    [States.MK_404]: 'Vasilevo',
    [States.MK_101]: 'Veles',
    [States.MK_301]: 'Vevcani',
    [States.MK_202]: 'Vinica',
    [States.MK_603]: 'Vrapciste',
    [States.MK_806]: 'Zelenikovo',
    [States.MK_605]: 'Zelino',
    [States.ML_BKO]: 'Bamako',
    [States.ML_7]: 'Gao',
    [States.ML_1]: 'Kayes',
    [States.ML_8]: 'Kidal',
    [States.ML_2]: 'Koulikoro',
    [States.ML_5]: 'Mopti',
    [States.ML_4]: 'Segou',
    [States.ML_3]: 'Sikasso',
    [States.ML_6]: 'Tombouctou',
    [States.MM_07]: 'Ayeyarwady',
    [States.MM_02]: 'Bago',
    [States.MM_14]: 'Chin',
    [States.MM_11]: 'Kachin',
    [States.MM_12]: 'Kayah',
    [States.MM_03]: 'Magway',
    [States.MM_04]: 'Mandalay',
    [States.MM_15]: 'Mon',
    [States.MM_18]: 'Nay Pyi Taw',
    [States.MM_16]: 'Rakhine',
    [States.MM_01]: 'Sagaing',
    [States.MM_17]: 'Shan',
    [States.MM_05]: 'Tanintharyi',
    [States.MM_06]: 'Yangon',
    [States.MN_037]: 'Darhan uul',
    [States.MN_061]: 'Dornod',
    [States.MN_063]: 'Dornogovi',
    [States.MN_065]: 'Govi-Altay',
    [States.MN_064]: 'Govi-Sumber',
    [States.MN_043]: 'Hovd',
    [States.MN_035]: 'Orhon',
    [States.MN_055]: 'Ovorhangay',
    [States.MN_049]: 'Selenge',
    [States.MN_047]: 'Tov',
    [States.MN_1]: 'Ulaanbaatar',
    [States.MR_07]: 'Adrar',
    [States.MR_03]: 'Assaba',
    [States.MR_05]: 'Brakna',
    [States.MR_08]: 'Dakhlet Nouadhibou',
    [States.MR_04]: 'Gorgol',
    [States.MR_01]: 'Hodh ech Chargui',
    [States.MR_02]: 'Hodh el Gharbi',
    [States.MR_13]: 'Nouakchott Ouest',
    [States.MR_09]: 'Tagant',
    [States.MR_11]: 'Tiris Zemmour',
    [States.MR_06]: 'Trarza',
    [States.MT_01]: 'Attard',
    [States.MT_02]: 'Balzan',
    [States.MT_03]: 'Birgu',
    [States.MT_04]: 'Birkirkara',
    [States.MT_05]: 'Birzebbuga',
    [States.MT_06]: 'Bormla',
    [States.MT_07]: 'Dingli',
    [States.MT_08]: 'Fgura',
    [States.MT_09]: 'Floriana',
    [States.MT_10]: 'Fontana',
    [States.MT_14]: 'Gharb',
    [States.MT_15]: 'Gharghur',
    [States.MT_16]: 'Ghasri',
    [States.MT_17]: 'Ghaxaq',
    [States.MT_11]: 'Gudja',
    [States.MT_12]: 'Gzira',
    [States.MT_18]: 'Hamrun',
    [States.MT_19]: 'Iklin',
    [States.MT_20]: 'Isla',
    [States.MT_21]: 'Kalkara',
    [States.MT_22]: 'Kercem',
    [States.MT_23]: 'Kirkop',
    [States.MT_24]: 'Lija',
    [States.MT_25]: 'Luqa',
    [States.MT_26]: 'Marsa',
    [States.MT_27]: 'Marsaskala',
    [States.MT_28]: 'Marsaxlokk',
    [States.MT_29]: 'Mdina',
    [States.MT_30]: 'Mellieha',
    [States.MT_31]: 'Mgarr',
    [States.MT_32]: 'Mosta',
    [States.MT_33]: 'Mqabba',
    [States.MT_34]: 'Msida',
    [States.MT_35]: 'Mtarfa',
    [States.MT_36]: 'Munxar',
    [States.MT_37]: 'Nadur',
    [States.MT_38]: 'Naxxar',
    [States.MT_39]: 'Paola',
    [States.MT_40]: 'Pembroke',
    [States.MT_41]: 'Pieta',
    [States.MT_42]: 'Qala',
    [States.MT_43]: 'Qormi',
    [States.MT_45]: 'Rabat Gozo',
    [States.MT_46]: 'Rabat Malta',
    [States.MT_49]: 'Saint John',
    [States.MT_48]: "Saint Julian's",
    [States.MT_53]: "Saint Lucia's",
    [States.MT_51]: "Saint Paul's Bay",
    [States.MT_52]: 'Sannat',
    [States.MT_54]: 'Santa Venera',
    [States.MT_55]: 'Siggiewi',
    [States.MT_56]: 'Sliema',
    [States.MT_57]: 'Swieqi',
    [States.MT_58]: "Ta' Xbiex",
    [States.MT_59]: 'Tarxien',
    [States.MT_60]: 'Valletta',
    [States.MT_61]: 'Xaghra',
    [States.MT_62]: 'Xewkija',
    [States.MT_63]: 'Xghajra',
    [States.MT_64]: 'Zabbar',
    [States.MT_65]: 'Zebbug Gozo',
    [States.MT_67]: 'Zejtun',
    [States.MT_68]: 'Zurrieq',
    [States.MU_BL]: 'Black River',
    [States.MU_FL]: 'Flacq',
    [States.MU_GP]: 'Grand Port',
    [States.MU_MO]: 'Moka',
    [States.MU_PA]: 'Pamplemousses',
    [States.MU_PW]: 'Plaines Wilhems',
    [States.MU_PL]: 'Port Louis',
    [States.MU_RR]: 'Riviere du Rempart',
    [States.MU_RO]: 'Rodrigues Islands',
    [States.MU_SA]: 'Savanne',
    [States.MV_01]: 'Addu City',
    [States.MV_03]: 'Faadhippolhu',
    [States.MV_04]: 'Felidhu Atoll',
    [States.MV_05]: 'Hahdhunmathi',
    [States.MV_MLE]: 'Male',
    [States.MV_12]: 'Mulaku Atoll',
    [States.MV_13]: 'North Maalhosmadulu',
    [States.MV_14]: 'North Nilandhe Atoll',
    [States.MV_00]: 'South Ari Atoll',
    [States.MV_28]: 'South Huvadhu Atoll',
    [States.MV_20]: 'South Maalhosmadulu',
    [States.MV_25]: 'South Miladhunmadulu',
    [States.MV_17]: 'South Nilandhe Atoll',
    [States.MV_23]: 'South Thiladhunmathi',
    [States.MW_BA]: 'Balaka',
    [States.MW_BL]: 'Blantyre',
    [States.MW_CK]: 'Chikwawa',
    [States.MW_CR]: 'Chiradzulu',
    [States.MW_DE]: 'Dedza',
    [States.MW_DO]: 'Dowa',
    [States.MW_KR]: 'Karonga',
    [States.MW_LI]: 'Lilongwe',
    [States.MW_MH]: 'Machinga',
    [States.MW_MG]: 'Mangochi',
    [States.MW_MW]: 'Mwanza',
    [States.MW_MZ]: 'Mzimba',
    [States.MW_NE]: 'Neno',
    [States.MW_NK]: 'Nkhotakota',
    [States.MW_SA]: 'Salima',
    [States.MW_TH]: 'Thyolo',
    [States.MW_ZO]: 'Zomba',
    [States.MX_AGU]: 'Aguascalientes',
    [States.MX_BCN]: 'Baja California',
    [States.MX_BCS]: 'Baja California Sur',
    [States.MX_CAM]: 'Campeche',
    [States.MX_CHP]: 'Chiapas',
    [States.MX_CHH]: 'Chihuahua',
    [States.MX_CMX]: 'Ciudad de Mexico',
    [States.MX_COA]: 'Coahuila de Zaragoza',
    [States.MX_COL]: 'Colima',
    [States.MX_DUR]: 'Durango',
    [States.MX_GUA]: 'Guanajuato',
    [States.MX_GRO]: 'Guerrero',
    [States.MX_HID]: 'Hidalgo',
    [States.MX_JAL]: 'Jalisco',
    [States.MX_MEX]: 'Mexico',
    [States.MX_MIC]: 'Michoacan de Ocampo',
    [States.MX_MOR]: 'Morelos',
    [States.MX_NAY]: 'Nayarit',
    [States.MX_NLE]: 'Nuevo Leon',
    [States.MX_OAX]: 'Oaxaca',
    [States.MX_PUE]: 'Puebla',
    [States.MX_QUE]: 'Queretaro',
    [States.MX_ROO]: 'Quintana Roo',
    [States.MX_SLP]: 'San Luis Potosi',
    [States.MX_SIN]: 'Sinaloa',
    [States.MX_SON]: 'Sonora',
    [States.MX_TAB]: 'Tabasco',
    [States.MX_TAM]: 'Tamaulipas',
    [States.MX_TLA]: 'Tlaxcala',
    [States.MX_VER]: 'Veracruz de Ignacio de la Llave',
    [States.MX_YUC]: 'Yucatan',
    [States.MX_ZAC]: 'Zacatecas',
    [States.MY_01]: 'Johor',
    [States.MY_02]: 'Kedah',
    [States.MY_03]: 'Kelantan',
    [States.MY_04]: 'Melaka',
    [States.MY_05]: 'Negeri Sembilan',
    [States.MY_06]: 'Pahang',
    [States.MY_08]: 'Perak',
    [States.MY_09]: 'Perlis',
    [States.MY_07]: 'Pulau Pinang',
    [States.MY_12]: 'Sabah',
    [States.MY_13]: 'Sarawak',
    [States.MY_10]: 'Selangor',
    [States.MY_11]: 'Terengganu',
    [States.MY_14]: 'Wilayah Persekutuan Kuala Lumpur',
    [States.MY_15]: 'Wilayah Persekutuan Labuan',
    [States.MY_16]: 'Wilayah Persekutuan Putrajaya',
    [States.MZ_P]: 'Cabo Delgado',
    [States.MZ_G]: 'Gaza',
    [States.MZ_I]: 'Inhambane',
    [States.MZ_B]: 'Manica',
    [States.MZ_L]: 'Maputo',
    [States.MZ_N]: 'Nampula',
    [States.MZ_A]: 'Niassa',
    [States.MZ_S]: 'Sofala',
    [States.MZ_T]: 'Tete',
    [States.MZ_Q]: 'Zambezia',
    [States.NA_ER]: 'Erongo',
    [States.NA_HA]: 'Hardap',
    [States.NA_KA]: 'Karas',
    [States.NA_KE]: 'Kavango East',
    [States.NA_KH]: 'Khomas',
    [States.NA_KU]: 'Kunene',
    [States.NA_OW]: 'Ohangwena',
    [States.NA_OH]: 'Omaheke',
    [States.NA_OS]: 'Omusati',
    [States.NA_ON]: 'Oshana',
    [States.NA_OT]: 'Oshikoto',
    [States.NA_OD]: 'Otjozondjupa',
    [States.NA_CA]: 'Zambezi',
    [States.NE_1]: 'Agadez',
    [States.NE_2]: 'Diffa',
    [States.NE_3]: 'Dosso',
    [States.NE_4]: 'Maradi',
    [States.NE_8]: 'Niamey',
    [States.NE_5]: 'Tahoua',
    [States.NE_6]: 'Tillaberi',
    [States.NE_7]: 'Zinder',
    [States.NG_AB]: 'Abia',
    [States.NG_FC]: 'Abuja Federal Capital Territory',
    [States.NG_AD]: 'Adamawa',
    [States.NG_AK]: 'Akwa Ibom',
    [States.NG_AN]: 'Anambra',
    [States.NG_BA]: 'Bauchi',
    [States.NG_BY]: 'Bayelsa',
    [States.NG_BE]: 'Benue',
    [States.NG_BO]: 'Borno',
    [States.NG_CR]: 'Cross River',
    [States.NG_DE]: 'Delta',
    [States.NG_EB]: 'Ebonyi',
    [States.NG_ED]: 'Edo',
    [States.NG_EK]: 'Ekiti',
    [States.NG_EN]: 'Enugu',
    [States.NG_GO]: 'Gombe',
    [States.NG_IM]: 'Imo',
    [States.NG_JI]: 'Jigawa',
    [States.NG_KD]: 'Kaduna',
    [States.NG_KN]: 'Kano',
    [States.NG_KT]: 'Katsina',
    [States.NG_KE]: 'Kebbi',
    [States.NG_KO]: 'Kogi',
    [States.NG_KW]: 'Kwara',
    [States.NG_LA]: 'Lagos',
    [States.NG_NA]: 'Nasarawa',
    [States.NG_NI]: 'Niger',
    [States.NG_OG]: 'Ogun',
    [States.NG_ON]: 'Ondo',
    [States.NG_OS]: 'Osun',
    [States.NG_OY]: 'Oyo',
    [States.NG_PL]: 'Plateau',
    [States.NG_RI]: 'Rivers',
    [States.NG_SO]: 'Sokoto',
    [States.NG_TA]: 'Taraba',
    [States.NG_YO]: 'Yobe',
    [States.NG_ZA]: 'Zamfara',
    [States.NI_BO]: 'Boaco',
    [States.NI_CA]: 'Carazo',
    [States.NI_CI]: 'Chinandega',
    [States.NI_CO]: 'Chontales',
    [States.NI_AN]: 'Costa Caribe Norte',
    [States.NI_AS]: 'Costa Caribe Sur',
    [States.NI_ES]: 'Esteli',
    [States.NI_GR]: 'Granada',
    [States.NI_JI]: 'Jinotega',
    [States.NI_LE]: 'Leon',
    [States.NI_MD]: 'Madriz',
    [States.NI_MN]: 'Managua',
    [States.NI_MS]: 'Masaya',
    [States.NI_MT]: 'Matagalpa',
    [States.NI_NS]: 'Nueva Segovia',
    [States.NI_SJ]: 'Rio San Juan',
    [States.NI_RI]: 'Rivas',
    [States.NL_DR]: 'Drenthe',
    [States.NL_FL]: 'Flevoland',
    [States.NL_FR]: 'Fryslan',
    [States.NL_GE]: 'Gelderland',
    [States.NL_GR]: 'Groningen',
    [States.NL_LI]: 'Limburg',
    [States.NL_NB]: 'Noord-Brabant',
    [States.NL_NH]: 'Noord-Holland',
    [States.NL_OV]: 'Overijssel',
    [States.NL_UT]: 'Utrecht',
    [States.NL_ZE]: 'Zeeland',
    [States.NL_ZH]: 'Zuid-Holland',
    [States.NO_42]: 'Agder',
    [States.NO_34]: 'Innlandet',
    [States.NO_15]: 'More og Romsdal',
    [States.NO_18]: 'Nordland',
    [States.NO_03]: 'Oslo',
    [States.NO_11]: 'Rogaland',
    [States.NO_54]: 'Troms og Finnmark',
    [States.NO_50]: 'Trondelag',
    [States.NO_38]: 'Vestfold og Telemark',
    [States.NO_46]: 'Vestland',
    [States.NO_30]: 'Viken',
    [States.NP_BA]: 'Bagmati',
    [States.NP_BH]: 'Bheri',
    [States.NP_DH]: 'Dhawalagiri',
    [States.NP_GA]: 'Gandaki',
    [States.NP_JA]: 'Janakpur',
    [States.NP_KA]: 'Karnali',
    [States.NP_KO]: 'Kosi',
    [States.NP_LU]: 'Lumbini',
    [States.NP_MA]: 'Mahakali',
    [States.NP_ME]: 'Mechi',
    [States.NP_NA]: 'Narayani',
    [States.NP_RA]: 'Rapti',
    [States.NP_SA]: 'Sagarmatha',
    [States.NP_SE]: 'Seti',
    [States.NR_01]: 'Aiwo',
    [States.NR_03]: 'Anetan',
    [States.NR_14]: 'Yaren',
    [States.NZ_AUK]: 'Auckland',
    [States.NZ_BOP]: 'Bay of Plenty',
    [States.NZ_CAN]: 'Canterbury',
    [States.NZ_CIT]: 'Chatham Islands Territory',
    [States.NZ_GIS]: 'Gisborne',
    [States.NZ_HKB]: "Hawke's Bay",
    [States.NZ_MWT]: 'Manawatu-Wanganui',
    [States.NZ_MBH]: 'Marlborough',
    [States.NZ_NSN]: 'Nelson',
    [States.NZ_NTL]: 'Northland',
    [States.NZ_OTA]: 'Otago',
    [States.NZ_STL]: 'Southland',
    [States.NZ_TKI]: 'Taranaki',
    [States.NZ_TAS]: 'Tasman',
    [States.NZ_WKO]: 'Waikato',
    [States.NZ_WGN]: 'Wellington',
    [States.NZ_WTC]: 'West Coast',
    [States.OM_DA]: 'Ad Dakhiliyah',
    [States.OM_BU]: 'Al Buraymi',
    [States.OM_WU]: 'Al Wusta',
    [States.OM_ZA]: 'Az Zahirah',
    [States.OM_BJ]: 'Janub al Batinah',
    [States.OM_SJ]: 'Janub ash Sharqiyah',
    [States.OM_MA]: 'Masqat',
    [States.OM_MU]: 'Musandam',
    [States.OM_BS]: 'Shamal al Batinah',
    [States.OM_SS]: 'Shamal ash Sharqiyah',
    [States.OM_ZU]: 'Zufar',
    [States.PA_1]: 'Bocas del Toro',
    [States.PA_4]: 'Chiriqui',
    [States.PA_2]: 'Cocle',
    [States.PA_3]: 'Colon',
    [States.PA_5]: 'Darien',
    [States.PA_6]: 'Herrera',
    [States.PA_7]: 'Los Santos',
    [States.PA_NB]: 'Ngobe-Bugle',
    [States.PA_8]: 'Panama',
    [States.PA_9]: 'Veraguas',
    [States.PE_AMA]: 'Amazonas',
    [States.PE_ANC]: 'Ancash',
    [States.PE_APU]: 'Apurimac',
    [States.PE_ARE]: 'Arequipa',
    [States.PE_AYA]: 'Ayacucho',
    [States.PE_CAJ]: 'Cajamarca',
    [States.PE_CUS]: 'Cusco',
    [States.PE_CAL]: 'El Callao',
    [States.PE_HUV]: 'Huancavelica',
    [States.PE_HUC]: 'Huanuco',
    [States.PE_ICA]: 'Ica',
    [States.PE_JUN]: 'Junin',
    [States.PE_LAL]: 'La Libertad',
    [States.PE_LAM]: 'Lambayeque',
    [States.PE_LIM]: 'Lima',
    [States.PE_LOR]: 'Loreto',
    [States.PE_MDD]: 'Madre de Dios',
    [States.PE_MOQ]: 'Moquegua',
    [States.PE_PAS]: 'Pasco',
    [States.PE_PIU]: 'Piura',
    [States.PE_PUN]: 'Puno',
    [States.PE_SAM]: 'San Martin',
    [States.PE_TAC]: 'Tacna',
    [States.PE_TUM]: 'Tumbes',
    [States.PE_UCA]: 'Ucayali',
    [States.PG_NSB]: 'Bougainville',
    [States.PG_CPM]: 'Central',
    [States.PG_EBR]: 'East New Britain',
    [States.PG_ESW]: 'East Sepik',
    [States.PG_EHG]: 'Eastern Highlands',
    [States.PG_MPM]: 'Madang',
    [States.PG_MRL]: 'Manus',
    [States.PG_MBA]: 'Milne Bay',
    [States.PG_MPL]: 'Morobe',
    [States.PG_NCD]: 'National Capital District (Port Moresby)',
    [States.PG_SHM]: 'Southern Highlands',
    [States.PG_WBK]: 'West New Britain',
    [States.PG_SAN]: 'West Sepik',
    [States.PG_WPD]: 'Western',
    [States.PG_WHM]: 'Western Highlands',
    [States.PH_ABR]: 'Abra',
    [States.PH_AGN]: 'Agusan del Norte',
    [States.PH_AGS]: 'Agusan del Sur',
    [States.PH_AKL]: 'Aklan',
    [States.PH_ALB]: 'Albay',
    [States.PH_ANT]: 'Antique',
    [States.PH_APA]: 'Apayao',
    [States.PH_AUR]: 'Aurora',
    [States.PH_BAS]: 'Basilan',
    [States.PH_BAN]: 'Bataan',
    [States.PH_BTN]: 'Batanes',
    [States.PH_BTG]: 'Batangas',
    [States.PH_BEN]: 'Benguet',
    [States.PH_BIL]: 'Biliran',
    [States.PH_BOH]: 'Bohol',
    [States.PH_BUK]: 'Bukidnon',
    [States.PH_BUL]: 'Bulacan',
    [States.PH_CAG]: 'Cagayan',
    [States.PH_CAN]: 'Camarines Norte',
    [States.PH_CAS]: 'Camarines Sur',
    [States.PH_CAM]: 'Camiguin',
    [States.PH_CAP]: 'Capiz',
    [States.PH_CAT]: 'Catanduanes',
    [States.PH_CAV]: 'Cavite',
    [States.PH_CEB]: 'Cebu',
    [States.PH_NCO]: 'Cotabato',
    [States.PH_DAO]: 'Davao Oriental',
    [States.PH_COM]: 'Davao de Oro',
    [States.PH_DAV]: 'Davao del Norte',
    [States.PH_DAS]: 'Davao del Sur',
    [States.PH_DIN]: 'Dinagat Islands',
    [States.PH_EAS]: 'Eastern Samar',
    [States.PH_GUI]: 'Guimaras',
    [States.PH_IFU]: 'Ifugao',
    [States.PH_ILN]: 'Ilocos Norte',
    [States.PH_ILS]: 'Ilocos Sur',
    [States.PH_ILI]: 'Iloilo',
    [States.PH_ISA]: 'Isabela',
    [States.PH_KAL]: 'Kalinga',
    [States.PH_LUN]: 'La Union',
    [States.PH_LAG]: 'Laguna',
    [States.PH_LAN]: 'Lanao del Norte',
    [States.PH_LAS]: 'Lanao del Sur',
    [States.PH_LEY]: 'Leyte',
    [States.PH_MAG]: 'Maguindanao',
    [States.PH_MAD]: 'Marinduque',
    [States.PH_MAS]: 'Masbate',
    [States.PH_MDC]: 'Mindoro Occidental',
    [States.PH_MDR]: 'Mindoro Oriental',
    [States.PH_MSC]: 'Misamis Occidental',
    [States.PH_MSR]: 'Misamis Oriental',
    [States.PH_MOU]: 'Mountain Province',
    [States.PH_00]: 'National Capital Region',
    [States.PH_NEC]: 'Negros Occidental',
    [States.PH_NER]: 'Negros Oriental',
    [States.PH_NSA]: 'Northern Samar',
    [States.PH_NUE]: 'Nueva Ecija',
    [States.PH_NUV]: 'Nueva Vizcaya',
    [States.PH_PLW]: 'Palawan',
    [States.PH_PAM]: 'Pampanga',
    [States.PH_PAN]: 'Pangasinan',
    [States.PH_QUE]: 'Quezon',
    [States.PH_QUI]: 'Quirino',
    [States.PH_RIZ]: 'Rizal',
    [States.PH_ROM]: 'Romblon',
    [States.PH_WSA]: 'Samar',
    [States.PH_SAR]: 'Sarangani',
    [States.PH_SIG]: 'Siquijor',
    [States.PH_SOR]: 'Sorsogon',
    [States.PH_SCO]: 'South Cotabato',
    [States.PH_SLE]: 'Southern Leyte',
    [States.PH_SUK]: 'Sultan Kudarat',
    [States.PH_SLU]: 'Sulu',
    [States.PH_SUN]: 'Surigao del Norte',
    [States.PH_SUR]: 'Surigao del Sur',
    [States.PH_TAR]: 'Tarlac',
    [States.PH_TAW]: 'Tawi-Tawi',
    [States.PH_ZMB]: 'Zambales',
    [States.PH_ZSI]: 'Zamboanga Sibugay',
    [States.PH_ZAN]: 'Zamboanga del Norte',
    [States.PH_ZAS]: 'Zamboanga del Sur',
    [States.PK_JK]: 'Azad Jammu and Kashmir',
    [States.PK_BA]: 'Balochistan',
    [States.PK_GB]: 'Gilgit-Baltistan',
    [States.PK_IS]: 'Islamabad',
    [States.PK_KP]: 'Khyber Pakhtunkhwa',
    [States.PK_PB]: 'Punjab',
    [States.PK_SD]: 'Sindh',
    [States.PL_02]: 'Dolnoslaskie',
    [States.PL_04]: 'Kujawsko-pomorskie',
    [States.PL_10]: 'Lodzkie',
    [States.PL_06]: 'Lubelskie',
    [States.PL_08]: 'Lubuskie',
    [States.PL_12]: 'Malopolskie',
    [States.PL_14]: 'Mazowieckie',
    [States.PL_16]: 'Opolskie',
    [States.PL_18]: 'Podkarpackie',
    [States.PL_20]: 'Podlaskie',
    [States.PL_22]: 'Pomorskie',
    [States.PL_24]: 'Slaskie',
    [States.PL_26]: 'Swietokrzyskie',
    [States.PL_28]: 'Warminsko-mazurskie',
    [States.PL_30]: 'Wielkopolskie',
    [States.PL_32]: 'Zachodniopomorskie',
    [States.PS_BTH]: 'Bethlehem',
    [States.PS_DEB]: 'Deir El Balah',
    [States.PS_GZA]: 'Gaza',
    [States.PS_HBN]: 'Hebron',
    [States.PS_JEN]: 'Jenin',
    [States.PS_JRH]: 'Jericho and Al Aghwar',
    [States.PS_JEM]: 'Jerusalem',
    [States.PS_KYS]: 'Khan Yunis',
    [States.PS_NBS]: 'Nablus',
    [States.PS_QQA]: 'Qalqilya',
    [States.PS_RFH]: 'Rafah',
    [States.PS_RBH]: 'Ramallah',
    [States.PS_SLT]: 'Salfit',
    [States.PS_TBS]: 'Tubas',
    [States.PS_TKM]: 'Tulkarm',
    [States.PT_01]: 'Aveiro',
    [States.PT_02]: 'Beja',
    [States.PT_03]: 'Braga',
    [States.PT_04]: 'Braganca',
    [States.PT_05]: 'Castelo Branco',
    [States.PT_06]: 'Coimbra',
    [States.PT_07]: 'Evora',
    [States.PT_08]: 'Faro',
    [States.PT_09]: 'Guarda',
    [States.PT_10]: 'Leiria',
    [States.PT_11]: 'Lisboa',
    [States.PT_12]: 'Portalegre',
    [States.PT_13]: 'Porto',
    [States.PT_30]: 'Regiao Autonoma da Madeira',
    [States.PT_20]: 'Regiao Autonoma dos Acores',
    [States.PT_14]: 'Santarem',
    [States.PT_15]: 'Setubal',
    [States.PT_16]: 'Viana do Castelo',
    [States.PT_17]: 'Vila Real',
    [States.PT_18]: 'Viseu',
    [States.PW_004]: 'Airai',
    [States.PW_100]: 'Kayangel',
    [States.PW_150]: 'Koror',
    [States.PW_212]: 'Melekeok',
    [States.PW_214]: 'Ngaraard',
    [States.PW_222]: 'Ngardmau',
    [States.PY_10]: 'Alto Parana',
    [States.PY_13]: 'Amambay',
    [States.PY_ASU]: 'Asuncion',
    [States.PY_19]: 'Boqueron',
    [States.PY_5]: 'Caaguazu',
    [States.PY_6]: 'Caazapa',
    [States.PY_14]: 'Canindeyu',
    [States.PY_11]: 'Central',
    [States.PY_1]: 'Concepcion',
    [States.PY_3]: 'Cordillera',
    [States.PY_4]: 'Guaira',
    [States.PY_7]: 'Itapua',
    [States.PY_8]: 'Misiones',
    [States.PY_12]: 'Neembucu',
    [States.PY_9]: 'Paraguari',
    [States.PY_15]: 'Presidente Hayes',
    [States.PY_2]: 'San Pedro',
    [States.QA_DA]: 'Ad Dawhah',
    [States.QA_KH]: 'Al Khawr wa adh Dhakhirah',
    [States.QA_WA]: 'Al Wakrah',
    [States.QA_RA]: 'Ar Rayyan',
    [States.QA_MS]: 'Ash Shamal',
    [States.QA_ZA]: "Az Za'ayin",
    [States.QA_US]: 'Umm Salal',
    [States.RO_AB]: 'Alba',
    [States.RO_AR]: 'Arad',
    [States.RO_AG]: 'Arges',
    [States.RO_BC]: 'Bacau',
    [States.RO_BH]: 'Bihor',
    [States.RO_BN]: 'Bistrita-Nasaud',
    [States.RO_BT]: 'Botosani',
    [States.RO_BR]: 'Braila',
    [States.RO_BV]: 'Brasov',
    [States.RO_B]: 'Bucuresti',
    [States.RO_BZ]: 'Buzau',
    [States.RO_CL]: 'Calarasi',
    [States.RO_CS]: 'Caras-Severin',
    [States.RO_CJ]: 'Cluj',
    [States.RO_CT]: 'Constanta',
    [States.RO_CV]: 'Covasna',
    [States.RO_DB]: 'Dambovita',
    [States.RO_DJ]: 'Dolj',
    [States.RO_GL]: 'Galati',
    [States.RO_GR]: 'Giurgiu',
    [States.RO_GJ]: 'Gorj',
    [States.RO_HR]: 'Harghita',
    [States.RO_HD]: 'Hunedoara',
    [States.RO_IL]: 'Ialomita',
    [States.RO_IS]: 'Iasi',
    [States.RO_IF]: 'Ilfov',
    [States.RO_MM]: 'Maramures',
    [States.RO_MH]: 'Mehedinti',
    [States.RO_MS]: 'Mures',
    [States.RO_NT]: 'Neamt',
    [States.RO_OT]: 'Olt',
    [States.RO_PH]: 'Prahova',
    [States.RO_SJ]: 'Salaj',
    [States.RO_SM]: 'Satu Mare',
    [States.RO_SB]: 'Sibiu',
    [States.RO_SV]: 'Suceava',
    [States.RO_TR]: 'Teleorman',
    [States.RO_TM]: 'Timis',
    [States.RO_TL]: 'Tulcea',
    [States.RO_VL]: 'Valcea',
    [States.RO_VS]: 'Vaslui',
    [States.RO_VN]: 'Vrancea',
    [States.RS_00]: 'Beograd',
    [States.RS_14]: 'Borski okrug',
    [States.RS_11]: 'Branicevski okrug',
    [States.RS_23]: 'Jablanicki okrug',
    [States.RS_06]: 'Juznobacki okrug',
    [States.RS_04]: 'Juznobanatski okrug',
    [States.RS_09]: 'Kolubarski okrug',
    [States.RS_28]: 'Kosovsko-Mitrovacki okrug',
    [States.RS_08]: 'Macvanski okrug',
    [States.RS_17]: 'Moravicki okrug',
    [States.RS_20]: 'Nisavski okrug',
    [States.RS_24]: 'Pcinjski okrug',
    [States.RS_26]: 'Pecki okrug',
    [States.RS_22]: 'Pirotski okrug',
    [States.RS_10]: 'Podunavski okrug',
    [States.RS_13]: 'Pomoravski okrug',
    [States.RS_27]: 'Prizrenski okrug',
    [States.RS_19]: 'Rasinski okrug',
    [States.RS_18]: 'Raski okrug',
    [States.RS_01]: 'Severnobacki okrug',
    [States.RS_03]: 'Severnobanatski okrug',
    [States.RS_02]: 'Srednjebanatski okrug',
    [States.RS_07]: 'Sremski okrug',
    [States.RS_12]: 'Sumadijski okrug',
    [States.RS_21]: 'Toplicki okrug',
    [States.RS_15]: 'Zajecarski okrug',
    [States.RS_05]: 'Zapadnobacki okrug',
    [States.RS_16]: 'Zlatiborski okrug',
    [States.RU_AD]: 'Adygeya, Respublika',
    [States.RU_AL]: 'Altay, Respublika',
    [States.RU_ALT]: 'Altayskiy kray',
    [States.RU_AMU]: "Amurskaya oblast'",
    [States.RU_ARK]: "Arkhangel'skaya oblast'",
    [States.RU_AST]: "Astrakhanskaya oblast'",
    [States.RU_BA]: 'Bashkortostan, Respublika',
    [States.RU_BEL]: "Belgorodskaya oblast'",
    [States.RU_BRY]: "Bryanskaya oblast'",
    [States.RU_BU]: 'Buryatiya, Respublika',
    [States.RU_CE]: 'Chechenskaya Respublika',
    [States.RU_CHE]: "Chelyabinskaya oblast'",
    [States.RU_CHU]: 'Chukotskiy avtonomnyy okrug',
    [States.RU_CU]: 'Chuvashskaya Respublika',
    [States.RU_DA]: 'Dagestan, Respublika',
    [States.RU_IN]: 'Ingushetiya, Respublika',
    [States.RU_IRK]: "Irkutskaya oblast'",
    [States.RU_IVA]: "Ivanovskaya oblast'",
    [States.RU_KB]: 'Kabardino-Balkarskaya Respublika',
    [States.RU_KGD]: "Kaliningradskaya oblast'",
    [States.RU_KL]: 'Kalmykiya, Respublika',
    [States.RU_KLU]: "Kaluzhskaya oblast'",
    [States.RU_KAM]: 'Kamchatskiy kray',
    [States.RU_KC]: 'Karachayevo-Cherkesskaya Respublika',
    [States.RU_KR]: 'Kareliya, Respublika',
    [States.RU_KEM]: "Kemerovskaya oblast'",
    [States.RU_KHA]: 'Khabarovskiy kray',
    [States.RU_KK]: 'Khakasiya, Respublika',
    [States.RU_KHM]: 'Khanty-Mansiyskiy avtonomnyy okrug',
    [States.RU_KIR]: "Kirovskaya oblast'",
    [States.RU_KO]: 'Komi, Respublika',
    [States.RU_KOS]: "Kostromskaya oblast'",
    [States.RU_KDA]: 'Krasnodarskiy kray',
    [States.RU_KYA]: 'Krasnoyarskiy kray',
    [States.RU_KGN]: "Kurganskaya oblast'",
    [States.RU_KRS]: "Kurskaya oblast'",
    [States.RU_LEN]: "Leningradskaya oblast'",
    [States.RU_LIP]: "Lipetskaya oblast'",
    [States.RU_MAG]: "Magadanskaya oblast'",
    [States.RU_ME]: 'Mariy El, Respublika',
    [States.RU_MO]: 'Mordoviya, Respublika',
    [States.RU_MOS]: "Moskovskaya oblast'",
    [States.RU_MOW]: 'Moskva',
    [States.RU_MUR]: "Murmanskaya oblast'",
    [States.RU_NEN]: 'Nenetskiy avtonomnyy okrug',
    [States.RU_NIZ]: "Nizhegorodskaya oblast'",
    [States.RU_NGR]: "Novgorodskaya oblast'",
    [States.RU_NVS]: "Novosibirskaya oblast'",
    [States.RU_OMS]: "Omskaya oblast'",
    [States.RU_ORE]: "Orenburgskaya oblast'",
    [States.RU_ORL]: "Orlovskaya oblast'",
    [States.RU_PNZ]: "Penzenskaya oblast'",
    [States.RU_PER]: 'Permskiy kray',
    [States.RU_PRI]: 'Primorskiy kray',
    [States.RU_PSK]: "Pskovskaya oblast'",
    [States.RU_ROS]: "Rostovskaya oblast'",
    [States.RU_RYA]: "Ryazanskaya oblast'",
    [States.RU_SA]: 'Saha, Respublika',
    [States.RU_SAK]: "Sakhalinskaya oblast'",
    [States.RU_SAM]: "Samarskaya oblast'",
    [States.RU_SPE]: 'Sankt-Peterburg',
    [States.RU_SAR]: "Saratovskaya oblast'",
    [States.RU_SE]: 'Severnaya Osetiya, Respublika',
    [States.RU_SMO]: "Smolenskaya oblast'",
    [States.RU_STA]: "Stavropol'skiy kray",
    [States.RU_SVE]: "Sverdlovskaya oblast'",
    [States.RU_TAM]: "Tambovskaya oblast'",
    [States.RU_TA]: 'Tatarstan, Respublika',
    [States.RU_TOM]: "Tomskaya oblast'",
    [States.RU_TUL]: "Tul'skaya oblast'",
    [States.RU_TVE]: "Tverskaya oblast'",
    [States.RU_TYU]: "Tyumenskaya oblast'",
    [States.RU_TY]: 'Tyva, Respublika',
    [States.RU_UD]: 'Udmurtskaya Respublika',
    [States.RU_ULY]: "Ul'yanovskaya oblast'",
    [States.RU_VLA]: "Vladimirskaya oblast'",
    [States.RU_VGG]: "Volgogradskaya oblast'",
    [States.RU_VLG]: "Vologodskaya oblast'",
    [States.RU_VOR]: "Voronezhskaya oblast'",
    [States.RU_YAN]: 'Yamalo-Nenetskiy avtonomnyy okrug',
    [States.RU_YAR]: "Yaroslavskaya oblast'",
    [States.RU_YEV]: "Yevreyskaya avtonomnaya oblast'",
    [States.RU_ZAB]: "Zabaykal'skiy kray",
    [States.RW_02]: 'Est',
    [States.RW_03]: 'Nord',
    [States.RW_04]: 'Ouest',
    [States.RW_05]: 'Sud',
    [States.RW_01]: 'Ville de Kigali',
    [States.SA_14]: 'Asir',
    [States.SA_11]: 'Al Bahah',
    [States.SA_08]: 'Al Hudud ash Shamaliyah',
    [States.SA_12]: 'Al Jawf',
    [States.SA_03]: 'Al Madinah al Munawwarah',
    [States.SA_05]: 'Al Qasim',
    [States.SA_01]: 'Ar Riyad',
    [States.SA_04]: 'Ash Sharqiyah',
    [States.SA_06]: "Ha'il",
    [States.SA_09]: 'Jazan',
    [States.SA_02]: 'Makkah al Mukarramah',
    [States.SA_10]: 'Najran',
    [States.SA_07]: 'Tabuk',
    [States.SB_CH]: 'Choiseul',
    [States.SB_GU]: 'Guadalcanal',
    [States.SB_WE]: 'Western',
    [States.SC_02]: 'Anse Boileau',
    [States.SC_05]: 'Anse Royale',
    [States.SC_01]: 'Anse aux Pins',
    [States.SC_06]: 'Baie Lazare',
    [States.SC_07]: 'Baie Sainte Anne',
    [States.SC_08]: 'Beau Vallon',
    [States.SC_10]: 'Bel Ombre',
    [States.SC_11]: 'Cascade',
    [States.SC_16]: 'English River',
    [States.SC_13]: 'Grand Anse Mahe',
    [States.SC_14]: 'Grand Anse Praslin',
    [States.SC_15]: 'La Digue',
    [States.SC_20]: 'Pointe Larue',
    [States.SC_23]: 'Takamaka',
    [States.SD_NB]: 'Blue Nile',
    [States.SD_DC]: 'Central Darfur',
    [States.SD_GD]: 'Gedaref',
    [States.SD_GZ]: 'Gezira',
    [States.SD_KA]: 'Kassala',
    [States.SD_KH]: 'Khartoum',
    [States.SD_DN]: 'North Darfur',
    [States.SD_KN]: 'North Kordofan',
    [States.SD_NO]: 'Northern',
    [States.SD_RS]: 'Red Sea',
    [States.SD_NR]: 'River Nile',
    [States.SD_SI]: 'Sennar',
    [States.SD_DS]: 'South Darfur',
    [States.SD_KS]: 'South Kordofan',
    [States.SD_DW]: 'West Darfur',
    [States.SD_GK]: 'West Kordofan',
    [States.SD_NW]: 'White Nile',
    [States.SE_K]: 'Blekinge lan',
    [States.SE_W]: 'Dalarnas lan',
    [States.SE_X]: 'Gavleborgs lan',
    [States.SE_I]: 'Gotlands lan',
    [States.SE_N]: 'Hallands lan',
    [States.SE_Z]: 'Jamtlands lan',
    [States.SE_F]: 'Jonkopings lan',
    [States.SE_H]: 'Kalmar lan',
    [States.SE_G]: 'Kronobergs lan',
    [States.SE_BD]: 'Norrbottens lan',
    [States.SE_T]: 'Orebro lan',
    [States.SE_E]: 'Ostergotlands lan',
    [States.SE_M]: 'Skane lan',
    [States.SE_D]: 'Sodermanlands lan',
    [States.SE_AB]: 'Stockholms lan',
    [States.SE_C]: 'Uppsala lan',
    [States.SE_S]: 'Varmlands lan',
    [States.SE_AC]: 'Vasterbottens lan',
    [States.SE_Y]: 'Vasternorrlands lan',
    [States.SE_U]: 'Vastmanlands lan',
    [States.SE_O]: 'Vastra Gotalands lan',
    [States.SH_HL]: 'Saint Helena',
    [States.SI_001]: 'Ajdovscina',
    [States.SI_213]: 'Ankaran',
    [States.SI_195]: 'Apace',
    [States.SI_002]: 'Beltinci',
    [States.SI_148]: 'Benedikt',
    [States.SI_149]: 'Bistrica ob Sotli',
    [States.SI_003]: 'Bled',
    [States.SI_150]: 'Bloke',
    [States.SI_004]: 'Bohinj',
    [States.SI_005]: 'Borovnica',
    [States.SI_006]: 'Bovec',
    [States.SI_151]: 'Braslovce',
    [States.SI_007]: 'Brda',
    [States.SI_009]: 'Brezice',
    [States.SI_008]: 'Brezovica',
    [States.SI_152]: 'Cankova',
    [States.SI_011]: 'Celje',
    [States.SI_012]: 'Cerklje na Gorenjskem',
    [States.SI_013]: 'Cerknica',
    [States.SI_014]: 'Cerkno',
    [States.SI_196]: 'Cirkulane',
    [States.SI_015]: 'Crensovci',
    [States.SI_017]: 'Crnomelj',
    [States.SI_018]: 'Destrnik',
    [States.SI_019]: 'Divaca',
    [States.SI_154]: 'Dobje',
    [States.SI_020]: 'Dobrepolje',
    [States.SI_155]: 'Dobrna',
    [States.SI_021]: 'Dobrova-Polhov Gradec',
    [States.SI_156]: 'Dobrovnik',
    [States.SI_023]: 'Domzale',
    [States.SI_024]: 'Dornava',
    [States.SI_025]: 'Dravograd',
    [States.SI_026]: 'Duplek',
    [States.SI_207]: 'Gorje',
    [States.SI_029]: 'Gornja Radgona',
    [States.SI_031]: 'Gornji Petrovci',
    [States.SI_158]: 'Grad',
    [States.SI_032]: 'Grosuplje',
    [States.SI_159]: 'Hajdina',
    [States.SI_160]: 'Hoce-Slivnica',
    [States.SI_161]: 'Hodos',
    [States.SI_162]: 'Horjul',
    [States.SI_034]: 'Hrastnik',
    [States.SI_035]: 'Hrpelje-Kozina',
    [States.SI_036]: 'Idrija',
    [States.SI_037]: 'Ig',
    [States.SI_038]: 'Ilirska Bistrica',
    [States.SI_039]: 'Ivancna Gorica',
    [States.SI_040]: 'Izola',
    [States.SI_041]: 'Jesenice',
    [States.SI_042]: 'Jursinci',
    [States.SI_043]: 'Kamnik',
    [States.SI_044]: 'Kanal',
    [States.SI_045]: 'Kidricevo',
    [States.SI_046]: 'Kobarid',
    [States.SI_047]: 'Kobilje',
    [States.SI_048]: 'Kocevje',
    [States.SI_049]: 'Komen',
    [States.SI_164]: 'Komenda',
    [States.SI_050]: 'Koper',
    [States.SI_197]: 'Kosanjevica na Krki',
    [States.SI_165]: 'Kostel',
    [States.SI_052]: 'Kranj',
    [States.SI_053]: 'Kranjska Gora',
    [States.SI_166]: 'Krizevci',
    [States.SI_054]: 'Krsko',
    [States.SI_055]: 'Kungota',
    [States.SI_056]: 'Kuzma',
    [States.SI_057]: 'Lasko',
    [States.SI_058]: 'Lenart',
    [States.SI_059]: 'Lendava',
    [States.SI_060]: 'Litija',
    [States.SI_061]: 'Ljubljana',
    [States.SI_063]: 'Ljutomer',
    [States.SI_208]: 'Log-Dragomer',
    [States.SI_064]: 'Logatec',
    [States.SI_065]: 'Loska dolina',
    [States.SI_066]: 'Loski Potok',
    [States.SI_167]: 'Lovrenc na Pohorju',
    [States.SI_067]: 'Luce',
    [States.SI_068]: 'Lukovica',
    [States.SI_069]: 'Majsperk',
    [States.SI_198]: 'Makole',
    [States.SI_070]: 'Maribor',
    [States.SI_168]: 'Markovci',
    [States.SI_071]: 'Medvode',
    [States.SI_072]: 'Menges',
    [States.SI_073]: 'Metlika',
    [States.SI_074]: 'Mezica',
    [States.SI_169]: 'Miklavz na Dravskem polju',
    [States.SI_075]: 'Miren-Kostanjevica',
    [States.SI_212]: 'Mirna',
    [States.SI_170]: 'Mirna Pec',
    [States.SI_076]: 'Mislinja',
    [States.SI_199]: 'Mokronog-Trebelno',
    [States.SI_077]: 'Moravce',
    [States.SI_079]: 'Mozirje',
    [States.SI_080]: 'Murska Sobota',
    [States.SI_081]: 'Muta',
    [States.SI_082]: 'Naklo',
    [States.SI_083]: 'Nazarje',
    [States.SI_084]: 'Nova Gorica',
    [States.SI_085]: 'Novo Mesto',
    [States.SI_086]: 'Odranci',
    [States.SI_171]: 'Oplotnica',
    [States.SI_087]: 'Ormoz',
    [States.SI_090]: 'Piran',
    [States.SI_091]: 'Pivka',
    [States.SI_092]: 'Podcetrtek',
    [States.SI_172]: 'Podlehnik',
    [States.SI_200]: 'Poljcane',
    [States.SI_173]: 'Polzela',
    [States.SI_094]: 'Postojna',
    [States.SI_174]: 'Prebold',
    [States.SI_095]: 'Preddvor',
    [States.SI_175]: 'Prevalje',
    [States.SI_096]: 'Ptuj',
    [States.SI_097]: 'Puconci',
    [States.SI_098]: 'Race-Fram',
    [States.SI_099]: 'Radece',
    [States.SI_100]: 'Radenci',
    [States.SI_101]: 'Radlje ob Dravi',
    [States.SI_102]: 'Radovljica',
    [States.SI_103]: 'Ravne na Koroskem',
    [States.SI_176]: 'Razkrizje',
    [States.SI_209]: 'Recica ob Savinji',
    [States.SI_201]: 'Rence-Vogrsko',
    [States.SI_104]: 'Ribnica',
    [States.SI_106]: 'Rogaska Slatina',
    [States.SI_105]: 'Rogasovci',
    [States.SI_108]: 'Ruse',
    [States.SI_033]: 'Salovci',
    [States.SI_109]: 'Semic',
    [States.SI_183]: 'Sempeter-Vrtojba',
    [States.SI_117]: 'Sencur',
    [States.SI_118]: 'Sentilj',
    [States.SI_119]: 'Sentjernej',
    [States.SI_120]: 'Sentjur',
    [States.SI_211]: 'Sentrupert',
    [States.SI_110]: 'Sevnica',
    [States.SI_111]: 'Sezana',
    [States.SI_121]: 'Skocjan',
    [States.SI_122]: 'Skofja Loka',
    [States.SI_123]: 'Skofljica',
    [States.SI_112]: 'Slovenj Gradec',
    [States.SI_113]: 'Slovenska Bistrica',
    [States.SI_114]: 'Slovenske Konjice',
    [States.SI_124]: 'Smarje pri Jelsah',
    [States.SI_206]: 'Smarjeske Toplice',
    [States.SI_125]: 'Smartno ob Paki',
    [States.SI_194]: 'Smartno pri Litiji',
    [States.SI_179]: 'Sodrazica',
    [States.SI_180]: 'Solcava',
    [States.SI_126]: 'Sostanj',
    [States.SI_115]: 'Starse',
    [States.SI_127]: 'Store',
    [States.SI_203]: 'Straza',
    [States.SI_204]: 'Sveta Trojica v Slovenskih goricah',
    [States.SI_182]: 'Sveti Andraz v Slovenskih Goricah',
    [States.SI_116]: 'Sveti Jurij ob Scavnici',
    [States.SI_210]: 'Sveti Jurij v Slovenskih goricah',
    [States.SI_205]: 'Sveti Tomaz',
    [States.SI_184]: 'Tabor',
    [States.SI_010]: 'Tisina',
    [States.SI_128]: 'Tolmin',
    [States.SI_129]: 'Trbovlje',
    [States.SI_130]: 'Trebnje',
    [States.SI_185]: 'Trnovska Vas',
    [States.SI_131]: 'Trzic',
    [States.SI_186]: 'Trzin',
    [States.SI_132]: 'Turnisce',
    [States.SI_133]: 'Velenje',
    [States.SI_187]: 'Velika Polana',
    [States.SI_134]: 'Velike Lasce',
    [States.SI_188]: 'Verzej',
    [States.SI_135]: 'Videm',
    [States.SI_136]: 'Vipava',
    [States.SI_137]: 'Vitanje',
    [States.SI_138]: 'Vodice',
    [States.SI_139]: 'Vojnik',
    [States.SI_189]: 'Vransko',
    [States.SI_140]: 'Vrhnika',
    [States.SI_141]: 'Vuzenica',
    [States.SI_142]: 'Zagorje ob Savi',
    [States.SI_190]: 'Zalec',
    [States.SI_143]: 'Zavrc',
    [States.SI_146]: 'Zelezniki',
    [States.SI_191]: 'Zetale',
    [States.SI_147]: 'Ziri',
    [States.SI_144]: 'Zrece',
    [States.SI_193]: 'Zuzemberk',
    [States.SK_BC]: 'Banskobystricky kraj',
    [States.SK_BL]: 'Bratislavsky kraj',
    [States.SK_KI]: 'Kosicky kraj',
    [States.SK_NI]: 'Nitriansky kraj',
    [States.SK_PV]: 'Presovsky kraj',
    [States.SK_TC]: 'Trenciansky kraj',
    [States.SK_TA]: 'Trnavsky kraj',
    [States.SK_ZI]: 'Zilinsky kraj',
    [States.SL_E]: 'Eastern',
    [States.SL_N]: 'Northern',
    [States.SL_S]: 'Southern',
    [States.SL_W]: 'Western Area',
    [States.SM_07]: 'Citta di San Marino',
    [States.SM_03]: 'Domagnano',
    [States.SM_04]: 'Faetano',
    [States.SM_09]: 'Serravalle',
    [States.SN_DK]: 'Dakar',
    [States.SN_DB]: 'Diourbel',
    [States.SN_FK]: 'Fatick',
    [States.SN_KA]: 'Kaffrine',
    [States.SN_KL]: 'Kaolack',
    [States.SN_KD]: 'Kolda',
    [States.SN_LG]: 'Louga',
    [States.SN_MT]: 'Matam',
    [States.SN_SL]: 'Saint-Louis',
    [States.SN_SE]: 'Sedhiou',
    [States.SN_TC]: 'Tambacounda',
    [States.SN_TH]: 'Thies',
    [States.SN_ZG]: 'Ziguinchor',
    [States.SO_AW]: 'Awdal',
    [States.SO_BN]: 'Banaadir',
    [States.SO_BR]: 'Bari',
    [States.SO_HI]: 'Hiiraan',
    [States.SO_JH]: 'Jubbada Hoose',
    [States.SO_MU]: 'Mudug',
    [States.SO_NU]: 'Nugaal',
    [States.SO_SH]: 'Shabeellaha Hoose',
    [States.SO_TO]: 'Togdheer',
    [States.SO_WO]: 'Woqooyi Galbeed',
    [States.SR_BR]: 'Brokopondo',
    [States.SR_CM]: 'Commewijne',
    [States.SR_NI]: 'Nickerie',
    [States.SR_PR]: 'Para',
    [States.SR_PM]: 'Paramaribo',
    [States.SR_SI]: 'Sipaliwini',
    [States.SR_WA]: 'Wanica',
    [States.SS_EC]: 'Central Equatoria',
    [States.SS_EE]: 'Eastern Equatoria',
    [States.SS_JG]: 'Jonglei',
    [States.SS_LK]: 'Lakes',
    [States.SS_BN]: 'Northern Bahr el Ghazal',
    [States.SS_NU]: 'Upper Nile',
    [States.SS_EW]: 'Western Equatoria',
    [States.ST_01]: 'Agua Grande',
    [States.SV_AH]: 'Ahuachapan',
    [States.SV_CA]: 'Cabanas',
    [States.SV_CH]: 'Chalatenango',
    [States.SV_CU]: 'Cuscatlan',
    [States.SV_LI]: 'La Libertad',
    [States.SV_PA]: 'La Paz',
    [States.SV_UN]: 'La Union',
    [States.SV_MO]: 'Morazan',
    [States.SV_SM]: 'San Miguel',
    [States.SV_SS]: 'San Salvador',
    [States.SV_SV]: 'San Vicente',
    [States.SV_SA]: 'Santa Ana',
    [States.SV_SO]: 'Sonsonate',
    [States.SV_US]: 'Usulutan',
    [States.SY_HA]: 'Al Hasakah',
    [States.SY_LA]: 'Al Ladhiqiyah',
    [States.SY_QU]: 'Al Qunaytirah',
    [States.SY_RA]: 'Ar Raqqah',
    [States.SY_SU]: "As Suwayda'",
    [States.SY_DR]: "Dar'a",
    [States.SY_DY]: 'Dayr az Zawr',
    [States.SY_DI]: 'Dimashq',
    [States.SY_HL]: 'Halab',
    [States.SY_HM]: 'Hamah',
    [States.SY_HI]: 'Hims',
    [States.SY_ID]: 'Idlib',
    [States.SY_RD]: 'Rif Dimashq',
    [States.SY_TA]: 'Tartus',
    [States.SZ_HH]: 'Hhohho',
    [States.SZ_LU]: 'Lubombo',
    [States.SZ_MA]: 'Manzini',
    [States.TD_EE]: 'Ennedi-Est',
    [States.TD_GR]: 'Guera',
    [States.TD_LO]: 'Logone-Occidental',
    [States.TD_ME]: 'Mayo-Kebbi-Est',
    [States.TD_OD]: 'Ouaddai',
    [States.TD_ND]: 'Ville de Ndjamena',
    [States.TG_C]: 'Centrale',
    [States.TG_K]: 'Kara',
    [States.TG_M]: 'Maritime',
    [States.TG_P]: 'Plateaux',
    [States.TG_S]: 'Savanes',
    [States.TH_37]: 'Amnat Charoen',
    [States.TH_15]: 'Ang Thong',
    [States.TH_38]: 'Bueng Kan',
    [States.TH_31]: 'Buri Ram',
    [States.TH_24]: 'Chachoengsao',
    [States.TH_18]: 'Chai Nat',
    [States.TH_36]: 'Chaiyaphum',
    [States.TH_22]: 'Chanthaburi',
    [States.TH_50]: 'Chiang Mai',
    [States.TH_57]: 'Chiang Rai',
    [States.TH_20]: 'Chon Buri',
    [States.TH_86]: 'Chumphon',
    [States.TH_46]: 'Kalasin',
    [States.TH_62]: 'Kamphaeng Phet',
    [States.TH_71]: 'Kanchanaburi',
    [States.TH_40]: 'Khon Kaen',
    [States.TH_81]: 'Krabi',
    [States.TH_10]: 'Krung Thep Maha Nakhon',
    [States.TH_52]: 'Lampang',
    [States.TH_51]: 'Lamphun',
    [States.TH_42]: 'Loei',
    [States.TH_16]: 'Lop Buri',
    [States.TH_58]: 'Mae Hong Son',
    [States.TH_44]: 'Maha Sarakham',
    [States.TH_49]: 'Mukdahan',
    [States.TH_26]: 'Nakhon Nayok',
    [States.TH_73]: 'Nakhon Pathom',
    [States.TH_48]: 'Nakhon Phanom',
    [States.TH_30]: 'Nakhon Ratchasima',
    [States.TH_60]: 'Nakhon Sawan',
    [States.TH_80]: 'Nakhon Si Thammarat',
    [States.TH_55]: 'Nan',
    [States.TH_96]: 'Narathiwat',
    [States.TH_39]: 'Nong Bua Lam Phu',
    [States.TH_43]: 'Nong Khai',
    [States.TH_12]: 'Nonthaburi',
    [States.TH_13]: 'Pathum Thani',
    [States.TH_94]: 'Pattani',
    [States.TH_82]: 'Phangnga',
    [States.TH_93]: 'Phatthalung',
    [States.TH_56]: 'Phayao',
    [States.TH_67]: 'Phetchabun',
    [States.TH_76]: 'Phetchaburi',
    [States.TH_66]: 'Phichit',
    [States.TH_65]: 'Phitsanulok',
    [States.TH_14]: 'Phra Nakhon Si Ayutthaya',
    [States.TH_54]: 'Phrae',
    [States.TH_83]: 'Phuket',
    [States.TH_25]: 'Prachin Buri',
    [States.TH_77]: 'Prachuap Khiri Khan',
    [States.TH_85]: 'Ranong',
    [States.TH_70]: 'Ratchaburi',
    [States.TH_21]: 'Rayong',
    [States.TH_45]: 'Roi Et',
    [States.TH_27]: 'Sa Kaeo',
    [States.TH_47]: 'Sakon Nakhon',
    [States.TH_11]: 'Samut Prakan',
    [States.TH_74]: 'Samut Sakhon',
    [States.TH_75]: 'Samut Songkhram',
    [States.TH_19]: 'Saraburi',
    [States.TH_91]: 'Satun',
    [States.TH_33]: 'Si Sa Ket',
    [States.TH_17]: 'Sing Buri',
    [States.TH_90]: 'Songkhla',
    [States.TH_64]: 'Sukhothai',
    [States.TH_72]: 'Suphan Buri',
    [States.TH_84]: 'Surat Thani',
    [States.TH_32]: 'Surin',
    [States.TH_63]: 'Tak',
    [States.TH_92]: 'Trang',
    [States.TH_23]: 'Trat',
    [States.TH_34]: 'Ubon Ratchathani',
    [States.TH_41]: 'Udon Thani',
    [States.TH_61]: 'Uthai Thani',
    [States.TH_53]: 'Uttaradit',
    [States.TH_95]: 'Yala',
    [States.TH_35]: 'Yasothon',
    [States.TJ_DU]: 'Dushanbe',
    [States.TJ_KT]: 'Khatlon',
    [States.TJ_GB]: 'Kuhistoni Badakhshon',
    [States.TJ_RA]: 'Nohiyahoi Tobei Jumhuri',
    [States.TJ_SU]: 'Sughd',
    [States.TL_AN]: 'Ainaro',
    [States.TL_BO]: 'Bobonaro',
    [States.TL_CO]: 'Cova Lima',
    [States.TL_DI]: 'Dili',
    [States.TM_A]: 'Ahal',
    [States.TM_B]: 'Balkan',
    [States.TM_D]: 'Dasoguz',
    [States.TM_L]: 'Lebap',
    [States.TM_M]: 'Mary',
    [States.TN_31]: 'Beja',
    [States.TN_13]: 'Ben Arous',
    [States.TN_23]: 'Bizerte',
    [States.TN_81]: 'Gabes',
    [States.TN_71]: 'Gafsa',
    [States.TN_32]: 'Jendouba',
    [States.TN_41]: 'Kairouan',
    [States.TN_42]: 'Kasserine',
    [States.TN_73]: 'Kebili',
    [States.TN_12]: "L'Ariana",
    [States.TN_14]: 'La Manouba',
    [States.TN_33]: 'Le Kef',
    [States.TN_53]: 'Mahdia',
    [States.TN_82]: 'Medenine',
    [States.TN_52]: 'Monastir',
    [States.TN_21]: 'Nabeul',
    [States.TN_61]: 'Sfax',
    [States.TN_43]: 'Sidi Bouzid',
    [States.TN_34]: 'Siliana',
    [States.TN_51]: 'Sousse',
    [States.TN_83]: 'Tataouine',
    [States.TN_72]: 'Tozeur',
    [States.TN_11]: 'Tunis',
    [States.TN_22]: 'Zaghouan',
    [States.TO_02]: "Ha'apai",
    [States.TO_03]: 'Niuas',
    [States.TO_04]: 'Tongatapu',
    [States.TR_01]: 'Adana',
    [States.TR_02]: 'Adiyaman',
    [States.TR_03]: 'Afyonkarahisar',
    [States.TR_04]: 'Agri',
    [States.TR_68]: 'Aksaray',
    [States.TR_05]: 'Amasya',
    [States.TR_06]: 'Ankara',
    [States.TR_07]: 'Antalya',
    [States.TR_75]: 'Ardahan',
    [States.TR_08]: 'Artvin',
    [States.TR_09]: 'Aydin',
    [States.TR_10]: 'Balikesir',
    [States.TR_74]: 'Bartin',
    [States.TR_72]: 'Batman',
    [States.TR_69]: 'Bayburt',
    [States.TR_11]: 'Bilecik',
    [States.TR_12]: 'Bingol',
    [States.TR_13]: 'Bitlis',
    [States.TR_14]: 'Bolu',
    [States.TR_15]: 'Burdur',
    [States.TR_16]: 'Bursa',
    [States.TR_17]: 'Canakkale',
    [States.TR_18]: 'Cankiri',
    [States.TR_19]: 'Corum',
    [States.TR_20]: 'Denizli',
    [States.TR_21]: 'Diyarbakir',
    [States.TR_81]: 'Duzce',
    [States.TR_22]: 'Edirne',
    [States.TR_23]: 'Elazig',
    [States.TR_24]: 'Erzincan',
    [States.TR_25]: 'Erzurum',
    [States.TR_26]: 'Eskisehir',
    [States.TR_27]: 'Gaziantep',
    [States.TR_28]: 'Giresun',
    [States.TR_29]: 'Gumushane',
    [States.TR_30]: 'Hakkari',
    [States.TR_31]: 'Hatay',
    [States.TR_76]: 'Igdir',
    [States.TR_32]: 'Isparta',
    [States.TR_34]: 'Istanbul',
    [States.TR_35]: 'Izmir',
    [States.TR_46]: 'Kahramanmaras',
    [States.TR_78]: 'Karabuk',
    [States.TR_70]: 'Karaman',
    [States.TR_36]: 'Kars',
    [States.TR_37]: 'Kastamonu',
    [States.TR_38]: 'Kayseri',
    [States.TR_79]: 'Kilis',
    [States.TR_71]: 'Kirikkale',
    [States.TR_39]: 'Kirklareli',
    [States.TR_40]: 'Kirsehir',
    [States.TR_41]: 'Kocaeli',
    [States.TR_42]: 'Konya',
    [States.TR_43]: 'Kutahya',
    [States.TR_44]: 'Malatya',
    [States.TR_45]: 'Manisa',
    [States.TR_47]: 'Mardin',
    [States.TR_33]: 'Mersin',
    [States.TR_48]: 'Mugla',
    [States.TR_49]: 'Mus',
    [States.TR_50]: 'Nevsehir',
    [States.TR_51]: 'Nigde',
    [States.TR_52]: 'Ordu',
    [States.TR_80]: 'Osmaniye',
    [States.TR_53]: 'Rize',
    [States.TR_54]: 'Sakarya',
    [States.TR_55]: 'Samsun',
    [States.TR_63]: 'Sanliurfa',
    [States.TR_56]: 'Siirt',
    [States.TR_57]: 'Sinop',
    [States.TR_73]: 'Sirnak',
    [States.TR_58]: 'Sivas',
    [States.TR_59]: 'Tekirdag',
    [States.TR_60]: 'Tokat',
    [States.TR_61]: 'Trabzon',
    [States.TR_62]: 'Tunceli',
    [States.TR_64]: 'Usak',
    [States.TR_65]: 'Van',
    [States.TR_77]: 'Yalova',
    [States.TR_66]: 'Yozgat',
    [States.TR_67]: 'Zonguldak',
    [States.TT_ARI]: 'Arima',
    [States.TT_CHA]: 'Chaguanas',
    [States.TT_CTT]: 'Couva-Tabaquite-Talparo',
    [States.TT_DMN]: 'Diego Martin',
    [States.TT_MRC]: 'Mayaro-Rio Claro',
    [States.TT_PED]: 'Penal-Debe',
    [States.TT_PTF]: 'Point Fortin',
    [States.TT_POS]: 'Port of Spain',
    [States.TT_PRT]: 'Princes Town',
    [States.TT_SFO]: 'San Fernando',
    [States.TT_SJL]: 'San Juan-Laventille',
    [States.TT_SGE]: 'Sangre Grande',
    [States.TT_SIP]: 'Siparia',
    [States.TT_TOB]: 'Tobago',
    [States.TT_TUP]: 'Tunapuna-Piarco',
    [States.TV_FUN]: 'Funafuti',
    [States.TW_CHA]: 'Changhua',
    [States.TW_CYQ]: 'Chiayi',
    [States.TW_HSQ]: 'Hsinchu',
    [States.TW_HUA]: 'Hualien',
    [States.TW_KHH]: 'Kaohsiung',
    [States.TW_KEE]: 'Keelung',
    [States.TW_KIN]: 'Kinmen',
    [States.TW_LIE]: 'Lienchiang',
    [States.TW_MIA]: 'Miaoli',
    [States.TW_NAN]: 'Nantou',
    [States.TW_NWT]: 'New Taipei',
    [States.TW_PEN]: 'Penghu',
    [States.TW_PIF]: 'Pingtung',
    [States.TW_TXG]: 'Taichung',
    [States.TW_TNN]: 'Tainan',
    [States.TW_TPE]: 'Taipei',
    [States.TW_TTT]: 'Taitung',
    [States.TW_TAO]: 'Taoyuan',
    [States.TW_ILA]: 'Yilan',
    [States.TW_YUN]: 'Yunlin',
    [States.TZ_01]: 'Arusha',
    [States.TZ_02]: 'Dar es Salaam',
    [States.TZ_03]: 'Dodoma',
    [States.TZ_27]: 'Geita',
    [States.TZ_04]: 'Iringa',
    [States.TZ_05]: 'Kagera',
    [States.TZ_06]: 'Kaskazini Pemba',
    [States.TZ_07]: 'Kaskazini Unguja',
    [States.TZ_28]: 'Katavi',
    [States.TZ_08]: 'Kigoma',
    [States.TZ_09]: 'Kilimanjaro',
    [States.TZ_11]: 'Kusini Unguja',
    [States.TZ_12]: 'Lindi',
    [States.TZ_26]: 'Manyara',
    [States.TZ_13]: 'Mara',
    [States.TZ_14]: 'Mbeya',
    [States.TZ_15]: 'Mjini Magharibi',
    [States.TZ_16]: 'Morogoro',
    [States.TZ_17]: 'Mtwara',
    [States.TZ_18]: 'Mwanza',
    [States.TZ_29]: 'Njombe',
    [States.TZ_19]: 'Pwani',
    [States.TZ_21]: 'Ruvuma',
    [States.TZ_22]: 'Shinyanga',
    [States.TZ_30]: 'Simiyu',
    [States.TZ_23]: 'Singida',
    [States.TZ_31]: 'Songwe',
    [States.TZ_24]: 'Tabora',
    [States.TZ_25]: 'Tanga',
    [States.UA_43]: 'Avtonomna Respublika Krym',
    [States.UA_71]: 'Cherkaska oblast',
    [States.UA_74]: 'Chernihivska oblast',
    [States.UA_77]: 'Chernivetska oblast',
    [States.UA_12]: 'Dnipropetrovska oblast',
    [States.UA_14]: 'Donetska oblast',
    [States.UA_26]: 'Ivano-Frankivska oblast',
    [States.UA_63]: 'Kharkivska oblast',
    [States.UA_65]: 'Khersonska oblast',
    [States.UA_68]: 'Khmelnytska oblast',
    [States.UA_35]: 'Kirovohradska oblast',
    [States.UA_30]: 'Kyiv',
    [States.UA_32]: 'Kyivska oblast',
    [States.UA_09]: 'Luhanska oblast',
    [States.UA_46]: 'Lvivska oblast',
    [States.UA_48]: 'Mykolaivska oblast',
    [States.UA_51]: 'Odeska oblast',
    [States.UA_53]: 'Poltavska oblast',
    [States.UA_56]: 'Rivnenska oblast',
    [States.UA_40]: 'Sevastopol',
    [States.UA_59]: 'Sumska oblast',
    [States.UA_61]: 'Ternopilska oblast',
    [States.UA_05]: 'Vinnytska oblast',
    [States.UA_07]: 'Volynska oblast',
    [States.UA_21]: 'Zakarpatska oblast',
    [States.UA_23]: 'Zaporizka oblast',
    [States.UA_18]: 'Zhytomyrska oblast',
    [States.UG_314]: 'Abim',
    [States.UG_301]: 'Adjumani',
    [States.UG_323]: 'Alebtong',
    [States.UG_315]: 'Amolatar',
    [States.UG_324]: 'Amudat',
    [States.UG_216]: 'Amuria',
    [States.UG_316]: 'Amuru',
    [States.UG_302]: 'Apac',
    [States.UG_303]: 'Arua',
    [States.UG_217]: 'Budaka',
    [States.UG_218]: 'Bududa',
    [States.UG_201]: 'Bugiri',
    [States.UG_420]: 'Buhweju',
    [States.UG_117]: 'Buikwe',
    [States.UG_219]: 'Bukedea',
    [States.UG_118]: 'Bukomansibi',
    [States.UG_220]: 'Bukwo',
    [States.UG_225]: 'Bulambuli',
    [States.UG_401]: 'Bundibugyo',
    [States.UG_402]: 'Bushenyi',
    [States.UG_202]: 'Busia',
    [States.UG_221]: 'Butaleja',
    [States.UG_120]: 'Buvuma',
    [States.UG_226]: 'Buyende',
    [States.UG_317]: 'Dokolo',
    [States.UG_121]: 'Gomba',
    [States.UG_304]: 'Gulu',
    [States.UG_403]: 'Hoima',
    [States.UG_417]: 'Ibanda',
    [States.UG_203]: 'Iganga',
    [States.UG_418]: 'Isingiro',
    [States.UG_204]: 'Jinja',
    [States.UG_318]: 'Kaabong',
    [States.UG_404]: 'Kabale',
    [States.UG_405]: 'Kabarole',
    [States.UG_213]: 'Kaberamaido',
    [States.UG_101]: 'Kalangala',
    [States.UG_222]: 'Kaliro',
    [States.UG_122]: 'Kalungu',
    [States.UG_102]: 'Kampala',
    [States.UG_205]: 'Kamuli',
    [States.UG_413]: 'Kamwenge',
    [States.UG_206]: 'Kapchorwa',
    [States.UG_406]: 'Kasese',
    [States.UG_207]: 'Katakwi',
    [States.UG_112]: 'Kayunga',
    [States.UG_407]: 'Kibaale',
    [States.UG_103]: 'Kiboga',
    [States.UG_227]: 'Kibuku',
    [States.UG_419]: 'Kiruhura',
    [States.UG_421]: 'Kiryandongo',
    [States.UG_408]: 'Kisoro',
    [States.UG_305]: 'Kitgum',
    [States.UG_319]: 'Koboko',
    [States.UG_306]: 'Kotido',
    [States.UG_208]: 'Kumi',
    [States.UG_228]: 'Kween',
    [States.UG_123]: 'Kyankwanzi',
    [States.UG_422]: 'Kyegegwa',
    [States.UG_415]: 'Kyenjojo',
    [States.UG_326]: 'Lamwo',
    [States.UG_307]: 'Lira',
    [States.UG_229]: 'Luuka',
    [States.UG_104]: 'Luwero',
    [States.UG_124]: 'Lwengo',
    [States.UG_114]: 'Lyantonde',
    [States.UG_223]: 'Manafwa',
    [States.UG_105]: 'Masaka',
    [States.UG_409]: 'Masindi',
    [States.UG_214]: 'Mayuge',
    [States.UG_209]: 'Mbale',
    [States.UG_410]: 'Mbarara',
    [States.UG_423]: 'Mitooma',
    [States.UG_115]: 'Mityana',
    [States.UG_308]: 'Moroto',
    [States.UG_309]: 'Moyo',
    [States.UG_106]: 'Mpigi',
    [States.UG_107]: 'Mubende',
    [States.UG_108]: 'Mukono',
    [States.UG_311]: 'Nakapiripirit',
    [States.UG_116]: 'Nakaseke',
    [States.UG_109]: 'Nakasongola',
    [States.UG_230]: 'Namayingo',
    [States.UG_224]: 'Namutumba',
    [States.UG_327]: 'Napak',
    [States.UG_310]: 'Nebbi',
    [States.UG_231]: 'Ngora',
    [States.UG_411]: 'Ntungamo',
    [States.UG_328]: 'Nwoya',
    [States.UG_321]: 'Oyam',
    [States.UG_312]: 'Pader',
    [States.UG_210]: 'Pallisa',
    [States.UG_110]: 'Rakai',
    [States.UG_425]: 'Rubirizi',
    [States.UG_412]: 'Rukungiri',
    [States.UG_111]: 'Sembabule',
    [States.UG_232]: 'Serere',
    [States.UG_426]: 'Sheema',
    [States.UG_215]: 'Sironko',
    [States.UG_211]: 'Soroti',
    [States.UG_212]: 'Tororo',
    [States.UG_113]: 'Wakiso',
    [States.UG_313]: 'Yumbe',
    [States.UG_330]: 'Zombo',
    [States.UM_95]: 'Palmyra Atoll',
    [States.US_AL]: 'Alabama',
    [States.US_AK]: 'Alaska',
    [States.US_AZ]: 'Arizona',
    [States.US_AR]: 'Arkansas',
    [States.US_CA]: 'California',
    [States.US_CO]: 'Colorado',
    [States.US_CT]: 'Connecticut',
    [States.US_DE]: 'Delaware',
    [States.US_DC]: 'District of Columbia',
    [States.US_FL]: 'Florida',
    [States.US_GA]: 'Georgia',
    [States.US_HI]: 'Hawaii',
    [States.US_ID]: 'Idaho',
    [States.US_IL]: 'Illinois',
    [States.US_IN]: 'Indiana',
    [States.US_IA]: 'Iowa',
    [States.US_KS]: 'Kansas',
    [States.US_KY]: 'Kentucky',
    [States.US_LA]: 'Louisiana',
    [States.US_ME]: 'Maine',
    [States.US_MD]: 'Maryland',
    [States.US_MA]: 'Massachusetts',
    [States.US_MI]: 'Michigan',
    [States.US_MN]: 'Minnesota',
    [States.US_MS]: 'Mississippi',
    [States.US_MO]: 'Missouri',
    [States.US_MT]: 'Montana',
    [States.US_NE]: 'Nebraska',
    [States.US_NV]: 'Nevada',
    [States.US_NH]: 'New Hampshire',
    [States.US_NJ]: 'New Jersey',
    [States.US_NM]: 'New Mexico',
    [States.US_NY]: 'New York',
    [States.US_NC]: 'North Carolina',
    [States.US_ND]: 'North Dakota',
    [States.US_OH]: 'Ohio',
    [States.US_OK]: 'Oklahoma',
    [States.US_OR]: 'Oregon',
    [States.US_PA]: 'Pennsylvania',
    [States.US_RI]: 'Rhode Island',
    [States.US_SC]: 'South Carolina',
    [States.US_SD]: 'South Dakota',
    [States.US_TN]: 'Tennessee',
    [States.US_TX]: 'Texas',
    [States.US_UT]: 'Utah',
    [States.US_VT]: 'Vermont',
    [States.US_VA]: 'Virginia',
    [States.US_WA]: 'Washington',
    [States.US_WV]: 'West Virginia',
    [States.US_WI]: 'Wisconsin',
    [States.US_WY]: 'Wyoming',
    [States.UY_AR]: 'Artigas',
    [States.UY_CA]: 'Canelones',
    [States.UY_CL]: 'Cerro Largo',
    [States.UY_CO]: 'Colonia',
    [States.UY_DU]: 'Durazno',
    [States.UY_FS]: 'Flores',
    [States.UY_FD]: 'Florida',
    [States.UY_LA]: 'Lavalleja',
    [States.UY_MA]: 'Maldonado',
    [States.UY_MO]: 'Montevideo',
    [States.UY_PA]: 'Paysandu',
    [States.UY_RN]: 'Rio Negro',
    [States.UY_RV]: 'Rivera',
    [States.UY_RO]: 'Rocha',
    [States.UY_SA]: 'Salto',
    [States.UY_SJ]: 'San Jose',
    [States.UY_SO]: 'Soriano',
    [States.UY_TA]: 'Tacuarembo',
    [States.UY_TT]: 'Treinta y Tres',
    [States.UZ_AN]: 'Andijon',
    [States.UZ_BU]: 'Buxoro',
    [States.UZ_FA]: "Farg'ona",
    [States.UZ_JI]: 'Jizzax',
    [States.UZ_NG]: 'Namangan',
    [States.UZ_NW]: 'Navoiy',
    [States.UZ_QA]: 'Qashqadaryo',
    [States.UZ_QR]: "Qoraqalpog'iston Respublikasi",
    [States.UZ_SA]: 'Samarqand',
    [States.UZ_SI]: 'Sirdaryo',
    [States.UZ_SU]: 'Surxondaryo',
    [States.UZ_TK]: 'Toshkent',
    [States.UZ_XO]: 'Xorazm',
    [States.VC_01]: 'Charlotte',
    [States.VC_06]: 'Grenadines',
    [States.VC_04]: 'Saint George',
    [States.VC_05]: 'Saint Patrick',
    [States.VE_Z]: 'Amazonas',
    [States.VE_B]: 'Anzoategui',
    [States.VE_C]: 'Apure',
    [States.VE_D]: 'Aragua',
    [States.VE_E]: 'Barinas',
    [States.VE_F]: 'Bolivar',
    [States.VE_G]: 'Carabobo',
    [States.VE_H]: 'Cojedes',
    [States.VE_Y]: 'Delta Amacuro',
    [States.VE_A]: 'Distrito Capital',
    [States.VE_I]: 'Falcon',
    [States.VE_J]: 'Guarico',
    [States.VE_X]: 'La Guaira',
    [States.VE_K]: 'Lara',
    [States.VE_L]: 'Merida',
    [States.VE_M]: 'Miranda',
    [States.VE_N]: 'Monagas',
    [States.VE_O]: 'Nueva Esparta',
    [States.VE_P]: 'Portuguesa',
    [States.VE_R]: 'Sucre',
    [States.VE_S]: 'Tachira',
    [States.VE_T]: 'Trujillo',
    [States.VE_U]: 'Yaracuy',
    [States.VE_V]: 'Zulia',
    [States.VN_44]: 'An Giang',
    [States.VN_43]: 'Ba Ria - Vung Tau',
    [States.VN_54]: 'Bac Giang',
    [States.VN_53]: 'Bac Kan',
    [States.VN_55]: 'Bac Lieu',
    [States.VN_56]: 'Bac Ninh',
    [States.VN_50]: 'Ben Tre',
    [States.VN_31]: 'Binh Dinh',
    [States.VN_57]: 'Binh Duong',
    [States.VN_58]: 'Binh Phuoc',
    [States.VN_40]: 'Binh Thuan',
    [States.VN_59]: 'Ca Mau',
    [States.VN_CT]: 'Can Tho',
    [States.VN_04]: 'Cao Bang',
    [States.VN_DN]: 'Da Nang',
    [States.VN_33]: 'Dak Lak',
    [States.VN_72]: 'Dak Nong',
    [States.VN_71]: 'Dien Bien',
    [States.VN_39]: 'Dong Nai',
    [States.VN_45]: 'Dong Thap',
    [States.VN_30]: 'Gia Lai',
    [States.VN_03]: 'Ha Giang',
    [States.VN_63]: 'Ha Nam',
    [States.VN_HN]: 'Ha Noi',
    [States.VN_23]: 'Ha Tinh',
    [States.VN_61]: 'Hai Duong',
    [States.VN_HP]: 'Hai Phong',
    [States.VN_73]: 'Hau Giang',
    [States.VN_SG]: 'Ho Chi Minh',
    [States.VN_14]: 'Hoa Binh',
    [States.VN_66]: 'Hung Yen',
    [States.VN_34]: 'Khanh Hoa',
    [States.VN_47]: 'Kien Giang',
    [States.VN_28]: 'Kon Tum',
    [States.VN_01]: 'Lai Chau',
    [States.VN_35]: 'Lam Dong',
    [States.VN_09]: 'Lang Son',
    [States.VN_02]: 'Lao Cai',
    [States.VN_41]: 'Long An',
    [States.VN_67]: 'Nam Dinh',
    [States.VN_22]: 'Nghe An',
    [States.VN_18]: 'Ninh Binh',
    [States.VN_36]: 'Ninh Thuan',
    [States.VN_68]: 'Phu Tho',
    [States.VN_32]: 'Phu Yen',
    [States.VN_24]: 'Quang Binh',
    [States.VN_27]: 'Quang Nam',
    [States.VN_29]: 'Quang Ngai',
    [States.VN_13]: 'Quang Ninh',
    [States.VN_25]: 'Quang Tri',
    [States.VN_52]: 'Soc Trang',
    [States.VN_05]: 'Son La',
    [States.VN_37]: 'Tay Ninh',
    [States.VN_20]: 'Thai Binh',
    [States.VN_69]: 'Thai Nguyen',
    [States.VN_21]: 'Thanh Hoa',
    [States.VN_26]: 'Thua Thien-Hue',
    [States.VN_46]: 'Tien Giang',
    [States.VN_51]: 'Tra Vinh',
    [States.VN_07]: 'Tuyen Quang',
    [States.VN_49]: 'Vinh Long',
    [States.VN_70]: 'Vinh Phuc',
    [States.VN_06]: 'Yen Bai',
    [States.VU_SEE]: 'Shefa',
    [States.VU_TAE]: 'Tafea',
    [States.VU_TOB]: 'Torba',
    [States.WF_SG]: 'Sigave',
    [States.WF_UV]: 'Uvea',
    [States.WS_AT]: 'Atua',
    [States.WS_FA]: "Fa'asaleleaga",
    [States.WS_TU]: 'Tuamasaga',
    [States.YE_AD]: 'Adan',
    [States.YE_AM]: 'Amran',
    [States.YE_AB]: 'Abyan',
    [States.YE_DA]: "Ad Dali'",
    [States.YE_BA]: "Al Bayda'",
    [States.YE_HU]: 'Al Hudaydah',
    [States.YE_JA]: 'Al Jawf',
    [States.YE_MW]: 'Al Mahwit',
    [States.YE_SA]: "Amanat al 'Asimah",
    [States.YE_DH]: 'Dhamar',
    [States.YE_HD]: 'Hadramawt',
    [States.YE_HJ]: 'Hajjah',
    [States.YE_IB]: 'Ibb',
    [States.YE_MA]: "Ma'rib",
    [States.YE_SD]: "Sa'dah",
    [States.YE_SN]: "San'a'",
    [States.YE_SH]: 'Shabwah',
    [States.YE_TA]: "Ta'izz",
    [States.ZA_EC]: 'Eastern Cape',
    [States.ZA_FS]: 'Free State',
    [States.ZA_GP]: 'Gauteng',
    [States.ZA_KZN]: 'Kwazulu-Natal',
    [States.ZA_LP]: 'Limpopo',
    [States.ZA_MP]: 'Mpumalanga',
    [States.ZA_NW]: 'North-West',
    [States.ZA_NC]: 'Northern Cape',
    [States.ZA_WC]: 'Western Cape',
    [States.ZM_02]: 'Central',
    [States.ZM_08]: 'Copperbelt',
    [States.ZM_03]: 'Eastern',
    [States.ZM_04]: 'Luapula',
    [States.ZM_09]: 'Lusaka',
    [States.ZM_10]: 'Muchinga',
    [States.ZM_06]: 'North-Western',
    [States.ZM_05]: 'Northern',
    [States.ZM_07]: 'Southern',
    [States.ZM_01]: 'Western',
    [States.ZW_BU]: 'Bulawayo',
    [States.ZW_HA]: 'Harare',
    [States.ZW_MA]: 'Manicaland',
    [States.ZW_MC]: 'Mashonaland Central',
    [States.ZW_ME]: 'Mashonaland East',
    [States.ZW_MW]: 'Mashonaland West',
    [States.ZW_MV]: 'Masvingo',
    [States.ZW_MN]: 'Matabeleland North',
    [States.ZW_MS]: 'Matabeleland South',
    [States.ZW_MI]: 'Midlands',
  };

  static CurrencySymbols: Record<string, string> = {
    ['ALL']: 'Lek',
    ['AFN']: '؋',
    ['ARS']: '$',
    ['AWG']: 'ƒ',
    ['AUD']: '$',
    ['AZN']: '₼',
    ['BSD']: '$',
    ['BBD']: '$',
    ['BYN']: 'Br',
    ['BZD']: 'BZ$',
    ['BMD']: '$',
    ['BOB']: '$b',
    ['BAM']: 'KM',
    ['BWP']: 'P',
    ['BGN']: 'лв',
    ['BRL']: 'R$',
    ['BND']: '$',
    ['KHR']: '៛',
    ['CAD']: '$',
    ['KYD']: '$',
    ['CLP']: '$',
    ['CNY']: '¥',
    ['COP']: '$',
    ['CRC']: '₡',
    ['HRK']: 'kn',
    ['CUP']: '₱',
    ['CZK']: 'Kč',
    ['DKK']: 'kr',
    ['DOP']: 'RD$',
    ['XCD']: '$',
    ['EGP']: '£',
    ['SVC']: '$',
    ['EUR']: '€',
    ['FKP']: '£',
    ['FJD']: '$',
    ['GHS']: '¢',
    ['GIP']: '£',
    ['GTQ']: 'Q',
    ['GGP']: '£',
    ['GYD']: '$',
    ['HNL']: 'L',
    ['HKD']: '$',
    ['HUF']: 'Ft',
    ['ISK']: 'kr',
    ['INR']: '₹',
    ['IDR']: 'Rp',
    ['IRR']: '﷼',
    ['IMP']: '£',
    ['ILS']: '₪',
    ['JMD']: 'J$',
    ['JPY']: '¥',
    ['JEP']: '£',
    ['KZT']: 'лв',
    ['KPW']: '₩',
    ['KGS']: 'лв',
    ['LAK']: '₭',
    ['LBP']: '£',
    ['LRD']: '$',
    ['MKD']: 'ден',
    ['MYR']: 'RM',
    ['MUR']: '₨',
    ['MXN']: '$',
    ['MNT']: '₮',
    ['MZN']: 'MT',
    ['NAD']: '$',
    ['NPR']: '₨',
    ['ANG']: 'ƒ',
    ['NZD']: '$',
    ['NIO']: 'C$',
    ['NGN']: '₦',
    ['NOK']: 'kr',
    ['OMR']: '﷼',
    ['PKR']: '₨',
    ['PAB']: 'B/.',
    ['PYG']: 'Gs',
    ['PEN']: 'S/.',
    ['PHP']: '₱',
    ['PLN']: 'zł',
    ['QAR']: '﷼',
    ['RON']: 'lei',
    ['RUB']: '₽',
    ['SHP']: '£',
    ['SAR']: '﷼',
    ['RSD']: 'Дин.',
    ['SCR']: '₨',
    ['SGD']: '$',
    ['SBD']: '$',
    ['SOS']: 'S',
    ['KRW']: '₩',
    ['ZAR']: 'R',
    ['LKR']: '₨',
    ['SEK']: 'kr',
    ['CHF']: 'CHF',
    ['SRD']: '$',
    ['SYP']: '£',
    ['TWD']: 'NT$',
    ['THB']: '฿',
    ['TTD']: 'TT$',
    ['TRY']: '₺',
    ['TVD']: '$',
    ['UAH']: '₴',
    ['AED']: 'د.إ',
    ['GBP']: '£',
    ['USD']: '$',
    ['UYU']: '$U',
    ['UZS']: 'лв',
    ['VEF']: 'Bs',
    ['VND']: '₫',
    ['YER']: '﷼',
    ['ZWD']: 'Z$',
  };

  static CountryCurrencies: Record<Countries, string> = {
    [Countries.Afghanistan]: 'AFN',
    [Countries.Albania]: 'ALL',
    [Countries.Algeria]: 'DZD',
    [Countries.Andorra]: 'EUR',
    [Countries.Angola]: 'AOA',
    [Countries.AntiguaAndDeps]: 'XCD',
    [Countries.Argentina]: 'ARS',
    [Countries.Armenia]: 'AMD',
    [Countries.Australia]: 'AUD',
    [Countries.Austria]: 'EUR',
    [Countries.Azerbaijan]: 'AZN',
    [Countries.Bahamas]: 'BSD',
    [Countries.Bahrain]: 'BHD',
    [Countries.Bangladesh]: 'BDT',
    [Countries.Barbados]: 'BBD',
    [Countries.Belarus]: 'BYN',
    [Countries.Belgium]: 'EUR',
    [Countries.Belize]: 'BZD',
    [Countries.Benin]: 'XOF',
    [Countries.Bhutan]: 'BTN',
    [Countries.Bolivia]: 'BOB',
    [Countries.BosniaHerzegovina]: 'BAM',
    [Countries.Botswana]: 'BWP',
    [Countries.Brazil]: 'BRL',
    [Countries.Brunei]: 'BND',
    [Countries.Bulgaria]: 'BGN',
    [Countries.Burkina]: 'XOF',
    [Countries.Burundi]: 'BIF',
    [Countries.Cambodia]: 'KHR',
    [Countries.Cameroon]: 'XAF',
    [Countries.Canada]: 'CAD',
    [Countries.CapeVerde]: 'CVE',
    [Countries.CentralfricanRep]: 'XAF',
    [Countries.Chad]: 'XAF',
    [Countries.Chile]: 'CLP',
    [Countries.China]: 'CNY',
    [Countries.Colombia]: 'COP',
    [Countries.Comoros]: 'KMF',
    [Countries.DemocraticRepCostaRica]: 'CRC',
    [Countries.Croatia]: 'HRK',
    [Countries.Cuba]: 'CUP',
    [Countries.Cyprus]: 'EUR',
    [Countries.CzechRepublic]: 'CZK',
    // [Countries.DemocraticRepCostaRica]: "CDF",
    [Countries.Denmark]: 'DKK',
    [Countries.Djibouti]: 'DJF',
    [Countries.Dominica]: 'XCD',
    [Countries.DominicanRepublic]: 'DOP',
    [Countries.EastTimor]: 'USD',
    [Countries.Ecuador]: 'USD',
    [Countries.Egypt]: 'EGP',
    [Countries.ElSalvador]: 'USD',
    [Countries.EquatorialGuinea]: 'XAF',
    [Countries.Eritrea]: 'ERN',
    [Countries.Estonia]: 'EUR',
    [Countries.Ethiopia]: 'ETB',
    [Countries.Fiji]: 'FJD',
    [Countries.Finland]: 'EUR',
    [Countries.France]: 'EUR',
    [Countries.Gabon]: 'XAF',
    [Countries.Gambia]: 'GMD',
    [Countries.Georgia]: 'GEL',
    [Countries.Germany]: 'EUR',
    [Countries.Ghana]: 'GHS',
    [Countries.Greece]: 'EUR',
    [Countries.Grenada]: 'XCD',
    [Countries.Guatemala]: 'GTQ',
    [Countries.Guinea]: 'GNF',
    // [Countries.Guinea]: "XOF",
    [Countries.Guyana]: 'GYD',
    [Countries.Haiti]: 'HTG',
    [Countries.Honduras]: 'HNL',
    [Countries.Hungary]: 'HUF',
    [Countries.Iceland]: 'ISK',
    [Countries.India]: 'INR',
    [Countries.Indonesia]: 'IDR',
    [Countries.Iran]: 'IRR',
    [Countries.Iraq]: 'IQD',
    [Countries.Ireland]: 'EUR',
    [Countries.Israel]: 'ILS',
    [Countries.Italy]: 'EUR',
    [Countries.IvoryCoast]: 'XOF',
    [Countries.Jamaica]: 'JMD',
    [Countries.Japan]: 'JPY',
    [Countries.Jordan]: 'JOD',
    [Countries.Kazakhstan]: 'KZT',
    [Countries.Kenya]: 'KES',
    [Countries.Kiribati]: 'AUD',
    [Countries.KoreaNorth]: 'KPW',
    [Countries.KoreaSouth]: 'KRW',
    [Countries.Kosovo]: 'EUR',
    [Countries.Kuwait]: 'KWD',
    [Countries.Kyrgyzstan]: 'KGS',
    [Countries.Laos]: 'LAK',
    [Countries.Latvia]: 'EUR',
    [Countries.Lebanon]: 'LBP',
    [Countries.Lesotho]: 'LSL',
    [Countries.Liberia]: 'LRD',
    [Countries.Libya]: 'LYD',
    [Countries.Liechtenstein]: 'CHF',
    [Countries.Lithuania]: 'EUR',
    [Countries.Luxembourg]: 'EUR',
    [Countries.Madagascar]: 'MGA',
    [Countries.Malawi]: 'MWK',
    [Countries.Malaysia]: 'MYR',
    [Countries.Maldives]: 'MVR',
    [Countries.Mali]: 'XOF',
    [Countries.Malta]: 'EUR',
    [Countries.MarshallIslands]: 'USD',
    [Countries.Mauritania]: 'MRO',
    [Countries.Mauritius]: 'MUR',
    [Countries.Mexico]: 'MXN',
    [Countries.Micronesia]: 'USD',
    [Countries.Moldova]: 'MDL',
    [Countries.Monaco]: 'EUR',
    [Countries.Mongolia]: 'MNT',
    [Countries.Montenegro]: 'EUR',
    [Countries.Morocco]: 'MAD',
    [Countries.Mozambique]: 'MZN',
    [Countries.Myanmar]: 'MMK',
    [Countries.Namibia]: 'NAD',
    [Countries.Nauru]: 'AUD',
    [Countries.Nepal]: 'NPR',
    [Countries.Netherlands]: 'EUR',
    [Countries.NewZealand]: 'NZD',
    [Countries.Nicaragua]: 'NIO',
    [Countries.Niger]: 'XOF',
    [Countries.Nigeria]: 'NGN',
    [Countries.Macedonia]: 'MKD',
    [Countries.Norway]: 'NOK',
    [Countries.Oman]: 'OMR',
    [Countries.Pakistan]: 'PKR',
    [Countries.Palau]: 'USD',
    [Countries.Panama]: 'PAB',
    [Countries.PapuaNewGuinea]: 'PGK',
    [Countries.Paraguay]: 'PYG',
    [Countries.Peru]: 'PEN',
    [Countries.Philippines]: 'PHP',
    [Countries.Poland]: 'PLN',
    [Countries.Portugal]: 'EUR',
    [Countries.Qatar]: 'QAR',
    [Countries.Congo]: 'XAF',
    [Countries.Romania]: 'RON',
    [Countries.RussianFederation]: 'RUB',
    [Countries.Rwanda]: 'RWF',
    [Countries.StKittsNevis]: 'XCD',
    [Countries.StLucia]: 'XCD',
    [Countries.SaintVincenttheGrenadines]: 'XCD',
    [Countries.Samoa]: 'WST',
    [Countries.SanMarino]: 'EUR',
    [Countries.SaoTomePrincipe]: 'STD',
    [Countries.SaudiArabia]: 'SAR',
    [Countries.Senegal]: 'XOF',
    [Countries.Serbia]: 'RSD',
    [Countries.Seychelles]: 'SCR',
    [Countries.SierraLeone]: 'SLL',
    [Countries.Singapore]: 'SGD',
    [Countries.Slovakia]: 'EUR',
    [Countries.Slovenia]: 'EUR',
    [Countries.SolomonIslands]: 'SBD',
    [Countries.Somalia]: 'SOS',
    [Countries.SouthAfrica]: 'ZAR',
    [Countries.SouthSudan]: 'SSP',
    [Countries.Spain]: 'EUR',
    [Countries.SriLanka]: 'LKR',
    [Countries.Sudan]: 'SDG',
    [Countries.Suriname]: 'SRD',
    [Countries.Sweden]: 'SEK',
    [Countries.Switzerland]: 'CHF',
    [Countries.Syria]: 'SYP',
    [Countries.Taiwan]: 'TWD',
    [Countries.Tajikistan]: 'TJS',
    [Countries.Tanzania]: 'TZS',
    [Countries.Thailand]: 'THB',
    [Countries.Togo]: 'XOF',
    [Countries.Tonga]: 'TOP',
    [Countries.TrinidadTobago]: 'TTD',
    [Countries.Tunisia]: 'TND',
    [Countries.Turkey]: 'TRY',
    [Countries.Turkmenistan]: 'TMT',
    [Countries.Tuvalu]: 'AUD',
    [Countries.Uganda]: 'UGX',
    [Countries.Ukraine]: 'UAH',
    [Countries.UnitedArabEmirates]: 'AED',
    [Countries.UnitedKingdom]: 'GBP',
    [Countries.UnitedStates]: 'USD',
    [Countries.Uruguay]: 'UYU',
    [Countries.Uzbekistan]: 'UZS',
    [Countries.Vanuatu]: 'VUV',
    [Countries.VaticanCity]: 'EUR',
    [Countries.Venezuela]: 'VEF',
    [Countries.Vietnam]: 'VND',
    [Countries.Yemen]: 'YER',
    [Countries.Zambia]: 'ZMW',
    [Countries.Zimbabwe]: 'USD',
    [Countries.GuineaBissau]: 'XOF',
    [Countries.Swaziland]: 'ZAR',
  };

  //#endregion

  //#region ADMIN

  static addNewCompany = 'add new company';
  static newCompanyPlaceholder = 'enter company name';
  static newCompanyAdminEmail = 'admin email';
  static newCompanyAdminEmailPlaceholder = 'enter admin email';
  static newCompanyAdminPassword = 'admin password';
  static newCompanyAdminPasswordPlaceholder = 'enter admin password';
  static newCompanyAdminFirstName = 'admin first name';
  static newCompanyAdminFirstNamePlaceholder = 'enter admin first name';
  static newCompanyAdminSecondName = 'admin second name';
  static newCompanyAdminSecondNamePlaceholder = 'enter admin second name';
  static deleteCompany = 'delete company';

  //#region GLOBAL NOTIFICATIONS
  static message = 'message';
  static text = 'text';
  static dateFrom = 'date from';
  static dateTo = 'date to';
  static tomorrow = 'tomorrow';
  static createdBy = 'created by';
  static apply = 'apply';
  static reset = 'reset';

  static addGlobalNotification = 'add global notification';
  //#endregion

  //#region COMPANY
  static description = 'description';
  static addCompany = 'add company';
  //#endregion

  //#endregion

  //#region USER STAT

  static printHours = 'print hours';

  //#endregion
}
